import { router } from '@components/router';
import { UserProfileIcon } from '@components/avatars';
import { IcoCalendar, IcoVideoCamera } from '@components/icons';
import { ScheduleSummary } from './schedule-summary';
import { LoadedProps } from 'client/lib/loaders';
import { loadMeeting } from './store';
import { BulletIco } from './bullet-ico';
import { useDocumentTitle } from 'client/utils/use-document-title';

function MeetingUser({
  user,
  isHost,
}: {
  user: { name: string; email: string };
  isHost?: boolean;
}) {
  return (
    <li class="flex items-center gap-4">
      <UserProfileIcon user={user} size="size-10" />
      <span class="flex flex-col">
        <span>
          {user.name}
          {isHost && (
            <span class="bg-blue-500 text-white px-1 p-0.5 text-xs font-semibold rounded ml-2">
              Host
            </span>
          )}
        </span>
        <a class="text-inherit" href={`mailto:${encodeURIComponent(user.email)}`}>
          {user.email}
        </a>
      </span>
    </li>
  );
}

export function Page(props: LoadedProps<typeof loadMeeting>) {
  const { meeting } = props.state;
  const schedule = meeting.schedule!;
  const attendee = meeting.attendee!;

  useDocumentTitle(['Meeting scheduled!']);

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-2xl flex flex-col gap-6">
        <header>
          <h1 class="text-2xl font-semibold">Meeting scheduled!</h1>
          <p class="text-gray-500">
            We sent a calendar invitation and meeting details to <em>{attendee.email}</em>.
          </p>
        </header>
        <section class="flex flex-col border-y py-6 gap-8">
          <div class="flex flex-col gap-4">
            <h2 class="font-semibold text-lg text-gray-700">{meeting.title}</h2>

            <BulletIco Ico={IcoCalendar} multiline>
              <ScheduleSummary schedule={schedule} hour12={meeting.hour12} />
            </BulletIco>

            <BulletIco Ico={IcoVideoCamera}>{meeting.location}</BulletIco>
          </div>

          <ul class="leading-snug flex flex-col gap-6 col-span-3">
            <MeetingUser user={meeting.host} isHost />
            <MeetingUser user={attendee} />
          </ul>
        </section>
        <footer>
          You can{' '}
          <a href="/ruzcal" class="text-indigo-600 underline">
            reschedule
          </a>{' '}
          or{' '}
          <button type="button" class="text-indigo-600 underline">
            cancel
          </button>{' '}
          any time before the meeting starts.
        </footer>
      </section>
    </div>
  );
}

router.add({
  isPublic: true,
  url: 'ruzcal/bookings/:bookingId',
  load: loadMeeting,
  render: Page,
});
