import { rpx } from 'client/lib/rpx-client';
import { RouteProps } from '@components/router';

export const store = rpx.branding;

export async function load() {
  const [brands, schemes] = await Promise.all([store.getBrands(), store.getFontsAndColors()]);
  return { ...schemes, brands };
}

export type Data = Awaited<ReturnType<typeof load>>;
export type Brand = Data['brands'][0];
export type Props = RouteProps<Data>;
