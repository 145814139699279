import { AsyncForm, FormGroup, InputLabel } from '@components/async-form';
import { useMinidoc } from '@components/minidoc';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useIntl } from 'shared/intl/use-intl';
import { IcoArrowLeft, IcoEye } from '@components/icons';
import { ManualDom } from '@components/manual-dom';
import { showUpsellPreviewModal } from './checkout-modal';
import { useEffect, useState } from 'preact/hooks';
import { WizardSubtitle } from './upsell-wizard';
import { Offer, Upsell } from './types';
import { BtnSecondary } from '@components/buttons';
import { Spinner } from '@components/spinner';
import { showError } from '@components/app-error';

type Props = {
  upsell: Upsell;
  state: Offer;
  setState(fn: (o: Offer) => Offer): void;
  onSave(offer: Offer): Promise<unknown>;
};

export function EditOfferSalesForm({ state, setState, upsell, onSave }: Props) {
  const [isSaving, setIsSaving] = useState(false);
  const intl = useIntl();
  const editor = useMinidoc({
    doc: state.content || '',
    middleware: () => [
      placeholder('Promotional text and content...'),
      minidocToolbar([...defaultToolbarActions, toolbarDropdown({ intl, compact: true })]),
      cardMiddleware([mediaCard]),
      mediaMiddleware(),
    ],
    onChange: (content) => setState((s) => ({ ...s, content })),
  });

  useEffect(() => {
    editor.root.focus();
  }, []);

  return (
    <>
      <WizardSubtitle>Edit the sales copy for this offer</WizardSubtitle>
      <AsyncForm
        class="flex flex-col gap-6"
        onSubmit={async () => {
          try {
            setIsSaving(true);
            return await onSave({
              productId: state.product!.id,
              priceId: state.price!.id,
              upsellId: upsell.id,
              content: state.content,
              price: state.price!,
              product: state.product!,
              acceptText: state.acceptText || '',
              rejectText: state.rejectText || '',
              listPrice: state.listPrice,
            });
          } catch (err) {
            setIsSaving(false);
            showError(err);
          }
        }}
      >
        <div class="flex flex-col border rounded-md ring-2 ring-transparent focus-within:ring-indigo-400">
          <header class="border-b flex">
            <ManualDom el={editor.toolbar.root} />
          </header>
          <ManualDom
            class="flex-grow mini-padded [&>*]:min-h-40 [&>*]:p-3.5 [&>*]:py-10"
            el={editor.root}
          />
        </div>
        <FormGroup prop="acceptText" class="w-1/2">
          <InputLabel>Accept button text (optional)</InputLabel>
          <input
            placeholder="Add to cart"
            name="acceptText"
            class="ruz-input"
            value={state.acceptText}
            onInput={(e: any) => setState((s) => ({ ...s, acceptText: e.target.value }))}
          />
        </FormGroup>
        <FormGroup prop="rejectText" class="w-1/2">
          <InputLabel>Reject button text (optional)</InputLabel>
          <input
            placeholder="No thanks!"
            name="rejectText"
            class="ruz-input"
            value={state.rejectText}
            onInput={(e: any) => setState((s) => ({ ...s, rejectText: e.target.value }))}
          />
        </FormGroup>

        <footer class="flex items-center justify-between pt-4 sm:m-0 sm:shadow-none sm:px-0 sticky p-4 -mx-4 -bottom-10 inset-x-0 bg-white shadow-top">
          <button disabled={isSaving} class="rounded-full bg-indigo-600 text-white relative">
            <span class={`inline-flex items-center px-4 py-2 gap-2 ${isSaving ? 'invisible' : ''}`}>
              Save offer
              <IcoArrowLeft class="rotate-180" />
            </span>
            {isSaving && (
              <span class="absolute inset-0 flex items-center justify-center">
                <Spinner />
              </span>
            )}
          </button>
          <BtnSecondary
            class="gap-2 pl-3 pr-4 rounded-full"
            onClick={() =>
              showUpsellPreviewModal({
                ...upsell,
                offers: [state as Required<typeof state>],
              })
            }
          >
            <IcoEye />
            Preview
          </BtnSecondary>
        </footer>
      </AsyncForm>
    </>
  );
}
