import { IcoDownload } from '@components/icons';
import { useMemo } from 'preact/hooks';
import { Downloads } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';

export function DownloadsViewer({ downloads }: { downloads?: Downloads }) {
  const intl = useIntl();
  const downloadable = useMemo(() => downloads?.files?.filter((f) => f.isEnabled), [downloads]);

  if (!downloadable?.length) {
    return null;
  }

  return (
    <div class="border rounded-lg overflow-hidden text-sm dark:border-none dark:bg-gray-800">
      <header class="bg-gray-50 dark:bg-gray-700 border-b dark:border-none p-4 px-6">
        <h2 class="font-semibold text-gray-700 dark:text-gray-200">{intl('Downloads')}</h2>
      </header>
      <div class="p-4 flex flex-col">
        {downloadable.map((dl) => (
          <a
            key={dl.url}
            class="p-2 flex items-center gap-2 relative rounded text-gray-700 dark:text-gray-200 hover:bg-gray-600 hover:text-white"
            target="_blank"
            rel="noopener noreferrer"
            href={`${dl.url}?download=${encodeURIComponent(dl.name)}`}
          >
            <IcoDownload />
            {dl.name}
          </a>
        ))}
      </div>
    </div>
  );
}
