import { AutosizeText } from '@components/autosize-text';
import { useCurrentTenant } from '@components/router/session-context';
import { Toggle } from '@components/toggle';
import { DiscussionState } from './lesson-editor';
import { LessonPane } from './lesson-pane';
import { useMinidoc } from '@components/minidoc';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { ManualDom } from '@components/manual-dom';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useIntl } from 'shared/intl/use-intl';
import { mediaCard, mediaMiddleware } from '@components/media-card';

interface Props {
  state: DiscussionState;
  setState: (state: Partial<DiscussionState>) => void;
}

export function DiscussionEditor({ state, setState }: Props) {
  const { terminology } = useCurrentTenant();
  const intl = useIntl();
  const editor = useMinidoc({
    doc: state.description || '',
    middleware: () => [
      placeholder(`${terminology.Discussion} description`),
      minidocToolbar([
        ...defaultToolbarActions.filter((x) => x.id !== 'h1' && x.id !== 'h3'),
        toolbarDropdown({
          intl,
          mediaOnly: true,
        }),
      ]),
      cardMiddleware([mediaCard]),
      mediaMiddleware(),
    ],
    onChange(doc) {
      setState({ description: doc });
    },
  });

  return (
    <LessonPane
      isVisible
      hide={() =>
        setState({
          isEnabled: false,
        })
      }
      title={terminology.discussion}
    >
      <div class="mt-2 mb-4">
        <label>
          <Toggle
            checked={state.required}
            onClick={() => setState({ required: !state.required })}
            class="h-4 w-8 mr-2"
          />
          Require a comment before continuing in the course
        </label>
      </div>
      <AutosizeText
        name="discussionPrompt"
        class="ruz-input p-4"
        containerClass="mb-4"
        placeholder={`${terminology.Discussion} prompt`}
        maxLength={1024}
        value={state.prompt}
        onInput={(e: any) => setState({ prompt: e.target.value })}
      />
      <div class="flex flex-col items-center border-2 border-gray-200 focus-within:border-indigo-500 rounded p-2">
        <ManualDom el={editor.toolbar.root} />
        <EditorWrapper editor={editor} class="ruz-input p-4" />
      </div>
      <div class="text-gray-500 flex mt-4">
        <span class="pt-0.5 pr-2">
          <span class="bg-gray-400 w-4 h-4 rounded-full text-gray-50 inline-flex items-center justify-center font-serif">
            <em>i</em>
          </span>
        </span>
        This {terminology.discussion} prompt and description will display below your{' '}
        {terminology.lesson} content. Students can reply with text, images, videos, or file
        attachments.
      </div>
    </LessonPane>
  );
}
