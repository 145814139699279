import { router } from '@components/router';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { useMemo } from 'preact/hooks';
import { ConfirmBooking } from './confirm-booking';
import { toQueryString } from 'shared/urls';
import { ScheduleBooking } from './schedule-booking';
import { loadSchedule, parseRouteSchedule } from './store';
import { LoadedProps } from 'client/lib/loaders';
import { substateUpdater } from 'client/lib/hooks';

type Pane = 'date' | 'confirm';

export function Page(props: LoadedProps<typeof loadSchedule>) {
  const { meeting } = props.state;
  const schedule = useMemo(() => parseRouteSchedule(props.route.params), [props.route.params]);
  const pane: Pane =
    props.route.params.pane !== 'date' && props.route.params.start ? 'confirm' : 'date';

  // We're not supposed to mutate state, but this keeps the schedule in sync
  // with the URL without causing a redraw loop.
  meeting.schedule = schedule;

  useDocumentTitle([meeting.title]);

  if (pane === 'confirm' && meeting.schedule) {
    return (
      <ConfirmBooking
        {...meeting}
        onBack={() => router.goto(toQueryString({ ...props.route.params, pane: 'date' }, '?'))}
      />
    );
  }

  return (
    <ScheduleBooking
      meeting={meeting}
      setMeeting={substateUpdater(
        props.setState,
        (x) => x.meeting,
        (x, meeting) => ({ ...x, meeting }),
      )}
    />
  );
}

router.add({
  isPublic: true,
  url: 'ruzcal',
  load: loadSchedule,
  render: Page,
});
