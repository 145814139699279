import { Dispatch } from 'client/lib/hooks';
import { produce } from 'immer';
import { Reducer } from 'preact/hooks';
import { Member } from 'server/types';
import { rpx } from 'client/lib/rpx-client';

export interface Props {
  courseId: string;
  userId?: string;
}

export interface State {
  /**
   * User list.
   */
  users: Member[];

  /**
   * The cursor in our paginated list of users.
   */
  cursor?: string;

  /*
   * The user list is loading.
   */
  loading: boolean;

  /*
   * It means the the feed has more items to display.
   */
  hasMore: boolean;
}

export const initialState: State = {
  users: [],
  loading: true,
  hasMore: true,
};

type LoadedPayload = {
  users: Member[];
  cursor?: string;
  shouldReset: boolean;
  hasMore: boolean;
};

export type Action =
  | { type: 'loaded'; payload: LoadedPayload }
  | { type: 'toggleLoading'; payload: boolean };
export type Dispatcher = Dispatch<Action>;

/*
 * This is the requested member count in each batch.
 */
const PAGE_COUNT = 15;

export const reducer: Reducer<State, Action> = produce((state: State, action: Action) => {
  switch (action.type) {
    case 'loaded': {
      const { users, hasMore, shouldReset } = action.payload;

      if (shouldReset) {
        state.users = users;
      } else {
        state.users = state.users.concat(users);
      }

      state.cursor = action.payload.cursor;
      state.loading = false;
      state.hasMore = hasMore;
      break;
    }
    case 'toggleLoading': {
      state.loading = action.payload;
      break;
    }
  }
});

export async function searchMembers(
  courseId: UUID,
  searchTerm: string,
  dispatch: Dispatch<Action>,
) {
  dispatch({
    type: 'toggleLoading',
    payload: true,
  });
  try {
    const result = await rpx.members.getCourseMembers({
      courseId,
      limit: PAGE_COUNT,
      search: searchTerm,
    });
    dispatch({
      type: 'loaded',
      payload: {
        users: result.users,
        hasMore: result.hasMore,
        cursor: result.cursor,
        // Keep the user list until the search result arrives.
        // This prevents the list flickering that happens
        // when the responses arrive quickly.
        shouldReset: true,
      },
    });
  } finally {
    dispatch({
      type: 'toggleLoading',
      payload: false,
    });
  }
}

interface LoadNextPageOpts {
  courseId: UUID;
  searchTerm?: string;
  cursor?: string;
  dispatch: Dispatch<Action>;
}

export async function loadNextPage({ courseId, cursor, searchTerm, dispatch }: LoadNextPageOpts) {
  dispatch({
    type: 'toggleLoading',
    payload: true,
  });

  try {
    const result = await rpx.members.getCourseMembers({
      courseId,
      cursor,
      limit: PAGE_COUNT,
      search: searchTerm,
    });
    dispatch({
      type: 'loaded',
      payload: {
        users: result.users,
        hasMore: result.hasMore,
        cursor: result.cursor,
        shouldReset: false,
      },
    });
  } finally {
    dispatch({
      type: 'toggleLoading',
      payload: false,
    });
  }
}
