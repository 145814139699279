import type { h } from 'preact';
import { router, useRouteParams } from '@components/router';
import {
  IcoAcademicCap,
  IcoBook,
  IcoChartBar,
  IcoClock,
  IcoFolder,
  IcoHome,
  IcoList,
  IcoRuzukuLogo,
  SVGProps,
} from '@components/icons';
import { UpgradeToProWidget } from './upgrade-to-pro-widget';
import { GuideOverViewPage } from './guide-overview-page';
import { EmptyPage } from '@components/empty-page';
import { SearchBox } from '@components/search-box';
import { UserMenu } from '@components/nav/user-menu';
import { Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { SlideOver } from '@components/slide-over';

type HomepageTabs = 'dashboard' | 'courses' | 'products' | 'bundles' | 'upsells';

function NavLink(props: {
  isSelected: boolean;
  href: string;
  name: string;
  Icon(p: SVGProps): h.JSX.Element;
}) {
  return (
    <li class="relative">
      {props.isSelected && (
        <span class="absolute inset-y-0 -left-2 w-[3px] rounded-tr-xl rounded-br-xl bg-indigo-600"></span>
      )}
      <a
        href={props.href}
        class={`group flex gap-x-3 rounded-md px-4 py-2 text-sm/6 ${
          props.isSelected
            ? 'text-indigo-600 font-semibold'
            : 'text-gray-800 hover:bg-gray-50 hover:text-indigo-600'
        }`}
      >
        <props.Icon
          aria-hidden="true"
          class={`h-5 w-5 shrink-0 ${
            props.isSelected ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
          }`}
        />
        {props.name}
      </a>
    </li>
  );
}

function NavBarContent({ activeTab, hidePromo }: { activeTab: HomepageTabs; hidePromo?: boolean }) {
  return (
    <div class="flex h-full min-h-0 flex-col">
      <div class="flex h-16 shrink-0 items-center p-4">
        <IcoRuzukuLogo class="h-6" />
      </div>
      <nav class="flex flex-1 flex-col p-4">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <NavLink
                isSelected={activeTab === 'dashboard'}
                href="/guide-dashboard"
                name="Dashboard"
                Icon={IcoHome}
              />
              <NavLink
                isSelected={activeTab === 'courses'}
                href="/guide-dashboard/courses"
                name="Courses"
                Icon={IcoAcademicCap}
              />
              <NavLink
                isSelected={activeTab === 'products'}
                href="/guide-dashboard/products"
                name="Products"
                Icon={IcoBook}
              />
              <NavLink
                isSelected={activeTab === 'bundles'}
                href="/guide-dashboard/bundles"
                name="Bundles"
                Icon={IcoFolder}
              />
              <NavLink
                isSelected={activeTab === 'upsells'}
                href="/guide-dashboard/upsells"
                name="Upsells"
                Icon={IcoChartBar}
              />
            </ul>
          </li>
        </ul>
        {!hidePromo && <UpgradeToProWidget />}
      </nav>
    </div>
  );
}

function Page() {
  const params = useRouteParams();
  const activeTab = (params.tab || 'dashboard') as HomepageTabs;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div class="relative isolate flex min-h-svh w-full max-lg:flex-col lg:bg-gray-100">
      {isMobileMenuOpen && (
        <SlideOver
          position="left"
          contentContainerClass="mr-16"
          close={() => setIsMobileMenuOpen(false)}
        >
          <NavBarContent activeTab={activeTab} hidePromo />
        </SlideOver>
      )}
      <div class="hidden lg:flex lg:fixed lg:inset-y-0 lg:z-50 left-0 w-64 bg-white">
        <NavBarContent activeTab={activeTab} />
      </div>
      <main class="flex flex-1 flex-col p-4 lg:min-w-0 lg:pl-64 lg:pr-2 lg:pt-2 bg-gray-50">
        <div class="mx-auto lg:max-w-6xl">
          <header class="flex gap-4 py-8 pb-0">
            <Button class="lg:hidden" onClick={() => setIsMobileMenuOpen(true)}>
              <IcoList class="w-6 h-6 opacity-75" />
            </Button>
            <div class="grow">
              <SearchBox containerClass="lg:w-72" placeholder="Quick search" />
            </div>
            <UserMenu />
          </header>
          <div class="grow py-6 lg:rounded-lg lg:py-8 lg:shadow-sm">
            {activeTab === 'dashboard' && <GuideOverViewPage />}
            {activeTab !== 'dashboard' && (
              <EmptyPage
                Ico={IcoClock}
                title="Coming Soon"
                description="This page is still under construction. Check back soon!"
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

router.add({
  url: '/guide-dashboard',
  render: Page,
});

router.add({
  url: '/guide-dashboard/:tab',
  render: Page,
});
