import { IcoCoursePlaceholder } from '@components/icons';
import { useImageUrl } from 'client/utils/cdn';

type Props = {
  image?: string;
  class?: string;
  size?: string;
  maxWidth?: number;
};

export function CourseImage({ image, maxWidth, size = 'size-24', class: className = '' }: Props) {
  const imageUrl = useImageUrl(image, { maxWidth });

  if (!imageUrl) {
    return <IcoCoursePlaceholder class={`${size} ${className}`} />;
  }

  return <img class={`rounded shadow-lg ${size} ${className}`} src={imageUrl} />;
}

export function CourseLogo({ image, size = 'max-w-72 max-h-14', class: className = '' }: Props) {
  const imageUrl = useImageUrl(image);

  if (!imageUrl) {
    return <IcoCoursePlaceholder class={`${size} ${className}`} />;
  }

  return <img class={`rounded-lg overflow-hidden ${size} ${className}`} src={imageUrl} />;
}
