import { produce } from 'immer';
import { Reducer } from 'preact/hooks';
import { Dispatch } from 'client/lib/hooks';
import { Meeting } from 'server/types';

export interface State {
  meetings: Meeting[];
}

export type UpdateAction = {
  type: 'update';
  payload: { id: UUID } & Partial<Meeting>;
};

export type Action =
  | {
      type: 'update';
      payload: { id: UUID } & Partial<Meeting>;
    }
  | {
      type: 'add';
      payload: { meeting: Meeting };
    }
  | {
      type: 'delete';
      payload: { id: UUID };
    };

export type Dispatcher = Dispatch<Action>;

export const initialState: State = {
  meetings: [],
};

export const reducer: Reducer<State, Action> = produce((state: State, action: Action) => {
  switch (action.type) {
    case 'add': {
      state.meetings.push(action.payload.meeting);
      state.meetings.sort((a, b) => {
        if (a.scheduledAt === b.scheduledAt) {
          return 0;
        }
        if (!a.scheduledAt) {
          return -1;
        }
        if (!b.scheduledAt) {
          return 1;
        }
        return a.scheduledAt > b.scheduledAt ? 1 : -1;
      });
      break;
    }
    case 'update': {
      const index = state.meetings.findIndex((w) => w.id === action.payload.id);
      state.meetings[index] = {
        ...state.meetings[index],
        ...action.payload,
      };
      break;
    }
    case 'delete': {
      state.meetings = state.meetings.filter((w) => w.id !== action.payload.id);
      break;
    }
    default:
      return state;
  }
});
