/**
 * The empty screen for prices and coupon pages.
 */
import { ChecklistDialog } from '@components/course-checklist/checklist-dialog';
import { EmptyPage } from '@components/empty-page';
import { IcoCreditCard, IcoTag } from '@components/icons';
import { useRouteParams } from '@components/router';

interface Props {
  supportsStripe?: boolean;
  supportsPaypal?: boolean;
  hasDisabled?: boolean;
  newUrl: string;
}

export function EmptyPriceScreen(props: Props) {
  const { tour } = useRouteParams();

  if (!props.supportsStripe && !props.supportsPaypal) {
    return (
      <EmptyPage
        Ico={IcoCreditCard}
        centeredDescription
        actionText="Or configure a payment provider"
        description={
          <div class="flex flex-col space-y-4 relative">
            <p>You haven't configured any payment provider.</p>
            <a href={props.newUrl}>Create a free price point</a>
            {tour && (
              <ChecklistDialog
                text="Click this button to create a price point."
                position="left-80 -top-4"
              />
            )}
          </div>
        }
        title="No payment provider"
        href="/account/integrations"
      />
    );
  }

  return (
    <EmptyPage
      Ico={IcoCreditCard}
      centeredDescription
      actionText="Create your first price point"
      actionDialog={
        tour && (
          <ChecklistDialog text="Click this button to create a price point." position="left-56" />
        )
      }
      description="You haven't created any price points."
      title="Price points"
      href={props.newUrl}
    />
  );
}

export function EmptyCouponScreen(props: Props) {
  if (props.hasDisabled) {
    return (
      <EmptyPage
        Ico={IcoTag}
        centeredDescription
        title="Coupons"
        description="You don't have any active coupons."
        actionText="Create a new coupon"
        href={props.newUrl}
      />
    );
  }
  if (!props.supportsStripe && !props.supportsPaypal) {
    return (
      <EmptyPage
        Ico={IcoTag}
        centeredDescription
        title="No payment provider"
        description="You haven't configured any payment provider."
        actionText="Configure a payment provider"
        href="/account/integrations"
      />
    );
  }

  return (
    <EmptyPage
      Ico={IcoTag}
      centeredDescription
      title="Coupons"
      description="You haven't created any coupons."
      actionText="Create your first coupon"
      href={props.newUrl}
    />
  );
}
