import { IcoLibrary } from '@components/icons';
import { ComponentChildren } from 'preact';

function Card({ title, icon }: { title: string; icon: ComponentChildren }) {
  return (
    <div class="relative px-4 xl:px-4 gap-3 items-center flex py-3 xl:py-4 border rounded-lg  xl:rounded-lg cursor-pointer group hover:bg-violet-50 bg-white shadow-sm">
      {icon}
      <p class="font-bold text-sm">{title}</p>
    </div>
  );
}

export function Resources() {
  return (
    <div class="bg-gradient-to-b from-gray-100 to-indigo-50 p-4 sm:p-6 rounded-2xl">
      <p class="text-black text-md xl:text-lg font-bold mb-4 sm:mb-6">Resources</p>
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <Card title="First resource" icon={<IcoLibrary class="w-6 h-6 text-indigo-600" />} />
        <Card title="Second resource" icon={<IcoLibrary class="w-6 h-6 text-sky-600" />} />
        <Card title="Third resource" icon={<IcoLibrary class="w-6 h-6 text-green-600" />} />
        <Card title="Forth resource" icon={<IcoLibrary class="w-6 h-6 text-red-600" />} />
      </div>
    </div>
  );
}
