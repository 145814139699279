import { ComponentChildren } from 'preact';
import { useEffect, useLayoutEffect, useRef } from 'preact/hooks';
import { on } from 'minidoc-editor';
import { debounce } from 'client/utils/debounce';

interface Props {
  latestMessageId: UUID | undefined;
  editorHeight: number;
  children: ComponentChildren;
  onScrollToBottom: () => void;
}

export function ChatScrollContainer(props: Props) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollHeightRef = useRef(0);

  useEffect(() => {
    const el = scrollRef.current;

    if (!el) {
      return;
    }

    const onScroll = debounce(() => {
      const scrolledToBottom = Math.abs(el.scrollHeight - el.scrollTop - el.clientHeight) < 1;
      if (scrolledToBottom) {
        props.onScrollToBottom();
      }
    });

    const off = on(el, 'scroll', onScroll);

    return () => off();
  }, [scrollRef.current]);

  /*
   * Scroll to the bottom when the latest message changes.
   */
  useLayoutEffect(() => {
    const el = scrollRef.current;
    if (!el) {
      return;
    }

    let shouldScrollToBottom = false;
    // First render should scroll to bottom.
    if (scrollHeightRef.current === 0) {
      shouldScrollToBottom = true;
    } else {
      // Using the scrollHeightRef to determine if we should scroll to bottom
      // as we need the height of the element before the DOM updates.
      shouldScrollToBottom = Math.abs(scrollHeightRef.current - el.scrollTop - el.clientHeight) < 1;
    }

    if (shouldScrollToBottom) {
      // Wait for the DOM to updates to finish before scrolling
      setTimeout(() => {
        el.scrollTop = el.scrollHeight;
      }, 50);
    }
    scrollHeightRef.current = el.scrollHeight;
  }, [props.latestMessageId]);

  return (
    <div
      style={{
        height: `calc(100dvh - ${props.editorHeight}px - 5rem)`,
      }}
      class="mini-scroll overflow-auto p-4 max-h-3xl"
      ref={scrollRef}
    >
      {props.children}
    </div>
  );
}
