import { BtnPrimary } from '@components/buttons';
import { ComponentChildren } from 'preact';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  title: string;
  subtext: string;
  cta?: ComponentChildren;
  onNewCourse?(): void;
}

export function EmptyScreen(props: Props) {
  const intl = useIntl();

  return (
    <div class="flex flex-col items-center justify-center grow md:h-full">
      <div class="relative flex space-x-8 scale-50 md:scale-100">
        <div class="flex flex-col rounded-lg relative">
          <span class="bg-gray-100 rounded-lg w-24 h-24 mb-3"></span>
          <span class="bg-indigo-100 inline-block rounded-lg ml-3 h-2 w-1/3"></span>
          <span class="bg-gray-200 inline-block rounded-lg ml-3 h-2 w-1/2 mt-2"></span>
        </div>
        <div class="flex flex-col rounded-lg relative bg-white p-4 -mt-8">
          <span class="bg-indigo-100 rounded-lg w-32 h-32 mb-3"></span>
          <span class="bg-indigo-100 inline-block rounded-lg ml-3 h-2 w-1/3"></span>
          <span class="bg-gray-200 inline-block rounded-lg ml-3 h-2 w-1/2 mt-2"></span>
        </div>
        <div class="flex flex-col rounded-lg relative">
          <span class="bg-gray-100 rounded-lg w-24 h-24 mb-3"></span>
          <span class="bg-indigo-100 inline-block rounded-lg ml-3 h-2 w-1/3"></span>
          <span class="bg-gray-200 inline-block rounded-lg ml-3 h-2 w-1/2 mt-2"></span>
        </div>
      </div>
      <div class="px-4 mb-10 text-center border-t pt-8 md:mt-8">
        <h1 class="text-indigo-400 text-4xl mb-2">{props.title}</h1>
        <p class="text-cool-gray-600 mb-6">{props.subtext}</p>
        <div class="flex flex-col space-y-4">
          {props.cta}
          {props.onNewCourse && (
            <BtnPrimary onClick={props.onNewCourse}>{intl('Create your first course!')}</BtnPrimary>
          )}
        </div>
      </div>
    </div>
  );
}
