/* ===========================================================================
 *                               Drag / drop
 * ===========================================================================
 * This file contains general drag / drop logic.
 *
 * The approach tracks whether the mouse is moving up or down, and adjusts
 * the position of the dragged item accordingly. We determine *where* the
 * dragged item should be based on a combination of the onDragOver event
 * and the mouse direction. This is a simple mechanism, but has some buggy
 * edgecases. I think the tradeoff is worth it for the simplicity.
 *
 * An edge case example: if you drag an item too fast, it gets "stuck" in the
 * wrong location until you wiggle it around a bit. A more sophisticated sytem
 * would use mouseY changes to determine the drop location.
 *
 * If we end up needing this functionality elsewhere, we should move it and
 * add the more sophisticated drag / drop position logic.
 *
 */

import { ComponentChildren, createContext, Component, JSX } from 'preact';
import { useContext } from 'preact/hooks';
import { IcoGripper } from '@components/icons';

interface DragTarget {
  id: any;
  table: string;
}

export interface DragState {
  dragging: DragTarget;
  target: DragTarget;
  direction: '' | 'before' | 'after';
  mouseY: number;
}

interface DraggableProps {
  class?: string;
  id: any;
  table: string;
  children: ComponentChildren;
  onDragComplete?(): void;
}

interface DraggableProviderProps {
  canHandleDrop: DropCheck;
  onDragStart?: () => void;
  onDragComplete(s: DragState): void;
  onTargetChange(s: DragState): void;
  children: ComponentChildren;
}

type DropCheck = (s: DragState, table: string, id: UUID) => boolean;

interface DraggableContextValue {
  state?: DragState;
  dragStart(): void;
  dragComplete(): void;
  dragChange(s?: DragState): void;
  canHandleDrop: DropCheck;
}

const DraggableContext = createContext<DraggableContextValue>({
  dragStart: () => {},
  dragComplete: () => {},
  dragChange: () => {},
  canHandleDrop: () => false,
});

export function reorderItems<T extends { id: UUID }>(source: T[], dragState: DragState): T[] {
  if (
    dragState.target.table === dragState.dragging.table &&
    dragState.target.id === dragState.dragging.id
  ) {
    return source;
  }
  const result = [...source];
  const fromIndex = result.findIndex((x) => x.id === dragState.dragging.id);
  if (fromIndex < 0) {
    return result;
  }
  const items = result.splice(fromIndex, 1);
  const toIndex =
    result.findIndex((x) => x.id === dragState.target.id) +
    (dragState.direction === 'after' ? 1 : 0);
  result.splice(toIndex, 0, ...items);
  return result;
}

/**
 * DraggableProvider provides the drag / drop context used by all of its children.
 * We're using a class here instead of a function so that we don't end up with
 * stale references in our dragChange / dragComplete callbacks. This was pretty
 * problematic when using hooks / function components.
 */
export class DraggableProvider extends Component<
  DraggableProviderProps,
  { dragState?: DragState }
> {
  dragChange = (dragState: DragState) => {
    if (dragState) {
      this.props.onTargetChange(dragState);
      this.setState({ dragState });
    }
  };

  dragStart = () => {
    this.props.onDragStart?.();
  };

  dragComplete = () => {
    const { dragState } = this.state;
    if (dragState) {
      this.props.onDragComplete(dragState);
      this.setState(() => ({ dragState: undefined }));
    }
  };

  render() {
    const {
      state: { dragState },
      dragChange,
      dragStart,
      dragComplete,
    } = this;
    const { canHandleDrop, children } = this.props;

    return (
      <DraggableContext.Provider
        value={{ dragChange, dragStart, dragComplete, canHandleDrop, state: dragState }}
      >
        {children}
      </DraggableContext.Provider>
    );
  }
}

function createDragState(table: string, id: UUID, e: MouseEvent): DragState {
  return {
    // The id and table / type of the item being dragged
    dragging: { id, table },
    // The id of the latest drop target
    target: { id, table },
    // The direction: '' | 'before' | 'after'
    direction: '',
    // The previous pageY position, used to
    // detect direction changes.
    mouseY: e.pageY,
  };
}

/**
 * Update the mouse direction. Returns a truthy value if the direction has
 * changed and we're over a drop target that's not the currently dragged item.
 * If we change direction while we're over the currently dragged item, we
 * don't need to do anything. This mutates dragState, since we don't want to
 * create any UI churn on drag events *unless* the UI actually needs updating.
 */
function updateDirection(dragState: DragState, table: string, id: UUID, e: MouseEvent) {
  const mouseY = e.pageY;
  const diff = mouseY - dragState.mouseY;
  const direction = diff === 0 ? '' : diff > 0 ? 'after' : 'before';
  const directionChanged = direction !== dragState.direction;
  const isSelfTarget = dragState.dragging.table === table && dragState.dragging.id === id;
  const isNewTarget = dragState.target.id !== id || dragState.target.table !== table;
  dragState.mouseY = mouseY;
  dragState.direction = direction;
  dragState.target = { id, table };

  // Return truthy if we've changed direction and are in a foreign target (e.g. we didn't
  // change direction within the dragged element itself).
  return (isNewTarget && !isSelfTarget) || (isNewTarget && directionChanged && direction);
}

/**
 * A UI component that enables drag to reorder.
 */
export function Draggable(props: DraggableProps & Omit<JSX.HTMLAttributes<HTMLDivElement>, 'id'>) {
  const { table, id } = props;
  const { dragComplete, dragStart, dragChange } = useContext(DraggableContext);

  return (
    <DropTarget
      onDragStart={(e) => {
        if (!(e.target as HTMLElement).draggable) {
          return;
        }
        e.stopPropagation();
        dragStart();
        // This hack is to prevent the drag image from showing the dark purple fill
        setTimeout(() => dragChange(createDragState(table, id, e)));
        function clear(e: Event) {
          e.stopPropagation();
          e.preventDefault();
          dragComplete();
          props.onDragComplete?.();
          document.removeEventListener('drop', clear);
          document.removeEventListener('dragend', clear);
          document.removeEventListener('mousemove', clear);
        }
        document.addEventListener('drop', clear);
        document.addEventListener('dragend', clear);
        e.dataTransfer?.setData('text', id);
      }}
      {...props}
    />
  );
}

/**
 * A UI component that enables a draggable to be dropped, but is not
 * itself a draggable.
 */
export function DropTarget({
  class: className,
  table,
  id,
  children,
  ...props
}: Exclude<DraggableProps, 'dragComplete'> & JSX.HTMLAttributes<HTMLDivElement>) {
  const { state, dragChange, canHandleDrop } = useContext(DraggableContext);

  return (
    <div
      {...props}
      class={`relative ${className}`}
      onDragOver={(e) => {
        if (!state || !canHandleDrop(state, table, id)) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();

        if (updateDirection(state, table, id, e)) {
          // Force a rerender
          dragChange(state);
        }
      }}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
    >
      {state && state.dragging.id === id && state.dragging.table === table && (
        <div class="bg-gray-300 absolute inset-0 z-10 shadow-inner bg-opacity-50"></div>
      )}
      {children}
    </div>
  );
}

export function DraggableGripper() {
  return (
    <IcoGripper class="h-5 w-5 -ml-5 absolute p-1 text-gray-400 cursor-move opacity-1 opacity-0 transition-all duration-150 group-hover:opacity-1" />
  );
}
