import { TimeSlider, VolumeSlider } from '@vidstack/react';

export function Volume(props: { isVisible: boolean }) {
  return (
    <VolumeSlider.Root
      class={`transition-all group relative hidden md:inline-flex h-10 ${
        props.isVisible ? 'w-full ml-2 mr-4 opacity-1' : 'w-0 opacity-0'
      } max-w-20 cursor-pointer touch-none select-none items-center outline-none aria-hidden:hidden`}
    >
      <VolumeSlider.Track class="relative ring-sky-400 z-0 h-[5px] w-full rounded-md bg-white/30 group-data-[focus]:ring-[3px]">
        <VolumeSlider.TrackFill class="bg-sky-400 absolute h-full w-[var(--slider-fill)] rounded-md will-change-[width]" />
      </VolumeSlider.Track>
      <VolumeSlider.Thumb class="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white ring-white/40 group-data-[dragging]:ring-4 will-change-[left]" />
    </VolumeSlider.Root>
  );
}

export function Time(props: { isVideo: boolean }) {
  return (
    <TimeSlider.Root class="time-slider group relative mx-[7.5px] inline-flex h-10 w-full cursor-pointer touch-none select-none items-center outline-none">
      <TimeSlider.Chapters class="relative flex h-full w-full items-center rounded-[1px]">
        {(cues, forwardRef) =>
          cues.map((cue) => (
            <div
              class="last-child:mr-0 relative mr-0.5 flex h-full w-full items-center rounded-[1px]"
              style={{ contain: 'layout style' }}
              key={cue.startTime}
              ref={forwardRef}
            >
              <TimeSlider.Track
                class={`relative ring-media-focus z-0 h-[5px] w-full rounded-sm  ${
                  props.isVideo ? 'bg-white/30' : 'bg-gray-300 dark:bg-gray-700'
                } group-data-[focus]:ring-[3px]`}
              >
                <TimeSlider.TrackFill class="bg-sky-400 absolute h-full w-[var(--chapter-fill)] rounded-sm will-change-[width]" />
                <TimeSlider.Progress class="absolute z-10 h-full w-[var(--chapter-progress)] rounded-sm bg-white/50 will-change-[width]" />
              </TimeSlider.Track>
            </div>
          ))
        }
      </TimeSlider.Chapters>

      <TimeSlider.Thumb class="absolute left-[var(--slider-fill)] top-1/2 z-20 h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 rounded-full border border-[#cacaca] bg-white opacity-0 ring-white/40 transition-opacity group-data-[active]:opacity-100 group-data-[dragging]:ring-4 will-change-[left]" />

      <TimeSlider.Preview class="flex flex-col items-center opacity-0 transition-opacity duration-200 data-[visible]:opacity-100 pointer-events-none">
        <TimeSlider.ChapterTitle class="mt-2 text-sm" />
        <TimeSlider.Value class="text-[13px]" />
      </TimeSlider.Preview>
    </TimeSlider.Root>
  );
}
