import { showError } from '@components/app-error';
import { BtnSecondary, Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { IcoBell } from '@components/icons';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useEsc } from 'client/utils/use-esc';
import { useEffect, useState } from 'preact/hooks';
import { Course, Notification } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import { NotificationItem } from './notification-item';

interface Props {
  course: Pick<Course, 'id' | 'title'>;
}

interface ContentProps {
  course: Props['course'];
  notifications: Notification[];
  hasMore: boolean;
  isLoading: boolean;
  lastViewedNotification?: Date;
  loadMore: () => void;
  onItemSelected: (item: Notification) => void;
}

type NotificationsResponse = RpxResponse<typeof rpx.notifications.getNotifications>;

function Content({
  course,
  notifications,
  hasMore,
  isLoading,
  lastViewedNotification,
  loadMore,
  onItemSelected,
}: ContentProps) {
  const intl = useIntl();

  return (
    <div
      style={{
        maxHeight: 'calc(100vh - 6rem)',
      }}
      class="flex flex-col an-fade-down fixed left-2 md:left-auto right-2 lg:right-12 top-16 lg:w-96 py-4 rounded-md shadow-2xl z-50 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 overflow-y-auto"
      onClick={(e) => e.stopPropagation()}
    >
      <div class="text-gray-700 dark:text-white">
        <h2 class="p-4 text-xl font-bold">{intl('Notifications')}</h2>
        <Case
          when={notifications.length > 0}
          fallback={!isLoading && <p class="p-4">{intl(`You don't have any notifications.`)}</p>}
        >
          <ul class="mt-2">
            {notifications.map((notification, index) => (
              <NotificationItem
                key={notification.id}
                item={notification}
                course={course}
                isLast={notifications.length === index + 1}
                unread={!lastViewedNotification || lastViewedNotification < notification.createdAt}
                onSelected={onItemSelected}
              />
            ))}
          </ul>
        </Case>
        <Case when={isLoading || hasMore}>
          <div class="flex justify-center mt-4 mb-4">
            <BtnSecondary isLoading={isLoading} onClick={loadMore}>
              {intl('Load More')}
            </BtnSecondary>
          </div>
        </Case>
      </div>
    </div>
  );
}

export function StudentNotifications(props: Props) {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [lastViewedNotification, setLastViewedNotification] = useState<Date | undefined>(undefined);

  const [{ cursor, notifications, hasMore }, setState] = useState<NotificationsResponse>({
    cursor: undefined,
    notifications: [],
    hasMore: true,
  });

  useEsc(hideContent);

  async function loadMore() {
    setIsLoading(true);
    try {
      const result = await rpx.notifications.getNotifications({
        courseId: props.course.id,
        cursor,
      });
      setState({
        ...result,
        notifications: notifications.concat(result.notifications),
      });
      setUnreadCount(0);

      return result;
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function displayContent() {
    setIsActive(true);

    // Load the initial notifications.
    if (notifications.length === 0) {
      const result = await loadMore();

      if (result && result.notifications?.length > 0) {
        setUnreadCount(0);
        rpx.notifications.saveLastViewedNotification({
          id: result.notifications[0].id,
          courseId: props.course.id,
        });
      }
    }
  }

  function hideContent() {
    setIsActive(false);

    const firstNotification = notifications[0];
    if (firstNotification) {
      setLastViewedNotification(firstNotification.createdAt);
    }
  }

  useEffect(() => {
    async function fetchUnreadNotificationCount() {
      try {
        const result = await rpx.notifications.getUnreadNotificationCount({
          courseId: props.course.id,
        });
        setUnreadCount(result.unreadCount);
        setLastViewedNotification(result.lastViewedNotification);
      } catch (err) {
        console.log(err);
      }
    }

    fetchUnreadNotificationCount();
  }, []);

  return (
    <div class="items-center inline-flex flex-col relative">
      <Button
        class={`inline-flex items-center justify-start focus:outline-none rounded`}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          if (isActive) {
            hideContent();
          } else {
            displayContent();
          }
        }}
      >
        <IcoBell class="w-6 h-6 opacity-75 hover:opacity-100" />
        <Case when={unreadCount > 0}>
          <span class="absolute -top-3 -right-3 inline-flex items-center justify-center rounded-full text-xs font-semibold leading-4 bg-red-500 text-white dark:border-none w-6 h-6 overflow-hidden">
            {unreadCount}
          </span>
        </Case>
      </Button>
      <div class="relative w-full">
        {isActive && (
          <Content
            notifications={notifications}
            course={props.course}
            hasMore={hasMore}
            loadMore={loadMore}
            isLoading={isLoading}
            lastViewedNotification={lastViewedNotification}
            onItemSelected={hideContent}
          />
        )}
      </div>
    </div>
  );
}
