import { LessonRow, StudentQuizQuestion } from 'server/types';
import { useState } from 'preact/hooks';
import { QuizResults } from './quiz-results';
import { QuestionItem } from './question-item';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { ProgressBar } from '@components/progress-bar';
import { IcoChevronLeft, IcoChevronRight } from '@components/icons';
import { Case } from '@components/conditional';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { useRouteParams } from '@components/router';
import { useIntl } from 'shared/intl/use-intl';

interface QuizState {
  isSubmitted: boolean;
  questions: StudentQuizQuestion[];
}

interface Props {
  questions: StudentQuizQuestion[];
  lesson: Pick<LessonRow, 'id' | 'title'>;
  isSubmitted: boolean;
  allowRetake: boolean;
  onChange(updater: (q: QuizState) => QuizState): void;
}

export function Quiz(props: Props) {
  const intl = useIntl();
  const { lesson, allowRetake, questions, isSubmitted, onChange } = props;
  const { courseId } = useRouteParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Start with first unanswered question
  const [questionIndex, setQuestionIndex] = useState(() => {
    const firstUnanswered = questions.findIndex((q) => !q.isAnswered);
    return firstUnanswered === -1 ? 0 : firstUnanswered;
  });
  const currentQuestion = questions[questionIndex];
  const answeredCount = questions.filter((q) => q.isAnswered).length;

  function updateQuestion(id: UUID, update: StudentQuizQuestion) {
    onChange((s) => ({
      ...s,
      questions: s.questions.map((x) => (x.id === id ? update : x)),
    }));
  }

  async function submitQuiz() {
    setIsSubmitting(true);
    try {
      await rpx.assessments.submitQuiz({
        lessonId: lesson.id,
      });
      onChange((s) => ({ ...s, isSubmitted: true }));
    } catch (err) {
      showError(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  // The quiz must have been completed
  // if there are no more questions
  if (isSubmitted) {
    return (
      <div class="bg-white dark:bg-gray-800 p-6 md:p-8 md:rounded-md border-t border-b md:border dark:border-0">
        <QuizResults
          questions={questions}
          lesson={lesson}
          courseId={courseId}
          allowRetake={allowRetake}
          onRetake={() => {
            // Reset the questions
            onChange((s) => ({
              ...s,
              isSubmitted: false,
              questions: s.questions.map((q) => ({
                ...q,
                choices: q.choices.map((c) => ({ ...c, isSelected: false })),
                isAnswered: false,
              })),
            }));
            setQuestionIndex(0);
          }}
        />
      </div>
    );
  }

  return (
    <div class="drop-shadow-xl shadow-sm bg-white dark:bg-gray-800 border border-gray-100 dark:border-transparent md:-mx-6 p-8 px-10 rounded-lg dark:border-gray-700 my-10 py-10">
      <div class="mb-8">
        <header class="flex items-center justify-between mb-4 text-gray-500 dark:text-gray-200">
          <Button
            class="inline-flex items-center"
            disabled={questionIndex === 0}
            onClick={() => {
              const prev = questionIndex - 1;
              if (prev > -1) {
                setQuestionIndex(prev);
              }
            }}
          >
            <IcoChevronLeft class="w-4 h-4 opacity-75 mr-1" />
            <span class="hidden lg:inline">{intl('Previous')}</span>
          </Button>
          <span>
            {intl('Question {current:number} of {total:number}', {
              current: questionIndex + 1,
              total: questions.length,
            })}
          </span>
          <Button
            class="inline-flex items-center"
            // Allow going to next question if the current question is answered
            disabled={!currentQuestion.isAnswered || questionIndex >= questions.length - 1}
            onClick={() => {
              const next = questionIndex + 1;
              if (questions[next]) {
                setQuestionIndex(next);
              }
            }}
          >
            <span class="hidden lg:inline">{intl('Next')}</span>
            <IcoChevronRight class="ml-1 w-4 h-4 opacity-75" />
          </Button>
        </header>
        <ProgressBar progress={(answeredCount / questions.length) * 100} showPercent={false} />
      </div>
      <QuestionItem
        key={currentQuestion.id}
        question={currentQuestion}
        hideCorrectAnswer={allowRetake}
        updateQuestion={updateQuestion}
        nextButton={
          <Case
            when={answeredCount === questions.length}
            fallback={
              <BtnSecondary
                class="px-6 py-2 text-lg"
                onClick={() => setQuestionIndex(questionIndex + 1)}
              >
                {intl('Next')}
              </BtnSecondary>
            }
          >
            <BtnPrimary class="w-full p-4" isLoading={isSubmitting} onClick={submitQuiz}>
              {intl('See Results')}
            </BtnPrimary>
          </Case>
        }
      />
    </div>
  );
}
