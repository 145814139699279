/**
 * The modal editor for the calendar view.
 */

import { showError } from '@components/app-error';
import { BtnSecondary, Button } from '@components/buttons';
import { IcoTrash } from '@components/icons';
import { ModuleForm } from '@components/module-helpers';
import { DEFAULT_CONTENT, CustomMessage, CustomMessageEditor } from '@components/messages';
import { Spinner } from '@components/spinner';
import { showToast } from '@components/toaster';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { FullCourse, Meeting } from 'server/types';
import { toLocalDate, toUTCDate } from 'shared/dateutil';
import { UpdateMeetingForm } from '../guide-course-meetings/update-meeting-form';
import { ScheduledItem } from './types';
import { useCurrentTenant } from '@components/router/session-context';
import { emptyModuleTitle } from 'shared/terminology';
import { rpx, modulesService } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { StandardDialog, showDialog } from '@components/dialog';

const messageStore = rpx.messages;

interface EditorProps {
  course: Pick<FullCourse, 'id' | 'numStudents' | 'isAbsoluteSchedule'>;
  onDelete(item: ScheduledItem): void;
  onUpdate(item: ScheduledItem): void;
  item: ScheduledItem;
  hide(): void;
}

interface NewItemProps {
  type: 'message' | 'module' | 'meeting';
  course: EditorProps['course'];
  date: string;
  isDraft?: boolean;
  onUpdate(item: ScheduledItem): void;
  onDelete(item: ScheduledItem): void;
  hide(): void;
}

export function saveModule(
  item: ScheduledItem & { prices?: string[] },
  course: { isAbsoluteSchedule?: boolean },
) {
  if (item.type !== 'module') {
    throw new Error(`Cannot save ${item.type} as a module.`);
  }
  return modulesService.updateModule({
    isAbsoluteSchedule: course.isAbsoluteSchedule,
    id: item.id,
    title: item.title,
    startDate: item.date,
    prices: item.prices,
    isDraft: !!item.isDraft,
  });
}

async function createModule(opts: {
  isAbsoluteSchedule: boolean;
  courseId: UUID;
  startDate: Date;
  title: string;
  isDraft?: boolean;
}): Promise<ScheduledItem> {
  const id = await modulesService.createModule({
    isAbsoluteSchedule: opts.isAbsoluteSchedule,
    courseId: opts.courseId,
    title: opts.title,
    startDate: toUTCDate(opts.startDate),
    isDraft: !!opts.isDraft,
  });

  return {
    id: id,
    date: opts.startDate,
    key: `module-${id}`,
    title: opts.title,
    type: 'module',
  };
}

async function createMessage(opts: { courseId: UUID; startDate: Date }): Promise<ScheduledItem> {
  const title = 'Subject';
  const result = await messageStore.saveMessage({
    title,
    content: DEFAULT_CONTENT,
    courseId: opts.courseId,
    startDate: toUTCDate(opts.startDate),
  });

  return {
    id: result.id,
    date: opts.startDate,
    key: `message-${result.id}`,
    title,
    type: 'message',
  };
}

async function createMeeting(opts: {
  courseId: UUID;
  startDate: Date;
  title: string;
}): Promise<ScheduledItem> {
  // Set the default time as noon
  const noon = dayjs(opts.startDate).set('hours', 12);
  const scheduledAt = dayjs().isAfter(noon)
    ? // If the date is in the past, set the meeting to start in 1 hour
      dayjs().add(1, 'hour').startOf('hour')
    : noon;

  const result = await rpx.meetings.createMeeting({
    courseId: opts.courseId,
    title: opts.title,
    durationMinutes: 30,
    type: 'videoconference',
    scheduledAt: scheduledAt.toISOString(),
    slidesFile: undefined,
    recordMeeting: true,
    allowRecordingDownloads: true,
    zoomHasWaitingRoom: false,
  });

  return {
    id: result.id,
    date: scheduledAt.toDate(),
    key: `meeting-${result.id}`,
    title: opts.title,
    type: 'meeting',
  };
}

function FormLoadingIndicator() {
  return (
    <>
      <div class="animate-pulse bg-gray-200 p-4 mb-4 rounded"></div>
      <div class="animate-pulse bg-gray-200 p-8 rounded"></div>
      <div class="animate-pulse bg-gray-200 p-4 mb-4 rounded"></div>
    </>
  );
}

function ModuleEditor({ item, course, hide, onDelete, onUpdate }: EditorProps) {
  return (
    <ModuleForm
      id={item.id}
      title={item.title}
      prices={item.prices}
      startDate={item.date}
      accessFormat="scheduled"
      isAbsoluteSchedule={!!course.isAbsoluteSchedule}
      isDraft={!!item.isDraft}
      courseId={course.id}
      onClose={hide}
      onDelete={() => onDelete(item)}
      onUpdate={async (opts) => {
        await saveModule(
          {
            ...item,
            ...opts,
            date: opts.startDate || item.date,
          },
          course,
        );
        onUpdate({
          ...item,
          date: opts.startDate || item.date,
          title: opts.title,
          prices: opts.prices,
        });
        hide();
      }}
    />
  );
}

function MessageEditor(props: EditorProps) {
  const [message, setMessage] = useState<undefined | CustomMessage>(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const isLoading = !message;

  useAsyncEffect(async () => {
    const msg = await messageStore.getFullMessage({ courseId: props.course.id, id: props.item.id });
    setMessage(msg);
  });

  const promptForDelete = async () => {
    try {
      const confirmed = await showDialog({
        mode: 'warn',
        title: 'Permanently delete message?',
        children: `Do you want to delete "${props.item.title}"? Your changes will be lost and cannot be recovered.`,
        confirmButtonText: 'Delete message',
      });
      if (!confirmed) {
        return;
      }
      setIsDeleting(true);
      await messageStore.deleteMessage({ courseId: props.course.id, id: props.item.id });
      showToast({
        type: 'ok',
        title: 'Deleted message',
        message: props.item.title,
      });
      props.onDelete(props.item);
    } catch (err) {
      showError(err);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div class={`${isLoading ? '' : 'an-fade-in'}`}>
      <header class="flex justify-between mb-4">
        <h3 class="font-semibold">
          {props.item.type === 'sentMessage' ? 'Sent message' : 'Edit message'}
        </h3>
        <nav class="text-gray-500">
          <Button disabled={isDeleting} onClick={promptForDelete}>
            {!isDeleting && <IcoTrash />}
            {isDeleting && <Spinner class="inline-block" />}
          </Button>
        </nav>
      </header>
      {isLoading && <FormLoadingIndicator />}
      {message && (
        <>
          <CustomMessageEditor
            embeddedMode
            course={{
              id: props.course.id,
              accessFormat: 'scheduled',
              isAbsoluteSchedule: props.course.isAbsoluteSchedule,
              isBundle: false,
            }}
            message={message}
            onDelete={() => props.onDelete(props.item)}
            onUpdate={(updates) => {
              if (updates.subject || updates.startDate) {
                props.onUpdate({
                  ...props.item,
                  title: updates.subject || props.item.title,
                  date: (updates.startDate && toLocalDate(updates.startDate)) || props.item.date,
                });
              }
              setMessage((x) =>
                x
                  ? {
                      ...x,
                      title: updates.subject || x.title,
                      startDate: updates.startDate,
                    }
                  : x,
              );
            }}
          />
          <footer>
            <BtnSecondary class="min-w-1/4 rounded-full" onClick={props.hide}>
              Close
            </BtnSecondary>
          </footer>
        </>
      )}
    </div>
  );
}

function MeetingEditor({ item, course, onDelete, onUpdate, hide }: EditorProps) {
  const [meeting, setMeeting] = useState<undefined | Meeting>(undefined);
  const isLoading = !meeting;

  useAsyncEffect(async () => {
    const meeting = await rpx.meetings.getMeeting({ courseId: course.id, id: item.id });
    setMeeting(meeting);
  });

  return (
    <div class={`${isLoading ? '' : 'an-fade-in'}`}>
      {meeting && (
        <UpdateMeetingForm
          meeting={meeting}
          hasEnrolledStudents={course.numStudents ? course.numStudents > 0 : false}
          onDelete={() => onDelete(item)}
          onUpdate={(title, scheduledAt) => {
            onUpdate({
              ...item,
              date: scheduledAt || item.date,
              title,
            });
            hide();
          }}
          hide={hide}
        />
      )}
    </div>
  );
}
export function ItemEditor(props: EditorProps) {
  const { type } = props.item;
  if (type === 'module') {
    return <ModuleEditor {...props} />;
  }
  return (
    <StandardDialog onClose={props.hide} contentWidth>
      {(type === 'message' || type === 'sentMessage') && <MessageEditor {...props} />}
      {type === 'meeting' && <MeetingEditor {...props} />}
    </StandardDialog>
  );
}

export function NewItemModal(props: NewItemProps) {
  const tenant = useCurrentTenant();
  const [item, setItem] = useState<undefined | ScheduledItem>(undefined);
  const isLoading = !item;

  useAsyncEffect(async () => {
    let newItem: ScheduledItem;
    const startDate = toLocalDate(props.date)!;
    switch (props.type) {
      case 'message':
        newItem = await createMessage({
          courseId: props.course.id,
          startDate,
        });
        break;
      case 'module':
        newItem = await createModule({
          isAbsoluteSchedule: props.course.isAbsoluteSchedule,
          courseId: props.course.id,
          startDate,
          title: emptyModuleTitle(tenant),
          isDraft: false,
        });
        break;
      case 'meeting':
        newItem = await createMeeting({
          courseId: props.course.id,
          startDate,
          title: `Untitled ${tenant.terminology.meeting}`,
        });
        break;
    }

    setItem(newItem);
    props.onUpdate(newItem);
  });

  if (props.type === 'module' && item) {
    return <ModuleEditor {...props} item={item} />;
  }

  return (
    <StandardDialog onClose={props.hide}>
      <div class={`${isLoading ? '' : 'an-fade-in'}`}>
        {isLoading && <FormLoadingIndicator />}
        {props.type === 'message' && item && <MessageEditor {...props} item={item} />}
        {props.type === 'meeting' && item && <MeetingEditor {...props} item={item} />}
      </div>
    </StandardDialog>
  );
}
