/**
 * The Stripe payment component and related helpers.
 * Stripe.js type definitions. There didn't appear to be an up-to-date package
 * in npm for us to use.
 */

import { h as htm } from 'minidoc-editor';
import { useMemo } from 'preact/hooks';
import { rpx } from 'client/lib/rpx-client';
import { ManualDom } from '@components/manual-dom';
import { useAsyncData } from 'client/lib/hooks';
import { dispatchClearError } from '@components/async-form';
import { useCurrentTenant } from '@components/router/session-context';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';

export type StripeJs = Stripe;

export interface StripeContext {
  stripe: Stripe;
  elements: StripeElements;
}

interface Props {
  priceId: string;
  customerName: string;
  onReady(c: StripeContext): void;
  collectAddress: boolean;
}

/**
 * Load and render the StripeJS card element.
 */
export function StripeInput({ customerName, priceId, onReady, collectAddress }: Props) {
  const tenant = useCurrentTenant();
  const cardWrapper = useMemo(() => htm('div'), []);
  const addrWrapper = useMemo(() => htm('div.mt-4'), []);
  const cfg = useAsyncData(async () => {
    // Load stripe.js and wait for it to become available, then mount the
    // the initialized Stripe object: https://stripe.com/docs/stripe-js
    const config = await rpx.payments.getStripeClientConfig({ priceId });
    const stripe = await loadStripe(config.stripePublishableKey!, {
      stripeAccount: config.stripeAccount,
      locale: tenant.locale,
    });
    if (!stripe) {
      throw new Error(`Failed to load Stripe.`);
    }
    const elements = stripe.elements({
      appearance: {
        theme: 'flat',
        variables: {
          colorBackground: '#fff',
          borderRadius: '0.25rem',
          focusBoxShadow: 'none',
          focusOutline: '2px solid #4f46e5',
        },
        rules: {
          '.Input': {
            border: '1px solid #e2e8f0',
            padding: '0.5rem',
          },
        },
      },
    });
    const cardEl = elements.create('card', {
      classes: {
        base: 'border rounded p-2',
        focus: 'ring-2 ring-indigo-600',
      },
    });
    cardEl.on('ready', () => {
      onReady({ stripe, elements });
      cardEl.focus();
    });
    cardEl.on('change', () => dispatchClearError(cardWrapper, 'paymentMethod'));
    cardEl.mount(cardWrapper);
    if (collectAddress) {
      const addrEl = elements.create('address', {
        mode: 'shipping',
        defaultValues: {
          name: customerName,
        },
      });
      addrEl.mount(addrWrapper);
      cardEl.on('blur', () => addrEl.focus());
    }
    return stripe;
  }, [priceId]);

  return (
    <section class={`mb-1 ${!cfg.data ? 'animate-pulse' : ''}`} data-private>
      {!cfg.data && (
        <div class="py-1.5 px-2 w-full bg-gray-200 text-gray-400 rounded text-center">
          loading...
        </div>
      )}
      {!!cfg.data && <ManualDom el={cardWrapper} />}
      {!!cfg.data && collectAddress && <ManualDom el={addrWrapper} />}
    </section>
  );
}
