import { router } from '@components/router';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@components/admin-nav';
import { PaginatedList } from '@components/tenants';
import { BtnCopy, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { URLS } from 'shared/urls';
import { compactDate } from 'shared/dateutil';
import { Pill } from '@components/pill';
import { rpx, RpxResponse } from 'client/lib/rpx-client';

type Item = RpxResponse<typeof rpx.admin.getGiftsAsAdmin>['items'][0];

const filterDefinitions = {
  status: {
    title: 'Status',
    options: [
      { title: 'All', value: 'all' },
      { title: 'Pending', value: 'pending' },
      { title: 'Accepted', value: 'accepted' },
    ],
  },
};

interface Filters {
  status: 'all' | 'pending' | 'accepted';
}

type State = Filters & { searchTerm: string };

const defaultFilters: State = {
  searchTerm: '',
  status: 'all',
};

function Page() {
  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="gifts" />
        <AdminTabWrapper>
          <AdminTabHeader title="Gifts" />
          <PaginatedList
            fetcher={async (opts, cursor) => {
              const status = (opts as any).status;
              const result = await rpx.admin.getGiftsAsAdmin({
                searchTerm: opts.searchTerm,
                cursor,
                status,
              });
              return { items: result.items, cursor: result.cursor };
            }}
            initialState={defaultFilters}
            dontRewriteUrl
            searchBox={{
              placeholder: 'By user(id, email) or course(id, title)',
            }}
            tableHeaders={['Sender', 'Recipient', 'Date', 'Status', 'Course', '']}
            filterDefinitions={filterDefinitions}
            renderItem={({ item }: { item: Item }) => (
              <div class="table-row text-inherit hover:bg-indigo-50 text-sm relative">
                <span class="table-cell p-4 border-t">
                  <span class="flex flex-col">
                    <span class="font-semibold" data-private>
                      {item.senderName}
                    </span>
                    {item.fromUserId && (
                      <span>
                        {item.fromUserId}
                        <BtnCopy value={item.fromUserId} margin="m-0" />
                      </span>
                    )}
                  </span>
                </span>
                <span class="table-cell p-4 border-t">
                  <span class="flex flex-col">
                    <span class="font-semibold" data-private>
                      {item.email}
                    </span>
                    {item.toUserId && (
                      <span>
                        {item.toUserId}
                        <BtnCopy value={item.toUserId} margin="m-0" />
                      </span>
                    )}
                  </span>
                </span>
                <span class="table-cell p-4 border-t">{compactDate(item.createdAt)}</span>
                <span class="table-cell p-4 border-t">
                  {!item.toUserId && <Pill color="gray">Pending</Pill>}
                  {!!item.toUserId && <Pill color="green">Accepted</Pill>}
                </span>
                <span class="table-cell p-4 border-t">
                  <span class="flex flex-col">
                    <span class="font-semibold">{item.courseTitle}</span>
                    <span>
                      {item.courseId}
                      <BtnCopy value={item.courseId} margin="m-0" />
                    </span>
                    <span class="italic">Tenant: {item.tenantName}</span>
                  </span>
                </span>
                <span class="table-cell p-4 border-t">
                  <Button
                    class="top-0 right-0 bottom-0 group flex items-start p-4"
                    onClick={async () => {
                      try {
                        await rpx.admin.mimicUser({ userId: item.guideId });
                        location.assign(
                          URLS.guide.coursePage({
                            courseId: item.courseId,
                            page: `gifts`,
                          }),
                        );
                      } catch (err) {
                        showError(err);
                      }
                    }}
                  >
                    <span class="inline-block -mt-0.5 px-2 text-sm py-1 bg-gray-50 border group-hover:bg-indigo-600 group-hover:text-white group-hover:border-indigo-600 rounded whitespace-nowrap">
                      Mimic &amp; view
                    </span>
                  </Button>
                </span>
              </div>
            )}
          />
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  authLevel: 'superadmin',
  url: '/admin/gifts',
  render: Page,
});
