import { PollResults } from '@components/poll/poll-results';
import { useRouteParams } from '@components/router';
import { SlideOver } from '@components/slide-over';
import { DefaultSpinner } from '@components/spinner';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { UserProfile } from '../guide-course-students/user-profile';

export function PollSlideover(props: { close(): void }) {
  const { user, poll } = useRouteParams();
  const { isLoading, data } = useTryAsyncData(
    () =>
      rpx.assessments.getPollResults({
        lessonId: poll,
        userId: user,
      }),
    [user, poll],
  );

  return (
    <SlideOver close={props.close}>
      {isLoading && <DefaultSpinner />}
      {!isLoading && data?.user && data.results.pollQuestions && (
        <section class="flex flex-col gap-6">
          <header class="flex items-center gap-2">
            <UserProfile user={data.user} /> ➜ <strong>Poll Results</strong>
          </header>
          <div class="border rounded-md">
            <header class="border-b p-8 pb-6">
              <span class="opacity-75">{data.lesson.moduleTitle}</span>
              <h3 class="text-lg font-semibold">{data.lesson.moduleTitle}</h3>
            </header>
            <div class="p-8 py-6">
              <PollResults questions={data.results.pollQuestions} showStudentChoice />
            </div>
          </div>
        </section>
      )}
      {!isLoading && !data && <p>No poll results found.</p>}
    </SlideOver>
  );
}
