import { JSX } from 'preact';
import { useEffect, useState } from 'preact/hooks';

interface Props {
  progress: number;
  showPercent?: boolean;
  bgClass?: string;
  barClass?: string;
  barStyle?: JSX.CSSProperties;
  height?: string;
  width?: string;
}

export function ProgressBar({
  progress,
  showPercent = true,
  bgClass = 'bg-gray-100 dark:bg-gray-600',
  barClass = 'bg-green-400',
  barStyle = {},
  height = 'h-2',
  width = 'w-full,',
}: Props) {
  const [mounted, setMounted] = useState(false);

  /*
   * Delaying the initial value assignment to be able to
   * benefit from the width animation.
   */
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <span class={`flex items-center ${width}`}>
      <span
        class={`${bgClass} ${height} rounded-md relative block grow leading-none overflow-hidden`}
      >
        <span
          class={`${barClass} absolute top-0 bottom-0 left-0 transition`}
          style={{
            ...barStyle,
            width: `${mounted ? progress : 0}%`,
            transition: 'width 1s ease-in-out',
          }}
        ></span>
      </span>
      {showPercent && <span class="ml-2 w-8 text-right">{Math.round(progress)}%</span>}
    </span>
  );
}
