/**
 * Page data loading helper functions.
 */

import { RouteProps } from '@components/router';
import { AppRoute } from '../app-route/types';
import { rpx } from '../rpx-client';

type Loader<T> = (route: AppRoute) => Promise<T>;

export type LoadedProps<T extends Loader<any>> = RouteProps<Awaited<ReturnType<T>>>;

/**
 * Load the { course }, assumes a route param of `courseId`.
 */
export const courseLoader = async (route: AppRoute) => ({
  course: await rpx.courses.getGuideCourse({ id: route.params.courseId }),
});
