export function setUrlSearchParam(key: string, value: string) {
  const urlParams = new URLSearchParams(location.search);
  urlParams.set(key, value);
  return `?${urlParams.toString()}`;
}

export function addUrlSearchParam(key: string, value: string) {
  return `${location.origin}${location.pathname}${setUrlSearchParam(key, value)}`;
}

export function deleteUrlSearchParam(key: string) {
  const urlParams = new URLSearchParams(location.search);
  urlParams.delete(key);
  return `${location.origin}${location.pathname}?${urlParams.toString()}`;
}

export function getRedirectUrl() {
  const urlParams = new URLSearchParams(location.search);
  return decodeURIComponent(urlParams.get('redirect') || encodeURIComponent('/'));
}
