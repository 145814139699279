import { JSX } from 'preact';

type Props = JSX.HTMLAttributes<HTMLInputElement> & {
  name: string;
  wrapperClass?: string;
  m?: string;
  textClass?: string;
};

/**
 * A basic, styled radio button component.
 */
export function RadioButton({
  wrapperClass,
  children,
  m = 'mb-2',
  textClass = 'ml-2 block text-sm',
  ...props
}: Props) {
  return (
    <label class={`inline-flex items-center text-gray-700 ${m} ${wrapperClass}`}>
      <input
        type="radio"
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
        {...props}
      />
      {children && <span class={textClass}>{children}</span>}
    </label>
  );
}

/**
 * A radio button that highlights its label when checked, and doesn't introduce padding / margin,
 * leaving that up to its container.
 */
export function RadioGroupButton({ wrapperClass, children, ...props }: Props) {
  return (
    <label
      class={`flex gap-3 cursor-pointer ${
        props.checked ? 'bg-indigo-50 text-indigo-700 border border-indigo-200' : 'text-gray-700'
      } ${wrapperClass}`}
    >
      <input
        type="radio"
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mt-1"
        {...props}
      />
      {children}
    </label>
  );
}
