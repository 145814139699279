import { showError } from '@components/app-error';
import { BtnCopy, BtnPreWarning, BtnPrimary, BtnSecondary } from '@components/buttons';
import { HeadingPrimary } from '@components/headings';
import {
  IcoCalendar,
  IcoDotsHorizontal,
  IcoDuplicate,
  IcoRefresh,
  IcoTrash,
} from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { router, useRouteParams } from '@components/router';
import { Tabs, Tab } from '@components/tabs';
import { showToast } from '@components/toaster';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { UpdatableMeeting, Meeting, Recording, CourseRow } from 'server/types';
import { fmtFullTime, timezoneCity } from 'shared/dateutil';
import { DescriptionTab } from './description-tab';
import { RemindersTab } from './reminders-tab';
import { Dispatcher } from './reducer';
import { TypeTab } from './type-tab';
import { meetingUrl } from './urls';
import { Case } from '@components/conditional';
import { Pill } from '@components/pill';
import { RescheduleModal } from './reschedule-modal';
import { DuplicateModal } from './duplicate-modal';
import { MeetingDuration } from '@components/duration-select';
import { Dropdown } from '@components/dropdown';
import { useCurrentTenant } from '@components/router/session-context';
import { GuideMeetingActions } from '../student-meetings/meeting-actions';
import { useMeetingStatus } from 'client/lib/hooks/use-meeting-status';
import { handleZoomIntegrationError } from './helpers';
import { RecordingsEditor } from './recordings-editor';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useTimezone } from 'client/lib/hooks';
import { URLS } from 'shared/urls';
import { showDialog } from '@components/dialog';

type TabName = 'type' | 'recordings' | 'description' | 'reminders';

interface Props {
  course: Pick<CourseRow, 'isProduct'>;
  meeting: Meeting;
  hasEnrolledStudents: boolean;
  dispatch: Dispatcher;
}

export function MeetingDetail({ meeting, hasEnrolledStudents, dispatch, course }: Props) {
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const { tab = 'type' as TabName } = useRouteParams();
  const { courseId } = useRouteParams();
  const status = useMeetingStatus(meeting);
  const timezone = useTimezone();
  const [loading, setLoading] = useState<
    'updateMeeting' | 'deleteMeeting' | 'deleteSlidesFile' | undefined
  >(undefined);
  const [modal, setModal] = useState<'schedule' | 'duplicate' | undefined>(undefined);
  const [recordings, setRecordings] = useState<Recording[] | undefined>(undefined);

  useAsyncEffect(async () => {
    const recordings = await rpx.meetings.getMeetingRecordings({ meetingId: meeting.id });
    setRecordings(recordings);
  }, [meeting.id]);

  async function deleteMeeting() {
    try {
      if (
        await showDialog({
          mode: 'warn',
          title: `Permanently delete ${terminology.meeting}?`,
          children: `Do you want to delete "${meeting.title}"? Your changes will be lost and cannot be recovered.`,
          confirmButtonText: `Delete ${terminology.meeting}`,
        })
      ) {
        setLoading('deleteMeeting');
        await rpx.meetings.deleteMeeting({ courseId, id: meeting.id });
        showToast({
          type: 'ok',
          title: `Deleted ${terminology.meeting}`,
          message: meeting.title,
        });
        dispatch({
          type: 'delete',
          payload: {
            id: meeting.id,
          },
        });
      }
    } catch (err) {
      showError(err);
    } finally {
      setLoading(undefined);
    }
  }

  /**
   * Updates a subset of the meeting.
   */
  async function updateMeeting(subset: Partial<UpdatableMeeting>, isLocalUpdate?: boolean) {
    setLoading('updateMeeting');
    try {
      if (!isLocalUpdate) {
        await rpx.meetings.updateMeeting({
          courseId,
          id: meeting.id,
          ...subset,
        });
      }

      dispatch({
        type: 'update',
        payload: {
          id: meeting.id,
          ...subset,
        },
      });
    } catch (err) {
      const isHandled = await handleZoomIntegrationError({
        err,
        meetingId: meeting.id,
        courseId,
      });
      if (!isHandled) {
        showError(err);
      }
    } finally {
      setLoading(undefined);
    }
  }

  async function deleteMeetingSlidesFile() {
    setLoading('deleteSlidesFile');
    try {
      await rpx.meetings.deleteMeetingSlidesFile({
        courseId,
        id: meeting.id,
      });
      dispatch({
        type: 'update',
        payload: {
          id: meeting.id,
          slidesFile: undefined,
        },
      });
    } catch (err) {
      showError(err);
    } finally {
      setLoading(undefined);
    }
  }

  const actionButtons = (
    <>
      <Case when={status === 'draft'}>
        <BtnPrimary onClick={() => setModal('schedule')}>
          <IcoCalendar />
          <span class="ml-2">Schedule</span>
        </BtnPrimary>
      </Case>
      <Case when={status === 'scheduled'}>
        <BtnSecondary onClick={() => setModal('schedule')}>
          <IcoRefresh />
          <span class="ml-2">Reschedule</span>
        </BtnSecondary>
      </Case>

      <BtnSecondary type="button" onClick={() => setModal('duplicate')}>
        <IcoDuplicate />
        <span class="ml-1">Duplicate</span>
      </BtnSecondary>
      <Case when={status !== 'draft' && status !== 'ended'}>
        <GuideMeetingActions class="relative" meeting={meeting} />
      </Case>
      <Case when={status !== 'inProgress'}>
        <BtnPreWarning
          type="button"
          isLoading={loading === 'deleteMeeting'}
          onClick={deleteMeeting}
        >
          <IcoTrash />
          <span class="ml-1">Delete</span>
        </BtnPreWarning>
      </Case>

      <BtnCopy
        value={URLS.student.meeting({
          meeting,
          course: { id: courseId, title: '' },
          domain: tenant.domain,
        })}
      >
        Copy link
      </BtnCopy>
    </>
  );

  return (
    <>
      <LoadingIndicator isLoading={loading !== undefined} />
      {modal === 'duplicate' && (
        <DuplicateModal
          meeting={meeting}
          hide={() => setModal(undefined)}
          onSuccess={(newMeeting) => {
            setModal(undefined);
            showToast({
              type: 'ok',
              title: `Duplicated the ${terminology.meeting}`,
              message: `"${newMeeting.title}" is successfully created."`,
            });
            dispatch({
              type: 'add',
              payload: {
                meeting: newMeeting,
              },
            });
            router.goto(
              meetingUrl({
                courseId,
                meetingId: newMeeting.id,
              }),
            );
          }}
        />
      )}
      {modal === 'schedule' && (
        <RescheduleModal
          scheduledAt={meeting.scheduledAt}
          courseId={courseId}
          meetingId={meeting.id}
          hasEnrolledStudents={hasEnrolledStudents}
          hide={() => setModal(undefined)}
          onSuccess={(newDate) => {
            setModal(undefined);
            dispatch({
              type: 'update',
              payload: {
                id: meeting.id,
                scheduledAt: newDate,
              },
            });
            showToast({
              type: 'ok',
              title: `Rescheduled the ${terminology.meeting}`,
              message: `"${meeting.title}" is successfully rescheduled."`,
            });
          }}
        />
      )}
      <HeadingPrimary
        class="mb-2"
        title={
          <>
            <input
              class="p-1 -ml-1 leading-6 cursor-pointer rounded border border-transparent focus:outline-none hover:border-gray-300 focus:border-transparent focus:ring-2 focus:ring-indigo-500 w-full"
              value={meeting.title}
              onKeyDown={(e: any) => e.code === 'Enter' && e.target.blur()}
              onBlur={(e: any) => updateMeeting({ title: e.target.value })}
              placeholder="Name"
            />
            <Dropdown
              hideDownIcon
              class="text-gray-500 dark:text-gray-200"
              bg="bg-gray-100"
              renderMenu={() => <div class="flex flex-col p-2 space-y-2">{actionButtons}</div>}
            >
              <span class="inline-flex md:hidden rounded-full p-1 hover:bg-gray-100 dark:hover:bg-gray-700">
                <IcoDotsHorizontal class="w-6 h-6" />
              </span>
            </Dropdown>
          </>
        }
      >
        <MeetingDuration
          value={meeting.durationMinutes}
          onNewDuration={(newDuration: number) =>
            updateMeeting({
              durationMinutes: newDuration,
            })
          }
        />
        <div class="hidden md:flex space-x-2">{actionButtons}</div>
      </HeadingPrimary>
      <div class="flex flex-col md:flex-row mt-2 mb-3">
        <p class="text-gray-500 flex items-center space-x-2">
          {status === 'draft' && <Pill color="yellow">DRAFT</Pill>}
          {status === 'ended' && <Pill color="red">COMPLETED</Pill>}
          {status === 'inProgress' && (
            <span class="inline-block animate-live">
              <Pill color="green">ON AIR</Pill>
            </span>
          )}
          {meeting.scheduledAt && (
            <span class="opacity-75">
              {status === 'ended' ? 'Originally aired on ' : 'Scheduled for '}
              {fmtFullTime(meeting.scheduledAt)} ({timezoneCity(timezone)} time).
            </span>
          )}
          {!meeting.scheduledAt && <span class="opacity-75">Not scheduled yet.</span>}
        </p>
      </div>
      <Tabs>
        <Tab isSelected={tab === 'type'} href={meetingUrl({ courseId, meetingId: meeting.id })}>
          Type
        </Tab>
        <Tab
          isSelected={tab === 'recordings'}
          href={meetingUrl({ courseId, meetingId: meeting.id, page: 'recordings' })}
        >
          Recordings ({recordings?.length || 0})
        </Tab>
        <Tab
          isSelected={tab === 'description'}
          href={meetingUrl({ courseId, meetingId: meeting.id, page: 'description' })}
        >
          Description
        </Tab>
        <Tab
          isSelected={tab === 'reminders'}
          href={meetingUrl({
            courseId,
            meetingId: meeting.id,
            page: 'reminders',
          })}
        >
          Reminders
        </Tab>
      </Tabs>
      {tab === 'type' && (
        <TypeTab
          meeting={meeting}
          canEditDetails={status !== 'ended'}
          onChange={updateMeeting}
          onSlideDelete={deleteMeetingSlidesFile}
        />
      )}
      {tab === 'recordings' && recordings !== undefined && (
        <RecordingsEditor
          meeting={meeting}
          recordings={recordings}
          onChange={(recordings) => {
            setRecordings(recordings);
          }}
        />
      )}
      {tab === 'description' && (
        <DescriptionTab
          meeting={meeting}
          onChange={(description) => updateMeeting({ description })}
        />
      )}
      {tab === 'reminders' && (
        <RemindersTab course={course} meeting={meeting} onChange={updateMeeting} />
      )}
    </>
  );
}
