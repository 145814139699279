interface UrlParams {
  courseId: UUID;
  meetingId?: UUID;
  page?: string;
}

const baseUrl = (params: Pick<UrlParams, 'courseId'>) => `/manage/courses/${params.courseId}`;

/**
 * The route prefix for all meeting management screens.
 */
export const baseRoute = `manage/courses/:courseId/meetings`;

/**
 * Create a route URL with the baseRoute prefixed:
 * manage/courses/:courseId/meetings/{subRoute}
 * Example: manage/courses/:courseId/meetings/:meetingId/:tab
 */
export const meetingRoute = (subRoute: string) => `${baseRoute}/${subRoute}`;

/**
 * Create a meeting URL with the parameters populated.
 */
export const meetingUrl = (params: UrlParams) =>
  `${baseUrl(params)}/meetings/${params.meetingId ? params.meetingId + '/' : ''}${
    params.page || ''
  }`;
