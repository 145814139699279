import { Time } from '@components/time-input';
import { AccessFormat } from 'server/types';
import { abbreviatedDate, toHumanTime } from '../../../shared/dateutil';
import { FullLesson, State } from './types';
import { fixupModule } from 'client/lib/rpx-client/modules-service';

/**
 * The current lesson.
 */
export const getCurrentLesson = (state: State) =>
  state.lessonId ? (state.lessons[state.lessonId] as FullLesson) : undefined;

/**
 * Convert from an integer offset to a Time struct.
 */
export function offsetToTime(startOffset = 0): Time {
  if (!startOffset) {
    return {
      day: '1',
      hh: '12',
      mm: '00',
      ampm: 'AM',
    };
  }

  const day = Math.floor(startOffset / (24 * 60)) + 1;
  const hh24 = Math.floor(startOffset / 60) % 24;
  const isAM = hh24 < 12;
  const hh = hh24 % 12 || 12;
  const mm = `00${startOffset % 60}`.slice(-2);

  return {
    day: `${day}`,
    hh: `${hh}`,
    mm: `${mm}`,
    ampm: isAM ? 'AM' : 'PM',
  };
}

/**
 * Convert from the Time struct to an integer offset (in minutes).
 */
export function timeToOffset(time: Time) {
  const hh = ((parseInt(time.hh, 10) || 0) % 12) + (time.ampm === 'PM' ? 12 : 0);
  return ((parseInt(time.day, 10) || 1) - 1) * 24 * 60 + hh * 60 + (parseInt(time.mm, 10) || 0);
}

/**
 * Compute the absolute date that a module will be available.
 */
export function getStartDate({
  isAbsoluteSchedule,
  module,
  accessFormat,
  relativeTo,
}: {
  isAbsoluteSchedule: boolean | undefined;
  module: { startOffset?: number; startDate?: string | Date };
  relativeTo?: string | Date;
  accessFormat: AccessFormat;
}) {
  if (accessFormat === 'ondemand' && module.startOffset !== undefined) {
    // This is duplicate logic for the back-end's available_on function. We
    // might consider moving all of this logic into the query that retrieves
    // the data, so we have the logic in one place.
    const dt = relativeTo ? new Date(relativeTo) : new Date();
    // Convert the date to date-only (e.g. not date+time)
    dt.setHours(0, 0, 0, 0);
    // Adjust by the number of minutes from the signup date.
    dt.setMinutes(module.startOffset);
    return dt;
  }
  if (!module.startDate) {
    return;
  }
  const fixedModule = fixupModule({
    ...module,
    isAbsoluteSchedule,
  });
  return fixedModule.startDate;
}

/**
 * Get the date the module becomes available. If membershipDate
 * is provided, and the accessFormat is ondemand, the date will
 * be the module start day relative to the membershipDate.
 */
export function moduleAvailableOn({
  isAbsoluteSchedule,
  module,
  accessFormat,
  membershipDate,
}: {
  isAbsoluteSchedule: boolean;
  module: { startOffset?: number; startDate?: string | Date };
  accessFormat: AccessFormat;
  membershipDate?: Date;
}) {
  if (accessFormat === 'scheduled' || (accessFormat === 'ondemand' && membershipDate)) {
    const date = getStartDate({
      isAbsoluteSchedule,
      module,
      relativeTo: accessFormat === 'ondemand' ? membershipDate : undefined,
      accessFormat,
    });
    return date;
  }
}

/**
 * Get a human-friendly description of the module / message schedule.
 */
export function scheduleDescription({
  isAbsoluteSchedule,
  accessFormat,
  membershipDate,
  startDate,
  startOffset,
  omitPrefix,
}: {
  isAbsoluteSchedule: boolean | undefined;
  accessFormat: AccessFormat;
  membershipDate?: Date;
  startOffset?: number;
  startDate?: Date | string;
  omitPrefix?: boolean;
}) {
  const useScheduledDate = accessFormat === 'scheduled' || accessFormat === 'openaccess';
  const relativeTo = accessFormat === 'ondemand' ? membershipDate : undefined;
  if (useScheduledDate || relativeTo) {
    const dt = getStartDate({
      isAbsoluteSchedule,
      module: useScheduledDate ? { startDate } : { startOffset: startOffset },
      relativeTo,
      accessFormat,
    });
    return `${omitPrefix ? '' : 'Available '}${toHumanTime(dt, abbreviatedDate)}`;
  } else if (accessFormat === 'ondemand') {
    const time = offsetToTime(startOffset);
    return `${omitPrefix ? 'day ' : 'Day '}${time.day} at ${time.hh}:${time.mm} ${time.ampm}`;
  }
  return '';
}
