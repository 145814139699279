/**
 * This modulel contains all of the state management logic for the student lessons view.
 *
 * Loading here has similar requirements to the lesson editor, and we probably should
 * consider whether the commonalities should be shared or not.
 */

import { Lesson, State, getCurrentLesson } from '@components/module-helpers';

/**
 * The current course.
 */
export const getCurrentCourse = (state: State) => state.course;

/**
 * Returns the next lesson.
 */
export const getSiblingLessons = (state: State) => {
  const lesson = getCurrentLesson(state);

  if (!lesson) {
    return { next: undefined, prev: undefined };
  }

  const { orderedLessonIds } = state;
  const index = orderedLessonIds.indexOf(lesson.id);
  const nextId = orderedLessonIds[index + 1];
  const prevId = orderedLessonIds[index - 1];

  return {
    next: state.lessons[nextId],
    prev: state.lessons[prevId],
  };
};

/**
 * Finds all the prerequisite lessons
 * that are before the current lesson and
 * not completed.
 */
export const findUncompletedPrerequisites = (state: State) => {
  const currentLesson = getCurrentLesson(state);

  if (!currentLesson) {
    return [];
  }

  const prerequisiteLessons: Lesson[] = [];

  for (const lessonId of state.orderedLessonIds) {
    // We only care about lessons that are before the current lesson.
    if (lessonId === currentLesson.id) {
      break;
    }

    const lesson = state.lessons[lessonId];

    if (lesson.isPrerequisite && !state.completedLessons.includes(lesson.id)) {
      prerequisiteLessons.push(lesson);
    }
  }

  return prerequisiteLessons;
};
