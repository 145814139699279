import { FullCourse } from 'server/types';
import { HeadingSecondary } from '@components/headings';
import { useCurrentTenant } from '@components/router/session-context';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { Toggle } from '@components/toggle';
import { UserProfileIcon } from '@components/avatars';
import { useState } from 'preact/hooks';
import { showError } from '@components/app-error';

const store = rpx.facilitators;

export function FacilitatorList(props: {
  facilitators: RpxResponse<typeof store.getFacilitators>;
  courseId: UUID;
}) {
  const [state, setState] = useState(props.facilitators);

  const toggleFaciliator = (userId: UUID, isFacilitator: boolean) => {
    setState((s) => s.map((x) => (x.id !== userId ? x : { ...x, isFacilitator })));
    store
      .toggleFaciliator({ courseId: props.courseId, userId, isEnabled: isFacilitator })
      .catch(showError);
  };

  return (
    <section>
      <div class="inline-flex flex-col gap-4 py-6">
        {state.map((u) => (
          <label
            key={u.id}
            class={`flex gap-4 cursor-pointer items-center border rounded-lg p-2 px-4 ${
              u.isFacilitator ? 'border-indigo-500 ring-1 ring-indigo-500' : ''
            }`}
          >
            <Toggle
              checked={!!u.isFacilitator}
              onClick={() => toggleFaciliator(u.id, !u.isFacilitator)}
            />
            <UserProfileIcon user={u} size="w-8 h-8" />
            <span class="flex flex-col leading-snug">
              <strong>{u.displayName || u.name}</strong>
              <span>{u.email}</span>
            </span>
          </label>
        ))}
      </div>
    </section>
  );
}

export function FacilitatorsSettings({ course }: { course: FullCourse }) {
  const { terminology } = useCurrentTenant();
  const data = useTryAsyncData(() => store.getFacilitators({ courseId: course.id }), [course.id]);

  return (
    <div class="flex flex-col">
      <HeadingSecondary>{terminology.Facilitators}</HeadingSecondary>
      <p class="opacity-75">
        Choose fellow guides who can collaborate with you and help you manage this{' '}
        {terminology.course}.
      </p>
      {data.isLoading && <DefaultSpinner />}
      {data.data && <FacilitatorList facilitators={data.data} courseId={course.id} />}
    </div>
  );
}
