import { ComponentChildren } from 'preact';

export function AccountTabContent(props: {
  children: ComponentChildren;
  title: ComponentChildren;
}) {
  return (
    <div class="mt-6 dark:text-gray-200">
      <header class="mb-4">
        <h3 class="text-lg font-medium text-gray-900 dark:text-gray-100 leading-6 capitalize">
          {props.title}
        </h3>
      </header>
      {props.children}
    </div>
  );
}
