import { useRouteParams } from '../router';
import { UserProfileIcon } from '../avatars';
import { useImageUrl } from 'client/utils/cdn';
import { CheckoutCourse } from './types';

/**
 * The course logo and title, etc that we display on all checkout pages.
 */
export function CourseBanner({ course }: { course: CheckoutCourse }) {
  const { renewal } = useRouteParams();
  const src = useImageUrl(course.imagePath);
  const { guide } = course;

  return (
    <div class="flex flex-col">
      {!!renewal && (
        <div class="w-full bg-red-300 py-3 px-3 sm:px-6 lg:px-8 text-lg text-gray-900 text-center">
          {guide.name} has revoked your access to the course. Please{' '}
          <a href={`mailto:${guide.email}`}>contact them</a> if you have any questions.
        </div>
      )}

      <div>
        <section class="flex items-center">
          {src && (
            <span class="mr-6 block">
              <img class="rounded w-24 min-w-24" src={`${src}?width=200`} />
            </span>
          )}

          <div class="flex flex-grow flex-col">
            <h2 class="font-medium mb-1 line-clamp-2">{course.title}</h2>

            <div class="text-gray-500 flex items-center">
              <UserProfileIcon size="h-8 w-8" user={guide} />
              <span class="ml-2">by {guide.name || guide.email}</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
