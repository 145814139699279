import { DefaultSpinner } from '@components/spinner';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import * as fmt from 'shared/payments/fmt';
import { ComponentChildren } from 'preact';
import { PriceRow } from 'server/types';
import { corePrices } from 'shared/urls';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { IcoBanknotes } from '@components/icons';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

const store = rpx.pricingTable;

function PricingTableSection({
  title,
  description,
  defaultPrice,
  monthlyPrice,
}: {
  title: ComponentChildren;
  description: ComponentChildren;
  defaultPrice?: PriceRow;
  monthlyPrice?: PriceRow;
}) {
  const intl = useIntl();
  if (!defaultPrice) {
    return null;
  }

  return (
    <div class="border rounded-lg p-6">
      <h2 class="text-lg leading-6 font-medium text-zinc-900">{title}</h2>
      <p class="mt-4 text-sm text-gray-500 h-12">{description}</p>
      <div class="py-6">
        <span class="text-4xl font-extrabold text-zinc-900">{fmt.price(defaultPrice)}</span>
        <span class="text-base font-medium text-zinc-500 ml-1">
          {fmt.priceSuffix({ item: defaultPrice, compact: true, intl })}
        </span>
      </div>
      <div>
        <Button
          class="bg-indigo-600 rounded w-full p-2 text-white text-center block"
          href={corePrices.checkoutUrl({ priceId: defaultPrice.id })}
        >
          Purchase {title}
        </Button>
        <div class="my-2">
          {monthlyPrice && (
            <Button
              class="block w-full text-center p-2"
              href={monthlyPrice && corePrices.checkoutUrl({ priceId: monthlyPrice.id })}
            >
              Or make <strong>12 payments of {fmt.price(monthlyPrice)} / month</strong>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

function CreditsPricingTable() {
  const loader = useAsyncData(store.getInstantCourseCreditsPricing, []);

  if (loader.isLoading) {
    return <DefaultSpinner />;
  }
  if (!loader.data) {
    return null;
  }
  const prices = loader.data;
  return (
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <PricingTableSection
        title="1 Credit"
        description="Access 1 Instant Course"
        defaultPrice={prices.oneCredit}
      />
      <PricingTableSection
        title="5 Credits"
        description="Access 5 Instant Courses"
        defaultPrice={prices.fiveCredits}
      />
      <PricingTableSection
        title="Unlimited Access"
        description="Access all Instant Courses"
        defaultPrice={prices.unlimited}
        monthlyPrice={prices.unlimitedMonthly}
      />
    </div>
  );
}

export function BuyCreditsBanner({
  credits,
  usage,
  isUnlimited,
  expanded,
}: {
  credits: number;
  usage: number;
  isUnlimited?: boolean;
  expanded?: boolean;
}) {
  const [showPricing, setShowPricing] = useState(!!expanded);
  const remaining = credits - usage;

  return (
    <div class="mx-4 lg:mx-8 mt-4 mb-8">
      <div class="bg-indigo-50 rounded-lg max-w-4xl mx-auto flex items-center justify-between text-gray-900">
        <div class="p-6 md:px-12 pt-8">
          <h2 class="text-3xl font-semibold tracking-tight mb-2">
            {isUnlimited
              ? 'Unlimited Access to "Done-For-You" Courses'
              : 'Get Instant Access to “Done-For-You” Courses'}
          </h2>
          <h3 class="text-base mb-4">
            {isUnlimited
              ? 'You have unlimited access to the Instant Courses library.'
              : 'Purchase credits for individual Instant Courses, or get access to our entire library of 30+ courses.'}
          </h3>
          {!isUnlimited && credits > 0 && (
            <div class="mb-6">
              <div class="flex items-center">
                <IcoBanknotes />
                <span class="ml-2 text-lg font-bold">Your Credits</span>
              </div>
              <div class="pl-8 space-x-8">
                <span>Credits available: {isUnlimited ? 'Unlimited' : remaining}</span>
                <span class="opacity-50">Credits used: {usage}</span>
              </div>
            </div>
          )}
          <div class="space-y-2">
            <Case
              when={!showPricing}
              fallback={
                <BtnSecondary onClick={() => setShowPricing(false)}>Hide Pricing</BtnSecondary>
              }
            >
              {!isUnlimited && (
                <BtnPrimary onClick={() => setShowPricing(true)}>View Pricing ⤑</BtnPrimary>
              )}
              <a
                class="block text-indigo-600 underline"
                target="_blank"
                rel="noreferrer"
                href="https://ruzuku.appointlet.com/s/7360"
              >
                Questions? Schedule a chat with our team
              </a>
            </Case>
          </div>
          {showPricing && (
            <div class="mt-4">
              <CreditsPricingTable />
            </div>
          )}
        </div>
        {!showPricing && (
          <div class="-mr-3 md:-mr-12">
            <img
              class="w-36 h-36"
              data-attribution="https://undraw.co"
              src={`${RUZUKU_ASSETS_BASE_URL}/undraw_outer_space_re_u9vd.svg`}
            />
          </div>
        )}
      </div>
    </div>
  );
}
