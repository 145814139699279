import { Button } from '@components/buttons';
import { IcoChevronDown, IcoRuzukuLogo, IcoX } from '@components/icons';
import { Toggle } from '@components/toggle';
import { useImageUrl } from 'client/utils/cdn';
import { accentGradient, themeDefaults } from 'shared/tenant-style-utils';
import { ColorPicker } from '@components/color-picker';
import { Brand } from './load';
import { pickLogo } from './pick-logo';

export function LoginPreview({
  brand,
  setBrand,
}: {
  brand: Brand;
  setBrand: (fn: (b: Brand) => Brand) => void;
}) {
  const loginBgUrl = useImageUrl(brand.loginBgUrl);
  const iconUrl = useImageUrl(brand.iconUrl);

  return (
    <div
      class="w-full p-10 py-40 rounded-sm flex items-center justify-center relative bg-gray-50 bg-cover"
      style={{
        backgroundImage: loginBgUrl ? `url(${loginBgUrl})` : undefined,
      }}
    >
      <div class="absolute flex top-4 left-4 space-x-4">
        <Button
          type="button"
          class="relative cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-600 inline-flex items-center justify-center rounded-sm p-2 bg-white shadow-2xl drop-shadow-md"
          onClick={async () => {
            const loginBg = await pickLogo({ cropRatio: undefined, requireCrop: false });
            loginBg && setBrand((s) => ({ ...s, loginBgUrl: loginBg.publicUrl }));
          }}
        >
          {!!loginBgUrl && <img src={loginBgUrl} class="max-w-80 h-10" />}
          {!loginBgUrl && <span class="px-2">Choose login screen background</span>}
          <IcoChevronDown class="w-4 h-4 ml-2 opacity-75" />
        </Button>
        {!!loginBgUrl && (
          <Button
            type="button"
            class="cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-600 inline-flex items-center justify-center rounded-sm px-4 bg-white shadow-2xl drop-shadow-md"
            onClick={() => setBrand((s) => ({ ...s, loginBgUrl: '' }))}
          >
            Remove background image
            <IcoX class="w-4 h-4 ml-2 opacity-75" />
          </Button>
        )}
      </div>
      <div class="relative py-3 my-4 w-lg max-w-full">
        <div
          class="absolute inset-0 bg-gradient-to-r from-indigo-400 to-blue-400 shadow-lg transform -skew-y-6 rounded mx-2.5"
          style={{
            backgroundImage: accentGradient(brand),
          }}
        ></div>
        <div class="relative p-8 bg-white shadow-lg rounded-sm flex flex-col space-y-6">
          <div class="absolute top-8 right-8">
            <ColorPicker
              name="toAccent"
              color={brand.toAccentColor || themeDefaults.toAccentColor}
              onPick={(toAccentColor) => setBrand((s) => ({ ...s, toAccentColor }))}
            />
          </div>
          <div class="flex items-center justify-center">
            <Button
              type="button"
              class="cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-600 inline-flex items-center justify-center rounded-sm px-8 relative"
              onClick={async () => {
                const icon = await pickLogo();
                icon && setBrand((s) => ({ ...s, iconUrl: icon.publicUrl }));
              }}
            >
              <span class="inline-block h-16">
                {!!iconUrl && <img src={iconUrl} class="h-full" />}
                {!iconUrl && <IcoRuzukuLogo class="h-full w-auto" />}
              </span>
              <IcoChevronDown class="w-4 h-4 ml-2 opacity-75 absolute right-2" />
            </Button>
          </div>
          {brand.showName && (
            <span
              class="text-indigo-700 text-2xl px-12 flex justify-center"
              style={{ color: brand.logoColor }}
            >
              <ColorPicker
                name="logo"
                color={brand.logoColor || themeDefaults.logoColor}
                onPick={(logoColor) => setBrand((s) => ({ ...s, logoColor }))}
              >
                <span class="px-2">{brand.name}</span>
              </ColorPicker>
            </span>
          )}
          <div>
            <label class="space-x-3 text-zinc-500 cursor-pointer">
              <Toggle
                checked={brand.showName}
                onClick={() => setBrand((s) => ({ ...s, showName: !s.showName }))}
              />
              <span>Show brand name</span>
            </label>
          </div>
          <div class="flex flex-col text-center justify-center border-t border-dashed mt-4 pt-4">
            <ColorPicker
              name="primaryAction"
              color={brand.primaryActionColor || themeDefaults.primaryActionColor}
              onPick={(primaryActionColor) => setBrand((s) => ({ ...s, primaryActionColor }))}
            >
              Choose primary button &amp; link color
            </ColorPicker>
            <span class="btn-primary w-full mt-4" style={{ background: brand.primaryActionColor }}>
              Example primary action
            </span>
            <a href="#" class="block mt-4" style={{ color: brand.primaryActionColor }}>
              Example link
            </a>
          </div>
          <div class="flex justify-between">
            <ColorPicker
              name="fromAccent"
              color={brand.fromAccentColor || themeDefaults.fromAccentColor}
              onPick={(fromAccentColor) => setBrand((s) => ({ ...s, fromAccentColor }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
