import { AppRoute } from 'client/lib/app-route/types';
import { timeZoneFormatter } from './dateutil';
import { MeetingDetail, ScheduledMeetingDetail } from './types';
import { mkdate } from '@components/date-picker';

export function parseRouteSchedule(params: Record<string, string>) {
  const { start, end } = params;
  if (!start || !end) {
    return;
  }
  try {
    return {
      start: new Date(start),
      end: new Date(end),
    };
  } catch (err) {
    console.error(err);
  }
}

export async function loadSchedule(route: AppRoute) {
  const meeting: MeetingDetail = {
    host: route.auth.user!,
    title: 'Woodworking 1:1 consultation',
    description:
      'Chat with a real Lincoln Log cabin, tongue depresser birdhouse, and Elmer glue specialist.',
    duration: 15,
    location: 'Zoom',
    timeZone: timeZoneFormatter.resolvedOptions().timeZone.replaceAll('_', ' '),
    hour12: true,
    schedule: parseRouteSchedule(route.params),
  };

  return { meeting };
}

export async function loadMeeting(route: AppRoute): Promise<{ meeting: ScheduledMeetingDetail }> {
  const { meeting } = await loadSchedule(route);
  const result: ScheduledMeetingDetail = {
    ...meeting,
    attendee: {
      name: 'Bob Demo',
      email: 'bobdemo@example.com',
    },
    schedule: meeting.schedule || {
      start: new Date(),
      end: mkdate((x) => x.setHours(x.getHours() + 1), new Date()),
    },
  };

  return { meeting: result };
}
