import { showError } from '@components/app-error';
import { BtnSecondary, Button } from '@components/buttons';
import { Dropdown, MenuItem } from '@components/dropdown';
import { IcoX } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { router } from '@components/router';
import { useCurrentUser } from '@components/router/session-context';
import { SearchBox } from '@components/search-box';
import { serialAsync } from 'client/utils/serial-async';
import { Dispatch, StateUpdater, useMemo, useRef, useState } from 'preact/hooks';
import { UserLevel } from 'server/types';
import { hasLevel } from 'shared/auth';
import { toQueryString } from 'shared/urls';

const DEBOUNCE_MS = 500;

export type FilterDefinitions<T, K extends keyof T> = Record<
  K,
  {
    title: string;
    options: Array<{ title: string; value: T[K] }>;
    level?: UserLevel;
  }
>;

type FetchOptions<T, K extends keyof T> = Partial<Record<K, T[K]>> & {
  searchTerm?: string;
  secondSearchTerm?: string;
  cursor?: string;
};

type SearchBoxProps = {
  prefix?: string;
  placeholder: string;
};

interface Props<T, K extends keyof T, TItem extends { id: any }> {
  filterDefinitions?: FilterDefinitions<T, K>;
  searchBox: SearchBoxProps;
  secondSearchBox?: SearchBoxProps;
  fetcher(opts: FetchOptions<T, K>, cursor?: string): Promise<{ items: TItem[]; cursor: string }>;
  tableHeaders: string[];
  renderItem(props: { item: TItem }): JSX.Element | null;
  onSelect?(item: TItem): void;

  initialState: FetchOptions<T, K>;

  items?: TItem[];
  cursor?: string;
  dontRewriteUrl?: boolean;
}

function ClearFilters({
  onClear,
  filters,
  filterDefinitions,
}: {
  filters: any;
  filterDefinitions: undefined | FilterDefinitions<any, any>;
  onClear(): void;
}) {
  const filterDescription: string[] = [];
  const addFilter = (key: string, defaultValue: any) => {
    if (filterDefinitions && filters[key] !== defaultValue) {
      filterDescription.push(
        `${filterDefinitions[key].title} ${
          filterDefinitions[key].options.find((d) => d.value === filters[key])?.title
        }`.toLowerCase(),
      );
    }
  };
  if (filters.searchTerm) {
    filterDescription.push('Search term');
  }
  if (filterDefinitions) {
    Object.keys(filterDefinitions).forEach((k) => {
      addFilter(k, filterDefinitions[k].options[0].value);
    });
  }

  return (
    <Button class="inline-flex items-center" onClick={onClear}>
      <span class="border rounded bg-gray-50 mr-2 p-0.5 inline-block">
        <IcoX class="w-3 h-3 opacity-75" />
      </span>
      Clear current filters: {filterDescription.join(', ')}
    </Button>
  );
}

function FilterOptions<Filters, State extends Filters, K extends keyof Filters>({
  title,
  field,
  options,
  isFirst,
  filters,
  setFilters,
}: {
  title: string;
  field: K;
  isFirst?: boolean;
  options: Array<{ title: string; value: Filters[K] }>;
  filters: Filters;
  setFilters: Dispatch<StateUpdater<State>>;
}) {
  const currentValue = filters[field];
  const option = options.find((o) => o.value === currentValue);
  return (
    <Dropdown
      triggerClass={`bg-gray-50 text-gray-700 border border-gray-300 -ml-px rounded-none inline-flex h-full p-2 px-4 whitespace-nowrap text-sm text-ellipsis overflow-hidden ${
        isFirst ? 'rounded-l' : 'last-of-type:rounded-r'
      }`}
      position="left-0 top-full mt-2"
      class="text-gray-500 text-sm"
      renderMenu={() => (
        <>
          {/*
            This span is a hack to prevent triggering the underlying row's hover effect
            when the user's mouse moves from the menu trigger to the flyout.
          */}
          <span class="absolute -top-4 left-0 right-0 h-4 opacity-0"></span>
          <h3 class="uppercase text-xs font-bold tracking-wider text-gray-400 border-b p-2">
            {title}
          </h3>
          {options.map((o) => (
            <MenuItem
              key={o.value}
              class={`${o.value === currentValue ? 'font-bold' : ''} p-2 text-left`}
              onClick={() =>
                setFilters((s) => ({
                  ...s,
                  [field]: o.value,
                }))
              }
            >
              {o.title}
            </MenuItem>
          ))}
        </>
      )}
    >
      <span class="mr-1 opacity-75">{title}:</span>
      <span>{option?.title}</span>
    </Dropdown>
  );
}

function filtersEqual<T extends Record<string, any>>(a: T, b: T, defaults: T) {
  return Object.keys(defaults).every((k) => (a as any)[k] === (b as any)[k]);
}

function areDefaultFilters<T extends Record<string, any>>(filters: T, defaults: T) {
  return filtersEqual(filters, defaults, defaults);
}

export function PaginatedList<T, K extends keyof T, TItem extends { id: any }>(
  props: Props<T, K, TItem>,
) {
  const currentUser = useCurrentUser();
  const [items, setItems] = useState<TItem[]>(props.items || []);
  const [cursor, setCursor] = useState(props.cursor);
  const [state, setState] = useState(() => props.initialState);
  const [isLoading, setIsLoading] = useState(!props.items);
  const timeout = useRef<any>(undefined);
  const hasFilters = !areDefaultFilters(state, props.initialState);
  const UserItem = props.renderItem;
  const fetcher = useRef(props.fetcher);
  fetcher.current = props.fetcher;

  const fetchItems = useMemo(() => {
    return serialAsync(async (opts: FetchOptions<T, K>, cursor?: string) => {
      try {
        setIsLoading(true);
        if (!props.dontRewriteUrl) {
          router.rewrite(
            `${location.pathname}?${toQueryString({
              q: opts.searchTerm,
              q2: opts.secondSearchTerm,
            })}`,
          );
        }
        const result = await fetcher.current(opts, cursor);
        setItems((s) => (cursor ? [...s, ...result.items] : result.items));
        setCursor(result.cursor);
        return result;
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    });
  }, []);

  const fetchOnFilterChange = useMemo(() => {
    let prevFilters = props.items ? state : undefined;

    return (opts: FetchOptions<T, K>) => {
      clearTimeout(timeout.current);
      if (prevFilters && filtersEqual(opts, prevFilters, props.initialState)) {
        return;
      }
      const shouldRunImmediately =
        prevFilters?.searchTerm === opts.searchTerm || opts.searchTerm === '';
      prevFilters = opts;
      if (shouldRunImmediately) {
        fetchItems(opts);
      } else {
        timeout.current = setTimeout(() => fetchItems(opts), DEBOUNCE_MS);
      }
    };
  }, []);

  fetchOnFilterChange(state);

  return (
    <div>
      {isLoading && <LoadingIndicator />}
      <header class="mb-6">
        <div class="inline-flex flex-wrap rounded gap-4">
          <div class="flex">
            {props.filterDefinitions &&
              Object.keys(props.filterDefinitions)
                .filter((f) => {
                  const wantedLevel = (props.filterDefinitions as any)[f].level as UserLevel;
                  if (!wantedLevel) {
                    return true;
                  }
                  return hasLevel(currentUser, wantedLevel);
                })
                .map((k: any, i) => (
                  <FilterOptions
                    key={k}
                    isFirst={i === 0}
                    field={k}
                    filters={state as any}
                    setFilters={setState}
                    options={(props.filterDefinitions as any)[k].options}
                    title={(props.filterDefinitions as any)[k].title}
                  />
                ))}
          </div>
          <SearchBox
            prefix={props.searchBox.prefix}
            focusOnce
            containerClass="w-72"
            class={`ruz-input text-sm ${props.filterDefinitions ? 'rounded-l-none' : ''} ${
              props.searchBox.prefix ? '' : 'pl-9'
            } h-full grow`}
            onTermChange={(searchTerm) => {
              const opts = { ...state, searchTerm, cursor: '' };
              setState((s) => ({ ...s, searchTerm, cursor: '' }));
              setIsLoading(true);
              clearTimeout(timeout.current);
              if (searchTerm === '') {
                fetchItems(opts);
              } else {
                timeout.current = setTimeout(() => fetchItems(opts), DEBOUNCE_MS);
              }
            }}
            onKeyDown={(e: any) => {
              if (e.code === 'Enter') {
                // We've already loaded the results, so just use state
                if (!isLoading && items.length === 1) {
                  props.onSelect?.(items[0]);
                  return;
                }
                // We're in the "loading" state, so we'll preempt the timeout
                clearTimeout(timeout.current);
                fetchItems({ ...state, searchTerm: e.target.value, cursor: '' }).then((results) => {
                  if (results?.items?.length === 1) {
                    props.onSelect?.(results.items[0]);
                  }
                });
              }
            }}
            placeholder={props.searchBox.placeholder}
            value={state.searchTerm}
            name="q"
          />
          {!!props.secondSearchBox && (
            <SearchBox
              prefix={props.secondSearchBox.prefix}
              containerClass="w-72 -ml-px"
              class={`ruz-input text-sm ${
                props.filterDefinitions ? 'rounded-l-none' : ''
              } h-full grow py-3`}
              onTermChange={(secondSearchTerm) => {
                const opts = { ...state, secondSearchTerm, cursor: '' };
                setState((s) => ({ ...s, secondSearchTerm, cursor: '' }));
                setIsLoading(true);
                clearTimeout(timeout.current);
                if (secondSearchTerm === '') {
                  fetchItems(opts);
                } else {
                  timeout.current = setTimeout(() => fetchItems(opts), DEBOUNCE_MS);
                }
              }}
              onKeyDown={(e: any) => {
                if (e.code === 'Enter') {
                  // We've already loaded the results, so just use state
                  if (!isLoading && items.length === 1) {
                    props.onSelect?.(items[0]);
                    return;
                  }
                  // We're in the "loading" state, so we'll preempt the timeout
                  clearTimeout(timeout.current);
                  fetchItems({ ...state, secondSearchTerm: e.target.value, cursor: '' }).then(
                    (results) => {
                      if (results?.items?.length === 1) {
                        props.onSelect?.(results.items[0]);
                      }
                    },
                  );
                }
              }}
              placeholder={props.secondSearchBox.placeholder}
              value={state.secondSearchTerm}
              name="q2"
            />
          )}
        </div>
      </header>

      {hasFilters && (
        <div class="mb-6">
          <ClearFilters
            filters={state}
            filterDefinitions={props.filterDefinitions}
            onClear={() => setState(props.initialState)}
          />
        </div>
      )}

      {!items.length && hasFilters && <p class="text-gray-500">No matches found</p>}
      {!items.length && !hasFilters && <p class="text-gray-500">Not in any courses</p>}
      {items.length > 0 && (
        <>
          <div class="table table-auto bg-white rounded border w-full divide-y">
            <div class="table-row bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {props.tableHeaders.map((txt) => (
                <div key={txt} class="table-cell pl-4 pr-2 py-2">
                  {txt}
                </div>
              ))}
            </div>
            {items.map((u) => (
              <UserItem key={u.id} item={u} />
            ))}
          </div>
          {cursor && (
            <footer class="text-center p-4">
              <BtnSecondary
                isLoading={isLoading}
                onClick={() => {
                  fetchItems(state, cursor);
                }}
              >
                Load more
              </BtnSecondary>
            </footer>
          )}
        </>
      )}
    </div>
  );
}
