import { Attachments } from '@components/attachments';
import { ComponentChildren } from 'preact';
import { BtnPrimary } from '@components/buttons';
import { StudentQuizQuestion } from 'server/types';
import { FormGroup } from '@components/async-form';
import { useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { showError } from '@components/app-error';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useRouteParams } from '@components/router';
import { IcoCheckCircle, IcoXCircle } from '@components/icons';
import { ALPHABET, ChoiceItem } from './choice-item';
import { generateQuizQuestionResult } from './quiz-results';
import { useIntl } from 'shared/intl/use-intl';

type AnswerResult = RpxResponse<typeof rpx.assessments.answerQuizQuestion>;

interface Props {
  question: StudentQuizQuestion;
  answerResult?: AnswerResult;
  hideCorrectAnswer: boolean;
  nextButton?: ComponentChildren;
  updateQuestion?: (id: UUID, update: StudentQuizQuestion) => void;
}

export function QuestionItem({
  question,
  answerResult,
  hideCorrectAnswer,
  nextButton,
  updateQuestion,
}: Props) {
  const intl = useIntl();
  const { lessonId } = useRouteParams();
  const hasAttachment = question.choices.some((c) => c.file);
  const [selectedChoices, setSelectedChoices] = useState(
    () => new Set(question.choices.filter((c) => c.isSelected).map((c) => c.id)),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState<AnswerResult | undefined>(() => {
    if (question.isAnswered) {
      return generateQuizQuestionResult(question);
    }
    return answerResult;
  });

  function toggleChoice(id: UUID) {
    if (!question.isMultipleChoice) {
      setSelectedChoices(new Set([id]));
      return;
    }

    const result = new Set(selectedChoices);
    result.has(id) ? result.delete(id) : result.add(id);
    setSelectedChoices(result);
  }

  async function submitAnswer() {
    setIsSubmitting(true);
    try {
      const response = await rpx.assessments.answerQuizQuestion({
        lessonId,
        questionId: question.id,
        choiceIds: Array.from(selectedChoices),
      });
      setResult(response);

      if (updateQuestion) {
        updateQuestion(question.id, {
          ...question,
          isAnswered: true,
          correctFeedback: response.correctFeedback,
          incorrectFeedback: response.incorrectFeedback,
          correctFeedbackFile: response.correctFeedbackFile,
          incorrectFeedbackFile: response.incorrectFeedbackFile,
          choices: question.choices.map((c) => ({
            ...c,
            isCorrect: response.correctChoices.includes(c.id),
            isSelected: selectedChoices.has(c.id),
          })),
        });
      }
    } catch (err) {
      showError(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div class="rounded-lg text-zinc-900 dark:text-zinc-200">
      <FormGroup prop={question.id}>
        <header class="mb-6">
          <h2 class="text-lg whitespace-pre-line mb-1 font-medium text-gray-600 dark:text-gray-200">
            {question.content || intl('Question')}
          </h2>
          <p class="text-xs text-gray-500 dark:text-gray-400">
            {question.isMultipleChoice
              ? intl('Select all that apply')
              : intl('Select the best answer')}{' '}
            {intl('({points:number} {points | pluralize point points})', {
              points: question.points || 0,
            })}
          </p>
        </header>
        {question.file && <Attachments attachments={[question.file]} />}
        <div class={hasAttachment ? 'lg:grid grid-cols-2 gap-8' : 'space-y-4'}>
          {question.choices.map((choice, index) => {
            return (
              <ChoiceItem
                key={choice.id}
                index={index}
                choice={choice}
                galleryView={hasAttachment}
                hideCorrectAnswer={hideCorrectAnswer}
                isCorrect={result?.correctChoices.includes(choice.id)}
                disabled={question.isAnswered || false}
                checked={selectedChoices.has(choice.id)}
                handleChange={() => toggleChoice(choice.id)}
              />
            );
          })}
        </div>
      </FormGroup>
      <Case
        when={!!result}
        fallback={
          <footer class="flex mt-8">
            {!question.isAnswered && (
              <BtnPrimary
                class="w-full p-4"
                disabled={selectedChoices.size === 0}
                isLoading={isSubmitting}
                onClick={submitAnswer}
              >
                {intl('Submit Answer')}
              </BtnPrimary>
            )}
          </footer>
        }
      >
        <footer class="flex flex-col mt-8 space-y-8">
          {result && (
            <Feedback question={question} data={result} hideCorrectAnswer={hideCorrectAnswer} />
          )}
          {nextButton}
        </footer>
      </Case>
    </div>
  );
}

function Feedback({
  question,
  data,
  hideCorrectAnswer,
}: {
  question: StudentQuizQuestion;
  data: AnswerResult;
  hideCorrectAnswer: boolean;
}) {
  const intl = useIntl();
  const {
    isCorrect,
    correctChoices,
    correctFeedback,
    incorrectFeedback,
    correctFeedbackFile,
    incorrectFeedbackFile,
  } = data;

  return (
    <div
      class={`flex flex-col border w-full dark:border-transparent ${
        isCorrect
          ? 'bg-green-50 border-green-300 dark:bg-green-700 text-green-700 dark:text-white'
          : 'bg-red-50 border-transparent dark:bg-red-700 text-red-900 dark:text-white'
      } dark:bg-opacity-60 p-4 rounded space-y-4`}
    >
      {isCorrect && (
        <div class="flex items-center">
          <IcoCheckCircle class="w-6 h-6 opacity-75 mr-3" />
          <span class="font-bold">{intl('This answer is correct.')}</span>
        </div>
      )}
      {!isCorrect && (
        <div class="flex items-center">
          <IcoXCircle class="w-6 h-6 opacity-75 mr-3" />
          <span class="font-bold mr-1">{intl('This answer is incorrect.')}</span>
          {!hideCorrectAnswer && (
            <span>
              {intl('The correct answer is {answer:string}.', {
                answer: correctChoices
                  .map((correctChoice) => {
                    const index = question.choices.findIndex((c) => c.id === correctChoice);
                    return ALPHABET[index];
                  })
                  .join(', '),
              })}
            </span>
          )}
        </div>
      )}
      <Case
        when={isCorrect}
        fallback={
          <>
            {incorrectFeedback && <p>{incorrectFeedback}</p>}
            {incorrectFeedbackFile && <Attachments attachments={[incorrectFeedbackFile]} />}
          </>
        }
      >
        {correctFeedback && <p>{correctFeedback}</p>}
        {correctFeedbackFile && <Attachments attachments={[correctFeedbackFile]} />}
      </Case>
    </div>
  );
}
