import { IcoLogo } from '@components/icons';
import { useCurrentTenant } from '@components/router/session-context';
import { BrandRow } from 'server/types';

/**
 * The application logo and branding.
 */
export function SiteNavLogo(props: {
  href?: string;
  brand?: Pick<BrandRow, 'name' | 'showName' | 'iconUrl' | 'logoColor'>;
}) {
  const tenant = useCurrentTenant();
  const brand = props.brand || tenant;
  return (
    <a
      href={props.href || '/manage/courses'}
      class="theme-logo-color flex items-center gap-4"
      style={props.brand?.logoColor ? `color: ${props.brand.logoColor}` : ''}
    >
      <IcoLogo class="h-8" url={brand.iconUrl} />
      {brand.showName && <span class="text-2xl">{brand.name || tenant.name || 'ruzuku'}</span>}
    </a>
  );
}
