import { showError } from '@components/app-error';
import { useBasicAutosaver } from '@components/autosaver';
import { Checkbox } from '@components/checkbox';
import { DefaultSpinner } from '@components/spinner';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';

type Props = {
  courseId: string;
};

async function load({ courseId }: Props) {
  const { tenantFields, courseFields } = await rpx.profileFields.getCourseProfileFields({
    courseId,
  });

  const checkedFields = new Set(courseFields.fields.map((x) => x.id));

  return {
    courseId,
    fields: tenantFields.map((field) => {
      return {
        ...field,
        isChecked: checkedFields.has(field.id),
      };
    }),
  };
}

function CourseProfileFields(props: Awaited<ReturnType<typeof load>>) {
  const [fields, setFields] = useState(props.fields);
  const toggleField = (id: UUID) =>
    setFields((s) => s.map((x) => (x.id === id ? { ...x, isChecked: !x.isChecked } : x)));

  useBasicAutosaver(fields, (val) => {
    const promise = rpx.profileFields.saveCourseProfileFields({
      courseId: props.courseId,
      fields: val.filter((x) => x.isChecked).map((x) => ({ id: x.id })),
    });
    promise.catch(showError);
    return promise;
  });

  return (
    <div class="flex flex-col gap-4">
      {fields.map((x) => (
        <Checkbox key={x.id} checked={x.isChecked} onClick={() => toggleField(x.id)}>
          {x.label}
        </Checkbox>
      ))}
    </div>
  );
}

export function CourseProfileFieldsForm(props: Props) {
  const response = useTryAsyncData(() => load(props), [props.courseId]);

  if (!response.data) {
    return <DefaultSpinner />;
  }

  if (!response.data.fields.length) {
    return <p class="text-gray-500">No fields available.</p>;
  }

  return <CourseProfileFields {...response.data} />;
}
