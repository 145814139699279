import { useRouteParams } from '@components/router';
import { SlideOver } from '@components/slide-over';
import { DefaultSpinner } from '@components/spinner';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { UserProfile } from '../guide-course-students/user-profile';
import { QuizResults } from '@components/quiz/quiz-results';

export function QuizSlideover(props: { close(): void }) {
  const { courseId, user, quiz } = useRouteParams();
  const { isLoading, data } = useTryAsyncData(
    () =>
      rpx.assessments.getQuizResults({
        lessonId: quiz,
        userId: user,
      }),
    [user, quiz],
  );

  return (
    <SlideOver close={props.close}>
      {isLoading && <DefaultSpinner />}
      {!isLoading && data?.user && data.results.length > 0 && (
        <section class="flex flex-col gap-6">
          <header class="flex items-center gap-2">
            <UserProfile user={data.user} /> ➜ <strong>Quiz Results</strong>
          </header>
          <div class="border rounded-md p-8">
            <QuizResults
              subject={data.user.name}
              questions={data.results}
              lesson={data.lesson}
              courseId={courseId}
              allowRetake={false}
            />
          </div>
        </section>
      )}
      {!isLoading && !data && <p>No poll results found.</p>}
    </SlideOver>
  );
}
