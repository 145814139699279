/**
 * The GuidePage module contains the wrapper, top bar, command palette, etc
 * for course management pages.
 */
import { Button } from '@components/buttons';
import { LoadingIndicator } from '@components/loading-indicator';
import { ComponentChildren } from 'preact';
import { FullCourse, MembershipLevel, Tenant, UserLevel } from 'server/types';
import { InvalidPaypalBanner } from './invalid-paypal-banner';
import { CourseChecklistButton } from './course-launch-checklist-button';
import { useDocumentTitle } from 'client/utils/use-document-title';
import {
  IcoBook,
  IcoCalendar,
  IcoChartBar,
  IcoChat,
  IcoCheckBadge,
  IcoChevronRight,
  IcoCreditCard,
  IcoDotsHorizontal,
  IcoEye,
  IcoHome,
  IcoList,
  IcoMail,
  IcoPencil,
  IcoPresentation,
  IcoReply,
  IcoSettings,
  IcoTag,
  IcoUser,
  IcoVideoCamera,
} from '@components/icons';
import { useCourseGuideMenu } from '@components/guide-course-menu/use-course-guide-menu';
import { CourseEnrollmentToggle } from '@components/course-enrollment-status';
import { courseLabel } from 'shared/terminology';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { UserMenu } from '@components/nav/user-menu';

export * from './guide-product-page';

type PageType =
  | 'dashboard'
  | 'lessons'
  | 'calendar'
  | 'style'
  | 'messages'
  | 'meetings'
  | 'certificate'
  | 'students'
  | 'assessments'
  | 'discussions'
  | 'prices'
  | 'coupons'
  | 'upsells'
  | 'salespage'
  | 'settings'
  | 'profilefields'
  | 'invitations';

type PageCategory = 'content' | 'sales' | 'students';

interface PageDef {
  href(course: { id: UUID }): string;
  type: PageType;
  icon: JSX.Element;
  title: (tenant: Tenant) => string;
  tenantOnly?: boolean;
  level?: MembershipLevel;
  role?: UserLevel;
  availableForBundles?: boolean;
}

const categoryLabels: Record<PageCategory, string> = {
  students: 'Student Support',
  sales: 'Sales & Signups',
  content: 'Content',
};

const modulesAndLessonsDef: PageDef = {
  type: 'lessons',
  href: ({ id }) => `/manage/courses/${id}/lessons`,
  title: (t) => t.terminology.Lessons || 'Lessons',
  icon: <IcoBook />,
};

const pageDefs: Record<PageCategory, PageDef[]> = {
  content: [
    {
      type: 'dashboard',
      href: ({ id }) => `/manage/courses/${id}`,
      title: () => 'Dashboard',
      icon: <IcoHome />,
      availableForBundles: true,
      level: 'guide',
    },
    modulesAndLessonsDef,
    {
      type: 'calendar',
      href: ({ id }) => `/manage/courses/${id}/calendar`,
      title: () => 'Calendar',
      icon: <IcoCalendar />,
    },
    {
      type: 'style',
      href: ({ id }) => `/manage/courses/${id}/style`,
      title: () => 'Style',
      icon: <IcoPencil />,
      availableForBundles: true,
    },
    {
      type: 'certificate',
      href: ({ id }) => `/manage/courses/${id}/certificate`,
      title: () => 'Certificate',
      icon: <IcoCheckBadge />,
      tenantOnly: true,
    },
    {
      type: 'messages',
      href: ({ id }) => `/manage/courses/${id}/messages`,
      title: () => 'Messages',
      icon: <IcoReply />,
      availableForBundles: true,
    },
    {
      type: 'meetings',
      href: ({ id }) => `/manage/courses/${id}/meetings`,
      title: (t) => t.terminology.Meetings || 'Meetings',
      icon: <IcoVideoCamera />,
    },
    {
      type: 'settings',
      href: ({ id }) => `/manage/courses/${id}/settings`,
      title: () => 'Settings',
      icon: <IcoSettings />,
    },
  ],
  sales: [
    {
      type: 'prices',
      href: ({ id }) => `/manage/courses/${id}/prices`,
      title: () => 'Prices',
      icon: <IcoCreditCard />,
      availableForBundles: true,
      level: 'guide',
    },
    {
      type: 'coupons',
      href: ({ id }) => `/manage/courses/${id}/coupons`,
      title: () => 'Coupons',
      icon: <IcoTag />,
      availableForBundles: true,
      level: 'guide',
    },
    {
      type: 'salespage',
      href: ({ id }) => `/manage/courses/${id}/salespage`,
      title: () => 'Sales Page',
      icon: <IcoPresentation />,
      availableForBundles: true,
    },
    {
      type: 'invitations',
      href: ({ id }) => `/manage/courses/${id}/invitations`,
      title: () => 'Invitations',
      icon: <IcoMail />,
      availableForBundles: true,
    },
    {
      type: 'profilefields',
      href: ({ id }) => `/manage/courses/${id}/profile-fields`,
      title: () => 'Profile Fields',
      icon: <IcoList />,
      tenantOnly: true,
    },
  ],
  students: [
    {
      type: 'students',
      href: ({ id }) => `/manage/courses/${id}/students`,
      title: () => 'Students',
      icon: <IcoUser />,
      availableForBundles: true,
      level: 'guide',
    },
    {
      type: 'assessments',
      href: ({ id }) => `/manage/courses/${id}/assessments`,
      title: () => 'Assessments',
      icon: <IcoChartBar />,
    },
    {
      type: 'discussions',
      href: ({ id }) => `/manage/courses/${id}/discussions`,
      title: (t) => t.terminology.Discussions || 'Discussion Categories',
      icon: <IcoChat />,
    },
  ],
};

const pageTypeToCategory: Record<PageType, PageCategory> = Object.keys(pageDefs).reduce(
  (acc, category) => {
    const cat = category as PageCategory;
    pageDefs[cat].forEach((d) => {
      acc[d.type] = cat;
    });
    return acc;
  },
  {} as Record<PageType, PageCategory>,
);

interface Props {
  course: Pick<
    FullCourse,
    | 'id'
    | 'title'
    | 'hidePeople'
    | 'hideDiscussions'
    | 'isBundle'
    | 'accessFormat'
    | 'isAbsoluteSchedule'
    | 'status'
    | 'level'
    | 'isArchived'
    | 'checklistStatus'
    | 'checklistCompletedSteps'
    | 'numStudents'
    | 'supportsCaptions'
    | 'guide'
  >;
  viewLink?: string;
  children?: ComponentChildren;
  isLoading?: boolean;
  documentTitle?: string;
}

/**
 * GuidePage represents a guide / course-management page. It includes all of
 * the wrapping chrome, top nav, etc.
 */
export function GuidePage({
  children,
  isLoading,
  documentTitle,
  ...props
}: Props & { type: PageType }) {
  const category = pageTypeToCategory[props.type];
  const user = useCurrentUser();
  const { course } = props;
  const defs = course.isBundle
    ? pageDefs[category].filter((d) => d.availableForBundles)
    : pageDefs[category];
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const showMenu = useCourseGuideMenu({ course });
  const def = defs.find((x) => x.type === props.type);

  useDocumentTitle([documentTitle, def?.title(tenant), props.course.title]);

  return (
    <div class="flex flex-col bg-white text-gray-700 text-sm min-h-screen">
      {isLoading && <LoadingIndicator />}

      <div class="bg-gradient-to-r from-violet-600 to-indigo-600 text-white flex flex-col md:flex-row md:gap-4 px-2 p-1">
        <div class="flex items-center justify-between font-semibold">
          <div class="flex">
            <a
              href={course.isBundle ? '/courses?show=bundles' : '/courses?show=courses'}
              class="guide-page-home text-inherit rounded-md p-1.5 hover:bg-violet-800/50 inline-flex gap-2 items-center"
            >
              <IcoHome class="size-5" />
            </a>
            <a
              class="guide-page-course-title text-base text-inherit rounded-md p-1 line-clamp-1 hover:bg-violet-800/50 "
              href={`/manage/courses/${course.id}`}
            >
              {course.title}
            </a>
          </div>
          <UserMenu class="flex md:hidden" />
        </div>

        <nav class="flex flex-grow justify-start md:justify-end gap-2 sm:gap-4 items-center font-semibold">
          {!course.isBundle && (
            <div class="hidden md:block">
              <CourseChecklistButton course={course} />
            </div>
          )}
          <div class="hidden lg:block">
            <CourseEnrollmentToggle course={course} />
          </div>
          {!course.isBundle && (
            <TopNavLink href={modulesAndLessonsDef.href(course)}>
              {modulesAndLessonsDef.icon}
              <span>{modulesAndLessonsDef.title(tenant)}</span>
            </TopNavLink>
          )}
          <TopNavLink onClick={showMenu}>
            <IcoSettings />
            <span class="capitalize">
              Manage
              <span class="hidden sm:inline">
                {' '}
                {courseLabel({
                  course,
                  tenant,
                })}
              </span>
            </span>
          </TopNavLink>
          <TopNavLink class="inline-flex" href={props.viewLink || `/courses/${course.id}`}>
            <IcoEye />
            <span>
              <span class="hidden sm:inline-block">Student</span> View
            </span>
          </TopNavLink>
          <UserMenu class="hidden md:flex" />
        </nav>
      </div>

      <nav class="hidden lg:flex whitespace-nowrap overflow-x-auto mini-scroll bg-gradient-to-r from-white to-gray-50 font-medium p-4 py-2 items-center justify-start gap-4 text-sm border-b">
        <Button
          class="font-medium hover:bg-gray-100 hover:text-gray-800 inline-flex items-center gap-1 border rounded-md p-0.5 px-2"
          onClick={showMenu}
        >
          <span>{course.isBundle ? 'Manage Bundle' : `Manage ${terminology.Course}`}</span>
          <IcoChevronRight />
          <span>{categoryLabels[category]}</span>
          <IcoDotsHorizontal class="rotate-90 opacity-75" />
        </Button>
        <nav class="flex gap-6">
          {defs.map(
            (d) =>
              (!d.role || d.role === user?.level) &&
              (!d.tenantOnly || !tenant.isCore) &&
              (!d.level || d.level === course.level) && (
                <NavLink key={d.type} href={d.href(course)} isSelected={d.type === props.type}>
                  {d.icon}
                  {d.title(tenant)}
                </NavLink>
              ),
          )}
        </nav>
      </nav>

      <InvalidPaypalBanner />

      <div class="flex flex-col md:flex-row flex-grow">{children}</div>
    </div>
  );
}

function TopNavLink({ children, class: className = '', ...props }: Parameters<typeof Button>[0]) {
  return (
    <Button
      class={`text-inherit font-semibold inline-flex items-center hover:bg-white/20 p-2 py-1 gap-2 rounded-md whitespace-nowrap ${className}`}
      {...props}
    >
      {children}
    </Button>
  );
}

function NavLink({
  children,
  href,
  isSelected,
}: {
  children: ComponentChildren;
  href: string;
  isSelected?: boolean;
}) {
  return (
    <Button
      href={href}
      class={`${
        isSelected
          ? 'bg-gray-200 text-gray-900 font-semibold'
          : 'font-medium text-inherit hover:bg-gray-100'
      } p-0.5 px-2 rounded-md inline-flex items-center gap-2`}
    >
      {children}
    </Button>
  );
}
