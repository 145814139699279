import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { SearchBox } from '@components/search-box';
import { Spinner } from '@components/spinner';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useRef, useState } from 'preact/hooks';

export type Searchable = { id: any; query: string };

interface SearchableProps<T> {
  placeholder?: string;
  label?: string;
  items: T[];
  renderItem(item: T): JSX.Element | string;
  onPick(item: T): void;
  autoFocus?: boolean;
}

export function toSearchable<T extends { id: any; title: string }>(items: Array<T>) {
  return items.map((x) => ({
    ...x,
    query: `${x.id} ${x.title.toLocaleLowerCase()}`,
  }));
}

function SearchableList<T extends Searchable>({
  placeholder = 'Find an item',
  label,
  items,
  renderItem,
  onPick,
  autoFocus,
}: SearchableProps<T>) {
  const [term, setTerm] = useState('');
  const normalizedTerm = term.toLocaleLowerCase();
  const matches = term ? items.filter((c) => c.query.includes(normalizedTerm)) : items;
  const listEl = useRef<Element | null | undefined>();

  return (
    <div
      class="flex flex-col"
      onKeyDown={(e: any) => {
        const isUp = e.code === 'ArrowUp';
        const isDown = e.code === 'ArrowDown';
        if (!listEl.current || (!isUp && !isDown)) {
          return;
        }
        e.preventDefault();
        if (isUp) {
          if (listEl.current.contains(e.target)) {
            e.target.previousElementSibling?.focus();
          }
        } else {
          if (listEl.current.contains(e.target)) {
            e.target.nextElementSibling?.focus();
          } else {
            listEl.current.querySelector('button')?.focus();
          }
        }
      }}
    >
      <label class="flex flex-col mb-4">
        {label && <span class="mb-1.5">{label}</span>}
        <SearchBox
          onTermChange={setTerm}
          value={term}
          placeholder={placeholder}
          focusOnce={autoFocus}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
              const item = matches[0];
              item && onPick(item);
            }
          }}
        />
      </label>
      {!matches.length && <div class="p-4 text-center text-gray-400">No match for "{term}"</div>}
      <div
        class="flex flex-col border rounded divide-y overflow-hidden"
        ref={(el) => el && (listEl.current = el)}
      >
        {matches.map((item) => (
          <Button
            key={item.id}
            onClick={() => onPick(item)}
            type="button"
            class="text-left p-4 hover:bg-gray-50 hover:text-gray-900 focus:ring focus:ring-indigo-600 focus:rounded outline-none"
          >
            {renderItem(item)}
          </Button>
        ))}
      </div>
    </div>
  );
}

export function AsyncSearchableList<T extends Searchable>({
  loadItems,
  ...props
}: Omit<SearchableProps<T>, 'items'> & { loadItems(): Promise<T[]> }) {
  const [items, setItems] = useState<T[] | undefined>(undefined);

  useAsyncEffect(async () => {
    try {
      setItems(await loadItems());
    } catch (err) {
      showError(err);
    }
  }, []);

  if (!items) {
    return (
      <div class="p-4 text-center">
        <Spinner class="inline-block border-indigo-500" />
      </div>
    );
  }

  return <SearchableList items={items} {...props} />;
}
