import useEmblaCarousel from 'embla-carousel-react';
import { useState, useCallback, useEffect, useRef } from 'preact/hooks';
import { AttachmentItem } from './attachment-item';
import { Case } from '@components/conditional';
import { isImage } from 'shared/media';
import { IcoChevronLeft, IcoChevronRight } from '@components/icons';
import { Thumbnail } from './thumbnail';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';
import { AttachmentProps, FullScreenHeader } from './index';
import { Button } from '@components/buttons';

export function Carousel({
  attachments,
  user,
  timestamp,
  allowFullScreen,
  editMode,
  onDelete,
  onSort,
}: AttachmentProps) {
  const agent = window.navigator.userAgent.toLowerCase();
  const isSafariMobile = !!agent.match(/iPad/i) || !!agent.match(/iPhone/i);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mainViewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  });

  useBodyScrollLock(isFullScreen);

  const scrollToPrevious = useCallback(() => {
    const prevIndex = selectedIndex > 0 ? selectedIndex - 1 : attachments.length - 1;
    embla?.scrollTo(prevIndex);
  }, [embla, selectedIndex, attachments]);

  const scrollToNext = useCallback(() => {
    const nextIndex = selectedIndex >= attachments.length - 1 ? 0 : selectedIndex + 1;
    embla?.scrollTo(nextIndex);
  }, [embla, selectedIndex, attachments]);

  /*
   * Syncs the slides and thumbnails.
   */
  useEffect(() => {
    function sync() {
      if (!embla || !emblaThumbs) {
        return;
      }

      setSelectedIndex(embla?.selectedScrollSnap());
      emblaThumbs.scrollTo(embla?.selectedScrollSnap());
    }

    embla?.on('select', sync);
  }, [embla, emblaThumbs]);

  // Pause all videos when the carousel page changes
  useEffect(() => {
    if (!embla) {
      return;
    }

    function onSelect() {
      const media: NodeListOf<HTMLVideoElement | HTMLAudioElement> =
        document.querySelectorAll('video,audio');
      media.forEach((m) => m.pause?.());
    }

    embla.on('select', onSelect);

    return () => {
      embla.off('select', onSelect);
    };
  }, [embla]);

  /*
   * Listening keydown events to handle arrow and escape keys.
   */
  useEffect(() => {
    function handleUserKeyPress(event: KeyboardEvent) {
      const { key } = event;

      if (key === 'Escape') {
        setIsFullScreen(false);
      } else if (key === 'ArrowLeft') {
        scrollToPrevious();
      } else if (key === 'ArrowRight') {
        scrollToNext();
      }
    }

    function removeListener() {
      document.removeEventListener('keydown', handleUserKeyPress);
    }

    // Ignore keyboard events if this is not full screen
    if (isFullScreen) {
      document.addEventListener('keydown', handleUserKeyPress);
    } else {
      removeListener();
    }

    return removeListener;
  }, [isFullScreen, scrollToNext, scrollToPrevious]);

  /*
   * There is a weird bug on Safari mobile where it fails
   * to redraw the page when the container div contains `fixed` class.
   * I couldn't find any other solution and this one is working
   * and I have no idea why.
   */
  useEffect(() => {
    if (isSafariMobile && isFullScreen) {
      containerRef.current?.classList.add('fixed');
    }
  }, [isSafariMobile, isFullScreen]);

  return (
    <div
      ref={containerRef}
      class={
        isFullScreen
          ? `${
              isSafariMobile ? '' : 'fixed h-screen'
            } z-50 flex flex-col inset-0 bg-white dark:bg-gray-900 bg-opacity-95`
          : 'dark:bg-gray-800 mb-4'
      }
    >
      {isFullScreen && (
        <FullScreenHeader
          user={user}
          timestamp={timestamp}
          onClose={() => setIsFullScreen(false)}
        />
      )}
      <div class="relative flex flex-grow overflow-hidden">
        {/* min-h-2 is a hack for the carousel library as it fails rendering lazy images otherwise */}
        <div
          class={`carousel-viewport overflow-hidden min-h-2 w-full ${isFullScreen ? 'h-full' : ''}`}
          ref={mainViewportRef}
        >
          <div class={`carousel-container flex h-full`}>
            {attachments.map((attachment) => (
              <div
                class="flex justify-center items-center min-w-full max-w-full rounded overflow-hidden h-full"
                key={attachment.id}
              >
                <AttachmentItem
                  attachment={attachment}
                  fullSize={isFullScreen}
                  allowFullScreen={allowFullScreen}
                  onClick={() => {
                    if (isImage(attachment.type)) {
                      setIsFullScreen(true);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <Case when={attachments.length > 1}>
          <Button
            class={`hidden lg:flex absolute justify-center items-center inset-y-1/4 p-4 text-gray-500 dark:text-gray-200 ${
              isFullScreen ? 'left-0' : 'left-0 z-10'
            } group`}
            onClick={scrollToPrevious}
          >
            <span class="bg-white dark:bg-gray-800 rounded-full group-hover:shadow-lg group-focus:ring-2 group-focus:ring-indigo-500 p-1">
              <IcoChevronLeft class={`${isFullScreen ? 'w-12 h-12' : 'w-6 h-6'}`} />
            </span>
          </Button>
          <Button
            class={`hidden lg:flex absolute justify-center items-center inset-y-1/4 p-4 text-gray-500 dark:text-gray-200 ${
              isFullScreen ? 'right-0' : '-right-3'
            } group`}
            onClick={scrollToNext}
          >
            <span class="bg-white dark:bg-gray-800 rounded-full group-hover:shadow-lg group-focus:ring-2 group-focus:ring-indigo-500 p-1">
              <IcoChevronRight class={`${isFullScreen ? 'w-12 h-12' : 'w-6 h-6'}`} />
            </span>
          </Button>
        </Case>
      </div>

      <div class={`lg:p-2 ${isFullScreen ? 'border-top-1 bg-white dark:bg-gray-900 shadow' : ''}`}>
        <p class="text-center pb-2 text-sm">
          {selectedIndex + 1} / {attachments.length}
        </p>
        <div class="carousel-viewport overflow-hidden w-full" ref={thumbViewportRef}>
          <div class="carousel-container flex">
            {attachments.map((attachment, index) => (
              <Thumbnail
                key={attachment.id}
                index={index}
                selected={index === selectedIndex}
                attachment={attachment}
                isFullScreen={isFullScreen}
                isLast={index === attachments.length - 1}
                canDelete={!!editMode && !isFullScreen}
                onClick={() => {
                  embla?.scrollTo(index);
                }}
                onDelete={onDelete}
                onSort={onSort}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
