import { showDialog } from '@components/dialog';
import { router } from '@components/router';

export async function showLostChangesWarning(opts: {
  gotoHref: string;
  hasUnsavedChanges: boolean;
}) {
  const ok =
    !opts.hasUnsavedChanges ||
    (await showDialog({
      title: 'Discard your changes?',
      children: `You have unsaved changes. Would you like to discard your changes?`,
      mode: 'warn',
      cancelButtonText: 'Keep editing',
      confirmButtonText: 'Discard my changes',
    }));
  if (ok) {
    router.goto(opts.gotoHref);
  }
}
