import { CurrentUserProfileIcon } from '@components/avatars';
import { Button } from '@components/buttons';
import { IcoImage } from '@components/icons';
import { filepicker } from 'client/components/filepicker';
import { useState } from 'preact/hooks';
import { FileRec } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import { CommentEditor } from './comment-editor';
import { toCommentAttachment } from './utils';

export function RootCommentForm(props: { courseId: UUID }) {
  const intl = useIntl();
  const [isActive, setIsActive] = useState(true);
  const [autoFocus, setAutoFocus] = useState(false);
  const [attachment, setAttachment] = useState<FileRec | undefined>(undefined);

  const attachFile = async () => {
    const result = await filepicker();
    if (result) {
      setAttachment(toCommentAttachment(result));
      setIsActive(true);
    }
  };

  if (isActive) {
    return (
      <div class="flex flex-grow">
        <div class="flex-grow relative dark:border-gray-700">
          <CommentEditor
            courseId={props.courseId}
            attachments={attachment ? [attachment] : []}
            buttonTitle={intl('Post')}
            insertMenuTriggerHTML={`<span class="text-indigo-700 theme-link dark:text-gray-400 dark:hover:text-white inline-flex items-center relative text-sm"><svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
        </svg>
          <span class="sm:hidden">${intl('Insert file')}</span>
          <span class="hidden sm:inline-block">${intl('Add a photo, video, or file')}</span>
          <span class="hidden sm:inline-block absolute -left-3 inset-y-0 border-r dark:border-gray-700"></span>
        </span>`}
            autoFocus={autoFocus}
            onHide={() => {
              setIsActive(false);
              setAutoFocus(true);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div class="flex flex-col border sm:flex-row rounded-2xl cursor-pointer relative dark:bg-gray-800 dark:border-none hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-200">
      <Button
        onClick={() => setIsActive(true)}
        class="flex grow theme-link text-indigo-600 dark:text-gray-200 text-left p-4 pr-0 focus:outline-none items-center justify-start focus:border-indigo-400 border-2 border-transparent rounded"
      >
        <CurrentUserProfileIcon size="w-10 h-10 shadow-md" />
        <span class="ml-3">{intl('Add a comment')}</span>
      </Button>
      <Button
        class="theme-link py-2 px-4 text-indigo-500 hidden dark:text-sky-500 rounded-2xl sm:flex items-center justify-center border border-transparent hover:border-indigo-200"
        onClick={attachFile}
      >
        <IcoImage class="mx-1" />
        <span>{intl('Add a photo, video, or file')}</span>
      </Button>
    </div>
  );
}
