import { AccessFormat } from 'server/types';
import { v4 as uuidv4 } from 'uuid';

/**
 * Determine if x is null or undefined.
 */
export function isNullish(x: unknown): x is null | undefined {
  return x == null;
}

export function truncateId(id: UUID, size = -6) {
  return id ? `...${id.slice(size)}` : '';
}

export function compactId(id: UUID) {
  return id ? `${id.slice(0, 4)}...${id.slice(-4)}` : '';
}

/**
 * This file contains Ramda-like functions.
 */
export function props<V>(props: any[], obj: { [k: string]: V } | { [k: number]: V }): V[] {
  return obj && props ? props.map((p) => (obj as any)[p]) : [];
}

type PipeFn<TState> = (state: TState) => TState;

export function pipe<TState>(...fns: PipeFn<TState>[]): PipeFn<TState> {
  return (state) => fns.reduce((acc, fn) => fn(acc), state);
}

/**
 * Remove the specified key from the object.
 */
export function dissoc<T, K extends keyof T>(k: K, obj: T): Omit<T, K> {
  const result = { ...obj };
  delete result[k];
  return result;
}

/**
 * Insert item uniquely into the array. If it's already there, it will
 * be repositioned.
 * @param {any} item the item being inserted / moved
 * @param {'before'|'after'} direction indicates whether to place item before or after the specified sibling
 * @param {any} sibling the item before / after which item is being inserted
 * @param {any[]} arr the array
 */
export function insertUnique<T>(
  item: T,
  direction: 'before' | 'after',
  sibling: T | undefined,
  arr: T[],
): T[] {
  const result = arr.filter((x) => x !== item);
  const siblingIndex = sibling ? result.indexOf(sibling) : -1;
  const insertAt = direction === 'before' ? siblingIndex : siblingIndex + 1;

  result.splice(insertAt, 0, item);

  return result;
}

/**
 * Return last element of array
 */
export function last<T>(arr: T[]): T | undefined {
  return arr.length ? arr[arr.length - 1] : undefined;
}

/**
 * Given two arrays, return values of arr that do not exist in omit array
 */
export function exclude<T>(arr: T[], omit: T[]): T[] {
  const valuesToOmit = new Set(omit);
  return arr.filter((x) => !valuesToOmit.has(x));
}

export function groupBy<T, K extends number | string>(fn: (t: T) => K, items: T[]): Record<K, T[]> {
  return items.reduce((acc, item) => {
    const k = fn(item);
    const arr = acc[k] || [];
    arr.push(item);
    acc[k] = arr;
    return acc;
  }, {} as Record<K, T[]>);
}

export function indexBy<T, K extends number | string>(fn: (t: T) => K, items: T[]): Record<K, T> {
  return items.reduce((acc, p) => {
    acc[fn(p)] = p;
    return acc;
  }, {} as Record<K, T>);
}

/**
 * Split the specified array into two arrays: [truthy[], falsy[]]
 */
export function partition<T>(fn: (t: T) => boolean, items: T[]): [T[], T[]] {
  return items.reduce<[T[], T[]]>(
    (acc, p) => {
      if (fn(p)) {
        acc[0].push(p);
      } else {
        acc[1].push(p);
      }
      return acc;
    },
    [[], []],
  );
}

export function uniqueBy<T>(fn: (t: T) => any, items: T[]): T[] {
  const set = new Set();
  return items.filter((x) => {
    const k = fn(x);
    if (set.has(k)) {
      return false;
    }
    set.add(k);
    return true;
  });
}

export function unique<T>(items: T[]): T[] {
  return Array.from(new Set(items));
}

/**
 * Perform a shallow comparison of two objects / values.
 */
export function shallowEq(a: any, b: any) {
  if (a === b) {
    return true;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return isNaN(a) && isNaN(b);
  }

  if (!a || !b) {
    return false;
  }
  for (const k in a) {
    if (a[k] !== b[k]) {
      return false;
    }
  }
  for (const k in b) {
    if (a[k] !== b[k]) {
      return false;
    }
  }
  return true;
}

/**
 * Return a function which can be called multiple
 * times, but will only run fn once.
 */
export const once = (fn: () => void) => {
  let ran = false;
  return () => {
    if (!ran) {
      ran = true;
      fn();
    }
  };
};

/**
 * Determine whether or not the specified access format supports scheduling
 * of any kind.
 */
export const isSchedulable = (accessFormat: AccessFormat) =>
  accessFormat === 'ondemand' || accessFormat === 'scheduled';

/**
 * Create a new validation error.
 */
export function validationError(...errors: Array<{ field: string; message: string }>) {
  return { data: { errors } };
}

/**
 * Create a new validation error.
 */
export function validationErrorWithMessage({
  errors,
  message,
}: {
  errors: Array<{ field: string; message: string }>;
  message: string;
}) {
  return { data: { errors }, message };
}

/*
 * Returns `Foo` for `foo`.
 */
export function capitalizeFirst(s: string) {
  if (s.length === 0) {
    return s;
  }
  return s[0].toUpperCase() + s.slice(1);
}

export function generateUUID() {
  return uuidv4() as UUID;
}
