import { rpx } from 'client/lib/rpx-client';
import { UserProfileIcon } from '@components/avatars';
import { BtnSecondary, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { IcoArrowLeft } from '@components/icons';
import { truncateId } from 'shared/utils';
import { ModalForm, showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { showToast } from '@components/toaster';
import { TenantUsersList } from '../admin-people/user-list';
import { CourseRow, Tenant } from 'server/types';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { TenantsList } from '../superadmin-manage-tenants/tenants-list';
import { FormGroup } from '@components/async-form';
import { StandardDialog } from '@components/dialog';

type Course = Pick<CourseRow, 'id' | 'title' | 'isBundle'> & {
  guide: {
    id: UUID;
  };
};

export function showCourseCopyModal(course: Course) {
  showModalForm(({ resolve }) => {
    const currentUser = useCurrentUser();
    const currentTenant = useCurrentTenant();
    const courseLabel = course.isBundle ? 'bundle' : 'course';

    const [toUser, setToUser] = useState<{ id: UUID; name?: string } | undefined>(undefined);
    const [toTenant, setToTenant] = useState<Pick<Tenant, 'id' | 'name'>>(currentTenant);
    const [isSelectingTenant, setIsSelectingTenant] = useState(false);

    const copyCourse = async (formData: { title: string }) => {
      if (!toUser) {
        return;
      }
      try {
        await rpx.admin.copyCourseAsAdmin({
          courseId: course.id,
          toGuideId: toUser.id,
          tenantId: toTenant.id,
          title: formData.title,
          isBundle: course.isBundle,
          // Do not duplicate subcourses if the course is being copied to the same user
          duplicateSubcourses: course.guide.id !== toUser.id,
        });
        showToast({
          title: 'Copy succeeded',
          message: `The ${courseLabel} has been copied to ${toUser.name} with the title "${formData.title}".`,
          type: 'ok',
        });
        resolve(true);
      } catch (err) {
        if (err.type === 'validation') {
          throw err;
        }
        showError(err);
      }
    };

    if (toUser) {
      return (
        <ModalForm
          onClose={resolve}
          onSubmit={copyCourse}
          title={
            <>
              Copy the {courseLabel} to <strong>{toUser.name}</strong>
            </>
          }
          confirmButtonText={<>Copy the {courseLabel}</>}
        >
          <nav>
            <Button
              type="button"
              class="inline-flex items-center gap-2 text-indigo-600"
              onClick={() => setToUser(undefined)}
            >
              <IcoArrowLeft /> Back
            </Button>
          </nav>

          <FormGroup prop="title">
            <label>
              <span>Enter the title of the new {courseLabel}</span>
              <input
                type="text"
                placeholder={`${courseLabel} title`}
                name="title"
                class="ruz-input"
                defaultValue={course.title}
              />
            </label>
          </FormGroup>
        </ModalForm>
      );
    }

    if (isSelectingTenant) {
      return (
        <StandardDialog contentWidth onClose={resolve} title="Select a tenant">
          <TenantsList
            onItemSelect={(tenant) => {
              setToTenant(tenant);
              setIsSelectingTenant(false);
            }}
          />
        </StandardDialog>
      );
    }

    return (
      <StandardDialog
        contentWidth
        onClose={resolve}
        title={
          <>
            Copy {courseLabel}: choose a guide from {toTenant.name} tenant
          </>
        }
        subtitle={
          currentUser?.level === 'superadmin' && (
            <BtnSecondary onClick={() => setIsSelectingTenant(true)}>
              Select another tenant
            </BtnSecondary>
          )
        }
      >
        <TenantUsersList
          type="all"
          tenantId={toTenant.id}
          userLevels={['guide', 'admin', 'superadmin']}
          tableHeaders={['New Guide', 'Role / ID']}
          onSelect={setToUser}
          render={({ user, href }) => (
            <a
              class="table-row text-inherit hover:bg-indigo-50 text-sm"
              href={`#${href}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setToUser(user);
              }}
            >
              <span class="table-cell p-4 border-t">
                <span class="flex items-center">
                  <UserProfileIcon user={user} size="w-10 h-10" />
                  <span class="flex flex-col ml-4">
                    <strong>{user.name}</strong>
                    <span>{user.email}</span>
                  </span>
                </span>
              </span>
              <span class="table-cell p-4 border-t">
                <span class="flex flex-col">
                  <span>{user.level}</span>
                  <span>{truncateId(user.id)}</span>
                </span>
              </span>
            </a>
          )}
        />
      </StandardDialog>
    );
  });
}
