import { IcoStar } from '@components/icons';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

export type FeatureTestimonial = {
  body: string;
  imagePath: string;
  author: {
    name: string;
    imagePath: string;
    description: string;
  };
};

type Props = {
  testimonial: FeatureTestimonial;
};

export function TestimonialWithFeature({ testimonial }: Props) {
  const { body, imagePath, author } = testimonial;

  return (
    <div class="flex flex-col grow justify-center items-center max-w-xl">
      <div class="rounded-2xl p-4 hidden sm:block mb-6">
        <div class="flex gap-x-1 text-indigo-600">
          <IcoStar />
          <IcoStar />
          <IcoStar />
          <IcoStar />
          <IcoStar />
        </div>
        <blockquote class="mt-10 text-xl leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
          <p
            dangerouslySetInnerHTML={{
              __html: `"${body}"`,
            }}
          ></p>
        </blockquote>
        <figcaption class="mt-10 flex items-center gap-x-6">
          <img
            class="h-12 w-12 rounded-full bg-gray-50"
            src={`${RUZUKU_ASSETS_BASE_URL}/onboarding/${author.imagePath}`}
          />
          <div class="text-sm leading-6">
            <div class="font-semibold text-gray-900">{author.name}</div>
            <div
              class="mt-0.5 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: author.description,
              }}
            />
          </div>
        </figcaption>
      </div>
      <div class="bg-gradient-to-br from-indigo-800 to-indigo-300 p-8 rounded-lg shadow-lg">
        <img src={`${RUZUKU_ASSETS_BASE_URL}/onboarding/${imagePath}`} loading="lazy" />
      </div>
    </div>
  );
}
