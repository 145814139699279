import { minidoc, on } from 'minidoc-editor';
import { useDisposableMemo } from 'client/lib/hooks';
import { uploadCount } from '../media-card/media-uploader';
import { useEffect, useRef } from 'preact/hooks';
import { EditorMiddleware, MinidocOptions } from 'minidoc-editor/dist/types/types';
import { delayedDebounce } from 'client/utils/debounce';
import { onBeforeUnload } from 'client/lib/app-route/before-unload';

export function useMinidoc<T extends Array<EditorMiddleware>>(
  opts: Omit<MinidocOptions<T>, 'middleware'> & {
    middleware?: () => MinidocOptions<T>['middleware'];
    autoFocus?: boolean;
    onChange(doc: string): void;
  },
) {
  const result = useDisposableMemo(() => {
    const editor = minidoc({
      ...opts,
      middleware: opts.middleware?.(),
    });
    const baseDispose = editor.dispose;
    const removeLeaveWarning = onBeforeUnload(() => {
      if (uploadCount(editor) <= 0) {
        return;
      }
      return `You have pending uploads. If you leave this page, they will be lost.`;
    });

    editor.dispose = () => {
      baseDispose.call(editor);
      removeLeaveWarning();
    };

    if (opts.autoFocus) {
      setTimeout(() => (editor.root as any).focus());
    }

    return editor;
  }, []);

  // We need to track the text as a ref so that the effects can efficiently refer
  // to it without regenerating the effect handlers. We use this ref to detect
  // changes to the minidoc text which need to be applied (e.g. due to sales page
  // undo / redo or other externally applied changes).
  const richText = useRef(opts.doc || '');
  const onChange = useRef(opts.onChange);

  onChange.current = opts.onChange;

  useEffect(() => {
    const bufferedOnChange = delayedDebounce(() => {
      const val = result.serialize(true);
      // If the new value is the same as the old value (e.g. due to undo / redo
      // or a manual conversion back to an original state), we do nothing. Otherwise,
      // undo / redo behavior gets lost.
      if (richText.current !== val) {
        richText.current = val;
        onChange.current(val);
      }
    }, 200);

    return on(result.root, 'mini:change', bufferedOnChange);
  }, []);

  return result;
}
