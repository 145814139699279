import { BtnSecondary } from '@components/buttons';
import { showError } from '@components/app-error';
import { useState } from 'preact/hooks';
import { IcoPencil, IcoTrash } from '@components/icons';
import { showToast } from '@components/toaster';
import { LoadingIndicator } from '@components/loading-indicator';
import { router } from '@components/router';
import { URLS } from 'shared/urls';
import { useCurrentUser } from '@components/router/session-context';
import { Case } from '@components/conditional';
import { ModalForm, showModalForm } from '@components/modal-form';
import { FormGroup } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { InstantCourse } from './index';
import { Pill } from '@components/pill';
import { Toggle } from '@components/toggle';
import { showDialog } from '@components/dialog';

interface Props {
  course: InstantCourse;
  onDelete: () => void;
  onChange: (newCourse: InstantCourse) => void;
}

const store = rpx.instantCourses;

export function InstantCourseItem({ course, onChange, onDelete }: Props) {
  const user = useCurrentUser();
  const [loading, setLoading] = useState(false);

  async function removeCourse(courseId: UUID) {
    const confirmed = await showDialog({
      mode: 'warn',
      title: 'Remove Instant Course',
      children: `Are you sure you want to remove '${course.title}' from Instant Courses?`,
      confirmButtonText: 'Remove Instant Course',
    });

    if (!confirmed) {
      return;
    }

    setLoading(true);
    try {
      await store.removeCourse({ courseId });
      showToast({
        title: 'Course removed from instant courses',
        message: `${course.title} has been removed from instant courses list.`,
        type: 'ok',
      });
      onDelete();
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  }

  async function mimicAndView() {
    setLoading(true);
    try {
      await rpx.admin.mimicUser({ userId: course.guideId });
      location.assign(
        URLS.guide.course({
          courseId: course.id,
        }),
      );
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div key={course.id} class="table-row text-inherit text-sm">
        <LoadingIndicator isLoading={loading} />
        <span class="table-cell p-4 border-t">
          <a
            className="mr-2"
            href={URLS.admin.course({
              courseId: course.id,
            })}
          >
            {course.title}
          </a>
          {course.isGlobal && <Pill color="yellow">Global</Pill>}
        </span>
        <span class="table-cell p-4 border-t">{course.category}</span>
        <div class="flex p-4 border-t justify-end items-center space-x-2">
          <Case
            when={user?.id !== course.guideId}
            fallback={
              <BtnSecondary
                class="px-10"
                onClick={() =>
                  router.goto(
                    URLS.guide.course({
                      courseId: course.id,
                    }),
                  )
                }
              >
                View
              </BtnSecondary>
            }
          >
            <BtnSecondary isLoading={loading} onClick={mimicAndView}>
              Mimic &amp; view
            </BtnSecondary>
          </Case>
          <BtnSecondary
            class="hover:text-red-500 hover:border-red-500"
            isLoading={loading}
            onClick={() => removeCourse(course.id)}
          >
            <IcoTrash class="w-4 h-4" />
          </BtnSecondary>
          <BtnSecondary
            isLoading={loading}
            onClick={() => {
              showModalForm(() => (
                <ModalForm
                  title={`Modify ${course.title}`}
                  confirmButtonText="Update"
                  onSubmit={async (data) => {
                    await store.updateCourse({
                      courseId: course.id,
                      category: data.category,
                      description: data.description,
                      isGlobal: data.isGlobal,
                    });
                    showToast({
                      title: 'Saved',
                      message: `Updates are saved for '${course.title}'.`,
                      type: 'ok',
                    });
                    onChange({
                      ...course,
                      category: data.category,
                      description: data.description,
                      isGlobal: data.isGlobal,
                    });
                  }}
                >
                  <div class="space-y-4 my-6">
                    <FormGroup prop="category">
                      <label class="space-y-1">
                        <span>Category</span>
                        <input
                          class="ruz-input"
                          placeholder="Category"
                          type="text"
                          name="category"
                          // @ts-ignore
                          defaultValue={course.category}
                        />
                      </label>
                    </FormGroup>
                    <FormGroup prop="description">
                      <label class="space-y-1">
                        <span>Description</span>
                        <textarea
                          placeholder="Description"
                          name="description"
                          class="ruz-input w-full h-48 font-mono"
                          // @ts-ignore
                          defaultValue={course.description || ''}
                        />
                      </label>
                    </FormGroup>
                    <FormGroup prop="isGlobal">
                      <Toggle class="mr-2" name="isGlobal" checked={course.isGlobal} />
                      <span>Is Global</span>
                    </FormGroup>
                  </div>
                </ModalForm>
              ));
            }}
          >
            <IcoPencil class="w-4 h-4" />
          </BtnSecondary>
        </div>
      </div>
      {!!course.description && (
        <div class="table-row">
          <div class="p-4 pt-0 -mt-2 text-xs italic">{course.description}</div>
        </div>
      )}
    </>
  );
}
