import { ModalForm, showModalForm } from '@components/modal-form';
import { Folder, store } from './state';
import { showToast } from '@components/toaster';
import { FormGroup } from '@components/async-form';

export function showRenameFolderModal(props: { folder: Folder; onRename(name: string): void }) {
  return showModalForm(() => {
    return (
      <ModalForm
        title="Rename Folder"
        confirmButtonText="Rename Folder"
        onSubmit={async (data) => {
          const result = await store.renameFolder({
            id: props.folder.id,
            name: data.name,
          });
          props.onRename(result.name);
          showToast({
            title: 'Folder renamed',
            message: `Renamed ${props.folder.name} to ${result.name}.`,
            type: 'ok',
          });
        }}
      >
        <FormGroup
          class="flex-1 overflow-y-auto mini-scroll max-h-(screen-80) pl-1 pr-2"
          prop="toFolderId"
        >
          <label class="space-y-1 sticky top-0 bg-white block py-2">
            <input
              class="ruz-input"
              type="text"
              name="name"
              placeholder="Folder name"
              defaultValue={props.folder.name}
            />
          </label>
        </FormGroup>
      </ModalForm>
    );
  });
}
