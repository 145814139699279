import { showError } from '@components/app-error';
import { FormGroup } from '@components/async-form';
import { BtnCopy, BtnPrimary } from '@components/buttons';
import { Case } from '@components/conditional';
import { useRouteParams } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { Toggle } from '@components/toggle';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { JSX, ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { Meeting } from 'server/types';
import { handleZoomIntegrationError } from './helpers';
import { RuzukuCallDetails } from './ruzuku-call-details';

export type MeetingTypeData = Pick<
  Meeting,
  | 'type'
  | 'slidesFile'
  | 'recordMeeting'
  | 'allowRecordingDownloads'
  | 'externalJoinUrl'
  | 'zoomMeetingId'
  | 'zoomPassword'
  | 'zoomHasWaitingRoom'
> & {
  id?: UUID;
};

interface RadioProps extends JSX.HTMLAttributes<HTMLInputElement> {
  title: string;
  desc: ComponentChildren;
  details?: ComponentChildren;
}

interface Props {
  canEditDetails: boolean;
  onChange: (subset: Partial<MeetingTypeData>, isLocalUpdate?: boolean) => void;
  onSlideDelete: () => void;
}

const store = rpx.zoom;

function zoomJoinLink(meeting: Pick<Meeting, 'zoomMeetingId' | 'zoomPassword'>) {
  if (!meeting.zoomPassword || !meeting.zoomMeetingId) {
    return undefined;
  }
  return `https://zoom.us/j/${meeting.zoomMeetingId}?pwd=${meeting.zoomPassword}`;
}

function MeetingTypeRadio({ title, desc, details, ...props }: RadioProps) {
  const cursor = !props.checked && !props.disabled ? 'cursor-pointer' : '';
  return (
    <div
      class={`my-2 p-4 pr-8 rounded-md border text-sm ${
        props.checked ? 'border-indigo-600 ring-1 ring-indigo-600 text-gray-700' : cursor
      } `}
    >
      <label class={`flex items-start ${cursor}`}>
        <input
          type="radio"
          class={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-4 mt-1 ${
            props.disabled ? 'opacity-40 cursor-not-allowed' : ''
          }`}
          {...props}
        />
        <span class="flex flex-col">
          <span class={`font-bold mb-2 ${props.disabled ? 'opacity-40' : ''}`}>{title}</span>
          <p class="text-gray-500 text-sm mb-3 -mt-1 opacity-100">{desc}</p>
        </span>
      </label>
      {!!details && props.checked && <div class="px-8 py-4">{details}</div>}
    </div>
  );
}

function ZoomCallDetails({
  meeting,
  canEditDetails,
  onChange,
}: {
  meeting: MeetingTypeData;
  canEditDetails: boolean;
  onChange: Props['onChange'];
}) {
  const { courseId } = useRouteParams();
  const url = zoomJoinLink(meeting);
  const [isCreatingZoomMeeting, setIsCreatingZoomMeeting] = useState(false);

  async function createZoomMeeting() {
    if (!meeting.id) {
      return;
    }

    setIsCreatingZoomMeeting(true);
    try {
      const result = await rpx.meetings.createMissingZoomMeeting({
        meetingId: meeting.id,
        courseId,
      });
      onChange(
        {
          zoomMeetingId: result.id,
          zoomPassword: result.password,
        },
        true,
      );
    } catch (err) {
      const isHandled = await handleZoomIntegrationError({
        err,
        courseId,
        meetingId: meeting.id,
      });
      if (!isHandled) {
        showError(err);
      }
      setIsCreatingZoomMeeting(false);
    }
  }

  return (
    <div>
      {!!meeting.id && canEditDetails && (
        <div class="border-b pb-4">
          {!!url && (
            <div class="flex justify-between items-center grow bg-indigo-100 rounded-lg p-4">
              <span class="max-w-96 break-words">{url}</span>
              <BtnCopy value={url}>Copy student invitation link</BtnCopy>
            </div>
          )}
          {!url && (
            <BtnPrimary isLoading={isCreatingZoomMeeting} onClick={createZoomMeeting}>
              Show invitation link
            </BtnPrimary>
          )}
        </div>
      )}
      <div class="pt-4 gap-3">
        <label
          class={`flex items-center gap-3 ${
            canEditDetails ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'
          }`}
        >
          <Toggle
            checked={meeting.recordMeeting}
            disabled={!canEditDetails}
            onClick={() =>
              onChange({
                recordMeeting: !meeting.recordMeeting,
              })
            }
          />
          <span>Record Event</span>
        </label>
        <p class="text-xs mt-2 mb-4">
          {canEditDetails
            ? 'Please note that you may start and pause the the session recording anytime after the event starts.'
            : 'This option is disabled because the meeting has started.'}
        </p>
        <label class="flex items-center gap-3 cursor-pointer pb-4">
          <Toggle
            checked={meeting.allowRecordingDownloads && (!canEditDetails || meeting.recordMeeting)}
            onClick={() =>
              onChange({
                allowRecordingDownloads: !meeting.allowRecordingDownloads,
              })
            }
          />
          <span>Allow students to download recordings</span>
        </label>
        <div class="pt-4 border-t">
          <label class="flex items-center gap-3 cursor-pointer">
            <Toggle
              checked={meeting.zoomHasWaitingRoom}
              onClick={() =>
                onChange({
                  zoomHasWaitingRoom: !meeting.zoomHasWaitingRoom,
                })
              }
            />
            <span>Enable Waiting Room</span>
          </label>
          {meeting.zoomMeetingId && (
            <p class="text-xs mt-2 mb-4">
              You can visit{' '}
              <a
                href={`https://zoom.us/meeting/${meeting.zoomMeetingId}/edit`}
                target="_blank"
                rel="noopener noreferrer"
              >
                the Zoom page of this meeting
              </a>{' '}
              to see all configuration options.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export function MeetingTypes(
  props: Props & {
    meeting: MeetingTypeData;
    canEditDetails: boolean;
    hideDetails?: boolean;
  },
) {
  const { meeting, hideDetails, onChange } = props;
  const { terminology } = useCurrentTenant();
  const [hasZoomAccount, setHasZoomAccount] = useState(false);

  useAsyncEffect(async () => {
    const result = await store.getZoomAccount();
    setHasZoomAccount(result.hasAccount);
  }, []);

  return (
    <>
      <MeetingTypeRadio
        title="Video Conference"
        desc="The host and participants may stream their audio/video in this option. Support up to 60 participants."
        checked={meeting.type === 'videoconference'}
        details={!hideDetails && <RuzukuCallDetails {...props} />}
        onClick={() => onChange({ type: 'videoconference' })}
      />
      <MeetingTypeRadio
        title="Presentation"
        desc="Only the host streams audio/video in this option. Supports up to 250 participants."
        checked={meeting.type === 'presentation'}
        details={!hideDetails && <RuzukuCallDetails {...props} />}
        onClick={() => onChange({ type: 'presentation' })}
      />
      <MeetingTypeRadio
        title="Zoom"
        disabled={!hasZoomAccount}
        desc={
          <span>
            <Case when={!hasZoomAccount} fallback={`Host your ${terminology.meeting} on Zoom.`}>
              <a href="/account/integrations">Configure Zoom </a>
              if you want to host your {terminology.meeting} on Zoom.
            </Case>
          </span>
        }
        checked={meeting.type === 'zoom'}
        details={!hideDetails && <ZoomCallDetails {...props} />}
        onClick={() => onChange({ type: 'zoom' })}
      />
      <MeetingTypeRadio
        title="External"
        desc={`Host your ${terminology.meeting} on an external platform.`}
        checked={meeting.type === 'external'}
        onClick={() => onChange({ type: 'external' })}
        details={
          !hideDetails && (
            <FormGroup prop="externalJoinUrl">
              <p class="text-gray-500 text-sm ml-1 mb-2">
                Join URL is required for external {terminology.meetings}. You may enter it anytime
                before the {terminology.meeting} starts.
              </p>
              <input
                type="text"
                class="ruz-input w-1/2"
                placeholder={`Join ${terminology.meeting} URL`}
                value={meeting.externalJoinUrl}
                onBlur={(e: any) => onChange({ externalJoinUrl: e.target.value })}
              />
              <p class="text-gray-500 text-sm ml-1 mt-4">
                You may also share any other call details in the description section.
              </p>
            </FormGroup>
          )
        }
      />
    </>
  );
}

export function TypeTab({
  meeting,
  canEditDetails,
  onChange,
  onSlideDelete,
}: Props & {
  meeting: { id: UUID } & MeetingTypeData;
}) {
  return (
    <div class="py-8">
      <MeetingTypes
        meeting={meeting}
        canEditDetails={canEditDetails}
        onChange={onChange}
        onSlideDelete={onSlideDelete}
      />
    </div>
  );
}
