import { SalesPageState } from '@components/page-builder/page-editor';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { indexBy } from 'shared/utils';
import { createContext } from 'preact';
import { Dispatch, StateUpdater } from 'preact/hooks';

export async function load() {
  const result = await rpx.storefront.load();
  const builderState: SalesPageState = {
    selectedId: result.content.blockIds[0] || '',
    content: result.content,
  };

  return {
    builderState,
    courses: indexBy((x) => x.id, result.courses),
    prices: indexBy((x) => x.id, result.prices),
    coupons: indexBy((x) => x.id, result.coupons),
  };
}

export type StorefrontCourse = RpxResponse<typeof rpx.storefront.load>['courses'][0];
export type StorefrontPrice = RpxResponse<typeof rpx.storefront.load>['prices'][0];
export type StorefrontCoupon = RpxResponse<typeof rpx.storefront.load>['coupons'][0];
export type State = Awaited<ReturnType<typeof load>>;
export interface CourseBlockContextState {
  state: State;
  setState: Dispatch<StateUpdater<State>>;
}

export const CourseBlockContext = createContext<CourseBlockContextState>({
  state: {} as any,
  setState: (() => {}) as any,
});
