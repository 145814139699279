function StatCard({ title, value, change }: { title: string; value: string; change: number }) {
  return (
    <div>
      <hr role="presentation" class="w-full border-t" />
      <div class="mt-6 font-medium">{title}</div>
      <div class="mt-3 text-2xl font-semibold">{value}</div>
      <div class="mt-3 text-xs">
        <span
          class={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 font-medium forced-colors:outline ${
            change > 0 ? 'bg-green-400/20 text-green-700' : 'bg-pink-400/15 text-pink-700'
          }`}
        >
          {change > 0 ? `+${change}` : change}
        </span>{' '}
        <span class="text-gray-500">from last week</span>
      </div>
    </div>
  );
}

export function GuideStats() {
  return (
    <>
      <div class="mt-8 flex flex-col lg:flex-row lg:items-end justify-between">
        <h2 class="text-lg font-semibold text-gray-900">Your overview</h2>
        <div>
          <select class="inline-ruz-input">
            <option>Last week</option>
            <option>Last two weeks</option>
            <option>Last month</option>
            <option>Last quarter</option>
          </select>
        </div>
      </div>
      <div class="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
        <StatCard title="Total revenue" value="$2.6M" change={4.5} />
        <StatCard title="Average order value" value="$455" change={-0.5} />
        <StatCard title="Enrolled students" value="1235" change={21.2} />
        <StatCard title="Completed lessons" value="3512" change={10.8} />
      </div>
    </>
  );
}
