import { BrandRow } from 'server/types';
import { indexBy } from './utils';

export interface ColorScheme {
  id: UUID;
  title: string;
  /**
   * The values for the ruz CSS variables.
   */
  rootVars?: {
    btnBg?: string;
    btnBgHover?: string;
    baseColor?: string;
    color?: string;
    colorHover?: string;
    colorLite?: string;
    minidocLink?: string; // optional (hex) color for links in the minidoc editor; otherwise generated from --ruz-color
    minidocLinkHover?: string; // optional (hex) hover color for links in the minidoc editor
  };
  /**
   * The style applied to the main nav.
   */
  navStyle?: string;
  /**
   * Raw CSS that overrides any desired rules.
   */
  overrides?: string;
}

const baseTheme = `
  :root {
    --ruz-link: hsl(var(--ruz-color), var(--ruz-color-lite));
    --ruz-link-hover: hsl(var(--ruz-color-hover), calc(var(--ruz-color-lite) + 10%));
  }

  .text-theme-link,
  .theme-link {
    color: var(--ruz-link);
  }

  .border-theme {
    border-color: var(--ruz-link);
  }

  .hover\\:theme-link:hover,
  .theme-link:hover {
    color: var(--ruz-link-hover);
  }

  .student-page .bg-theme-primary,
  .student-page .btn-primary {
    background: var(--ruz-btn-bg, var(--ruz-link));
  }

  .dark .student-page .bg-theme-primary:focus,
  .dark .student-page .btn-primary:focus {
    --ruz-link: var(--tw-ring-color);
  }

  .student-page .bg-theme-primary:focus,
  .student-page .btn-primary:focus {
    box-shadow: var(--tw-ring-offset-shadow), 0 0 0 4px var(--ruz-link, var(--ruz-btn-bg)), var(--tw-shadow, 0 0 #0000);
  }

  .student-page .bg-theme-primary:not(:disabled):hover,
  .student-page .btn-primary:not(:disabled):hover {
    background: var(--ruz-btn-hover-bg, var(--ruz-link-hover));
  }

  .bg-theme-100 {
    background: hsl(var(--ruz-color), 95%);
  }

  .student-page .text-theme-400 {
    color: hsl(var(--ruz-color), calc(var(--ruz-color-lite) + 10%));
  }

  .student-page a.downloads-item,
  .student-page .attachment-item a,
  .student-page .minidoc-content a {
    color: var(--minidoc-link, var(--ruz-link));
  }
  
  .student-page a.downloads-item:hover,
  .student-page .attachment-item a:hover,
  .student-page .minidoc-content a:hover {
    color: var(--minidoc-link-hover, var(--ruz-link-hover));
  }

`;

export const themeDefaults = {
  primaryActionColor: 'rgba(79, 70, 229, var(--tw-bg-opacity))',
  logoColor: '#1D4ED8',
  fromAccentColor: '#2dd4bf',
  toAccentColor: '#60a5fa',
};

export function accentGradient(theme: Pick<BrandRow, 'fromAccentColor' | 'toAccentColor'>) {
  return `linear-gradient(to right, ${theme.fromAccentColor || themeDefaults.fromAccentColor}, ${
    theme.toAccentColor || themeDefaults.toAccentColor
  })`;
}

export const DEFAULT_COLOR_SCHEME = '6926c6c7-9ff1-455d-a6fe-da534ffd0975';

// Gradients were generated here: https://www.joshwcomeau.com/gradient-generator/
export const COLOR_SCHEMES: Record<UUID, ColorScheme> = indexBy(
  (x) => x.id,
  [
    {
      id: 'eea4af39-ca78-48d4-9fbc-a7eaf361d904',
      title: 'Forest',
      rootVars: {
        color: '142, 72%',
        colorHover: '142, 76%',
        colorLite: '29%',
      },
      navStyle: `background: #14532D; color: #F0FDF4;`,
    },
    {
      id: '98f8bd79-016d-4cc3-ac5f-45d206a72574',
      title: 'Blue Deep',
      rootVars: {
        color: '221, 83%',
        colorHover: '217, 91%',
        colorLite: '60%',
      },
      navStyle: `background: #1E3A8A; color: #f7fee7;`,
    },
    {
      id: '6bdb2dd4-88b6-453c-91c8-d25e84a675b2',
      title: 'Teal',
      rootVars: {
        btnBg: `#0F766E`,
        btnBgHover: `#0D9488`,
        color: `175, 84%`,
        colorHover: `175, 77%`,
        colorLite: `32%`,
      },
      navStyle: `background: #306f6c; color: #ddf5f5;`,
    },
    {
      id: 'f8ec4820-3e1f-407e-98f9-0df64c4219f5',
      title: 'Brown',
      rootVars: {
        color: '33, 5%',
        colorHover: '25, 5%',
        colorLite: '32%',
        minidocLink: `#0eb8ff`,
        minidocLinkHover: `#0eb8ffcf`,
      },
      navStyle: `background: #44403c; color: #f5f5f5;`,
    },
    {
      id: '8dd69293-270a-48db-ab90-67a0309aba74',
      title: 'Seagreen',
      rootVars: {
        baseColor: `164`,
        color: 'var(--ruz-base-color), 90%',
        colorHover: '164, 80%',
        colorLite: '30%',
      },
      navStyle: `
        background-image: linear-gradient(
          210deg,
          hsl(240deg 83% 66%) 0%,
          hsl(208deg 100% 50%) 4%,
          hsl(202deg 100% 50%) 10%,
          hsl(191deg 100% 44%) 18%,
          hsl(174deg 100% 38%) 30%,
          hsl(156deg 84% 44%) 48%,
          hsl(164deg 100% 40%) 71%,
          hsl(170deg 100% 41%) 85%,
          hsl(175deg 100% 41%) 93%,
          hsl(179deg 100% 41%) 97%,
          hsl(183deg 79% 48%) 100%
        );
        color: white;
      `,
      overrides: `
        .light .student-page .bg-theme-100 {
          background: hsl(var(--ruz-base-color), 70%, 95%);
        }
      `,
    },
    {
      id: 'd259b8a4-9fbd-4d3e-b66f-1255b3bd061c',
      title: 'Purple Pop',
      rootVars: {
        color: '271, 81%',
        colorHover: '293, 69%',
        colorLite: '56%',
      },
      navStyle: `
        background-image: linear-gradient(
          45deg,
          hsl(240deg 83% 66%) 0%,
          hsl(252deg 77% 59%) 25%,
          hsl(266deg 75% 49%) 50%,
          hsl(272deg 77% 49%) 75%,
          hsl(277deg 79% 48%) 100%
        );
        color: white;
      `,
    },
    {
      id: 'a3c2215e-abe6-4d3d-8a39-8cc8fbc69e4e',
      title: 'Purple Deep',
      rootVars: {
        color: '263, 36%',
        colorHover: '258, 90%',
        colorLite: '50%',
      },
      navStyle: `background: #432e59; color: #eef2ff;`,
    },
    {
      id: '6926c6c7-9ff1-455d-a6fe-da534ffd0975',
      title: 'Default',
    },
    {
      id: 'ed0df508-6b2f-4dd7-9247-aa66899df878',
      title: 'Red',
      rootVars: {
        btnBg: `#b91c1c`,
        btnBgHover: `#dc2626`,
        minidocLink: `#b91c1c`,
        minidocLinkHover: `#dc2626`,
      },
      navStyle: `background: #991b1b; color: #fef2f2;`,
    },
    {
      id: 'a570ca5e-9395-4572-962f-e63274b4f2e8',
      title: 'Orange',
      rootVars: {
        btnBg: `#d97706`,
        btnBgHover: `#b45309`,
        minidocLink: `#d97706`,
        minidocLinkHover: `#b45309`,
      },
      navStyle: `background: #d97706; color: #fffbeb;`,
    },
    {
      id: '09d4fc6d-90d7-452d-b852-ed67fd12fe83',
      title: 'Salmon',
      rootVars: {
        btnBg: `#eb614d`,
        btnBgHover: `#db513d`,
        minidocLink: `#eb614d`,
        minidocLinkHover: `#db513d`,
      },
      navStyle: `background: #eb614d; color: #fff1f2;`,
    },
  ],
);

function rootVars(vars: ColorScheme['rootVars']) {
  if (!vars) {
    return '';
  }
  const rootVar = (k: string, val: string | undefined) => (!val ? '' : `--${k}: ${val};`);
  return `:root {
    ${rootVar('ruz-base-color', vars.baseColor)}
    ${rootVar('ruz-btn-bg', vars.btnBg)}
    ${rootVar('ruz-btn-hover-bg', vars.btnBgHover)}
    ${rootVar('ruz-color', vars.color)}
    ${rootVar('ruz-color-hover', vars.colorHover)}
    ${rootVar('ruz-color-lite', vars.colorLite)}
    ${rootVar('minidoc-link', vars.minidocLink)}
    ${rootVar('minidoc-link-hover', vars.minidocLinkHover)}
  }`;
}

export function primaryNavRules(rules: string) {
  return `
    @media (min-width: 1024px) {
      .theme-primary-nav {${rules}}
    }
  `;
}

export function colorCSS(scheme: ColorScheme) {
  if (!scheme.rootVars && !scheme.navStyle && !scheme.overrides) {
    return '';
  }
  return [
    rootVars(scheme.rootVars),
    scheme.navStyle ? primaryNavRules(scheme.navStyle) : '',
    baseTheme,
    scheme.overrides || '',
  ].join('');
}
