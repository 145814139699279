import { showError } from '@components/app-error';
import { BtnPrimary, Button } from '@components/buttons';
import { LoadingIndicator } from '@components/loading-indicator';
import { ManualDom } from '@components/manual-dom';
import { filepicker } from 'client/components/filepicker';
import { useDisposableMemo } from 'client/lib/hooks';
import { useContext, useState } from 'preact/hooks';
import { Comment, CommentAttachment } from 'server/types';
import { DiscussionContext } from './reducer';
import {
  defaultToolbarActions,
  minidoc,
  minidocToolbar,
  placeholder as placeholderMiddleware,
  scrubbable,
} from 'minidoc-editor';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { toCommentAttachment } from './utils';
import { useCtrlSaveKey } from 'client/lib/hooks';
import { useCurrentUser } from '@components/router/session-context';
import { Case } from '@components/conditional';
import { Carousel } from '@components/attachments/carousel';
import { CurrentUserProfileIcon } from '@components/avatars';
import { showToast } from '@components/toaster';
import { useIntl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';
import { Mentionable } from './mention-picker';

const store = rpx.comments;

interface Props {
  courseId: UUID;
  comment?: Comment;
  parent?: Comment;
  attachments?: CommentAttachment[];
  buttonTitle: string;
  class?: string;
  autoFocus?: boolean;
  onHide: () => void;
  /**
   * The content of the minidoc toolbar "insert" menu trigger
   */
  insertMenuTriggerHTML?: string;
}

// Swap positions x and y in array arr
function swap<T>(arr: T[], x: number, y: number) {
  const newArr = [...arr];
  const tmp = newArr[x];
  newArr[x] = newArr[y];
  newArr[y] = tmp;
  return newArr;
}

export function CommentEditor(props: Props) {
  const intl = useIntl();
  const { comment, parent, buttonTitle, class: className = '', onHide } = props;
  const user = useCurrentUser()!;

  const [{ discussionId }, dispatch] = useContext(DiscussionContext);
  const [isSaving, setIsSaving] = useState(false);

  const [attachments, setAttachments] = useState<CommentAttachment[]>(
    props.attachments || comment?.attachments || [],
  );
  const visibleAttachments = attachments.filter((a) => !a.isDeleted);

  const editor = useDisposableMemo(() => {
    const blockquote = defaultToolbarActions.find((x) => x.id === 'blockquote')!;
    const bold = defaultToolbarActions.find((x) => x.id === 'bold')!;
    const italic = defaultToolbarActions.find((x) => x.id === 'italic')!;
    const link = defaultToolbarActions.find((x) => x.id === 'link')!;

    const result = minidoc({
      doc: comment?.content || '',
      middleware: [
        placeholderMiddleware(
          parent
            ? intl('Replying to {name:string} ...', {
                name: parent.user.name,
              })
            : intl('Enter your response here...'),
        ),
        scrubbable.middleware(
          scrubbable.createScrubber({
            leaf: {
              P: {},
              BLOCKQUOTE: {},
            },
            child: {
              A: scrubbable.rules.child.A,
              BR: {},
              // Both of bold and italic tags are supported
              STRONG: {},
              B: {},
              EM: {},
              I: {},
            },
            allowEmpty: ['HR'],
          }),
        ),
        minidocToolbar([
          bold,
          italic,
          blockquote,
          link,
          toolbarDropdown({
            mediaOnly: true,
            triggerHTML: props.insertMenuTriggerHTML,
            intl,
            onItemSelect: async () => {
              const result = await filepicker();
              if (result) {
                const newAttachment = toCommentAttachment(result);

                if (newAttachment) {
                  setAttachments((attachments) => {
                    return [...attachments, newAttachment];
                  });
                }
              }
            },
          }),
        ]),
      ],
    });

    result.root.classList.add('minidoc-p-6', 'px-4', 'pt-6');

    if (props.autoFocus !== false) {
      setTimeout(() => (result.root as any).focus());
    }
    return result;
  }, []);

  const updateAttachment = (id: UUID, updates: Partial<CommentAttachment>) => {
    setAttachments((a) =>
      a.map((x) =>
        x.id === id
          ? {
              ...x,
              ...updates,
            }
          : x,
      ),
    );
  };

  const save = async () => {
    if (!editor.root) {
      return;
    }
    setIsSaving(true);
    try {
      const EMPTY_LINE = '<p><br></p>';
      // Remove leading and trailing empty lines
      const regex = new RegExp(`^${EMPTY_LINE}+|${EMPTY_LINE}+$`, 'g');
      const content = editor.serialize().replace(regex, '');

      // If the content is empty, don't save
      if (!content && attachments.length === 0) {
        showToast({
          type: 'warn',
          title: intl('Empty comment'),
          message: intl('Please enter some content before posting a comment.'),
        });
        return;
      }

      // Mentions are stored as anchor tags with a data-user-id attribute.
      // The data attribute gets stripped when we save, but we use it here
      // to extract the new mentions so the backend can notify the relevant
      // users, etc.
      const mentions = Array.from(editor.root.querySelectorAll<HTMLElement>('a[data-user-id]'))
        .map((x) => x.dataset.userId || '')
        .filter((x) => !!x);

      const payload = {
        id: comment?.id,
        discussionId,
        parentId: parent?.id,
        mentions,
        content,
        files: attachments.map((a) => ({
          id: a.id,
          path: a.url,
          isDeleted: a.isDeleted,
        })),
      };
      const result = await store.saveComment(payload);
      dispatch({
        type: 'saved',
        payload: {
          ...result,
          content: payload.content,
        },
      });
      onHide();
    } catch (err) {
      showError(err);
    } finally {
      setIsSaving(false);
    }
  };

  // Post the comment on CTRL + Enter keys.
  useCtrlSaveKey((e) => {
    const target = e.target as HTMLElement;
    // Ignore if the target is not the editor.
    if (target && editor.root.contains(target)) {
      save();
    }
  }, 'Enter');

  return (
    <div class={`${className} w-full sm:text-sm leading-6`}>
      {isSaving && <LoadingIndicator />}

      <div class="bg-white dark:bg-gray-700 border dark:border-none rounded-lg relative">
        <div>
          <Case when={visibleAttachments.length > 0}>
            <Carousel
              attachments={visibleAttachments}
              user={user}
              editMode
              onDelete={(attachment) =>
                updateAttachment(attachment.id, {
                  isDeleted: true,
                })
              }
              onSort={(attachment, direction) => {
                const index = attachments.findIndex((a) => a.id === attachment.id);
                const move = direction === 'next' ? 1 : -1;
                const newIndex = index + move;

                const newAttachments = swap(attachments, index, newIndex);
                setAttachments(newAttachments);
              }}
            />
          </Case>
          <div class="flex relative">
            <Mentionable courseId={props.courseId} editor={editor} />
            <span class="pl-4 pt-4">
              <CurrentUserProfileIcon size="w-8 h-8" />
            </span>
            <ManualDom class="flex-grow prose prose-indigo dark:prose-invert" el={editor.root} />
          </div>
          <header class="flex items-center text-inherit px-2 border-t dark:border-gray-600 text-gray-600 dark:text-gray-400">
            <ManualDom el={editor.toolbar.root} />
          </header>
        </div>
      </div>

      <footer class="flex items-center justify-end dark:border-gray-700 text-gray-600 dark:text-gray-400 mt-6">
        <Button
          class="mr-6 border-none shadow-none text-base text-inherit dark:hover:text-white"
          onClick={onHide}
        >
          {intl('Cancel')}
        </Button>
        <BtnPrimary class="p-2 px-6" isLoading={isSaving} onClick={save}>
          {buttonTitle}
        </BtnPrimary>
      </footer>
    </div>
  );
}
