import { router, useRouteParams } from '@components/router';
import { LoginWrapper } from '@components/login-wrapper';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { rpx } from 'client/lib/rpx-client';
import { LoadingIndicator } from '@components/loading-indicator';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { detectTimezone } from 'shared/dateutil';

const demoStore = rpx.guideDemoAuth;

function DemoLoginPage() {
  const { email, templateId } = useRouteParams();
  useDocumentTitle(['Demo Login to Ruzuku']);

  useAsyncEffect(async () => {
    const { redirectTo } = await demoStore.createDemoAccount({
      templateId,
      email,
      timezone: detectTimezone(),
    });
    location.assign(redirectTo);
  }, []);

  return (
    <LoginWrapper>
      <LoadingIndicator />
    </LoginWrapper>
  );
}

router.add({
  url: 'demo-login',
  isPublic: true,
  render: DemoLoginPage,
});
