export const weekdayColumnFormatter = new Intl.DateTimeFormat(undefined, { weekday: 'narrow' });
export const monthFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  year: 'numeric',
});
export const weekdayFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
});
export const fullDate = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  year: 'numeric',
});
export const summarizeDate = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
});
export const time12Formatter = new Intl.DateTimeFormat(undefined, {
  hour12: true,
  timeStyle: 'short',
});
export const time24Formatter = new Intl.DateTimeFormat(undefined, {
  hour12: false,
  timeStyle: 'short',
});
export const timeZoneFormatter = new Intl.DateTimeFormat(undefined, { timeZoneName: 'long' });

export function timeZoneName() {
  return timeZoneFormatter.formatToParts().find((x) => x.type === 'timeZoneName')?.value;
}

export function getDate(dt: Date = new Date()) {
  return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
}
