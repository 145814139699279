/**
 * This file contains the form that we display in the limits tab for prices
 * (and maybe coupons), as well as in the "new price" screen.
 */
import { FormSection, FormSubSection } from '@components/async-form';
import { useState } from 'preact/hooks';
import { CourseAccessDuration } from '@components/duration-select';
import { Toggle } from '@components/toggle';

type Props = {
  accessDuration?: number;
};

export function AccessLimitsFormFields(props: Props) {
  const [accessDuration, setAccessDuration] = useState(props.accessDuration ?? -1);

  return (
    <FormSection title="Access limits">
      <FormSubSection>
        <div class="flex flex-col gap-2">
          <label class="inline-flex items-center space-x-3 cursor-pointer">
            <Toggle
              checked={accessDuration !== -1}
              name="_durationType"
              value="limited"
              // 180 days
              onClick={(e: any) => setAccessDuration(e.target.checked ? 24 * 60 * 180 : -1)}
            />
            <span>Limit how long students have access to this content</span>
          </label>
          {accessDuration !== -1 && (
            <div class="ml-14">
              <CourseAccessDuration
                name="accessDuration"
                value={accessDuration}
                onNewDuration={setAccessDuration}
              />
            </div>
          )}
        </div>
      </FormSubSection>
    </FormSection>
  );
}
