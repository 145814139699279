import { rpx } from 'client/lib/rpx-client';
import { BtnPrimary } from '@components/buttons';
import { AsyncForm, FormSubSection, FormGroupTwoCol } from '@components/async-form';
import { Password } from '@components/password';
import { showToast } from '@components/toaster';
import { useIntl } from 'shared/intl/use-intl';
import { AccountTabContent } from './account-tab-content';
import { useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import { Toggle } from '@components/toggle';
import { useState } from 'preact/hooks';

export function PasswordTab() {
  const intl = useIntl();
  const user = useCurrentUser();
  const tenant = useCurrentTenant();
  const [applyAcrossTenants, setApplyAcrossTenants] = useState(false);

  return (
    <AsyncForm
      onSubmit={async ({ password, ...data }, e) => {
        await rpx.auth.updateCurrentUserPassword({
          ...data,
          newPassword: password,
          applyAcrossTenants,
        });
        (e.target as HTMLFormElement)?.reset?.();
        showToast({
          type: 'ok',
          title: intl('Updated'),
          message: intl('Settings saved'),
        });
      }}
    >
      <AccountTabContent title={intl('Password')}>
        <FormSubSection class="text-gray-500">
          <FormGroupTwoCol
            prop="currentPassword"
            labelText={
              <div class="flex flex-col space-y-1">
                <label class="text-zinc-900">{intl('Enter your current password')}</label>
                <a class="text-sm" href="/forgot-password">
                  {intl('Forgot your password?')}
                </a>
              </div>
            }
          >
            <Password name="currentPassword" placeholder={intl('Enter your current password')} />
          </FormGroupTwoCol>
          <FormGroupTwoCol
            prop="password"
            labelText={intl('New password')}
            subText={
              tenant.strictPasswords
                ? intl(
                    `Password should be at least 12 characters, contain a number, an upper case letter, a lower case letter, and a special character`,
                  )
                : intl('Password should be at least 6 characters')
            }
          >
            <Password
              name="password"
              placeholder={
                tenant.strictPasswords
                  ? intl('New password (min. 12 chars)')
                  : intl('New password (min. 6 chars)')
              }
            />
          </FormGroupTwoCol>
          <FormGroupTwoCol prop="confirmPassword" labelText={intl('Re-enter password')}>
            <Password name="confirmPassword" placeholder="********" />
          </FormGroupTwoCol>

          {user?.level === 'superadmin' && tenant.isCore && (
            <FormGroupTwoCol prop="applyAcrossTenants" labelText="Apply across tenants">
              <label
                class="flex gap-4 items-center"
                onClick={() => setApplyAcrossTenants((x) => !x)}
              >
                <Toggle name="applyAcrossTenants" checked={applyAcrossTenants} />
                <span>Change all of your tenant credentials</span>
              </label>
            </FormGroupTwoCol>
          )}
        </FormSubSection>

        <BtnPrimary class="w-full mt-3">{intl('Update')}</BtnPrimary>
      </AccountTabContent>
    </AsyncForm>
  );
}
