import { useTryAsyncData } from 'client/lib/hooks';
import { FullUpsell, Upsell } from './types';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { DefaultSpinner } from '@components/spinner';
import { ComponentChildren } from 'preact';
import { useMemo } from 'preact/hooks';
import * as fmt from 'shared/payments/fmt';
import { Currency } from 'server/types';
import { indexBy } from 'shared/utils';
import { Button } from '@components/buttons';
import { CourseImage } from '@components/course-image';
import { pluralize } from 'shared/formatting';

type Props = {
  upsell: FullUpsell;
};

type Stats = RpxResponse<typeof rpx.upsells.getUpsellStats>;

function Stat(props: { label: ComponentChildren; value: ComponentChildren }) {
  return (
    <section class="flex flex-col p-8">
      <dt class="text-sm font-medium text-gray-500">{props.label}</dt>
      <dd class="text-3xl font-medium text-gray-900">{props.value}</dd>
    </section>
  );
}

function StatsHeader({ stats, upsell }: { stats: Stats; upsell: Upsell }) {
  const revenue = useMemo(() => {
    const byCurrency = stats.revenue.reduce<Record<string, number>>((acc, x) => {
      acc[x.currency] = (acc[x.currency] || 0) + x.amount;
      return acc;
    }, {});
    return Object.entries(byCurrency).map(([currency, amount]) => ({ currency, amount }));
  }, [stats.revenue]);

  return (
    <header class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 border-y divide-x border-x">
      <Stat label="Customers" value={stats.numCustomers} />
      <Stat label="Purchases" value={upsell.numSignups} />
      {revenue.map((x) => (
        <Stat
          key={x.currency}
          label={`Revenue ${x.currency}`}
          value={fmt.price({ priceInCents: x.amount, currency: x.currency as Currency })}
        />
      ))}
    </header>
  );
}

function OfferStats({ stats, upsell }: { stats: Stats; upsell: FullUpsell }) {
  const offersByRevenue = useMemo(() => {
    const revenue = indexBy((x) => x.productId, stats.revenue);
    const offers = upsell.offers.map((o) => ({ ...o, revenue: revenue[o.productId]?.amount || 0 }));
    return offers.sort((a, b) => b.revenue - a.revenue);
  }, [stats.revenue, upsell.offers]);

  return (
    <section class="flex flex-col">
      <h2 class="font-semibold">Revenue per offer</h2>
      {offersByRevenue.map((offer) => (
        <Button
          key={offer.priceId}
          href={`/manage/${offer.product.isProduct ? 'products' : 'courses'}/${
            offer.product.courseId
          }/${offer.product.isProduct ? 'customers' : 'students'}`}
          class="flex-grow flex items-center gap-4 p-4 pl-0 rounded-md text-inherit"
        >
          <CourseImage image={offer.product.imagePath} size="size-14" />
          <span class="flex flex-col">
            <span class="font-semibold">{offer.product.title}</span>
            <span>
              {fmt.price({
                priceInCents: offer.revenue,
                currency: offer.price.currency,
              })}
            </span>
          </span>
        </Button>
      ))}
    </section>
  );
}

export function SignupsTab(props: Props) {
  const { data } = useTryAsyncData(
    () => rpx.upsells.getUpsellStats({ upsellId: props.upsell.id }),
    [props.upsell.id],
  );

  return (
    <section class="flex flex-col gap-6">
      <header>
        <h2 class="font-semibold">
          {pluralize(`${props.upsell.numSignups} signup`, props.upsell.numSignups)}
        </h2>
      </header>
      {!data && <DefaultSpinner />}
      {data && <StatsHeader stats={data} upsell={props.upsell} />}
      {data && <OfferStats stats={data} upsell={props.upsell} />}
    </section>
  );
}
