import { JSX } from 'preact';

type Props = JSX.HTMLAttributes<HTMLInputElement> & {
  // This is needed as it's missing in JSX.HTMLAttributes
  defaultChecked?: boolean;
  wrapperClass?: string;
  margin?: string;
  alignment?: string;
};

/**
 * A basic, styled checkbox component.
 */
export function Checkbox({
  wrapperClass = '',
  children,
  alignment = 'inline-flex items-center',
  margin = '',
  ...props
}: Props) {
  return (
    <label class={`text-gray-700 cursor-pointer ${alignment} ${margin} ${wrapperClass}`}>
      <input
        {...props}
        type="checkbox"
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
      {children && (
        <span class={`ml-2 grow block text-sm font-medium ${props.class || ''}`}>{children}</span>
      )}
    </label>
  );
}
