import { StudentPage } from '@components/student-page';
import { RouteProps, router, useRouteParams } from '@components/router';
import { MemberProfile } from './member-profile';
import { MemberList } from './member-list';
import { useEffect, useReducer, useState } from 'preact/hooks';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { AppRoute } from 'client/lib/app-route/types';
import { initialState, loadNextPage, reducer } from './reducer';
import { useCurrentUser } from '@components/router/session-context';
import { URLS } from 'shared/urls';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { Spinner } from '@components/spinner';

export type SelectedUser = NonNullable<RpxResponse<typeof rpx.members.getCourseMember>>;

async function load({ params }: AppRoute) {
  const { courseId, userId } = params;

  const course = await rpx.courses.getStudentCourse({ id: courseId });
  const selectedUser =
    !course.hidePeople && userId
      ? await rpx.members.getCourseMember({
          courseId,
          userId,
        })
      : undefined;

  return {
    selectedUser,
    course,
  };
}

type PageData = Awaited<ReturnType<typeof load>>;

function RedirectToCourse({ course }: { course: PageData['course'] }) {
  useEffect(() => {
    setTimeout(() => router.goto(URLS.student.course({ course })));
  }, []);

  return <Spinner />;
}

function MembersPage(props: RouteProps<PageData>) {
  const currentUser = useCurrentUser();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { courseId, userId, redirectToGuide } = useRouteParams();
  const { selectedUser: initialSelectedUser, course } = props.data;
  const [selectedUser, setSelectedUser] = useState<SelectedUser | undefined>(initialSelectedUser);

  useEffect(() => {
    loadNextPage({
      courseId,
      dispatch,
    });
  }, [courseId]);

  /*
   * Redirects `/people` url to to the session user profile detail page
   * on desktop when the `userId` param is empty.
   */
  useEffect(() => {
    if (!userId && currentUser?.id) {
      router.rewrite(
        URLS.student.memberProfile({
          course,
          user: currentUser,
        }),
      );
    }
  }, []);

  /*
   * Redirects to the guide page when `redirectToGuide` is true.
   */
  useEffect(() => {
    if (redirectToGuide === 'true') {
      router.rewrite(
        URLS.student.memberProfile({
          course,
          user: {
            id: course.guide.id,
            name: course.guide.name,
          },
        }),
      );
    }
  }, [redirectToGuide]);

  useAsyncEffect(async () => {
    if (!userId) {
      setSelectedUser(undefined);
    } else if (userId !== selectedUser?.id) {
      const result = await rpx.members.getCourseMember({
        courseId,
        userId,
      });
      setSelectedUser(result);
    }
  }, [userId]);

  return (
    <StudentPage
      course={course}
      currentLink="people"
      editLink={{
        // Do not open student detail page if the user is active session user.
        // Because it'll be the guide in this case, and it won't be accessible
        // on the students page.
        url: `/manage/courses/${course.id}/students/${currentUser?.id !== userId ? userId : ''}`,
      }}
      sideNavContent={<MemberList course={course} state={state} dispatch={dispatch} />}
      documentTitle={selectedUser?.name}
    >
      {selectedUser && <MemberProfile key={selectedUser.id} user={selectedUser} course={course} />}
    </StudentPage>
  );
}

function Page(props: RouteProps<PageData>) {
  if (props.data.course.hidePeople) {
    return <RedirectToCourse course={props.data.course} />;
  }

  return <MembersPage {...props} />;
}

router.add({
  url: 'courses/:courseId/people',
  render: Page,
  authLevel: 'student',
  load,
});

router.add({
  url: 'courses/:courseId/people/:userId',
  render: Page,
  authLevel: 'student',
  load,
});
