import { ComponentChildren } from 'preact';
import { router } from '@components/router';
import { AsyncForm } from '@components/async-form';
import { useState } from 'preact/hooks';
import { useConfiguration, useCurrentTenant } from '@components/router/session-context';
import { IcoChevronDown, IcoRuzukuLogo } from '@components/icons';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { showError } from '@components/app-error';
import { imageUrl } from 'client/utils/cdn';
import { BtnCopy, BtnPreWarning, BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { useUnsavedWarning } from '@components/autosaver';
import { BrandRow } from 'server/types';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@components/admin-nav';
import { ModalForm, showModalForm } from '@components/modal-form';
import { Dropdown } from '@components/dropdown';
import { pickLogo } from './pick-logo';
import { GeneralPreview } from './general-preview';
import { load, store, Brand, Props } from './load';
import { LoginPreview } from './login-preview';
import { CoursePreview } from './course-preview';
import { fullyQualify } from '../pmts/components/urls';
import { showDialog } from '@components/dialog';

function toImageState(fileId?: UUID, publicUrl?: string) {
  if (!fileId || !publicUrl) {
    return undefined;
  }
  return { fileId, publicUrl };
}

function createBrand(onCreate: (b: Brand) => void) {
  return showModalForm(({ resolve }) => {
    const hide = () => resolve(undefined);
    const submit = async (data: any) => {
      try {
        const opts = {
          name: data.name || 'Unnamed Brand',
        };
        const { id } = await store.create(opts);
        const newBrand: Brand = {
          ...opts,
          id,
          showName: true,
        };
        onCreate(newBrand);
        router.goto(`/admin/branding/${id}`);
        hide();
      } catch (err) {
        showError(err);
      }
    };

    return (
      <ModalForm
        onClose={hide}
        onSubmit={submit}
        title="New brand"
        confirmButtonText="Create brand"
      >
        <label class="flex flex-col">
          <span>Brand name</span>
          <input type="text" placeholder="Brand name" name="name" class="ruz-input" />
        </label>
      </ModalForm>
    );
  });
}

function SectionTitle(props: { title: ComponentChildren }) {
  return <h3 class="font-medium text-xl mb-4">{props.title}</h3>;
}

async function deleteBrand(brand: Pick<BrandRow, 'id' | 'name'>, onDeleted: () => void) {
  try {
    const ok = await showDialog({
      mode: 'warn',
      title: `Delete ${brand.name}?`,
      children: `Any affected courses will become part of the default brand.`,
      confirmButtonText: `Delete Brand`,
    });
    if (!ok) {
      return;
    }
    await store.remove({ id: brand.id });
    onDeleted();
  } catch (err) {
    showError(err);
  }
}

function Page(props: Props) {
  const tenant = useCurrentTenant();
  const config = useConfiguration()!;
  const [faviconUrl, setFaviconUrl] = useState(() => {
    return toImageState(tenant.faviconId, imageUrl(config, tenant.faviconUrl))?.publicUrl;
  });
  const { setState } = props;
  const { brands, supportsBrands, fonts, defaultFontId, colorSchemes, defaultColorSchemeId } =
    props.state;
  const brand =
    brands.find((x) => x.id === props.route.params.brandId) ||
    brands.find((x) => x.id === tenant.id);
  const [savedState, setSavedState] = useState(brand);
  const hasUnsavedChanges = savedState !== brand;
  const isDefaultBrand = tenant.id === brand?.id;
  useUnsavedWarning(() => hasUnsavedChanges);
  const setBrand = (fn: (b: Brand) => Brand) => {
    if (brand) {
      setState((s) => ({
        ...s,
        brands: s.brands.map((b) => (b.id === brand.id ? fn(b) : b)),
      }));
    }
  };

  if (tenant.isCore) {
    return (
      <FixedPage>
        <FixedContent class="bg-white">
          <AdminNav currentPage="branding" pageTitle="Branding" />
          <AdminTabWrapper>
            <p class="text-gray-500">You cannot edit Ruzuku branding.</p>
          </AdminTabWrapper>
        </FixedContent>
      </FixedPage>
    );
  }

  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="branding" pageTitle="Branding" />
        <AdminTabWrapper>
          <AdminTabHeader
            title={
              <span>
                <span class="mr-4">Branding:</span>
                <Dropdown
                  triggerClass="text-gray-500"
                  renderMenu={() => {
                    return (
                      <nav class="flex flex-col min-w-80 space-y-2 p-4">
                        {brands.map((b) => (
                          <Button
                            key={b.id}
                            class={`flex items-center py-1 p-2 rounded-md hover:bg-gray-100 ${
                              b.id === brand?.id ? 'bg-gray-100' : ''
                            }`}
                            href={`/admin/branding/${b.id}`}
                          >
                            <span class="inline-block px-2">
                              {b.iconUrl && <img class="rounded-full h-10 w-10" src={b.iconUrl} />}
                              {!b.iconUrl && (
                                <span class="inline-block bg-gray-100 rounded-full h-10 w-10"></span>
                              )}
                            </span>
                            <span class="inline-block align-middle p-2">{b.name}</span>
                          </Button>
                        ))}
                      </nav>
                    );
                  }}
                >
                  {brand?.name || 'Choose Brand'}
                </Dropdown>
              </span>
            }
          >
            <BtnPrimary
              onClick={() =>
                createBrand((b) =>
                  setState((s) => ({
                    ...s,
                    brands: [b, ...s.brands],
                  })),
                )
              }
            >
              Create Brand
            </BtnPrimary>
          </AdminTabHeader>

          {brand && (
            <AsyncForm
              class="pb-20 mb-20 w-5xl max-w-full mx-auto"
              onSubmit={async () => {
                try {
                  await store.update(brand);
                  setSavedState(brand);
                  if (isDefaultBrand) {
                    setTimeout(() => location.reload());
                  }
                } catch (err) {
                  showError(err);
                }
              }}
            >
              <div class="mb-8 p-4 bg-gray-50 shadow text-gray-700 rounded-lg">
                {!isDefaultBrand && (
                  <p>
                    This is a custom brand. You can apply custom brands to a course in the course
                    style editor.
                  </p>
                )}
                {isDefaultBrand && (
                  <p>
                    This is the default brand. The style changes you make here will apply to your
                    entire site.
                  </p>
                )}
              </div>

              {!isDefaultBrand && (
                <div>
                  <SectionTitle title="Brand settings" />

                  <div class="border rounded p-2 mb-8">
                    <div class="w-full rounded-sm flex flex-col bg-gray-50 p-4">
                      <div class="flex justify-between">
                        <div>
                          <label class="inline-flex flex-col">
                            <span>Brand name</span>
                            <input
                              class="inline-ruz-input border p-1 px-2"
                              name="name"
                              value={brand.name}
                              onInput={(e: any) => {
                                setState((s) => ({
                                  ...s,
                                  brands: s.brands.map((b) =>
                                    b.id === brand.id ? { ...b, name: e.target.value } : b,
                                  ),
                                }));
                              }}
                            />
                          </label>
                        </div>
                        <div class="flex items-start space-x-2">
                          <BtnCopy
                            class="btn-primary"
                            value={fullyQualify(`/login?brandId=${brand.id}`)}
                            margin="m-0"
                          >
                            Copy branded login link
                          </BtnCopy>
                          <BtnPreWarning
                            onClick={() => {
                              deleteBrand(brand, () => {
                                location.assign(`/admin/branding`);
                              });
                            }}
                          >
                            Delete Brand
                          </BtnPreWarning>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <SectionTitle title="Site header" />

              {isDefaultBrand && (
                <div class="rounded-t border inline-flex items-center ml-4">
                  <Button
                    type="button"
                    class="cursor-pointer focus:outline-none bg-white border-b-0 -mb-0.5 relative z-10 focus:ring-2 focus:ring-indigo-600 inline-flex items-center justify-center p-2"
                    onClick={async () => {
                      const favicon = await pickLogo();
                      if (favicon) {
                        setFaviconUrl(favicon.publicUrl);
                        setBrand((s) => ({ ...s, faviconId: favicon.fileId }));
                      }
                    }}
                  >
                    <span class="w-4 h-4 mr-2 inline-flex items-center">
                      {!!faviconUrl && <img src={faviconUrl} class="w-full" />}
                      {!faviconUrl && <IcoRuzukuLogo class="w-full h-full" />}
                    </span>
                    Change your favicon (32x32 px)
                    <IcoChevronDown class="w-4 h-4 ml-2 opacity-75" />
                  </Button>
                </div>
              )}
              <div class="border rounded p-2 mb-8">
                <GeneralPreview brand={brand} setBrand={setBrand} />
              </div>

              <SectionTitle title="Login page" />
              <div class="border rounded p-2 mb-8">
                <LoginPreview brand={brand} setBrand={setBrand} />
              </div>

              <SectionTitle title="Course branding" />
              <div class="border rounded p-2">
                <CoursePreview
                  supportsBrands={supportsBrands}
                  brand={brand}
                  setBrand={setBrand}
                  fonts={fonts}
                  defaultFontId={defaultFontId}
                  colorSchemes={colorSchemes}
                  defaultColorSchemeId={defaultColorSchemeId}
                />
              </div>

              {hasUnsavedChanges && (
                <footer class="fixed bottom-0 p-6 pb-8 w-full border-t shadow-2xl drop-shadow-2xl left-0 bg-white an-slide-up">
                  <div class="max-w-5xl mx-auto space-x-4">
                    <BtnPrimary>Save changes</BtnPrimary>
                    <BtnSecondary
                      onClick={() => {
                        location.reload();
                      }}
                    >
                      Cancel
                    </BtnSecondary>
                  </div>
                </footer>
              )}
            </AsyncForm>
          )}
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  authLevel: 'admin',
  url: '/admin/branding',
  load,
  render: Page,
});

router.add({
  authLevel: 'admin',
  url: '/admin/branding/:brandId',
  load,
  render: Page,
});
