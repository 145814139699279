import { DefaultSpinner } from '@components/spinner';
import { StudentPage } from '@components/student-page';
import { useAsyncData } from 'client/lib/hooks';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { useImageUrl } from 'client/utils/cdn';
import { CourseWithGuide, MembershipLevel } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import { URLS } from 'shared/urls';
import { PurchasePane } from '../pmts/components/purchase-pane';
import { GuideCard } from './guide-card';
import { UserProfileIcon } from '@components/avatars';

type Subcourse = RpxResponse<typeof rpx.guideBundles.getSubcourses>[0];

interface Props {
  course: CourseWithGuide;
  subcourses: Subcourse[];
  accessLevel: MembershipLevel;
}

const paymentsStore = rpx.myCorePayments;

export function BundleOverview({ course, subcourses, accessLevel }: Props) {
  const intl = useIntl();

  return (
    <StudentPage
      currentLink="home"
      isPinnable
      course={course}
      accessLevel={accessLevel}
      sidePaneContent={
        <section class="lg:w-[40vw] xl:w-[33vw] bg-gray-50 dark:bg-gray-800 bg-opacity-50 border-l border-t lg:border-t-0 border-gray-100 dark:border-gray-700 lg:flex flex-col items-center py-20">
          <div class="max-w-4xl mx-auto lg:max-w-lg space-y-10 px-4 lg:px-6 xl:px-10">
            <GuideCard guide={course.guide} />
          </div>
        </section>
      }
    >
      <div class="flex flex-col flex-grow max-w-4xl mx-auto min-h-full px-6 md:px-12">
        <h2 class="mb-8 text-2xl font-bold text-zinc-900 dark:text-zinc-50 mr-4">
          {intl('Courses in this bundle')}
        </h2>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {subcourses.map((subcourse) => (
            <SubcourseItem key={subcourse.id} course={subcourse} />
          ))}
        </div>
        <PaymentPane courseId={course.id} />
      </div>
    </StudentPage>
  );
}

function PaymentPane({ courseId }: { courseId: string }) {
  const intl = useIntl();
  const loader = useAsyncData(() => paymentsStore.getCoursePurchase({ courseId }), [courseId]);
  if (loader.isLoading) {
    return <DefaultSpinner />;
  }
  if (!loader.data?.purchase) {
    return null;
  }
  return (
    <div class="border-t dark:border-gray-700 pt-8 mt-10 dark:text-gray-200">
      <header class="mb-4">
        <h3 class="text-lg font-medium text-zinc-900 dark:text-gray-100 leading-6">
          {intl('Payment Details')}
        </h3>
      </header>
      <PurchasePane {...loader.data} asSeller={false} />
    </div>
  );
}

function SubcourseItem({ course }: { course: Subcourse }) {
  const intl = useIntl();
  const src = useImageUrl(course.imagePath);

  return (
    <div class="relative inline-block border border-gray-300 hover:border-indigo-600 rounded-lg">
      <a
        class="flex flex-col rounded-md overflow-hidden text-inherit dark:text-gray-50"
        href={URLS.student.course({
          course,
        })}
        // We need this to avoid the `router` from trying to handle the link
        // because it thinks it's a subroute of the current page.
        data-noroute
      >
        <span class="flex items-center justify-center h-52 bg-gray-100 w-full border-b overflow-hidden">
          {src && <img src={`${src}?width=600`} class="object-cover" />}
          {!src && <span class="opacity-50">{intl('No Image')}</span>}
        </span>
        <span class="flex items-center px-4 my-4 text-sm border-b border-dashed pb-4 relative">
          <UserProfileIcon user={course.guide} size="w-8 h-8" />
          <span class="ml-3 inline-flex flex-col">
            <span class="opacity-50 text-sm leading-4">Guide</span>
            <span class="line-clamp-1">{course.guide.name}</span>
          </span>
        </span>
        <span class="flex items-center text-sm min-h-10 whitespace-normal w-full text-left p-4 pt-0 h-20">
          <strong class="line-clamp-2">{course.title}</strong>
        </span>
      </a>
    </div>
  );
}
