import { BtnManageCourse } from '@components/student-page/btn-manage-course';
import { SideNavProvider, StudentSideNav } from '@components/student-page/student-side-nav';
import { StudentThemeProvider } from '@components/student-theme-picker';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { CourseWithGuide } from 'server/types';
import { CopyCourseTemplateModal } from '@components/course-action-modals';
import { PurchaseInstantCourseModal } from '@components/course-action-modals/purchase-instant-course-modal';
import { IcoList, IcoSearch, IcoX } from '@components/icons';
import { UserMenu } from '@components/nav/user-menu';
import { StudentThemePicker } from '@components/student-theme-picker';
import { TopBarBanner } from '@components/top-bar';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { MembershipLevel } from 'server/types';
import { useStudentSearchModal } from '@components/student-search-modal';
import { Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { SiteNavLogo } from '@components/nav/site-nav-logo';

function PageHeader({
  course,
  accessLevel,
  manageButton,
  isMenuShowing,
  onMenuToggle,
}: {
  course: Props['course'];
  accessLevel: MembershipLevel;
  manageButton: ComponentChildren;
  isMenuShowing?: boolean;
  onMenuToggle?(): void;
}) {
  const intl = useIntl();
  const [showCopyTemplateModal, setShowCopyTemplateModal] = useState(false);
  const [showPurchaseCourseModal, setShowPurchaseCourseModal] = useState(false);
  const showSearchModal = useStudentSearchModal(course, {
    listenCtrlKey: true,
  });

  return (
    <header class="bg-white dark:bg-gray-900 z-10 border-b dark:border-gray-800">
      <div class="relative flex flex-col max-w-full justify-center">
        <div class="course-header-title">
          <nav class="flex justify-between md:grid md:grid-cols-3 dark:text-gray-300 gap-2 p-4 md:px-6">
            <nav class="flex items-center gap-2">
              {onMenuToggle && (
                <button class="lg:hidden p-2 -ml-2" onClick={onMenuToggle}>
                  {isMenuShowing ? (
                    <IcoX class="w-5 h-5 opacity-75" />
                  ) : (
                    <IcoList class="w-5 h-5 opacity-75" />
                  )}
                </button>
              )}

              <SiteNavLogo />
            </nav>
            <Button
              class="items-center text-gray-400 gap-4 p-2 px-4 border border-gray-300 dark:border-gray-600 hover:shadow rounded-full hidden md:flex text-left"
              onClick={showSearchModal}
            >
              <IcoSearch class="w-4 h-4 opacity-75 flex-shrink-0" />
              <span class="flex-grow overflow-hidden text-ellipsis whitespace-nowrap">
                {intl('Find something...')}
              </span>
            </Button>
            <nav class="flex items-center justify-end gap-6">
              {manageButton}
              <Button class="opacity-75 hover:opacity-100 md:hidden" onClick={showSearchModal}>
                <IcoSearch class="w-6 h-6 opacity-75" />
              </Button>
              <StudentThemePicker />
              <UserMenu />
            </nav>
          </nav>
        </div>
      </div>
      {accessLevel === 'limited' && (
        <TopBarBanner
          text="You need to purchase the instant course to see full course content."
          buttonProps={{
            children: 'Purchase Instant Course',
            onClick: () => setShowPurchaseCourseModal(true),
          }}
        />
      )}
      {accessLevel === 'preview' && (
        <TopBarBanner
          text="This is a student preview. Copy the course to make your own."
          buttonProps={{
            children: 'Copy Course',
            onClick: () => setShowCopyTemplateModal(true),
          }}
        />
      )}
      {showCopyTemplateModal && (
        <CopyCourseTemplateModal course={course} onCancel={() => setShowCopyTemplateModal(false)} />
      )}
      {showPurchaseCourseModal && (
        <PurchaseInstantCourseModal
          course={course}
          onCancel={() => setShowPurchaseCourseModal(false)}
        />
      )}
    </header>
  );
}

type Props = {
  documentTitle: string;
  course: CourseWithGuide;
  editLink: string;
  membershipLevel: MembershipLevel;
  sideNavContent?: ComponentChildren;
  children: ComponentChildren;
  isMenuShowing?: boolean;
  onMenuToggle?(): void;
};

export function StudentProductPage({
  sideNavContent,
  documentTitle,
  course,
  editLink,
  membershipLevel,
  onMenuToggle,
  isMenuShowing,
  children,
}: Props) {
  useDocumentTitle([documentTitle, course.title]);

  return (
    <StudentThemeProvider>
      <SideNavProvider isPinnable storageKey="studentPinSideNav">
        <div class="flex">
          <div class="relative flex flex-grow flex-col min-h-screen bg-white dark:bg-gray-900 student-page">
            <header class="sticky top-0 z-20">
              <PageHeader
                course={course}
                accessLevel={membershipLevel}
                onMenuToggle={onMenuToggle}
                isMenuShowing={isMenuShowing}
                manageButton={
                  editLink && (
                    <BtnManageCourse class="hidden md:inline-flex" course={course} url={editLink} />
                  )
                }
              />
            </header>
            <BtnManageCourse
              class="inline-flex md:hidden mx-4 mt-8"
              course={course}
              url={editLink}
            />
            <div class="relative flex flex-grow">
              <div class={sideNavContent ? '' : 'lg:hidden'}>{StudentSideNav}</div>
              <div class="flex flex-grow flex-col lg:flex-row">
                <div class="relative flex flex-grow max-w-full dark:bg-transparent">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </SideNavProvider>
    </StudentThemeProvider>
  );
}
