import { Button } from '@components/buttons';
import { useEffect, useState } from 'preact/hooks';
import { CourseStep, FullCourse } from 'server/types';
import { COURSE_CHECKLIST_STEPS } from 'shared/consts';
import { CourseChecklist, onCourseStepCompleted } from '@components/course-checklist';
import { rpx } from 'client/lib/rpx-client';
import { ProgressBar } from '@components/progress-bar';
import { SlideOver } from '@components/slide-over';
import { showDialog } from '@components/dialog';

const store = rpx.checklist;

type Props = {
  course: Pick<
    FullCourse,
    | 'id'
    | 'title'
    | 'status'
    | 'accessFormat'
    | 'numStudents'
    | 'checklistStatus'
    | 'checklistCompletedSteps'
    | 'isBundle'
    | 'isAbsoluteSchedule'
  >;
};

export function CourseChecklistButton({ course }: Props) {
  const [hasDismissed, setHasDismissed] = useState(course.checklistStatus !== 'incomplete');
  const [highlightButton, setHighlightButton] = useState(false);

  // We should show the course launch checklist as a modal if:
  //  1. it's a new course (with no students)
  //  2. it hasn't been completed yet
  //  3. it hasn't been actively dismissed
  const shouldShowChecklist = (): boolean => {
    const numCompletedSteps = new Set<CourseStep>(course.checklistCompletedSteps).size;
    const creationComplete = numCompletedSteps >= COURSE_CHECKLIST_STEPS.length;
    return !course.numStudents && !creationComplete && !hasDismissed;
  };

  // We default to having the checklist open for new/incomplete courses with no students
  const [displaySlideOver, setDisplaySlideOver] = useState(shouldShowChecklist());
  const [completedSteps, setCompletedSteps] = useState(
    () => new Set<CourseStep>(course.checklistCompletedSteps),
  );
  const progress = Math.round((completedSteps.size / COURSE_CHECKLIST_STEPS.length) * 100);

  // Dismiss the Quick Launch checklist, and let the user know how to find it again.
  const dismissChecklist = async () => {
    setDisplaySlideOver(false);
    if (hasDismissed) {
      return;
    }
    store.setStatus({
      id: course.id,
      status: 'ignored',
    });
    // Cache the dismissed state here instead of reloading course state: avoid voids showing
    // the confirmation dialog multiple times if the user closes and reopens the checklist
    setHasDismissed(true);
    // Highlight the quick launch button briefly
    await showDialog({
      mode: 'ok',
      hideCancel: true,
      confirmButtonText: 'OK, got it!',
      title: 'Quick Launch checklist hidden',
      children:
        'You can view the Quick Launch guide again any time by clicking the "percent ready" button in the top menu',
    });
  };
  useEffect(() => {
    return onCourseStepCompleted(async ({ step, scrollToTop }) => {
      if (!completedSteps.has(step)) {
        setHighlightButton(true);
        await store.completeStep({
          id: course.id,
          step,
        });

        // Scroll to the top of the page to show
        // the checklist item completed animation
        if (scrollToTop) {
          window.scrollTo(0, 0);
        }

        const result = new Set(completedSteps);
        result.add(step);
        setCompletedSteps(result);
        setTimeout(() => {
          setHighlightButton(false);
        }, 1000);
      }
    });
  }, [completedSteps]);

  return (
    <>
      <Button
        class={`p-2 py-1 rounded-md text-inherit font-semibold hover:bg-white/20 whitespace-nowrap group static transition-all duration-700 ${
          highlightButton ? 'bg-white/40 scale-150' : ''
        }`}
        onClick={() => {
          // Scroll the checklist into view if this is course overview page
          // and the checklist is visible.
          // And use the slide over if the checklist is not present.
          const checklistEl = document.querySelector('.js-course-checklist');
          if (checklistEl) {
            checklistEl.scrollIntoView({ behavior: 'smooth' });
          } else {
            setDisplaySlideOver(true);
          }
        }}
      >
        <span class="flex flex-col gap-1 relative">
          <span class="inline-flex justify-betwen">
            <span>{progress}% Ready</span>
          </span>
          <span class="absolute -bottom-1 left-0 right-0 group-hover:hidden">
            <ProgressBar
              progress={progress}
              showPercent={false}
              barClass="bg-indigo-400"
              bgClass="bg-indigo-100"
              height="h-1"
            />
          </span>
        </span>
      </Button>
      {displaySlideOver && (
        <SlideOver p="p-6" close={dismissChecklist}>
          <CourseChecklist
            course={{
              ...course,
              checklistCompletedSteps: Array.from(completedSteps),
            }}
            minimal
            onHide={dismissChecklist}
            showDismissText={!hasDismissed}
          />
        </SlideOver>
      )}
    </>
  );
}
