import { Button, ButtonProps } from '@components/buttons';
import { NavSectionHeader } from './nav-section-header';
import { SectionModal } from './section-modal';
import { SalesBlockDefinition } from './types';

interface Props {
  onHide(): void;
  definitions: Record<string, SalesBlockDefinition>;
  onInsert(blockType: string): void;
}

function BtnBlock({ label, children, ...props }: { label: string } & ButtonProps) {
  return (
    <Button class="block border p-2 rounded bg-white text-center m-2 min-w-80" {...props}>
      <span class="block font-bold text-sm mb-3">{label}</span>
      {children}
    </Button>
  );
}

export function NewSectionModal(props: Props) {
  return (
    <SectionModal onHide={props.onHide}>
      <div class="p-2 bg-white">
        <div class="p-2">
          <NavSectionHeader title="Insert a New Section" />
        </div>
        <div class="grid grid-cols-2">
          {Object.values(props.definitions).map((t) => (
            <BtnBlock key={t.type} label={t.name} onClick={() => props.onInsert(t.type)}>
              <t.MiniView />
            </BtnBlock>
          ))}
        </div>
      </div>
    </SectionModal>
  );
}
