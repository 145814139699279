/**
 * A page which presents as a modal.
 */

import { ComponentChildren } from 'preact';
import { useEsc } from 'client/utils/use-esc';
import { BaseDialog } from '@components/dialog';

export function ModalPage(props: { children: ComponentChildren; onClose(): void }) {
  useEsc(props.onClose, { escapeOnly: true });

  return (
    <BaseDialog onClose={props.onClose}>
      <section class="rounded-t-xl sm:rounded-3xl flex flex-col bg-white shadow-2xl w-full absolute sm:relative bottom-0 sm:w-2xl h-(screen-16) sm:h-auto max-h-(screen-16) sm:max-w-(screen-16) overflow-hidden">
        {props.children}
      </section>
    </BaseDialog>
  );
}
