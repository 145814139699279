/**
 * The main front-end entry point.
 */

import { Toaster } from '@components/toaster';
import { RouterPage } from '@components/router';
import { AcctBanner, MaintenanceBanner } from '@components/banners';
import { render } from 'preact';
import { ChatWidget } from '@components/chat-widget';

import './pages';
import { AuthContextProvider } from './lib/auth';
import { GlobalComponents } from '@components/dialog';

/**
 * Render the root component, and wire up the application context.
 */
function App() {
  return (
    <AuthContextProvider>
      <RouterPage
        prefix={
          <>
            <MaintenanceBanner />
            <AcctBanner />
            <ChatWidget />
          </>
        }
      >
        <GlobalComponents />
      </RouterPage>
      <Toaster />
    </AuthContextProvider>
  );
}

/**
 * Mount the application.
 */
render(<App />, document.querySelector('main')!);
