/**
 * This file contains the application router constructor.
 */

import { historyRouter } from './history-router';
import { ClientRouteDefinition, RouteParams } from './types';

function routeBuilder<T extends ClientRouteDefinition>(createRouter: typeof historyRouter) {
  const defs: { [k: string]: T } = {};
  let router: ReturnType<typeof createRouter>;

  return {
    /**
     * Get the definition associated with the url.
     */
    definition(url: string) {
      return defs[url];
    },

    /**
     * Add a new route.
     */
    add(def: T) {
      defs[def.url] = def;
      return def;
    },

    /**
     * Navigate the app to the specified url.
     */
    goto(url: string) {
      return router.goto(url);
    },

    /**
     * goto the specified URL, and wait a few ms to allow the UI to perform
     * any transitions it desires.
     */
    async redirectTo(url: string, ms = 1000) {
      this.goto(url);
      await new Promise((r) => setTimeout(r, ms));
    },

    /**
     * Rewrite the current URL. This removes the
     * current URL from history and replaces it.
     * This does *not* cause the route to re-evaluate.
     */
    rewrite(url: string) {
      return router.rewrite(url);
    },

    onLocationChange(callback: (params: RouteParams) => void) {
      return router.onLocationChange(callback);
    },

    /**
     * Initialize the router.
     */
    init(handler: (routeParams: RouteParams, def: T) => any) {
      router = createRouter(Object.keys(defs), (url, routeParams) => {
        handler(routeParams, defs[url]);
      });
      router.init();
    },
  };
}

export function createHistoryRouter<T extends ClientRouteDefinition>() {
  return routeBuilder<T>(historyRouter);
}
