/**
 * The breadcrumbs UI component.
 */

export interface Crumb {
  href?: string;
  text: string;
}

export function Divider() {
  return <span class="mx-2">⤑</span>;
}

export function Breadcrumbs({ crumbs }: { crumbs: Array<Crumb | undefined> }) {
  const crumbClass =
    'inline-block text-inherit whitespace-nowrap overflow-hidden max-w-40 text-ellipsis';

  return (
    <div class="text-sm flex items-center space-x-2 opacity-60">
      {crumbs.map((crumb) => {
        if (!crumb) {
          return;
        }
        if (crumb.href) {
          return (
            <>
              <a href={crumb.href} class={crumbClass} key={crumb.href}>
                {crumb.text}
              </a>
              <Divider />
            </>
          );
        }
        return (
          <span key={crumb.text} class={crumbClass}>
            {crumb.text}
          </span>
        );
      })}
    </div>
  );
}
