import { LoadingIndicator } from '@components/loading-indicator';
import { SearchBox } from '@components/search-box';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { ComponentChildren } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import { CourseImage } from '@components/course-image';
import { fmtFullDate } from 'shared/dateutil';
import { WizardSubtitle } from './upsell-wizard';
import { useCurrentTenant } from 'client/lib/auth';
import type { UpsellProduct } from './types';

type PickerOpts<T extends { search?: string }> = {
  load(): Promise<T[]>;
  onPick(t: T): void;
  hideSearch?: boolean;
  searchPlaceholder?: string;
  summarize(t: T): ComponentChildren;
};

export function Picker<T extends { id: string; search?: string }>(opts: PickerOpts<T>) {
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, data } = useTryAsyncData(opts.load, []);
  const matches = useMemo(() => {
    if (opts.hideSearch) {
      return data;
    }
    const lcase = searchTerm.toLowerCase();
    return data?.filter((c) => c.search?.includes(lcase));
  }, [data, searchTerm]);

  return (
    <>
      {!opts.hideSearch && (
        <header class="bg-white sticky top-0 w-full shadow-xl shadow-white">
          <SearchBox
            focusOnce
            roundFull
            onKeyDown={(e) => {
              switch (e.key) {
                case 'Enter': {
                  const item = matches?.[0];
                  if (item) {
                    opts.onPick(item);
                  }
                }
              }
            }}
            onTermChange={(term) => {
              setSearchTerm(term);
            }}
            value={searchTerm}
            placeholder={opts.searchPlaceholder}
          />
        </header>
      )}
      {isLoading && <LoadingIndicator />}
      {!!matches?.length && (
        <div class="border rounded-2xl flex flex-col overflow-auto">
          {matches?.map((c) => (
            <button
              key={c.id}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                opts.onPick(c);
              }}
              class={`flex items-center border-t last:rounded-b-2xl first:rounded-t-2xl first:border-transparent p-4 hover:bg-gray-50 gap-4`}
            >
              {opts.summarize(c)}
            </button>
          ))}
        </div>
      )}
    </>
  );
}

export function UpsellProductPicker(props: {
  title?: ComponentChildren;
  onPick(product: UpsellProduct): void;
  filter?(product: UpsellProduct): boolean;
}) {
  const { terminology } = useCurrentTenant();
  return (
    <>
      <WizardSubtitle>{props.title || `Choose a ${terminology.course} or product`}</WizardSubtitle>
      <Picker
        searchPlaceholder={`Find a ${terminology.course} or product`}
        onPick={props.onPick as any}
        load={async () => {
          const courses = await rpx.courses.getMyCourses({
            asGuide: true,
          });
          const products = courses.map((c) => ({
            ...c,
            id: c.productId,
            courseId: c.id,
            search: c.title.toLowerCase(),
          }));
          return products
            .filter((x) => (props.filter ? props.filter(x) : true))
            .sort((a, b) => a.search.localeCompare(b.search));
        }}
        summarize={(c) => {
          return (
            <span class="flex items-center text-left gap-4 flex-grow text-sm">
              <CourseImage image={c.imagePath} size="size-10" />
              <span class="flex flex-col">
                {c.title}
                <span class="text-gray-500 text-xs whitespace-nowrap">
                  Created {fmtFullDate(c.createdAt)}
                </span>
              </span>
              <span class="ml-auto">
                <span
                  class={`rounded text-xs px-1 border w-14 text-center overflow-hidden overflow-ellipsis ${
                    c.isProduct
                      ? 'bg-sky-50 border-sky-200 text-sky-600'
                      : c.isBundle
                      ? 'bg-gray-50'
                      : 'bg-green-50 border-green-200 text-green-600'
                  }`}
                >
                  {c.isProduct ? 'product' : c.isBundle ? 'bundle' : terminology.course}
                </span>
              </span>
            </span>
          );
        }}
      />
    </>
  );
}
