import { BtnPrimary } from '@components/buttons';
import { IcoPercentBadge } from '@components/icons';

export function UpgradeToProWidget() {
  return (
    <div class="bg-gray-800 rounded-3xl p-4 text-white text-center relative">
      <div class="absolute flex items-center justify-center inset-x-1/2 -ml-8 -top-6 w-16 h-16 bg-gray-800 rounded-full p-2">
        <div class="flex items-center justify-center rounded-full bg-white text-indigo-600">
          <IcoPercentBadge class="size-12" />
        </div>
      </div>
      <div class="mt-4">
        <span class="text-base">Fall Promo</span>
        <span class="block text-xs">Upgrade now and save 1000$/year</span>
        <BtnPrimary class="mt-4 rounded-full w-full">Get Pro Now</BtnPrimary>
      </div>
    </div>
  );
}
