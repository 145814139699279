import { Button } from '@components/buttons';
import { useRouteData } from '@components/router';
import { NavSectionHeader } from '@components/page-builder';
import { PriceRow } from 'server/types';
import { PriceSummary } from '../pmts/components/price-summary';
import { SectionModal } from '@components/page-builder/section-modal';
import { PageData } from './types';
import { DialogFooter, StandardDialog } from '@components/dialog';

interface Props {
  hide(): void;
  onApply(p: PriceRow): void;
}

export function PriceModal(props: Props) {
  const { prices } = useRouteData<PageData>();
  return (
    <SectionModal onHide={props.hide} position="middle">
      <div class="flex flex-col p-4">
        <NavSectionHeader title="Choose a price" />
        {prices.map((p) => (
          <Button
            key={p.id}
            onClick={() => {
              props.onApply(p);
              props.hide();
            }}
            class="my-2 p-4 pr-8 flex flex-col rounded bg-white border hover:bg-gray-100 text-sm cursor-pointer"
          >
            <strong class="block">{p.name}</strong>
            <PriceSummary price={p} />
          </Button>
        ))}
      </div>
    </SectionModal>
  );
}
export function PriceModalX(props: Props) {
  const { prices } = useRouteData<PageData>();
  return (
    <StandardDialog onClose={props.hide} title="Choose a price point">
      <div class="flex flex-col pt-4">
        {prices.map((p) => (
          <Button
            key={p.id}
            onClick={() => {
              props.onApply(p);
              props.hide();
            }}
            class="my-2 p-4 pr-8 flex flex-col rounded bg-white border text-sm cursor-pointer border-gray-200"
          >
            <strong class="block">{p.name}</strong>
            <PriceSummary price={p} />
          </Button>
        ))}
      </div>
      <DialogFooter hideConfirm onClose={props.hide} />
    </StandardDialog>
  );
}
