import { Button } from '@components/buttons';
import { ComponentChildren } from 'preact';
import { pluralize } from 'shared/formatting';
import { Coupon, Folder, FolderStats, Item, Price } from './state';
import * as fmt from 'shared/payments/fmt';
import * as urls from './urls';
import { IcoCreditCard, IcoFolder, IcoTag } from '@components/icons';
import { Checkbox } from '@components/checkbox';
import { useIntl } from 'shared/intl/use-intl';
import { useRouteParams } from '@components/router';
import { Case } from '@components/conditional';
import { CORE_PRODUCT_ID } from 'shared/ids';

function ItemRow(props: {
  href?: string;
  checkbox?: ComponentChildren;
  ico: ComponentChildren;
  description?: ComponentChildren;
  expandedDetail?: ComponentChildren;
  name: string;
  isEnabled: boolean;
  numSignups: number;
}) {
  return (
    <div
      class={`flex rounded-md text-gray-600 hover:bg-gray-50 ${
        !props.isEnabled ? 'opacity-75' : ''
      }`}
    >
      {props.checkbox}
      <Button
        href={props.href}
        class={`grid grid-cols-3 text-left rounded-md text-gray-600 hover:bg-gray-50 flex-grow ${
          !props.isEnabled ? 'opacity-75' : ''
        }`}
      >
        <span class="align-middle flex items-center font-medium">
          {props.ico}
          <span class="ml-3">{props.name}</span>
        </span>
        <span class="whitespace-nowrap px-4 p-2 align-middle text-gray-500">
          {props.description}
        </span>
        <span class="whitespace-nowrap px-4 p-2 flex items-center align-middle text-gray-500">
          {props.numSignups} {pluralize('signup', props.numSignups)} {props.expandedDetail}
        </span>
      </Button>
    </div>
  );
}

function RowCheckbox({ isSelected, onClick }: { isSelected: boolean; onClick(): void }) {
  return (
    <Checkbox
      wrapperClass="inline-flex cursor-pointer py-1 px-2"
      checked={isSelected}
      onClick={onClick}
    />
  );
}

function FolderLink({ folderId }: { folderId?: string }) {
  const { productId } = useRouteParams();
  if (!folderId) {
    return null;
  }
  return (
    <span class="ml-auto pl-2">
      <a
        data-tooltip="View Folder"
        class="inline-flex relative items-center border rounded-md p-2 py-1 hover:bg-white text-inherit"
        href={urls.itemUrl({ item: { type: 'folder', id: folderId }, productId })}
      >
        <IcoFolder />
      </a>
    </span>
  );
}

function PriceRow({
  price,
  isSelected,
  onSelect,
  showFolder,
}: {
  price: Price;
  isSelected: boolean;
  onSelect(): void;
  showFolder?: string;
}) {
  const intl = useIntl();
  const { productId } = useRouteParams();

  return (
    <ItemRow
      href={urls.itemUrl({ item: price, productId })}
      name={price.name}
      isEnabled={price.isEnabled}
      checkbox={<RowCheckbox isSelected={isSelected} onClick={onSelect} />}
      ico={
        <span class="rounded-full ml-2 p-1 inline-flex bg-green-400 text-white">
          <IcoCreditCard class="w-4 h-4" />
        </span>
      }
      numSignups={price.numSignups || 0}
      description={
        <span>
          {fmt.price({ ...price, omitCurrency: true })}
          {fmt.priceSuffix({ item: price, compact: true, intl })}
        </span>
      }
      expandedDetail={
        <>
          <span class="capitalize ml-2">
            <Case
              when={productId === CORE_PRODUCT_ID}
              fallback={
                <>
                  {price.metadata?.unlimitedInstantCourses && 'Unlimited Instant Courses'}
                  {price.metadata?.instantCourseCredits &&
                    `${price.metadata.instantCourseCredits} Instant Course credits`}
                </>
              }
            >
              {price.metadata?.tier || 'No Tier'}
              {price.metadata?.isLifetime && ', Lifetime'}
            </Case>
          </span>
          <FolderLink folderId={showFolder} />
        </>
      }
    />
  );
}

function CouponRow({
  coupon,
  isSelected,
  onSelect,
  showFolder,
}: {
  coupon: Coupon;
  isSelected: boolean;
  onSelect(): void;
  showFolder?: string;
}) {
  const intl = useIntl();
  const { productId } = useRouteParams();
  return (
    <ItemRow
      href={urls.itemUrl({ item: coupon, productId })}
      name={coupon.code}
      isEnabled={coupon.isEnabled}
      checkbox={<RowCheckbox isSelected={isSelected} onClick={onSelect} />}
      ico={
        <span class="rounded-full ml-2 p-1 inline-flex bg-indigo-400 text-white">
          <IcoTag class="w-4 h-4" />
        </span>
      }
      description={fmt.describeCoupon({
        coupon,
        intl,
      })}
      numSignups={coupon.numSignups || 0}
      expandedDetail={<FolderLink folderId={showFolder} />}
    />
  );
}

function FolderRow({
  folder,
  numItems,
  numSignups,
}: {
  folder: Folder;
  numItems: number;
  numSignups: number;
}) {
  const { productId } = useRouteParams();

  return (
    <ItemRow
      href={urls.itemUrl({ item: folder, productId })}
      isEnabled
      name={folder.name}
      ico={
        <span class="pl-2">
          <IcoFolder class="stroke-gray-500 fill-gray-200 w-6 h-6 opacity-75 stroke-1" />
        </span>
      }
      numSignups={numSignups}
      description={`${numItems} ${pluralize('item', numItems)}`}
    />
  );
}

export function Row({
  item,
  isSelected,
  folderStats,
  toggleSelection,
  showFolder,
}: {
  item: Item;
  folderStats: Record<string, FolderStats>;
  isSelected: boolean;
  toggleSelection(): void;
  showFolder?: string;
}) {
  if (item.type === 'folder') {
    return (
      <FolderRow
        key={item.id}
        folder={item}
        numItems={folderStats[item.id]?.numItems || 0}
        numSignups={folderStats[item.id]?.numSignups || 0}
      />
    );
  }
  if (item.type === 'coupon') {
    return (
      <CouponRow
        key={item.id}
        coupon={item}
        isSelected={isSelected}
        onSelect={toggleSelection}
        showFolder={showFolder}
      />
    );
  }
  if (item.type === 'price') {
    return (
      <PriceRow
        key={item.id}
        price={item}
        isSelected={isSelected}
        onSelect={toggleSelection}
        showFolder={showFolder}
      />
    );
  }
  return null;
}
