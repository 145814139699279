import { useEffect, useState } from 'preact/hooks';
import { DefaultSpinner } from '@components/spinner';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { useIntl } from 'shared/intl/use-intl';
import { BtnPrimary, Button } from '@components/buttons';
import { IcoChat, IcoChevronLeft } from '@components/icons';
import { UserProfileIcon } from '@components/avatars';
import { Case } from '@components/conditional';
import { timeago } from 'shared/dateutil';
import { URLS } from 'shared/urls';
import { onNewChatRoomCreated } from './events';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { showError } from '@components/app-error';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

const store = rpx.chats;

type ChatCourse = RpxResponse<typeof store.getChatCourses>[0];
type Room = RpxResponse<typeof store.getCourseRooms>[0];
type RoomUpdates = RpxResponse<typeof store.getRoomUpdates>;

interface Props {
  course: ChatCourse;
  roomUpdates?: RoomUpdates;
  onBack: () => void;
  onRoomClick: (room: Room) => void;
}

export function CourseChatsList({ course, roomUpdates, onRoomClick, onBack }: Props) {
  const intl = useIntl();
  const [rooms, setRooms] = useState<Room[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const roomUpdatesMap = new Map(roomUpdates?.map((u) => [u.roomId, u]) ?? []);

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const rooms = await store.getCourseRooms({ courseId: course.id });
      setRooms(rooms);
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, [course.id]);

  // Prepend a new room to the list when a new chat is initiated.
  useEffect(() => {
    return onNewChatRoomCreated(async (data) => {
      if (data.courseId === course.id) {
        const newRoom: Room = {
          id: data.roomId,
          recipient: data.user,
          lastMessage: data.message,
          courseId: data.courseId,
          isMuted: false,
        };
        setRooms((r) => [newRoom, ...r]);
      }
    });
  }, []);

  return (
    <>
      <div class="p-2 space-y-2">
        <Button
          class="inline-flex items-center text-sm text-left hover:opacity-60"
          onClick={onBack}
        >
          <span class="border rounded bg-gray-100 mr-2 p-0.5 inline-block">
            <IcoChevronLeft class="w-4 h-4" />
          </span>
          {intl('"{courseTitle:string}" chats', {
            courseTitle: course.title,
          })}
        </Button>
      </div>
      <Case when={!isLoading} fallback={<DefaultSpinner />}>
        {!rooms.length && <EmptyList course={course} />}
      </Case>
      {rooms &&
        rooms.map((room) => {
          const updates = roomUpdatesMap.get(room.id);

          return (
            <RoomItem
              key={room.id}
              room={room}
              unreadCount={updates?.unreadCount || 0}
              lastMessage={updates?.lastMessage || room.lastMessage}
              onClick={() => onRoomClick(room)}
            />
          );
        })}
    </>
  );
}

function RoomItem({
  room,
  lastMessage,
  unreadCount,
  onClick,
}: {
  room: Room;
  lastMessage?: {
    content: string;
    createdAt: Date;
  };
  unreadCount: number;
  onClick: () => void;
}) {
  return (
    <div class="px-3 flex items-center cursor-pointer hover:bg-gray-200" onClick={onClick}>
      <div class="relative">
        <UserProfileIcon size="w-10 h-10" user={room.recipient} />
        <Case when={unreadCount > 0}>
          <span class="absolute -top-2 -right-2 inline-flex items-center justify-center rounded-full text-xs font-semibold leading-4 bg-red-500 text-white dark:border-none w-5 h-5 overflow-hidden">
            {unreadCount}
          </span>
        </Case>
      </div>
      <div class="flex-1 pl-2 py-4">
        <div class="flex items-bottom justify-between">
          <p class="text-gray-900 line-clamp-2">{room.recipient.name}</p>
          <p class="text-gray-500 text-xs">{lastMessage ? timeago(lastMessage.createdAt) : ''}</p>
        </div>
        {lastMessage && <p class="text-xs text-gray-500">{lastMessage?.content || ''}</p>}
      </div>
    </div>
  );
}

function EmptyList({ course }: { course: ChatCourse }) {
  const intl = useIntl();
  const [prefix, link, suffix] = intl.split(
    '<>Head over to the People page</> to find a person and start a chat.',
  );
  const guideProfileUrl = URLS.student.guideProfile({
    course,
  });

  return (
    <div class="p-4">
      <img
        data-attribution="https://undraw.co"
        src={`${RUZUKU_ASSETS_BASE_URL}/undraw_chat_re_re1u.svg`}
      />
      <h3 class="text-xl text-center font-bold mt-8 mb-2">
        {intl('Welcome to your course chat!')}
      </h3>
      <p class="mb-2">{intl('You can send a message to anyone in this course.')}</p>
      <p>
        {prefix}
        <a class="font-semibold" href={guideProfileUrl}>
          {link}
        </a>
        {suffix}
      </p>
      <footer class="flex items-center justify-center mt-8">
        <BtnPrimary href={guideProfileUrl}>
          <IcoChat class="w-5 h-5 mr-2" /> {intl('Start Chatting')}
        </BtnPrimary>
      </footer>
    </div>
  );
}
