import { UserMenu } from './user-menu';
import { SiteNavLogo } from './site-nav-logo';

/**
 * The main nav bar at the top of the non-course student pages.
 */
export function StudentNavBar() {
  return (
    <nav class="flex items-center justify-between p-4 sm:px-6 sm:pr-4 py-4">
      <SiteNavLogo href="/courses" />
      <div class="flex items-center">
        <UserMenu />
      </div>
    </nav>
  );
}
