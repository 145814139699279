import { ComponentChild, ComponentChildren } from 'preact';
import { fmtFullTime } from 'shared/dateutil';
import { useRouteParams } from '@components/router';
import { Pill } from '@components/pill';
import { StudentPageNavHeader } from '@components/page-nav-bar';
import { MeetingType } from 'server/types';
import { IcoPhoneOutgoing, IcoPresentation, IcoVideoCamera } from '@components/icons';
import { URLS } from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';
import { useMeetingStatus } from 'client/lib/hooks/use-meeting-status';
import { RpxResponse, rpx } from 'client/lib/rpx-client';

type ListMeeting = RpxResponse<typeof rpx.meetings.getStudentMeetings>[0];

interface Props {
  past: ListMeeting[];
  upcoming: ListMeeting[];
}

interface ItemProps {
  meeting: ListMeeting;
  isPast: boolean;
}

export const MeetingTypeIcons: Record<MeetingType, ComponentChild> = {
  videoconference: <IcoVideoCamera class="h-4 opacity-75" />,
  presentation: <IcoPresentation class="h-4 opacity-75" />,
  external: <IcoPhoneOutgoing class="h-4 opacity-75" />,
  zoom: (
    <span class="bg-sky-400 rounded inline-flex items-center justify-center w-4 h-4" title="Zoom">
      <IcoVideoCamera fill="#fff" stroke="none" class="w-3 h-3" />
    </span>
  ),
};

function Item({ meeting, isPast }: ItemProps) {
  const { courseId, meetingId: selectedId, courseSlug } = useRouteParams();
  const isSelected = selectedId === meeting.id;
  const intl = useIntl();
  const status = useMeetingStatus(meeting);

  return (
    <a
      class={`border-l p-2 flex flex-col relative hover:text-indigo-600 dark:hover:bg-gray-800 w-full hover:theme-link ${
        isSelected
          ? 'border-theme theme-link border-indigo-500 dark:border-sky-400 text-indigo-600 dark:text-sky-400 relative z-10'
          : 'border-gray-300 dark:text-gray-300 text-gray-700 dark:border-gray-600'
      }`}
      href={URLS.student.meeting({
        course: {
          id: courseId,
          title: courseSlug,
        },
        meeting,
      })}
    >
      <span class="flex items-center gap-2">
        <span class="flex-1 inline-flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis mr-2">
          <span class={isSelected ? 'font-semibold' : ''}>{meeting.title}</span>
        </span>
        {!isPast && status === 'inProgress' && <Pill color="green">{intl('ON AIR')}</Pill>}
        {MeetingTypeIcons[meeting.type]}
      </span>
      <span class="opacity-75 font-normal text-xs mt-0.5 mr-2">
        {isPast
          ? intl('Aired on {date:string}', {
              date: fmtFullTime(meeting.scheduledAt),
            })
          : ''}
      </span>
    </a>
  );
}

function PageNavSegment({
  title,
  numItems,
  children,
}: {
  title: string;
  numItems?: number;
  children: ComponentChildren;
}) {
  return (
    <div class="py-4">
      <div class="font-display font-medium text-gray-900 dark:text-white flex justify-between mb-2 pr-2 opacity-75">
        <span class="block">{title}</span>
        {numItems !== undefined && <span class="opacity-75">{numItems}</span>}
      </div>
      <div class="flex flex-col gap-1">{children}</div>
    </div>
  );
}

export function NavBar({ past, upcoming }: Props) {
  const intl = useIntl();

  return (
    <div class="flex flex-col grow overflow-hidden">
      <StudentPageNavHeader title={intl('Meetings')} />
      <PageNavSegment title={intl('Upcoming')} numItems={upcoming.length}>
        {upcoming.map((meeting) => (
          <Item key={meeting.id} meeting={meeting} isPast={false} />
        ))}
      </PageNavSegment>
      <PageNavSegment title={intl('Past')} numItems={past.length}>
        {past.map((meeting) => (
          <Item key={meeting.id} meeting={meeting} isPast />
        ))}
      </PageNavSegment>
    </div>
  );
}
