import { IcoCalendar, IcoClock } from '@components/icons';
import { useRouteParams } from '@components/router';
import { OverviewCard } from 'client/pages/guide-course-overview/overview-card';
import { MeetingTypeIcons } from 'client/pages/student-meetings/nav-bar';
import dayjs from 'dayjs';
import { useState } from 'preact/hooks';
import { StudentMeeting } from 'server/types';
import { fmtFullTime, minutesToTimeString } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';
import { URLS } from 'shared/urls';
import { CalendarDate } from './calendar-date';
import { useMeetingType } from './use-meeting-type';
import { useCurrentUser } from 'client/lib/auth';

type Meeting = Pick<StudentMeeting, 'id' | 'title' | 'scheduledAt' | 'type' | 'durationMinutes'>;

interface Props {
  meetings: Meeting[];
  isGuide?: boolean;
  Wrapper?: typeof OverviewCard;
}

function Item({ meeting, isGuide }: { meeting: Meeting; isGuide?: boolean }) {
  const user = useCurrentUser();
  const intl = useIntl();
  const { courseId, courseSlug } = useRouteParams();
  const meetingTypes = useMeetingType();

  return (
    <a
      class="flex space-x-4 p-4 hover:bg-gray-50 dark:border-gray-700 dark:hover:bg-black dark:hover:bg-opacity-25 rounded-lg"
      href={
        isGuide
          ? URLS.guide.meeting({
              courseId,
              meetingId: meeting.id,
            })
          : URLS.student.meeting({
              course: {
                id: courseId,
                title: courseSlug,
              },
              meeting,
            })
      }
      data-tooltip={fmtFullTime(meeting.scheduledAt, {
        includeTimezone: true,
        timeZone: user?.timezone,
      })}
    >
      <CalendarDate date={meeting.scheduledAt} />
      <span class="flex flex-col justify-center">
        <span class="text-base font-medium text-gray-800 dark:text-gray-200 line-clamp-1 mb-1">
          {meeting.title}
        </span>
        <dl class="flex flex-wrap space-x-2 text-xs text-gray-500 dark:text-gray-400 leading-loose">
          <dd class="flex items-center space-x-1">
            <IcoCalendar />
            <span>{dayjs(meeting.scheduledAt).format('hh:mm A')}</span>
          </dd>
          <dd class="hidden md:flex items-center space-x-1">
            <IcoClock />
            <span>{minutesToTimeString(meeting.durationMinutes, intl)}</span>
          </dd>
          <dd class="flex items-center space-x-1">
            <span>{MeetingTypeIcons[meeting.type]}</span>
            <span>{meetingTypes[meeting.type]}</span>
          </dd>
        </dl>
      </span>
    </a>
  );
}

export function UpcomingEvents({ meetings, isGuide, Wrapper = OverviewCard }: Props) {
  const [displayedMeetings, setDisplayedMeetings] = useState(() => meetings.slice(0, 2));

  const hasMore = meetings.length > displayedMeetings.length;

  if (meetings.length === 0) {
    return null;
  }

  return (
    <Wrapper
      title="Upcoming Meetings"
      footerAction={
        hasMore
          ? {
              onClick() {
                // Display 5 more items
                setDisplayedMeetings(meetings.slice(0, displayedMeetings.length + 5));
              },
            }
          : undefined
      }
    >
      <div class="relative">
        {displayedMeetings.map((m) => (
          <Item key={m.id} meeting={m} isGuide={isGuide} />
        ))}
      </div>
    </Wrapper>
  );
}
