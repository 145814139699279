import { Props, setOrderBy } from './state';
import { useCurrentTenant } from '@components/router/session-context';
import { Cell, HCell, SortableColumn } from './table';
import { SummaryStat } from './summary-section';
import { urls } from './urls';
import { UserProfile } from './user-profile';
import { ProgressBar } from '@components/progress-bar';
import { compactDate } from 'shared/dateutil';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { Spinner } from '@components/spinner';
import { Button } from '@components/buttons';
import { URLS } from 'shared/urls';
import { IcoBadge } from '@components/icons';
import { AccessStatusBadge } from './access-status-badge';

type StudentStats = RpxResponse<typeof rpx.manageStudents.getInitialState>['stats'];

export function ProgressStats({
  course,
  stats,
}: Props['data'] & {
  stats?: StudentStats;
}) {
  const percent = (over: number, under: number) => {
    const value = over && under ? Math.ceil(100 * (over / under)) : 0;
    return `${value}%`;
  };

  if (!stats) {
    return <Spinner />;
  }

  return (
    <>
      <SummaryStat label="Number of students">
        {(course.numStudents || 0).toLocaleString()}
      </SummaryStat>
      <SummaryStat label="Lessons complete">
        {percent(stats.numCompleted, stats.numStudentLessons)}
      </SummaryStat>
      {stats.numQuizzes > 0 && (
        <SummaryStat label="Avg quiz score">{Math.round(stats.avgQuizScore || 0)}%</SummaryStat>
      )}
      {stats.numAssignments > 0 && (
        <SummaryStat label="Assignments complete">
          {percent(stats.numAssignmentsSubmitted, stats.numStudentAssignments)}
        </SummaryStat>
      )}
      {stats.numPolls > 0 && (
        <SummaryStat label="Poll participation rate">
          {percent(stats.numPollsSubmitted, stats.numStudentPolls)}
        </SummaryStat>
      )}
    </>
  );
}

export function ProgressTable({
  state,
  setState,
  stats,
  route: { params },
}: Props & {
  stats?: StudentStats;
}) {
  const { terminology } = useCurrentTenant();
  return (
    <div class="table table-fixed  bg-white rounded border w-full divide-y">
      <div class="table-row bg-gray-50 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ">
        <SortableColumn
          col="name"
          orderBy={state.opts}
          onClick={() => setOrderBy(setState, 'name')}
        >
          Student
        </SortableColumn>
        <SortableColumn
          col="lessonsComplete"
          hiddenOnMobile
          orderBy={state.opts}
          onClick={() => setOrderBy(setState, 'lessonsComplete')}
        >
          {terminology.Lessons} complete
        </SortableColumn>
        <HCell>
          <span class="block text-center">{terminology.Modules} complete</span>
        </HCell>
        <HCell>
          <span class="block text-center">Assessments</span>
        </HCell>
        {stats && stats.numQuizzes > 0 && (
          <HCell>
            <span class="block text-center">Avg Quiz Score</span>
          </HCell>
        )}
        {state.course.hasEnabledCert && <HCell class="block text-center">Certificate</HCell>}
        <HCell class="block text-center">Last Active</HCell>
        <HCell>Access</HCell>
      </div>
      {state.students.data.map((s) => {
        return (
          <a
            key={s.id}
            class="table-row text-inherit hover:bg-gray-50 text-sm"
            href={urls.showStudent({
              ...params,
              courseId: params.courseId,
              userId: s.id,
              tab: 'progress',
              baseUrl: state.course.isProduct ? urls.productsBaseUrl : urls.coursesBaseUrl,
            })}
          >
            <Cell>
              <UserProfile user={s} />
            </Cell>
            <Cell>
              <ProgressBar progress={s.progress} />
            </Cell>
            <Cell>
              <span class="block text-center">
                {s.modulesComplete || 0} of {s.numModules}
              </span>
            </Cell>
            <Cell>
              {stats && (
                <span class="block text-center">
                  {s.assessmentsComplete || 0} of {stats.numAssessments}
                </span>
              )}
            </Cell>
            {stats && stats.numQuizzes > 0 && (
              <Cell>
                <span class="block text-center">
                  {s.avgQuizScore != null ? `${Math.round(s.avgQuizScore)}%` : 'N/A'}
                </span>
              </Cell>
            )}
            {state.course.hasEnabledCert && (
              <Cell>
                {s.modulesComplete === s.numModules && (
                  <span class="flex justify-center">
                    <Button
                      href={URLS.student.certificate({ course: state.course, userId: s.id })}
                      target="_blank"
                      class="text-indigo-600 p-2 rounded hover:bg-indigo-600 hover:text-white hover:shadow"
                    >
                      <IcoBadge class="w-4 h-4" />
                    </Button>
                  </span>
                )}
              </Cell>
            )}
            <Cell>
              <span class="block text-center">{compactDate(s.lastActive)}</span>
            </Cell>
            <Cell key="access">
              <AccessStatusBadge status={s.status} />
            </Cell>
          </a>
        );
      })}
    </div>
  );
}
