/**
 * This displays a little checkout widget if the URL has a price or coupon
 * in it. This allows external links to provide special pricing.
 */

import { Button } from '@components/buttons';
import { PriceSummary } from '@components/checkout/price-summary';
import { useRouteParams } from '@components/router';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { coursePrices } from 'shared/urls';

function LoadCheckoutWidget({
  priceId,
  couponId,
  courseId,
}: {
  priceId: string;
  couponId?: string;
  courseId: string;
}) {
  const { data } = useTryAsyncData(
    () => rpx.courseCheckout.getCourseCheckoutState({ priceId, couponId, courseId }),
    [],
  );

  if (!data?.price) {
    return null;
  }

  return (
    <section class="fixed bottom-0 w-full border-t sm:border-0 sm:bottom-6 sm:right-6 z-50 sm:rounded-3xl bg-white shadow-2xl shadow-black p-6 an-slide-up flex flex-col gap-4 sm:max-w-96">
      <header class="font-bold line-clamp-2">{data.course.title}</header>

      <div>
        <PriceSummary price={data.price} coupon={data.coupon} />
      </div>

      <Button
        class="rounded-full p-2 px-4 bg-gray-800 text-white text-center"
        href={coursePrices.checkoutUrl({ courseId, priceId, couponId })}
      >
        Checkout Now ⤑
      </Button>
    </section>
  );
}

export function CheckoutWidget() {
  const { priceId, couponId, courseId } = useRouteParams();

  if (!priceId) {
    return null;
  }

  return <LoadCheckoutWidget priceId={priceId} couponId={couponId} courseId={courseId} />;
}
