import { Case } from '@components/conditional';
import { CourseEnrollmentToggle } from '@components/course-enrollment-status';
import { FullCourse } from 'server/types';
import { StepItem } from './step-item';

interface Props {
  course: Pick<
    FullCourse,
    'id' | 'title' | 'accessFormat' | 'isAbsoluteSchedule' | 'status' | 'isBundle'
  >;
  isEnabled: boolean;
}

export function EnrollmentStatusStep({ course, isEnabled }: Props) {
  return (
    <StepItem title="Open your course for signups" stepKey="publishCourse">
      <p>
        You’ll need to open signups for your course to allow students to join. If you close signups
        (which you can do at any time), new visitors will see a message saying that signups are
        closed.
      </p>
      <Case
        when={isEnabled}
        fallback={<p>You need to create a price point first to enable your course for signups.</p>}
      >
        <CourseEnrollmentToggle course={course} padding="py-1" dontAskForAccessFormat />
      </Case>
    </StepItem>
  );
}
