import { showError } from '@components/app-error';
import { useBasicAutosaver } from '@components/autosaver';
import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';
import { IcoDownload, IcoPlus } from '@components/icons';
import { UndoNotice } from '@components/undo-notice';
import { substateUpdater, useTryAsyncData, useUndoRedo } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { ComponentChildren } from 'preact';
import { useMemo, useState } from 'preact/hooks';
import {
  AssessmentQuestion as AssessmentQuestionOriginal,
  AssessmentChoice as AssessmentChoiceOriginal,
} from 'server/types';
import { generateUUID } from 'shared/utils';
import { ReadOnlyQuestion } from '../assessment-question';
import { QuestionItem, createChoice } from './question-item';
import { Toggle } from '@components/toggle';
import { LessonPane } from '../lesson-pane';
import { FullLesson } from '../types';
import { PollResults } from '@components/poll/poll-results';
import { SaveStatus } from '@components/save-status';
import { QuizSummary } from './quiz-summary';
import { showToast } from '@components/toaster';

export interface AssessmentChoice extends AssessmentChoiceOriginal {
  deleted?: boolean;
  isPlaceholder?: boolean;
}

export interface AssessmentQuestion extends AssessmentQuestionOriginal {
  choices: AssessmentChoice[];
  deleted?: boolean;
}

export interface Assessment {
  questions: AssessmentQuestion[];
  preferences: {
    allowRetake: boolean;
    shuffleQuestions: boolean;
    shuffleChoices: boolean;
    minQuizScore?: number;
  };
}

interface Props {
  lesson: Pick<
    FullLesson,
    'id' | 'assessmentPreferences' | 'title' | 'hasAssessmentSubmissions' | 'minQuizScore'
  >;
  isQuiz?: boolean;
  hide: () => void;
}

function AssessmentToggle(props: {
  checked: boolean;
  onClick(): void;
  children: ComponentChildren;
}) {
  return (
    <label class="flex gap-3 items-center cursor-pointer">
      <Toggle checked={props.checked} onClick={props.onClick} />
      {props.children}
    </label>
  );
}

export function LoadedAssessmentEditor(props: {
  lesson: Props['lesson'];
  isQuiz: boolean;
  assessment: Assessment;
  onClose(): void;
}) {
  const { lesson, isQuiz, onClose } = props;
  const [assessment, setAssessment] = useState<Assessment>(props.assessment);
  const [openedQuestion, setOpenedQuestion] = useState<UUID | undefined>();
  const [showUndoNotice, setShowUndoNotice] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [showMinQuizScore, setShowMinQuizScore] = useState(
    Boolean(isQuiz && assessment.preferences.minQuizScore),
  );
  const { questions } = assessment;
  const alwaysAllowRetake = isQuiz && !!assessment.preferences.minQuizScore;
  const activeQuestions = questions.filter((q) => !q.deleted);
  const undoRedo = useUndoRedo(assessment, setAssessment);
  const setQuestions = useMemo(
    () =>
      substateUpdater(
        setAssessment,
        (s) => s.questions,
        (s, questions) => ({ ...s, questions }),
      ),
    [setAssessment],
  );
  const totalPoints = useMemo(
    () => activeQuestions.reduce((x, q) => x + q.points, 0),
    [activeQuestions],
  );

  function updateQuestion(id: UUID, updater: (q: AssessmentQuestion) => AssessmentQuestion) {
    setQuestions((s) => s.map((x) => (x.id === id ? updater(x) : x)));
  }

  function updatePreferences(partial: Partial<Assessment['preferences']>) {
    setAssessment((s) => ({
      ...s,
      preferences: {
        ...s.preferences,
        ...partial,
      },
    }));
  }

  function createNewQuestion() {
    if (lesson.hasAssessmentSubmissions) {
      return showHasSubmissionsWarning();
    }

    const newId = generateUUID();
    const newQuestion = {
      id: newId,
      lessonId: lesson.id,
      content: '',
      isRequired: true,
      isMultipleChoice: false,
      // The default points of this question for quizzes
      points: 10,
      choices: [
        createChoice({
          questionId: newId,
          content: 'Option 1',
          isCorrect: isQuiz,
        }),
        // Placeholder for 'add option' button
        createChoice({
          questionId: newId,
          content: '',
          isPlaceholder: true,
        }),
      ],
    };
    setQuestions((s) => [...s, newQuestion]);
    setOpenedQuestion(newQuestion.id);
  }

  function showHasSubmissionsWarning() {
    showToast({
      type: 'warn',
      title: `Action is not available`,
      message: `This action is not available because this assessment already has student submissions.`,
    });
  }

  const preferencesSaveState = useBasicAutosaver(assessment.preferences, async (val) => {
    try {
      await rpx.assessments.saveAssessmentPreferences({
        lessonId: lesson.id,
        allowRetake: alwaysAllowRetake || val.allowRetake,
        shuffleQuestions: val.shuffleQuestions,
        shuffleChoices: val.shuffleChoices,
        minQuizScore: val.minQuizScore,
      });
    } catch (err) {
      showError(err);
    }
  });

  const autosaver = useBasicAutosaver(questions, async (val) => {
    try {
      /*
       * We don't need to save the assessment if there are no questions.
       * This is also true for the cases where the user deletes all
       * of the assessment questions. Because we're still sending
       * questions with `deleted: true` flag in that case.
       */
      if (val.length === 0) {
        return [];
      }

      /*
       * This is sending all the questions to the backend
       * for inserts, updates and deletes.
       * This is not ideal but it works well with the undo/redo system.
       */
      const savedQuestions = val.map((question, index) => ({
        id: question.id,
        deleted: question.deleted,
        content: question.content || '',
        file: question.file
          ? {
              id: question.file.id,
              path: question.file.url,
            }
          : undefined,
        isRequired: question.isRequired,
        isMultipleChoice: question.isMultipleChoice,
        seq: index,
        points: question.points,
        correctFeedback: question.correctFeedback,
        incorrectFeedback: question.incorrectFeedback,
        correctFeedbackFile: question.correctFeedbackFile
          ? {
              id: question.correctFeedbackFile.id,
              path: question.correctFeedbackFile.url,
            }
          : undefined,
        incorrectFeedbackFile: question.incorrectFeedbackFile
          ? {
              id: question.incorrectFeedbackFile.id,
              path: question.incorrectFeedbackFile.url,
            }
          : undefined,
        choices: question.choices
          .filter((c) => !c.isPlaceholder)
          .map((c, index) => ({
            id: c.id,
            content: c.content || '',
            seq: index,
            file: c.file
              ? {
                  id: c.file.id,
                  path: c.file.url,
                }
              : undefined,
            isCorrect: c.isCorrect || false,
            deleted: c.deleted,
          })),
      }));
      await rpx.assessments.upsertAssessmentQuestions({
        lessonId: lesson.id,
        questions: savedQuestions,
      });
    } catch (err) {
      showError(err);
    }
  });

  return (
    <LessonPane
      isVisible
      hide={onClose}
      header={
        <div class="flex grow justify-between">
          <div class="flex space-x-2">
            <span class="block font-bold text-gray-500 capitalize">{isQuiz ? 'Quiz' : 'Poll'}</span>
            <Case when={questions.length > 0}>
              <span> | </span>
              <div class="">
                <Button class="text-indigo-600" onClick={() => setShowResults(!showResults)}>
                  {showResults ? 'Hide Results' : 'View Results'}
                </Button>
              </div>
            </Case>
          </div>
          <SaveStatus isDirty={autosaver.isDirty || preferencesSaveState.isDirty} padding="pr-2" />
        </div>
      }
    >
      <Case
        when={!showResults}
        fallback={
          isQuiz ? (
            <QuizSummary lesson={lesson} totalPoints={totalPoints} />
          ) : (
            <>
              <div class="flex justify-end m-4">
                <Button
                  class="inline-flex items-center gap-1"
                  download={`poll-${lesson.title}.csv`}
                  href={`/csv/lessons/${lesson.id}/poll.csv`}
                >
                  <IcoDownload />
                  Download CSV
                </Button>
              </div>
              <PollResults
                showResponseCounts={true}
                questions={activeQuestions.map((q) => ({
                  ...q,
                  choices: q.choices.filter((c) => !c.deleted && !c.isPlaceholder),
                }))}
              />
            </>
          )
        }
      >
        <section class="flex flex-col gap-8 p-4">
          <AssessmentToggle
            checked={assessment.preferences.shuffleQuestions}
            onClick={() =>
              updatePreferences({
                shuffleQuestions: !assessment.preferences.shuffleQuestions,
              })
            }
          >
            Shuffle question order
          </AssessmentToggle>
          <AssessmentToggle
            checked={assessment.preferences.shuffleChoices}
            onClick={() =>
              updatePreferences({
                shuffleChoices: !assessment.preferences.shuffleChoices,
              })
            }
          >
            Shuffle option order
          </AssessmentToggle>
          {isQuiz && (
            <AssessmentToggle
              checked={showMinQuizScore}
              onClick={() => {
                setShowMinQuizScore((x) => !x);
                updatePreferences({ minQuizScore: undefined });
              }}
            >
              Specify a passing score
            </AssessmentToggle>
          )}
          {isQuiz && showMinQuizScore && (
            <div class="ml-14 -mt-6 flex flex-col gap-2">
              <span class="text-gray-500">
                Students will be allowed to retake this quiz until they pass.
              </span>
              <div>
                <div class="inline-flex items-center gap-2">
                  <label class="relative">
                    <input
                      class="inline-ruz-input w-14 text-center"
                      name="minQuizScore"
                      value={assessment.preferences.minQuizScore || ''}
                      defaultValue={`${lesson.minQuizScore || ''}`}
                      onInput={(e: any) => {
                        const value = parseInt(e.target.value);
                        updatePreferences({ minQuizScore: value || undefined });
                      }}
                      placeholder="0"
                    />
                  </label>
                  <span class="opacity-50">
                    of {totalPoints} points (
                    {Math.round(((assessment.preferences.minQuizScore || 0) / totalPoints) * 100)}%)
                  </span>
                </div>
              </div>
            </div>
          )}
          {!showMinQuizScore && (
            <AssessmentToggle
              checked={assessment.preferences.allowRetake}
              onClick={() =>
                updatePreferences({
                  allowRetake: !assessment.preferences.allowRetake,
                })
              }
            >
              Allow retaking after submission
            </AssessmentToggle>
          )}

          <div class="flex flex-col">
            <Case when={questions.length > 0}>
              <DraggableProvider
                canHandleDrop={(_, table) => table === 'assessment-questions'}
                onDragComplete={() => {}}
                onTargetChange={(dragState) => setQuestions((s) => reorderItems(s, dragState))}
              >
                {activeQuestions.map((question) => (
                  <Draggable key={question.id} id={question.id} table="assessment-questions">
                    <Case
                      when={openedQuestion === question.id}
                      fallback={
                        <ReadOnlyQuestion
                          question={question}
                          hideRequiredChar={isQuiz}
                          showScore={showMinQuizScore}
                          onClick={() => setOpenedQuestion(question.id)}
                        />
                      }
                    >
                      <QuestionItem
                        question={question}
                        isQuiz={isQuiz}
                        canDelete={activeQuestions.length > 1}
                        onChange={(newQuestion) => {
                          if (lesson.hasAssessmentSubmissions) {
                            const choicesUpdated =
                              question.choices.length !== newQuestion.choices.length ||
                              newQuestion.choices.find((c) => c.deleted);

                            if (choicesUpdated) {
                              return showHasSubmissionsWarning();
                            }
                          }
                          updateQuestion(question.id, () => newQuestion);
                        }}
                        onDelete={() => {
                          if (lesson.hasAssessmentSubmissions) {
                            return showHasSubmissionsWarning();
                          }
                          updateQuestion(question.id, (q) => ({ ...q, deleted: true }));
                          setShowUndoNotice(true);
                        }}
                        onDuplicate={() => {
                          if (lesson.hasAssessmentSubmissions) {
                            return showHasSubmissionsWarning();
                          }

                          const newQuestionId = generateUUID();
                          const newQuestion = {
                            ...question,
                            content: `Copy of ${question.content}`,
                            id: newQuestionId,
                            choices: question.choices.map((c) => ({
                              ...c,
                              id: generateUUID(),
                              questionId: newQuestionId,
                            })),
                          };
                          setQuestions((q) => [...q, newQuestion]);
                          setOpenedQuestion(newQuestion.id);
                        }}
                      />
                    </Case>
                  </Draggable>
                ))}
              </DraggableProvider>
            </Case>
            <footer class="mt-6">
              <Button
                class="inline-flex items-center text-indigo-600 outline-none focus:ring-2 focus:ring-indigo-400 rounded"
                onClick={createNewQuestion}
              >
                <IcoPlus class="h-6 w-6 mr-1 opacity-75" />
                {isQuiz ? 'Create a new quiz question' : 'Create a new poll question'}
              </Button>
            </footer>
          </div>
        </section>
      </Case>
      <UndoNotice
        displayed={showUndoNotice}
        text="Question deleted."
        onClick={undoRedo.undo}
        hide={() => setShowUndoNotice(false)}
      />
    </LessonPane>
  );
}

export function AssessmentEditor({ lesson, isQuiz, hide }: Props) {
  const questions = useTryAsyncData(
    () => rpx.assessments.getAssessmentQuestions({ lessonId: lesson.id }),
    [lesson.id],
  );

  if (!questions.data) {
    return null;
  }

  return (
    <LoadedAssessmentEditor
      lesson={lesson}
      onClose={hide}
      isQuiz={!!isQuiz}
      assessment={{
        preferences: {
          ...lesson.assessmentPreferences,
          minQuizScore: lesson.minQuizScore,
        },
        questions: questions.data,
      }}
    />
  );
}
