/*
 * Some components that are re-used by polls, quizzes and assignments.
 */
import { IcoGripperHorizontal, IcoPaperclip, IcoX } from '@components/icons';
import { AutosizeText } from '@components/autosize-text';
import { filepicker } from 'client/components/filepicker';
import { Attachments } from '@components/attachments';
import { showError } from '@components/app-error';
import { FileRec } from 'server/types';
import { Button } from '@components/buttons';
import { pluralize } from 'shared/formatting';

interface Question {
  content?: string;
  file?: FileRec;
  isRequired?: boolean;
  points?: number;
}

interface Props {
  question: Question;
  placeholder?: string;
  minHeight?: string;
  focusSelf?: boolean;
  onChange: (updates: Partial<Question>) => void;
}

export function ReadOnlyQuestion({
  question,
  hideRequiredChar,
  showScore,
  onClick,
}: {
  question: Question;
  showScore?: boolean;
  hideRequiredChar?: boolean;
  onClick: () => void;
}) {
  return (
    <div
      class="flex flex-col gap-4 p-6 py-4 border rounded-md mb-4 group relative"
      onClick={onClick}
    >
      <div
        class="absolute inset-x-9 top-0 flex justify-center invisible group-hover:visible text-gray-400 cursor-move"
        draggable
      >
        <IcoGripperHorizontal class="w-5 h-5" />
      </div>
      <p class="text-lg whitespace-pre-line">
        {showScore && (
          <span class="float-right text-gray-500 text-xs ml-4 mt-2">
            {question.points || 0} {pluralize('point', question.points || 0)}
          </span>
        )}
        {question.content || 'Question'}
        {!hideRequiredChar && question.isRequired && (
          <span class="text-red-600 ml-2 text-2xl">*</span>
        )}
      </p>
      {question.file && <Attachments attachments={[question.file]} />}
    </div>
  );
}

export function QuestionEditor({
  question,
  placeholder = 'Write a question...',
  minHeight,
  focusSelf = true,
  onChange,
}: Props) {
  async function attachFile() {
    try {
      const result = await filepicker();
      if (result) {
        onChange({
          file: {
            id: result.fileId,
            type: result.type,
            name: result.name,
            url: result.filePath,
          },
        });
      }
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div class="mt-6 mb-4">
      <div class="relative">
        <AutosizeText
          class="ruz-input p-2 pr-8"
          minHeight={minHeight}
          placeholder={placeholder}
          maxLength={4096}
          value={question.content || ''}
          focusSelf={focusSelf}
          onInput={(e: any) =>
            onChange({
              content: e.target.value,
            })
          }
        />
        <div class="absolute inset-y-0 right-0 pr-3 flex items-center">
          <Button
            type="button"
            tabIndex={-1}
            class="ml-2 text-gray-500 hover:text-gray-600"
            onClick={attachFile}
          >
            <IcoPaperclip />
          </Button>
        </div>
      </div>
      {question.file && (
        <div class="mt-4 bg-gray-50 rounded p-2 relative">
          <Attachments attachments={[question.file]} />
          <Button
            class="absolute -top-2 -right-2 p-2 rounded-full bg-gray-300 shadow"
            onClick={() => onChange({ file: undefined })}
          >
            <IcoX class="w-5 h-5 text-black" />
          </Button>
        </div>
      )}
    </div>
  );
}
