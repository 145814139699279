import { CourseStatus } from 'server/types';
import { Course, CourseType, NormalizedStatus, Filters } from './types';

type HighlightResult = {
  prefix: string;
  highlight?: string;
  suffix?: string;
};

export function normalizedStatus(c: {
  status: CourseStatus;
  numStudents?: number;
}): NormalizedStatus {
  if (c.status === 'draft' && !!c.numStudents) {
    return 'closed';
  }

  return c.status;
}

export function highlightTerms(
  course: { id: UUID; title: string },
  searchTerm: string,
): HighlightResult {
  const { title } = course;

  if (!searchTerm) {
    return { prefix: title };
  }

  const [prefix] = title.toLowerCase().split(searchTerm, 1);

  return {
    prefix: title.substring(0, prefix.length),
    highlight: title.substring(prefix.length, prefix.length + searchTerm.length),
    suffix: title.substring(prefix.length + searchTerm.length),
  };
}

export function filterCourses({
  courses,
  type,
  filters,
}: {
  courses: Course[];
  type: CourseType;
  filters: Pick<Filters, 'type' | 'status' | 'show'>;
}) {
  return courses
    .filter((c) => {
      if (c.isBundle && type !== 'bundles') {
        return false;
      }
      if (!c.isBundle && type === 'bundles') {
        return false;
      }
      if (filters.show === 'all') {
        return true;
      }
      if (filters.show === 'archived') {
        return c.isArchived;
      }
      if (c.isArchived) {
        return false;
      }
      if (filters.show === 'teaching') {
        return c.level === 'guide' || c.level === 'facilitator';
      }
      if (filters.show === 'attending') {
        return c.level === 'student';
      }
      return true;
    })
    .filter((c) => {
      const typeCondition = filters.type === 'all' || c.accessFormat === filters.type;
      const statusCondition = filters.status === 'all' || c.normalizedStatus === filters.status;
      return typeCondition && statusCondition;
    });
}
