import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useEffect, useState } from 'preact/hooks';
import { timeago } from 'shared/dateutil';
import { QuizResultsModal } from '@components/quiz/quiz-results';
import { UserProfileIcon } from '@components/avatars';
import { IcoClock } from '@components/icons';
import { PrimaryOverviewCard } from './overview-card';
import { showModalForm } from '@components/modal-form';

interface Props {
  courseId: UUID;
}

type SubmissionFeed = RpxResponse<typeof rpx.assessments.getQuizSubmissions>;

export function RecentQuizSubmissions({ courseId }: Props) {
  const [{ submissions, cursor, hasMore }, setFeed] = useState<SubmissionFeed>({
    submissions: [],
    hasMore: true,
    cursor: '',
  });

  async function loadNextPage() {
    try {
      const result = await rpx.assessments.getQuizSubmissions({
        courseId,
        cursor,
        limit: 3,
      });
      setFeed({
        submissions: submissions.concat(result.submissions),
        hasMore: result.hasMore,
        cursor: result.cursor,
      });
    } catch (e) {
      showError(e);
    }
  }

  // Load the first page
  useEffect(() => {
    loadNextPage();
  }, []);

  if (!submissions.length) {
    return null;
  }

  return (
    <PrimaryOverviewCard
      title="Quiz Submissions"
      footerAction={hasMore ? { onClick: loadNextPage, text: 'Show More' } : undefined}
    >
      {submissions.map((submission) => (
        <Button
          key={submission.user.id}
          class="flex w-full border-t border-gray-200 p-4 hover:bg-gray-50"
          onClick={() =>
            showModalForm(({ resolve }) => (
              <QuizResultsModal
                courseId={courseId}
                lesson={submission.lesson}
                user={submission.user}
                hide={resolve}
              />
            ))
          }
        >
          <div class="flex items-center space-x-4">
            <div class="flex-shrink-0">
              <UserProfileIcon user={submission.user} size="w-10 h-10" />
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-left text-sm font-medium text-gray-900">
                <strong>{submission.user.name}</strong> completed{' '}
                <strong>{submission.lesson.title}</strong> with a score of {submission.score}%.
              </p>
              <dl class="flex space-x-2 text-xs text-gray-500 dark:text-gray-200">
                <dd class="hidden md:flex items-center space-x-1">
                  <IcoClock class="h-4 w-4" />
                  <span>{timeago(submission.submittedAt)}</span>
                </dd>
              </dl>
            </div>
          </div>
        </Button>
      ))}
    </PrimaryOverviewCard>
  );
}
