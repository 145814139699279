import { router } from '@components/router';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { OfferPriceForm, PriceType } from './offer-price-form';
import { UpsellsPage } from './upsells-page';
import { UpsellWizard, WizardProgress, WizardSubtitle } from './upsell-wizard';
import { Button } from '@components/buttons';
import { IcoArrowLeft, IcoDotsHorizontal, IcoTrash } from '@components/icons';
import { Picker, UpsellProductPicker } from './pickers';
import { EditOfferSalesForm } from './offer-edit-sales-form';
import { ProductAndPricePreview } from './product-price-preview';
import { Dropdown } from '@components/dropdown';
import { showError } from '@components/app-error';
import { useCurrentTenant } from 'client/lib/auth';
import { rpx } from 'client/lib/rpx-client';
import { FullUpsell, Offer, Upsell } from './types';
import { useRef } from 'preact/hooks';
import { showDialog } from '@components/dialog';
import { showToast } from '@components/toaster';

type Pane = 'pickproduct' | 'pricetype' | 'priceform' | 'salescopy';

type State = {
  page: 'new' | 'edit';
  offer: Partial<Offer>;
  upsell: FullUpsell;
  pane: Pane;
  priceType: PriceType;
};

async function loadUpsell(upsellId: string) {
  const upsell = await rpx.upsells.getUpsell({ upsellId });
  return {
    upsell: {
      ...upsell,
      offers: upsell.offers.map((o) => ({ ...o, id: o.productId })),
    },
  };
}

async function loadNew(props: AppRoute): Promise<State> {
  const { upsell } = await loadUpsell(props.params.id);
  return {
    page: 'new',
    upsell,
    pane: 'pickproduct',
    priceType: 'single',
    offer: {
      content: '',
      upsellId: upsell.id,
      acceptText: '',
      rejectText: '',
    },
  };
}

async function loadExisting(props: AppRoute): Promise<State> {
  const { upsell } = await loadUpsell(props.params.id);
  const offer = upsell.offers.find((x) => x.id === props.params.offerId);
  if (!offer) {
    throw new Error(`Upsell not found!`);
  }
  return { page: 'edit', upsell, pane: 'salescopy', priceType: 'single', offer };
}

type Props = LoadedProps<typeof loadNew>;

function UpsellPriceTypePicker(props: Props) {
  const items: Array<{ id: PriceType; desc: string }> = [
    { id: 'single', desc: 'Single payment' },
    { id: 'plan', desc: 'Payment plan' },
    { id: 'monthly', desc: 'Monthly subscription' },
    { id: 'annually', desc: 'Annual subscription' },
  ];

  return (
    <>
      <ProductAndPricePreview product={props.state.offer.product!} />
      <WizardSubtitle>Select a payment type</WizardSubtitle>
      <Picker
        hideSearch
        onPick={({ id }) => props.setState((s) => ({ ...s, pane: 'priceform', priceType: id }))}
        load={async () => items}
        summarize={(c) => c.desc}
      />
    </>
  );
}

function diffPrices({ original, current }: { original: Offer['price']; current: Offer['price'] }) {
  const fields: Array<keyof Offer['price']> = [
    'interval',
    'priceInCents',
    'currency',
    'paymentType',
    'numPayments',
    'allowPaypal',
    'allowStripe',
  ];
  for (const field of fields) {
    if (original[field] !== current[field]) {
      return field;
    }
  }
}

async function savePrice({
  upsell,
  original,
  courseId,
  current,
}: {
  upsell: Upsell;
  courseId?: string;
  original?: Offer['price'];
  current: Offer['price'];
}) {
  // If we have an original price, we'll compare to see if the original and current
  // have diverged significantly enough to require archiving the original and saving
  // a new price.
  const requiresNew = !!original && diffPrices({ original, current });
  if (original?.id && current?.id && !requiresNew) {
    await rpx.prices.updatePrice({
      productId: current.productId,
      priceId: current.id,
      allowSelfCancel: current.allowSelfCancel,
      allowStripe: current.allowStripe,
      allowPaypal: current.allowPaypal,
    });
    return current;
  }
  if (courseId) {
    await rpx.prices.initForCourse({ courseId });
  }
  const price = await rpx.prices.createPrice({
    paymentType: current.paymentType,
    currency: current.currency,
    priceInCents: current.priceInCents,
    interval: current.interval,
    allowStripe: current.allowStripe,
    allowPaypal: current.allowPaypal,
    productId: current.productId,
    numPayments: current.numPayments,
    allowSelfCancel: current.allowSelfCancel,
    isUpsellOffer: true,
    offerUpsellId: upsell.id,
    isCorePrice: false,
    name: current.name || `Upsell ${upsell.title.slice(0, 30)}...`,
    isEnabled: true,
  });
  return price;
}

function Page(props: Props) {
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const { upsell, offer } = state;
  const applyToPrice = props.route.params.applyto;
  const applyToProduct = props.route.params.product;
  const paneSequence: Pane[] = ['pickproduct', 'pricetype', 'priceform', 'salescopy'];
  const paneIndex = paneSequence.indexOf(state.pane);
  const title = state.page === 'new' ? 'New offer' : 'Edit offer';
  const cancelURL = props.route.params.redirect || `/upsells/${upsell.id}?tab=offers`;
  const firstPaneIndex = state.page === 'new' ? 0 : 1;
  const originalOffer = useRef(offer);

  const gotoPane = (value: number | Pane) => {
    const index = Math.max(
      firstPaneIndex,
      typeof value === 'number' ? value : paneSequence.indexOf(value),
    );
    const pane: Pane = paneSequence[index];
    setState((s) => ({
      ...s,
      pane,
      offer: {
        ...s.offer,
        product: index === 0 ? undefined : s.offer.product,
        price: index <= 1 ? undefined : s.offer.price,
      },
    }));
  };

  const showDeleteUpsellOfferModal = async () => {
    try {
      const ok = await showDialog({
        title: 'Delete item',
        children: `This will permanently delete this upsell item. Any customers who purchased this item will continue to have access to the ${terminology.course} / product.`,
        confirmButtonText: 'Permanently delete item',
        mode: 'warn',
      });
      if (ok) {
        await rpx.upsells.deleteOffer({ upsellId: upsell.id, productId: offer.productId! });
        router.goto(cancelURL);
      }
    } catch (err) {
      showError(err);
    }
  };

  return (
    <UpsellsPage title={title}>
      <UpsellWizard
        cancelURL={cancelURL}
        upsell={upsell}
        title={title}
        paneIndex={paneIndex}
        hasUnsavedChanges={() => JSON.stringify(offer) !== JSON.stringify(originalOffer.current)}
        header={
          <>
            {state.page === 'edit' && (
              <Dropdown
                class="absolute top-8 right-8"
                triggerClass="hover:bg-gray-200 size-8 rounded-full inline-flex items-center justify-center transition-all"
                hideDownIcon
                noPadding
                renderMenu={() => (
                  <section class="p-2 flex flex-col">
                    <Button
                      class="flex items-center gap-2 p-2 rounded hover:bg-red-50 hover:text-red-600"
                      onClick={showDeleteUpsellOfferModal}
                    >
                      <IcoTrash />
                      <span>Delete offer</span>
                    </Button>
                  </section>
                )}
              >
                <IcoDotsHorizontal />
              </Dropdown>
            )}

            {state.page === 'new' && (
              <nav class="flex flex-col gap-4">
                <WizardProgress
                  paneIndex={paneIndex}
                  numPanes={paneSequence.length}
                  gotoPane={gotoPane}
                />
              </nav>
            )}

            {firstPaneIndex < paneIndex && (
              <span>
                <Button
                  class="inline-flex gap-2 items-center text-indigo-600 font-semibold"
                  onClick={() => gotoPane(paneIndex - 1)}
                >
                  <IcoArrowLeft class="size-4" />
                  {state.page === 'new' && 'Back'}
                  {state.page === 'edit' &&
                    `Back to ${state.pane === 'salescopy' ? 'pricing' : 'payment type'}`}
                </Button>
              </span>
            )}
          </>
        }
      >
        {state.pane === 'pickproduct' && (
          <UpsellProductPicker
            title={`Offer a ${terminology.course} or product as part of this upsell`}
            onPick={(product) =>
              setState((s) => ({ ...s, pane: 'pricetype', offer: { ...s.offer, product } }))
            }
            filter={(product) =>
              product.id !== applyToProduct &&
              !upsell.offers.some((x) => x.productId === product.id)
            }
          />
        )}
        {state.pane === 'pricetype' && <UpsellPriceTypePicker {...props} />}
        {state.pane === 'priceform' && (
          <OfferPriceForm
            product={offer.product!}
            priceType={state.priceType}
            listPrice={offer.listPrice}
            price={offer.price}
            onSave={(data) => {
              setState((s) => ({
                ...s,
                offer: { ...s.offer, price: data.price, listPrice: data.listPrice },
              }));
              gotoPane('salescopy');
            }}
          />
        )}
        {state.pane === 'salescopy' && (
          <>
            <ProductAndPricePreview
              product={offer.product!}
              price={offer.price}
              listPrice={offer.listPrice}
            />
            <EditOfferSalesForm
              state={offer as Required<Offer>}
              setState={(fn) => setState((s) => ({ ...s, offer: fn(s.offer as Required<Offer>) }))}
              upsell={upsell}
              onSave={async (offer) => {
                const price = await savePrice({
                  upsell,
                  courseId: offer.product.courseId,
                  original: originalOffer.current.price,
                  current: offer.price,
                });
                await rpx.upsells.saveOffer({
                  rejectText: offer.rejectText,
                  acceptText: offer.acceptText,
                  listPrice: offer.listPrice,
                  priceId: price.id,
                  productId: offer.productId,
                  upsellId: offer.upsellId,
                  content: offer.content,
                });
                if (applyToPrice) {
                  await rpx.upsells.applyPrice({
                    upsellId: upsell.id,
                    priceId: applyToPrice,
                    apply: true,
                  });
                }
                showToast({
                  type: 'ok',
                  title: 'Upsell created',
                  message: upsell.title,
                });
                return router.goto(cancelURL);
              }}
            />
          </>
        )}
      </UpsellWizard>
    </UpsellsPage>
  );
}

router.add({ url: 'upsells/:id/new', render: Page, authLevel: 'guide', load: loadNew });
router.add({
  url: 'upsells/:id/offers/:offerId',
  render: Page,
  authLevel: 'guide',
  load: loadExisting,
});
