import { Button } from '@components/buttons';
import {
  IcoArchive,
  IcoCheckCircle,
  IcoChevronDown,
  IcoDotsVertical,
  IcoEye,
  IcoPencil,
  IcoXCircle,
} from '@components/icons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { showError } from '@components/app-error';
import { Dropdown } from '@components/dropdown';
import { showUpsellPreviewModal } from './checkout-modal';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { showDialog } from '@components/dialog';

type Upsell = RpxResponse<typeof rpx.upsells.getUpsell>;

export function UpsellHeader({
  upsell,
  setUpsell,
}: {
  upsell: Upsell;
  setUpsell(fn: (u: Upsell) => Upsell): void;
}) {
  const showRenameUpsellModal = () =>
    showModalForm(() => (
      <ModalForm
        title="Rename upsell"
        onSubmit={async (data) => {
          const updated = { ...upsell, title: data.title };
          await rpx.upsells.saveUpsell({
            upsellId: updated.id,
            title: updated.title,
          });
          setUpsell((s) => ({ ...s, title: updated.title }));
        }}
      >
        <input name="title" value={upsell.title} placeholder="Upsell title" class="ruz-input" />
      </ModalForm>
    ));

  const saveUpsellState = async (opts: { isEnabled: boolean; isArchived: boolean }) => {
    try {
      await rpx.upsells.setIsArchived({
        upsellId: upsell.id,
        isArchived: opts.isArchived,
        isEnabled: opts.isEnabled,
      });
      setUpsell((u) => ({
        ...u,
        isArchived: opts.isArchived,
        isEnabled: opts.isEnabled,
      }));
    } catch (err) {
      showError(err);
    }
  };

  const showToggleArchiveUpsellModal = async () => {
    if (!upsell.isArchived) {
      const ok = await showDialog({
        title: 'Archive upsell?',
        children: `This will disable the upsell and change how it displays in your management screens.`,
        confirmButtonText: 'Archive upsell',
        mode: 'warn',
      });
      if (!ok) {
        return;
      }
    }
    await saveUpsellState({ isArchived: !upsell.isArchived, isEnabled: false });
  };

  return (
    <div class="grow flex items-center justify-between gap-4">
      <div class="flex items-center">
        <h1 class="text-2xl">
          <button onClick={showRenameUpsellModal}>{upsell.title}</button>
        </h1>
        <Dropdown
          triggerClass="hover:bg-gray-100 size-8 rounded-full inline-flex items-center justify-center transition-all"
          hideDownIcon
          noPadding
          renderMenu={() => (
            <section class="p-2 flex flex-col">
              <Button
                class="flex items-center gap-2 p-2 rounded hover:bg-gray-100"
                onClick={showRenameUpsellModal}
              >
                <IcoPencil />
                <span>Rename upsell</span>
              </Button>
            </section>
          )}
        >
          <IcoDotsVertical />
        </Dropdown>
      </div>
      <nav class="flex items-center gap-4">
        {!!upsell.numOffers && (
          <button
            class="flex items-center gap-2 border rounded-full pl-3 pr-4 py-1 hover:bg-gray-50"
            onClick={() => showUpsellPreviewModal(upsell as any /*TODO*/)}
          >
            <IcoEye />
            Preview
          </button>
        )}
        <Dropdown
          hideDownIcon
          noPadding
          renderMenu={() => (
            <section class="p-2 flex flex-col">
              <Button
                class="flex items-center gap-2 p-2 rounded hover:bg-red-50 hover:text-red-600"
                onClick={showToggleArchiveUpsellModal}
              >
                <IcoArchive />
                <span>{upsell.isArchived ? 'Unarchive' : 'Archive'} upsell</span>
              </Button>
              {upsell.isEnabled && (
                <Button
                  class="flex items-center gap-2 p-2 rounded hover:bg-gray-50"
                  onClick={() =>
                    saveUpsellState({ isEnabled: false, isArchived: !!upsell.isArchived })
                  }
                >
                  <IcoXCircle />
                  <span>Close signups</span>
                </Button>
              )}
              {!upsell.isEnabled && (
                <Button
                  class="flex items-center gap-2 p-2 rounded hover:bg-gray-50"
                  onClick={() => saveUpsellState({ isEnabled: true, isArchived: false })}
                >
                  <IcoCheckCircle />
                  <span>Open signups</span>
                </Button>
              )}
            </section>
          )}
        >
          <span
            class={`inline-flex gap-2 items-center rounded-full border pl-3 pr-2 p-1 ${
              upsell.isArchived
                ? 'bg-red-50 border-red-200 text-red-600 hover:bg-red-100'
                : upsell.isEnabled
                ? 'bg-green-50 border-green-200 text-green-600 hover:bg-green-100'
                : 'bg-gray-50 hover:bg-gray-100'
            }`}
          >
            <span>
              {upsell.isArchived
                ? 'Archived'
                : upsell.isEnabled
                ? 'Signups open'
                : 'Signups closed'}
            </span>
            <IcoChevronDown />
          </span>
        </Dropdown>
      </nav>
    </div>
  );
}
