import { RecentActivities } from './activities-feed';
import { GuideStats } from './guide-stats';
import { GuideChecklist } from './guide-checklist';
import { Resources } from './resources';
import { CreateWidget } from './create-widget';

export function GuideOverViewPage() {
  return (
    <>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
        <GuideChecklist />
        <Resources />
      </div>
      <CreateWidget />
      <GuideStats />
      <RecentActivities />
    </>
  );
}
