import { FixedPage, FixedContent } from '@components/fixed-page';
import { RouteProps, router } from '@components/router';
import { TopBar } from '@components/top-bar';
import { groupBy } from 'shared/utils';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { CourseItem } from './course-item';
import { BuyCreditsBanner } from './buy-credits-banner';

const store = rpx.instantCourses;
const PURCHASED_CATEGORY = 'Purchased';

export async function load() {
  const [courses, creditsInfo] = await Promise.all([
    store.getInstantCourses({
      isGlobal: false,
    }),
    store.getInstantCourseCredits(),
  ]);
  const categories = groupBy(
    (course) => (course.isPurchased ? PURCHASED_CATEGORY : course.category),
    courses,
  );
  return {
    categories: Object.values(categories),
    creditsInfo,
  };
}

export type InstantCourse = RpxResponse<typeof store.getInstantCourses>[0];

type Data = Awaited<ReturnType<typeof load>>;

function Page({ data }: RouteProps<Data>) {
  const { categories, creditsInfo } = data;

  return (
    <FixedPage title="Instant Courses">
      <FixedContent class="flex flex-col min-h-screen bg-white">
        <TopBar />
        <div class="px-6 py-10 md:py-16 w-full mx-auto">
          <BuyCreditsBanner
            credits={creditsInfo.credits}
            usage={creditsInfo.usage}
            isUnlimited={creditsInfo.isUnlimited}
          />
          <div class="text-center">
            <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">Instant Courses</h1>
          </div>
          {categories.map((courses) => {
            const firstCourse = courses[0];
            const category = firstCourse.isPurchased ? PURCHASED_CATEGORY : firstCourse.category;

            return (
              <section key={category} class="pt-16 xl:max-w-7xl xl:mx-auto xl:px-8">
                <h2 class="text-2xl font-extrabold tracking-tight text-gray-900 mb-6">
                  {category} Instant Courses
                </h2>
                <div class="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-12 flex flex-col space-y-6 md:space-y-0">
                  {courses.map((course) => (
                    <CourseItem key={course.id} course={course} />
                  ))}
                </div>
              </section>
            );
          })}
        </div>
      </FixedContent>
    </FixedPage>
  );
}

router.add({ url: 'instant-courses', render: Page, load, authLevel: 'guide' });
