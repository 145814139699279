/**
 * Provision the core purchase-- guide account, instant course purchase, etc.
 */

import { CORE_INSTANT_COURSES_PRODUCT_ID } from 'shared/ids';
import { CheckoutPage, ProvisioningForm } from '@components/checkout';
import { router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { rpx } from 'client/lib/rpx-client';
import * as urls from 'shared/urls';
import { ErrorPage } from '@components/error-page';
import { CoreBanner } from './core-banner';
import { HyrosTracker } from './hyros-tracker';

async function load(route: AppRoute) {
  const purchases = await rpx.payments.getPurchases({
    priceId: route.params.priceId,
    status: ['provisioning', 'active', 'complete'],
    limit: 1,
  });

  return { purchase: purchases.data[0] };
}

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  const { purchase } = props.state;
  const isInstantCourseCheckout = purchase?.productId === CORE_INSTANT_COURSES_PRODUCT_ID;

  if (!purchase) {
    return <ErrorPage title="404 | Not Found"></ErrorPage>;
  }

  const provision = async () => {
    const priceId = purchase.priceId;
    let url = '/';
    if (isInstantCourseCheckout) {
      await rpx.myCorePayments.provisionInstantCourseCredits({ priceId });
      url = '/instant-courses';
    } else {
      await rpx.myCorePayments.provisionGuideAccount({ priceId });
    }
    location.assign(url);
    return url;
  };

  return (
    <CheckoutPage bannerContent={<CoreBanner />}>
      <HyrosTracker />
      <ProvisioningForm provision={provision} purchase={purchase} />
    </CheckoutPage>
  );
}

router.add({
  url: urls.corePrices.provisionRoute,
  load,
  render: Page,
});
