/**
 * This file contains the new coupon form for the general payment system.
 */
import { showToast } from '@components/toaster';
import { AsyncForm, FormGroup, FormSection, FormSubSection } from '@components/async-form';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { useMemo, useState, useEffect } from 'preact/hooks';
import { IcoChevronDown, IcoChevronLeft, IcoTag } from '@components/icons';
import { formDataToPriceLimits, SignupLimitsFormFields } from './signup-limits-tab';
import { HeadingPrimary } from '@components/headings';
import { CouponRow, PriceRow, CouponDuration, Currency } from 'server/types';
import { RadioButton } from '@components/radio-button';
import * as fmt from 'shared/payments/fmt';
import { Checkbox } from '@components/checkbox';
import { ExpandedCoupon } from 'server/payments/service';
import { isNullish, unique } from 'shared/utils';
import { useIntl } from 'shared/intl/use-intl';
import { Toggle } from '@components/toggle';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { DefaultSpinner } from '@components/spinner';

type CreateOpts = Pick<
  CouponRow,
  | 'code'
  | 'currency'
  | 'amountOff'
  | 'percentOff'
  | 'expiresOn'
  | 'numPayments'
  | 'freeTrialPeriod'
  | 'availableOn'
  | 'maxPurchases'
  | 'productId'
> & { applyTo: []; duration: CouponDuration };

interface FormData {
  discount:
    | { type: '$'; currency: CreateOpts['currency']; amountOff: string }
    | {
        type: '%';
        percentOff: string;
      };
  period: {
    duration: CouponDuration;
    numPayments: string;
  };
}

interface Props {
  productId: string;
  cancelHref?: string;
  onCancel?(): void;
  applicablePrices?: PriceRow[];
  createCoupon(opts: CreateOpts): Promise<unknown>;
}

export function FreeTrialPeriod(props: { value?: number }) {
  const [value, setValue] = useState(props.value || 0);

  return (
    <FormSection title="Free trial">
      <FormSubSection>
        <FormGroup prop="freeTrialPeriod" class="inline-flex items-center space-x-2">
          <label class="inline-flex items-center space-x-3">
            <Toggle checked={value > 0} onClick={() => setValue(value === 0 ? 7 : 0)} />
            <span>Enable free trial for</span>
          </label>
          <input
            type="number"
            name="freeTrialPeriod"
            disabled={value === 0}
            class="inline-ruz-input w-16 appearance-none"
            placeholder="#"
            // Display placeholder when value is 0
            value={value || ''}
            onBlur={(e: any) => {
              const newValue = parseInt(e.target.value, 10);
              setValue(newValue || 0);
            }}
          />
          <span>days</span>
        </FormGroup>
        {value > 0 && (
          <div class="border border-yellow-200 bg-yellow-50 p-2 rounded-md mt-8">
            Note: free trials will only apply to payment plans and subscriptions.
          </div>
        )}
      </FormSubSection>
    </FormSection>
  );
}

export function NewCouponForm({ productId, createCoupon, cancelHref, onCancel }: Props) {
  const [state, setState] = useState<FormData>({
    discount: { type: '%', percentOff: '' },
    period: {
      duration: 'once',
      numPayments: '',
    },
  });

  const currency: Currency = state.discount.type === '$' ? state.discount.currency : 'USD';
  const percentOff = state.discount.type === '%' ? parseInt(state.discount.percentOff) : undefined;
  const amountOff =
    state.discount.type === '$'
      ? fmt.decimalToCents(state.discount.amountOff, state.discount.currency)
      : undefined;

  return (
    <AsyncForm
      class="flex flex-col gap-6"
      onSubmit={async (formData) => {
        const limits = formDataToPriceLimits(formData);
        const numPayments = formData.numPayments ? parseInt(formData.numPayments) : undefined;
        await createCoupon({
          code: formData.code.toUpperCase(),
          applyTo: formData.applyTo,
          duration: formData.duration,
          amountOff,
          percentOff,
          productId,
          currency,
          freeTrialPeriod: formData.freeTrialPeriod
            ? parseInt(formData.freeTrialPeriod)
            : undefined,
          numPayments,
          maxPurchases: isNullish(limits.maxPurchases) ? undefined : limits.maxPurchases,
          availableOn: limits.availableOn || undefined,
          expiresOn: limits.expiresOn || undefined,
        });
        showToast({
          type: 'ok',
          title: 'Saved',
          message: 'Coupon saved.',
        });
      }}
    >
      {cancelHref && <BackNavigation cancelHref={cancelHref} />}
      <HeadingPrimary
        title={
          <span class="flex items-center">
            <IcoTag class="w-12 h-12 bg-indigo-400 rounded-full text-white p-2 mr-4" />
            New Coupon
          </span>
        }
      />
      <CouponCode />
      <DiscountAmount
        discount={state.discount}
        setDiscount={(discount) => setState((s) => ({ ...s, discount }))}
      />
      <Duration period={state.period} setPeriod={(period) => setState((s) => ({ ...s, period }))} />
      <FreeTrialPeriod />
      <ExpandableLimitsFields />
      <AssignToPrices
        productId={productId}
        coupon={{
          type: state.discount.type,
          currency,
          percentOff,
          amountOff,
          duration: state.period.duration,
          numPayments: parseInt(state.period.numPayments) || 0,
        }}
      />
      <footer class="flex items-center">
        <BtnPrimary class="mr-2">Create Coupon</BtnPrimary>
        <BtnSecondary type="button" href={cancelHref} onClick={onCancel}>
          Cancel
        </BtnSecondary>
      </footer>
    </AsyncForm>
  );
}

function CouponCode() {
  return (
    <FormSection title="Create a code">
      <FormSubSection>
        <FormGroup prop="code">
          <input
            class="inline-ruz-input uppercase"
            type="text"
            name="code"
            placeholder="e.g. MYDISCOUNT16"
          />
        </FormGroup>
      </FormSubSection>
    </FormSection>
  );
}

function DiscountAmount({
  discount,
  setDiscount,
}: {
  discount: FormData['discount'];
  setDiscount(d: FormData['discount']): void;
}) {
  return (
    <FormSection title="Specify the discount">
      <FormSubSection class="inline-flex space-x-4">
        <div class="inline-flex">
          <select
            value={discount.type === '%' ? '%' : discount.currency}
            name="currency"
            class="inline-ruz-input rounded-r-none rounded-l text-right pl-0 -mr-px focus:z-10"
            onChange={(e: any) => {
              const value = e.target.value;
              const amount = discount.type === '$' ? discount.amountOff : discount.percentOff;
              if (value === '%') {
                setDiscount({ ...discount, type: '%', percentOff: amount });
              } else {
                setDiscount({ ...discount, type: '$', currency: value, amountOff: amount });
              }
            }}
          >
            <option value="%">% Off</option>
            {fmt.currencies.map((c) => (
              <option key={c} value={c}>
                {fmt.currencySymbol(c)} {c}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="price"
            class="inline-ruz-input rounded-l-none rounded-r w-28"
            onInput={(e: any) => {
              const amount = e.target.value;
              if (discount.type === '%') {
                setDiscount({ ...discount, percentOff: amount });
              } else {
                setDiscount({ ...discount, amountOff: amount });
              }
            }}
          />
        </div>
      </FormSubSection>
    </FormSection>
  );
}

function Duration({
  period,
  setPeriod,
}: {
  period: FormData['period'];
  setPeriod(p: FormData['period']): void;
}) {
  const { duration, numPayments } = period;
  return (
    <FormSection title="Apply to">
      <FormSubSection>
        <FormGroup prop="duration" class="inline-flex flex-col">
          <RadioButton
            checked={duration === 'once'}
            name="duration"
            value="once"
            onClick={() => setPeriod({ ...period, duration: 'once' })}
          >
            First payment
          </RadioButton>
          <FormGroup prop="numPayments">
            <RadioButton
              checked={duration === 'repeating'}
              name="duration"
              value="repeating"
              onClick={() => setPeriod({ ...period, duration: 'repeating' })}
            >
              The first
              <input
                type="text"
                class="inline-ruz-input w-16 mx-2"
                placeholder="e.g. 4"
                name="numPayments"
                value={numPayments}
                onInput={(e: any) => setPeriod({ ...period, numPayments: e.target.value })}
              />
              payments
            </RadioButton>
          </FormGroup>
          <RadioButton
            checked={duration === 'forever'}
            name="duration"
            value="forever"
            onClick={() => setPeriod({ ...period, duration: 'forever' })}
          >
            All payments
          </RadioButton>
        </FormGroup>
      </FormSubSection>
    </FormSection>
  );
}

export function AssignToPrices({
  productId,
  coupon,
}: {
  productId: string;
  coupon: { type: '%' | '$' } & Pick<
    ExpandedCoupon,
    'numPayments' | 'duration' | 'currency' | 'amountOff' | 'percentOff' | 'applyTo'
  >;
}) {
  const intl = useIntl();
  const [applyTo, setApplyTo] = useState(coupon.applyTo?.map((x) => x.id) || []);
  const asyncPrices = useTryAsyncData(async () => {
    const result = await rpx.prices.getProductPrices({ productId });
    return result.filter((x) => x.paymentType !== 'free' && !x.isUpsellOffer);
  }, [productId]);
  const allPrices = useMemo(() => {
    if (!asyncPrices.data || coupon.type === '%') {
      return asyncPrices.data;
    }
    return asyncPrices.data.filter((x) => x.currency === coupon.currency);
  }, [asyncPrices, coupon.currency, coupon.type]);

  useEffect(() => {
    if (allPrices) {
      setApplyTo((x) => x.filter((id) => allPrices.some((p) => p.id === id)));
    }
  }, [allPrices]);

  return (
    <FormSection title="Assign to price points">
      {!asyncPrices.data && <DefaultSpinner />}
      {allPrices && !allPrices.length && <p class="opacity-75">There are no applicable prices.</p>}
      {allPrices && !!allPrices.length && (
        <div class="rounded-lg border">
          <header class="border-b p-3 pb-2 px-4">
            <Checkbox
              wrapperClass="cursor-pointer w-full"
              checked={applyTo.length === allPrices.length}
              onClick={() => setApplyTo(applyTo.length ? [] : allPrices.map((x) => x.id))}
            >
              <span class="pl-1">All</span>
            </Checkbox>
          </header>
          <div class="flex flex-col space-y-6 p-4">
            {allPrices.map((price) => {
              const discountPrice = fmt.discountPrice({ price, coupon });
              const checked = applyTo.includes(price.id);
              return (
                <Checkbox
                  wrapperClass="cursor-pointer w-full"
                  alignment="flex items-start"
                  checked={checked}
                  onClick={() => {
                    setApplyTo(
                      checked
                        ? applyTo.filter((x) => x !== price.id)
                        : unique([...applyTo, price.id]),
                    );
                  }}
                  key={price.id}
                  name="[]applyTo"
                  value={price.id}
                >
                  <span class="flex flex-col -mt-1 ml-1">
                    <span>
                      <strong class="text-gray-600 mr-2">{price.name}</strong>
                      <span class="opacity-75">
                        {fmt.price(price)}{' '}
                        {fmt.priceSuffix({
                          item: price,
                          intl,
                        })}
                      </span>
                    </span>
                    {discountPrice && (
                      <span class="opacity-75">
                        Discount {discountPrice} {fmt.discountSuffix({ price, coupon, intl })}
                      </span>
                    )}
                  </span>
                </Checkbox>
              );
            })}
          </div>
        </div>
      )}
    </FormSection>
  );
}

function BackNavigation({ cancelHref }: Pick<Props, 'cancelHref'>) {
  return (
    <header class="mb-2">
      <a href={cancelHref} class="inline-flex items-center space-x-1.5 text-zinc-400 -ml-1">
        <IcoChevronLeft />
        <span>Back to coupons</span>
      </a>
    </header>
  );
}

export function ExpandableLimitsFields(props: { coupon?: CouponRow }) {
  const [showLimits, setShowLimits] = useState(() => {
    const coupon = props.coupon;
    return !!coupon && !!(coupon.expiresOn || coupon.availableOn || coupon.maxPurchases);
  });
  if (!showLimits) {
    return (
      <span>
        <Button
          class="flex items-center gap-2 justify-between border rounded-lg p-2 px-4"
          onClick={() => setShowLimits(true)}
        >
          <span>Configure signup limits (optional)</span> <IcoChevronDown />
        </Button>
      </span>
    );
  }
  return <SignupLimitsFormFields itemName="coupon" item={props.coupon} />;
}
