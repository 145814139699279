import { BtnSecondary, Button } from '@components/buttons';
import { IcoChevronDown, IcoChevronUp, IcoDownload, IcoSearch, IcoX } from '@components/icons';
import { TextTrack } from '@vidstack/react';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { durationToClockString } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';

type Props = {
  fileId: UUID;
  track: TextTrack;
  height: number;
  jumpToTime: (time: number) => void;
};

type Cue = {
  id: string;
  startTime: number;
  endTime: number;
  timeStr: string;
  text: string;
  lowerText: string;
};

export function highlightTerms(
  text: string,
  searchTerm: string,
): {
  prefix: string;
  highlight?: string;
  suffix?: string;
} {
  if (!searchTerm) {
    return { prefix: text };
  }

  const [prefix] = text.toLowerCase().split(searchTerm, 1);

  return {
    prefix: text.substring(0, prefix.length),
    highlight: text.substring(prefix.length, prefix.length + searchTerm.length),
    suffix: text.substring(prefix.length + searchTerm.length),
  };
}

function CaptionLine({
  cue,
  searchTerm,
  isCurrent,
  isFound,
  isFocused,
  isSyncDisabled,
  onClick,
}: {
  cue: Cue;
  searchTerm: string;
  isCurrent: boolean;
  isFound: boolean;
  isFocused: boolean;
  isSyncDisabled: boolean;
  onClick: () => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { prefix, highlight, suffix } = useMemo(() => {
    const { text } = cue;

    if (!isFound) {
      return {
        prefix: text,
      };
    }

    const [prefix] = text.toLowerCase().split(searchTerm, 1);

    return {
      prefix: text.substring(0, prefix.length),
      highlight: text.substring(prefix.length, prefix.length + searchTerm.length),
      suffix: text.substring(prefix.length + searchTerm.length),
    };
  }, [cue, isFound, searchTerm]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const shouldScroll = (!isSyncDisabled && isCurrent) || isFocused;

    if (shouldScroll) {
      const parent = containerRef.current.parentNode as HTMLElement;
      parent.scrollTop = containerRef.current.offsetTop - parent.offsetTop;
    }
  }, [isCurrent, isFocused, isSyncDisabled]);

  return (
    <div
      ref={containerRef}
      class={`px-4 py-3 cursor-pointer text-sm text-gray-700 dark:text-gray-200 ${
        isCurrent
          ? 'bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-gray-50 js-current-cue'
          : 'hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-50'
      } ${
        isFound && !isCurrent ? 'bg-gray-50 dark:bg-gray-800 text-gray-800 dark:text-gray-50' : ''
      }`}
      onClick={onClick}
    >
      <span class="rounded px-1 bg-blue-100 dark:bg-blue-900 text-blue-500 dark:text-blue-50 font-bold mr-2">
        {cue.timeStr}
      </span>
      <span>{prefix}</span>
      {highlight && <span class="font-bold bg-yellow-200">{highlight}</span>}
      {suffix && <span>{suffix}</span>}
    </div>
  );
}

export function Transcript({ fileId, track, jumpToTime }: Props) {
  const intl = useIntl();
  const input = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [activeCue, setActiveCue] = useState<TextTrack['activeCues'][0] | undefined>(undefined);
  const [foundCues, setFoundCues] = useState<string[]>([]);
  const [focusedCue, setFocusedCue] = useState<string | undefined>(undefined);
  const [isSyncedToCurrentTime, setIsSyncedToCurrentTime] = useState(true);
  const focusedCueIndex = foundCues.indexOf(focusedCue || '');

  const cues = useMemo(() => {
    return track.cues.map((cue) => {
      return {
        id: `${cue.startTime}_${cue.endTime}`,
        startTime: cue.startTime,
        endTime: cue.endTime,
        timeStr: durationToClockString({
          duration: cue.startTime,
        }),
        text: cue.text,
        lowerText: cue.text.toLowerCase(),
      } as Cue;
    });
  }, [track.cues]);

  useEffect(() => {
    if (!track) {
      return;
    }

    function handleCueChange() {
      setActiveCue(track.activeCues[0]);
    }

    track.addEventListener('cue-change', handleCueChange);
    // Run this once to set the initial active cue
    handleCueChange();

    return () => {
      track.removeEventListener('cue-change', handleCueChange);
    };
  }, [track]);

  function goToNextFoundCue() {
    const nextIndex = focusedCueIndex === foundCues.length - 1 ? 0 : focusedCueIndex + 1;
    setFocusedCue(foundCues[nextIndex]);
  }

  function goToPrevFoundCue() {
    const prevIndex = focusedCueIndex === 0 ? foundCues.length - 1 : focusedCueIndex - 1;
    setFocusedCue(foundCues[prevIndex]);
  }

  return (
    <div class="border rounded">
      <header class="p-4 relative">
        <div class="flex justify-between items-center pb-2">
          <span class="flex items-center text-lg font-bold dark:text-gray-200">
            {intl('Transcript')}
            <Button
              class="ml-2 p-2 rounded hover:bg-indigo-200 relative"
              download={`transcript-${fileId}.vtt`}
              href={`/captions/${fileId}?download`}
              data-tooltip={intl('Download Transcript')}
            >
              <IcoDownload class="size-5" />
            </Button>
          </span>
          {!isSyncedToCurrentTime && (
            <BtnSecondary
              class="bg-white dark:bg-gray-800"
              onClick={() => setIsSyncedToCurrentTime(true)}
            >
              {intl('Return to current time')}
            </BtnSecondary>
          )}
        </div>
        <div class={`relative w-full inline-flex grow`}>
          <Button
            tabIndex={-1}
            type="button"
            class="btn absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer shadow-none text-gray-400 dark:text-white"
            onClick={() => {
              setSearchTerm?.('');
              setFoundCues([]);
              setFocusedCue(undefined);
              input.current?.focus();
            }}
          >
            {searchTerm ? <IcoX /> : <IcoSearch />}
          </Button>
          <input
            ref={input}
            type="text"
            class={`ruz-input block w-full py-1 text-sm pl-9`}
            placeholder={intl('Search transcript')}
            value={searchTerm}
            onInput={(e: any) => {
              const term = e.target.value.toLowerCase();
              setSearchTerm?.(term);
              if (e.target.value.length > 2) {
                const foundCues = cues.filter((cue) => cue.lowerText.includes(term));
                setFoundCues(foundCues.map((cue) => cue.id));
                setFocusedCue(foundCues[0]?.id);
              } else {
                setFoundCues([]);
                setFocusedCue(undefined);
              }
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                if (e.shiftKey) {
                  goToPrevFoundCue();
                } else {
                  goToNextFoundCue();
                }
              }
            }}
          />
          {foundCues.length > 0 && (
            <div class="flex items-center absolute right-2 top-2 gap-2">
              <span class="text-xs">
                {focusedCueIndex + 1}/{foundCues.length}
              </span>
              <Button onClick={goToPrevFoundCue}>
                <IcoChevronUp />
              </Button>
              <Button onClick={goToNextFoundCue}>
                <IcoChevronDown />
              </Button>
            </div>
          )}
        </div>
      </header>
      <div
        class="h-96 overflow-y-auto"
        onScrollEnd={(e) => {
          // Display "return to current time" button when the user
          // scrolls away from the active cue
          const scrollEl = e.target as HTMLElement;
          const currentCueEl = document.querySelector('.js-current-cue') as HTMLElement | null;

          if (!currentCueEl) {
            return;
          }

          const { top, bottom, height } = currentCueEl.getBoundingClientRect();
          const holderRect = scrollEl.getBoundingClientRect();

          const isVisible =
            top <= holderRect.top
              ? holderRect.top - top <= height
              : bottom - holderRect.bottom <= height;
          setIsSyncedToCurrentTime(isVisible);
        }}
      >
        {cues.map((cue) => (
          <CaptionLine
            key={cue.id}
            cue={cue}
            searchTerm={searchTerm}
            isCurrent={cue.startTime === activeCue?.startTime && cue.endTime === activeCue?.endTime}
            isFocused={focusedCue === cue.id}
            isSyncDisabled={!isSyncedToCurrentTime}
            isFound={foundCues.includes(cue.id)}
            onClick={() => jumpToTime(cue.startTime)}
          />
        ))}
      </div>
    </div>
  );
}
