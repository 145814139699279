import { FormGroup, InputLabel } from '@components/async-form';
import { DateInput } from '@components/date-picker';
import dayjs from 'dayjs';
import { toUTCDate } from 'shared/dateutil';
import { useState } from 'preact/hooks';
import { BtnSecondary, Button } from '@components/buttons';
import { IcoDownload } from '@components/icons';
import { BtnCancel, StandardDialog } from '@components/dialog';

type Props = {
  type: 'guide' | 'tenant';
};

export function DownloadCsvButton({ type }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        class="flex items-center hover:bg-gray-200 rounded-full p-1 px-2"
        onClick={() => setOpen(true)}
      >
        <IcoDownload class="w-4 h-4 mr-1" />
        Export
      </Button>
      {open && <DownloadCsvModal type={type} onCancel={() => setOpen(false)} />}
    </>
  );
}

function DownloadCsvModal({
  type,
  onCancel,
}: Props & {
  onCancel: () => void;
}) {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const params = new URLSearchParams({
    type,
    start: toUTCDate(startDate)?.toString() || '',
    end: toUTCDate(endDate)?.toString() || '',
  }).toString();
  // We need to pass this so the datepicker allows us to select older dates
  const minDate = dayjs('2010-01-01').toDate();

  return (
    <StandardDialog onClose={onCancel} title="Download CSV">
      <div class="flex flex-col gap-6">
        <FormGroup prop="availableOn">
          <InputLabel>Report Start Date (optional)</InputLabel>
          <DateInput
            class="w-full"
            placeholder="All Time"
            min={minDate}
            value={startDate}
            allowClear
            onChange={setStartDate}
          />
        </FormGroup>
        <FormGroup prop="availableOn">
          <InputLabel>Report End Date (optional)</InputLabel>
          <DateInput
            class="w-full"
            placeholder="All Time"
            min={minDate}
            value={endDate}
            allowClear
            onChange={setEndDate}
          />
        </FormGroup>
        <footer class="flex gap-4">
          <BtnSecondary
            class="rounded-full gap-2 pr-4"
            download="payments.csv"
            href={`/csv/signups?${params}&data=payments`}
          >
            <IcoDownload />
            <span>Payments CSV</span>
          </BtnSecondary>
          <BtnSecondary
            class="rounded-full gap-2 pr-4"
            download="signups.csv"
            href={`/csv/signups?${params}&data=students`}
          >
            <IcoDownload />
            <span>Signups CSV</span>
          </BtnSecondary>
          <BtnCancel onClick={onCancel} />
        </footer>
      </div>
    </StandardDialog>
  );
}
