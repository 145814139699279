/**
 * Design inspiration:
 *
 * - https://dribbble.com/shots/6103611-Forum-Thread
 *
 */

import { useEffect, useReducer } from 'preact/hooks';
import { RootCommentForm } from './root-comment-form';
import { DiscussionContext, initialState, loadNextPage, reducer } from './reducer';
import { BtnSecondary } from '@components/buttons';
import { Case } from '@components/conditional';
import { RootComment } from './root-comment';
import { useIntl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';

const store = rpx.comments;

interface Props {
  discussionId: UUID;
  courseId: UUID;
  highlightedId?: UUID;
  highlightedReplyId?: UUID;
  guideId: UUID;
  hidePeople: boolean;
}

export function Discussion(props: Props) {
  const intl = useIntl();
  const { discussionId, highlightedId, highlightedReplyId, guideId, hidePeople } = props;
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    discussionId,
    guideId,
    hidePeople,
  });
  const highlightedComment = highlightedId ? state.comments[highlightedId] : undefined;

  useEffect(() => {
    loadNextPage(state, dispatch);
  }, [discussionId]);

  /*
   * Fetching the highlighted comment ids that are passed through the url.
   */
  useEffect(() => {
    async function fetchHighlightedComment() {
      if (!highlightedId) {
        return;
      }

      const result = await store.getCommentById({
        discussionId,
        id: highlightedId,
      });

      if (result) {
        dispatch({
          type: 'loaded',
          payload: {
            comments: result.comments,
            cursor: state.cursor,
          },
        });
      }
    }

    fetchHighlightedComment();
  }, [highlightedId, highlightedReplyId]);

  return (
    <DiscussionContext.Provider value={[state, dispatch]}>
      <div class="w-full discussion font-studentlayout dark:text-gray-400">
        <div class="mb-12 js-root-comment-form">
          <RootCommentForm courseId={props.courseId} />
        </div>

        {state.rootCommentIds.map((id) => {
          const comment = state.comments[id];

          // The comment might be null when it's deleted by the author.
          if (!comment) {
            return null;
          }

          return (
            <RootComment
              key={comment.id}
              highlightedComment={highlightedComment?.id}
              courseId={props.courseId}
              comment={comment}
            />
          );
        })}
        <Case when={state.hasMore}>
          <div class="flex justify-center mt-8">
            <BtnSecondary isLoading={state.loading} onClick={() => loadNextPage(state, dispatch)}>
              {intl('Load More Comments')}
            </BtnSecondary>
          </div>
        </Case>
      </div>
    </DiscussionContext.Provider>
  );
}
