/**
 * This is a redirection page which looks up the upsell and offer for the specified
 * price and then redirects to the manage offer page, if the offer is found:
 *
 * /upsells/offer-by-price?price=price-p32Q-G10lZNsk4IVOgeMTQ
 */
import { router } from '@components/router';
import { LoadedProps } from 'client/lib/loaders';
import { UpsellsPage } from './upsells-page';
import { rpx } from 'client/lib/rpx-client';
import { AppRoute } from 'client/lib/app-route/types';
import { DefaultSpinner } from '@components/spinner';
import { useAsyncEffect } from 'client/utils/use-async-effect';

async function load(route: AppRoute) {
  try {
    const offer = await rpx.upsells.getOfferByPrice({ priceId: route.params.price });
    return { offer, err: undefined };
  } catch (err) {
    console.error(err);
    return { offer: undefined, err };
  }
}

type Props = LoadedProps<typeof load>;

function Page({ state: { offer, err } }: Props) {
  useAsyncEffect(async () => {
    if (offer) {
      router.rewrite(`/upsells/${offer.upsellId}/offers/${offer.productId}`);
    }
  }, []);

  return (
    <UpsellsPage>
      {!!offer && <DefaultSpinner />}
      {err && (
        <div class="flex flex-col items-center justify-center py-10 gap-4">
          <h1 class="text-2xl">Failed to look up the offer for this price</h1>
          <p>It's possible the offer or upsell was deleted.</p>
        </div>
      )}
    </UpsellsPage>
  );
}

router.add({ url: 'upsells/offer-by-price', render: Page, authLevel: 'guide', load });
