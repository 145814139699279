import { Button } from '@components/buttons';
import { IcoAcademicCap, IcoChartBar, IcoFolder } from '@components/icons';
import { useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import { ComponentChildren } from 'preact';
import { useIntl } from 'shared/intl/use-intl';

type TabName = 'courses' | 'bundles' | 'upsells';
type Props = {
  tab: TabName;
  buttons?: ComponentChildren;
  children: ComponentChildren;
  hideSubnav?: boolean;
};

function TabLink(props: {
  href: string;
  isSelected: boolean;
  isCore: boolean;
  children: ComponentChildren;
}) {
  if (props.isCore) {
    return (
      <Button
        href={props.href}
        class={`inline-flex items-center gap-1.5 py-1 border-b-2 hover:border-current ${
          props.isSelected ? 'border-current' : 'border-transparent text-inherit'
        }`}
      >
        {props.children}
      </Button>
    );
  }

  return (
    <Button
      href={props.href}
      class={`inline-flex items-center gap-1.5 py-1.5 px-4 rounded-full text-gray-800 hover:bg-gray-200 ${
        props.isSelected ? 'bg-gray-200' : ''
      }`}
    >
      {props.children}
    </Button>
  );
}

export function MyCoursesPageWrapper(props: Props) {
  const intl = useIntl();
  const user = useCurrentUser();
  const tenant = useCurrentTenant();
  const showUpsell = user?.level === 'superadmin' || user?.tier === 'pro' || !tenant.isCore;

  return (
    <>
      {!props.hideSubnav && (
        <header
          class={`text-indigo-700 font-semibold mt-2 mb-6 ${
            tenant.isCore
              ? 'bg-gradient-to-r from-sky-100 to-violet-200 via-indigo-50'
              : 'bg-gradient-to-b from-transparent to-gray-50 border-b'
          }`}
        >
          <nav class="flex gap-8 sm:justify-center sm:gap-14 max-w-5xl mx-auto p-6 py-4">
            <TabLink
              isSelected={props.tab === 'courses'}
              href="/courses?show=courses"
              isCore={tenant.isCore}
            >
              <IcoAcademicCap />
              {intl('Courses')}
            </TabLink>
            <TabLink
              isSelected={props.tab === 'bundles'}
              href="/courses?show=bundles"
              isCore={tenant.isCore}
            >
              <IcoFolder />
              {intl('Bundles')}
            </TabLink>
            {showUpsell && (
              <TabLink isSelected={props.tab === 'upsells'} href="/upsells" isCore={tenant.isCore}>
                <IcoChartBar />
                {intl('Upsells')}
              </TabLink>
            )}
          </nav>
        </header>
      )}
      {props.buttons && <nav class="flex w-full max-w-5xl mx-auto p-6 gap-2">{props.buttons}</nav>}
      <div class="w-screen max-w-5xl px-6 mb-40 mx-auto grow">{props.children}</div>
    </>
  );
}
