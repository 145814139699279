import { IcoChevronDown, IcoRuzukuLogo } from '@components/icons';
import { useImageUrl } from 'client/utils/cdn';
import { themeDefaults } from 'shared/tenant-style-utils';
import { Button } from '@components/buttons';
import { Toggle } from '@components/toggle';
import { pickLogo } from './pick-logo';
import { Brand } from './load';
import { ColorPicker } from '@components/color-picker';

export function GeneralPreview({
  brand,
  setBrand,
}: {
  brand: Brand;
  setBrand(fn: (b: Brand) => Brand): void;
}) {
  const iconUrl = useImageUrl(brand.iconUrl);

  return (
    <div class="w-full rounded-sm flex flex-col bg-gray-50">
      <header class="flex items-center p-4">
        <Button
          type="button"
          class="cursor-pointer focus:outline-none focus:ring-2 focus:ring-indigo-600 inline-flex items-center justify-center rounded-sm relative "
          onClick={async () => {
            const icon = await pickLogo();
            icon && setBrand((s) => ({ ...s, iconUrl: icon.publicUrl }));
          }}
        >
          <span class="inline-block h-8">
            {!!iconUrl && <img class="h-full" src={iconUrl} />}
            {!iconUrl && <IcoRuzukuLogo class="h-full w-auto" />}
          </span>
          <IcoChevronDown class="w-4 h-4 ml-2 opacity-75" />
        </Button>
        {brand.showName && (
          <span
            class="text-indigo-700 text-2xl inline-flex items-center relative"
            style={{ color: brand.logoColor }}
          >
            <span class="ml-4 mr-2">{brand.name}</span>
            <ColorPicker
              name="logo"
              color={brand.logoColor || themeDefaults.logoColor}
              onPick={(logoColor) => setBrand((s) => ({ ...s, logoColor }))}
            />
          </span>
        )}

        <div class="p-4">
          <label class="space-x-3 text-zinc-500 cursor-pointer">
            <Toggle
              checked={brand.showName}
              onClick={() => setBrand((s) => ({ ...s, showName: !s.showName }))}
            />
            <span>Show brand name</span>
          </label>
        </div>
      </header>
    </div>
  );
}
