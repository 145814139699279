import { UserProfileIcon } from '@components/avatars';
import { IcoCalendar, IcoClock, IcoGlobe, IcoInfo, IcoVideoCamera } from '@components/icons';
import { useState } from 'preact/hooks';
import { MeetingDetail } from './types';
import { ScheduleSummary } from './schedule-summary';
import { BulletIco } from './bullet-ico';

export function MeetingSummary({
  host,
  title,
  description,
  duration,
  location,
  timeZone,
  hour12 = true,
  schedule,
}: MeetingDetail) {
  const [expand, setExpand] = useState(false);
  return (
    <div class="flex flex-col gap-4 overflow-auto">
      <div class="flex items-center gap-3">
        <UserProfileIcon user={host} size="size-10" />
        <span class="text-base text-gray-500 text-ellipsis whitespace-nowrap overflow-hidden">
          {host.name}
        </span>
      </div>
      <h1 class="text-lg font-bold text-gray-700">{title}</h1>
      {schedule && (
        <BulletIco Ico={IcoCalendar} multiline>
          <ScheduleSummary schedule={schedule} hour12={hour12} />
        </BulletIco>
      )}
      {!schedule && (
        <BulletIco
          Ico={IcoInfo}
          multiline
          class="cursor-pointer"
          onClick={() => setExpand((x) => !x)}
        >
          <div class={`${expand ? '' : 'line-clamp-2'}`}>{description}</div>
        </BulletIco>
      )}
      <BulletIco Ico={IcoClock}>{duration} min</BulletIco>
      <BulletIco Ico={IcoVideoCamera}>{location}</BulletIco>
      {!schedule && <BulletIco Ico={IcoGlobe}>{timeZone}</BulletIco>}
    </div>
  );
}
