import { router } from '@components/router';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabWrapper } from '@components/admin-nav';
import { TenantsList } from './tenants-list';

function Page() {
  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="tenants" />
        <AdminTabWrapper>
          <h2 class="text-2xl mb-4">Tenants</h2>
          <TenantsList />
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  authLevel: 'superadmin',
  url: '/admin/tenants',
  render: Page,
});
