import { BtnSecondary, Button } from '@components/buttons';
import { IcoZapier } from '@components/icons';
import { DefaultSpinner } from '@components/spinner';
import { useAsyncData } from 'client/lib/hooks';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { Case } from '@components/conditional';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { IntegrationRow, IntegrationValueItem } from './integration-value-item';

const store = rpx.zapierIntegration;

function BtnZapierIntegration({
  class: className = '',
  apiKey: initialApiKey,
  children,
}: {
  class?: string;
  apiKey?: string;
  children: ComponentChildren;
}) {
  const user = useCurrentUser();
  const tenant = useCurrentTenant();

  const [isEditing, setIsEditing] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [apiKey, setApiKey] = useState(initialApiKey);

  async function generateApiKey() {
    setIsGenerating(true);
    try {
      const apiKey = await store.generateZapierApiKey();
      setApiKey(apiKey);
    } catch (err) {
      showError(err);
    } finally {
      setIsGenerating(false);
    }
  }

  if (!isEditing) {
    return (
      <>
        <p class="mb-2">Configure Zapier to automate your tasks.</p>
        <Button
          type="button"
          class={`text-indigo-600 ${className}`}
          data-test="btnzapierconnect"
          onClick={() => setIsEditing(true)}
        >
          {children}
        </Button>
      </>
    );
  }

  return (
    <div class="space-y-4 py-4">
      <Case
        when={!!apiKey}
        fallback={
          <div>
            <p class="mb-2">
              You need an API key to be able to authenticate with your account on Zapier
            </p>
            <BtnSecondary isLoading={isGenerating} onClick={generateApiKey}>
              Generate an API key
            </BtnSecondary>
          </div>
        }
      >
        <p class="font-bold">
          Connect your Ruzuku courses to thousands of other apps using Zapier.
        </p>
        <p>
          Note: You’ll need a Zapier account. If you don’t already have one, you can{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://zapier.com/sign-up">
            start with their free plan.
          </a>
        </p>
        <ul class="list-disc pl-4">
          <li>
            Log in at{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://zapier.com">
              Zapier.com
            </a>
            .
          </li>
          <li>Select the Ruzuku app in the Zap creator.</li>
          <li>
            You will be asked to enter your API key. Copy the values below and paste them into
            Zapier’s authentication window.
          </li>
        </ul>
        <p>
          Learn more:{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.ruzuku.com/article/796-how-to-set-up-your-zapier-intergration"
          >
            How to Set up Your Zapier Integration on Ruzuku ⤑
          </a>
        </p>
        <div class="mt-8 space-y-4">
          <IntegrationValueItem title="API Key" value={apiKey} />
          <IntegrationValueItem title="User ID" value={user?.id} />
          <IntegrationValueItem title="Site ID" value={tenant.id} />
        </div>
        <div>
          <Button onClick={generateApiKey}>
            <span class="text-indigo-600 inline-flex items-center">
              Regenerate
              <strong class="ml-1 italic">API key</strong>
            </span>
          </Button>
          <p class="text-xs">
            Please note that you'll need to go over the authentication flow on Zapier.
          </p>
        </div>
      </Case>
    </div>
  );
}

function ConnectZapier() {
  const loader = useAsyncData(() => store.getZapierApiKey(), []);
  const zapierKey = loader.data;

  if (loader.isLoading) {
    return <DefaultSpinner />;
  }

  return (
    <div>
      <BtnZapierIntegration apiKey={zapierKey}>
        <span class="bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-3 inline-flex items-center justify-center rounded">
          <span class="flex items-center gap-2">
            Configure
            <IcoZapier class="h-8 w-auto -mt-1" fill="white" />
          </span>
        </span>
      </BtnZapierIntegration>
    </div>
  );
}

export function ZapierIntegration() {
  return (
    <IntegrationRow icon={<IcoZapier />}>
      <ConnectZapier />
    </IntegrationRow>
  );
}
