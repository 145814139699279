/**
 * This is the embed-logic for v2. The raw-html-card is the old embed logic
 * which we need to keep around for legacy purposes.
 *
 * This new card serves up embedded content in an iframe on a different
 * domain in order to prevent XSS.
 */

import { Cardable, MinidocCardDefinition, MinidocToolbarAction } from 'minidoc-editor/dist/types';
import { h } from 'minidoc-editor';
import { showRawHtmlModal } from './raw-html-modal';
import { Minidoc, mediaContextMenu } from 'client/lib/minidoc';
import { globalConfig } from 'client/lib/auth';
import { IframeSize } from 'shared/media';

interface EmbedHtmlProps {
  content: string;
  size?: IframeSize;
}

const sizes: IframeSize[] = ['auto', 'small', 'medium', 'large'];

const base64Replacements = {
  '+': '-',
  '/': '_',
  '=': '',
};

/**
 * Attach iframe-resizing behavior to the browser.
 */
(function resizeEmbeds() {
  const onResizeMessage = (e: any) => {
    const isEmbedHeightMessage = e.data && e.data.href && e.data.embedHeight;
    if (!isEmbedHeightMessage) {
      return;
    }
    const iframe = Array.from(document.querySelectorAll('iframe')).find(
      (x) => x.src === e.data.href,
    );
    if (!iframe) {
      return;
    }
    iframe.style.height = e.data.embedHeight + 'px';
  };
  window.addEventListener('message', onResizeMessage, true);
})();

/**
 * Encode the specified buffer as a URL friendly string.
 * See https://thewoods.blog/base64url
 */
function base64UrlEncode(buffer: ArrayBuffer): string {
  const str = btoa(Array.from(new Uint8Array(buffer), (b) => String.fromCharCode(b)).join(''));
  return str.replace(/[+/=]/g, (ch) => {
    return base64Replacements[ch as keyof typeof base64Replacements];
  });
}

/**
 * This custom element renders embedded HTML as an iframe pointing to our
 * special domain.
 */
export class EmbedHtml extends HTMLElement {
  constructor(content?: string, size?: IframeSize) {
    super();
    if (content != null) {
      this.dataset.content = content;
    }
    if (size != null) {
      this.dataset.size = size;
    }
    this.className = 'flex justify-center';
  }

  redraw() {
    const content = this.dataset.content;
    const size = this.dataset.size;
    if (!content) {
      return;
    }
    const menu = this.querySelector('.mini-context-wrapper');

    // Load the content into a separate domain. See (/api/embed-iframe.ts for details).
    const iframe = document.createElement('iframe');
    iframe.className = 'auto-height';
    iframe.width = '100%';
    iframe.src = `//${globalConfig().scriptableDomain}:${
      location.port
    }/embed/iframe#content=${base64UrlEncode(new TextEncoder().encode(content))}&size=${size}`;
    // This is needed for videos to go fullscreen.
    // Fullscreen is not available if the child iframe does not have it in the allow attribute
    // so we don't need to worry about that scenario.
    iframe.allowFullscreen = true;
    this.replaceChildren(iframe, menu || '');
  }

  connectedCallback() {
    this.redraw();
  }
}

customElements.define('embed-html', EmbedHtml);

/**
 * The minidoc toolbar button responsible for adding embedded HTML.
 */
export const embedHtmlToolbarAction: MinidocToolbarAction = {
  id: 'embed',
  label: 'Embed Raw HTML',
  html: `&lt;/&gt;`,
  async run(t) {
    const editor = t as unknown as Minidoc & Cardable;
    // Remember the cursor location...
    const range = document.getSelection()?.getRangeAt(0).cloneRange();
    const props = await showRawHtmlModal({ content: '' });
    if (props) {
      // Restore the cursor location...
      document.getSelection()?.removeAllRanges();
      document.getSelection()?.addRange(range!);
      editor.insertCard('embed', props);
    }
  },
};

/**
 * The minidoc card responsible for rendering arbitrary HTML.
 */
export const embedHtmlCard: MinidocCardDefinition<EmbedHtmlProps> = {
  type: 'embed',

  /**
   * This card will take over any element that matches this selector.
   */
  selector: 'embed-html',

  /**
   * Derive the state from the specified element.
   */
  deriveState(el) {
    return { content: el.dataset.content || '', size: (el.dataset.size || 'auto') as IframeSize };
  },

  /**
   * Convert the specified state to HTML.
   */
  serialize({ state }) {
    return new EmbedHtml(state.content, state.size).outerHTML;
  },

  /**
   * Convert the specified state to a DOM element.
   */
  render(opts) {
    const el = new EmbedHtml(opts.state.content, opts.state.size);

    if (!opts.readonly) {
      const size = opts.state.size || 'auto';
      const contextMenu = mediaContextMenu(
        { label: 'HTML' },
        // The iframe edit button
        h('button.mini-context-action', {
          onclick() {
            showRawHtmlModal({ content: opts.state.content }).then((result) => {
              if (result) {
                opts.stateChanged(Object.assign(opts.state, result));
                el.dataset.content = result.content;
                el.append(contextMenu);
                el.redraw();
              }
            });
          },
          type: 'button',
          class: 'p-3 aspect-square flex.items-center justify-center',
          // Pencil icon
          innerHTML:
            '<svg viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" /></svg>',
        }),

        // Divider
        h('span.border-l.flex.h-6.opacity-25'),

        // The iframe size dropdown
        h<HTMLSelectElement>(
          'select.mini-context-action.bg-transparent.border-none.rounded-lg.cursor-pointer',
          {
            value: size,
            oninput(e: any) {
              const size: IframeSize = e.target.value;
              opts.stateChanged(Object.assign(opts.state, { size }));
              el.dataset.size = size;
              el.redraw();
            },
          },
          sizes.map((value) =>
            h('option', { value, selected: value === size }, `Height: ${value}`),
          ),
        ),
      );

      el.append(contextMenu);
    }

    return el;
  },
};
