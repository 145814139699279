import { FixedPage } from '@components/fixed-page';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { ErrorPage } from '@components/error-page';
import { router, useRouteParams } from '@components/router';
import { blockDefinitions } from '../guide-course-salespage/blocks';
import { URLS } from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { BlockViewer, ErrHandler } from '@components/page-builder';
import { CheckoutWidget } from './checkout-widget';

export async function load(route: AppRoute) {
  const result = await rpx.courses.getSalesPage({ id: route.params.courseId });
  return { ...result, isProduct: URLS.isProduct(route.url) };
}

export type PageData = Awaited<ReturnType<typeof load>>;

export function Page(props: LoadedProps<typeof load>) {
  const { content, isGuide, title, isProduct } = props.data;
  const { priceId, v1 } = useRouteParams();

  /**
   * This is a bit of a hack, but is the way we avoid confusing users who
   * are coming in from a redirected v1 price-specific sales page link.
   * We want to show them their requested price (and possibly coupon)
   * and not the default price which is what the sales page shows.
   * To accomplish this, we're just going to put a class on the wrapper
   * so that the CTA buttons, CTA sections, and pricing sections will
   * hide.
   */
  const isV1SalesPage = !!priceId && v1 === 'true';

  if (!content?.blockIds.length) {
    return <ErrorPage title="404 | Not found" />;
  }

  return (
    <FixedPage title={title}>
      <div
        class={`flex grow flex-col bg-white sales-page text-base text-gray-700 ${
          isV1SalesPage ? 'v1-sales-page' : ''
        }`}
      >
        {content.blockIds.map((id, i) => {
          const blockState = content.blocks[id];
          const definition = blockDefinitions[blockState.type];
          return (
            <ErrHandler key={id}>
              <BlockViewer
                isFirst={i === 0}
                definition={definition}
                state={blockState}
                nextSkewed={content.blocks[content.blockIds[i + 1] || '']?.skewed}
              />
            </ErrHandler>
          );
        })}
      </div>
      {isGuide && (
        <a
          class="btn fixed top-8 right-8 bg-white shadow-lg"
          href={URLS.guide.coursePage({
            isProduct,
            courseId: props.route.params.courseId,
            page: 'salespage',
          })}
        >
          Edit Sales Page
        </a>
      )}

      <CheckoutWidget />
    </FixedPage>
  );
}

['courses/:courseId/salespage', 'products/:courseId/salespage'].map((url) => {
  router.add({
    isPublic: true,
    url,
    key: (x) => x.courseId,
    render: Page,
    load,
  });
});
