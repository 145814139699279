/**
 * The student / public-facing tenant storefront. See admin-storefront for
 * the management screen.
 */

import { FixedPage } from '@components/fixed-page';
import { LoadedProps } from 'client/lib/loaders';
import { ErrorPage } from '@components/error-page';
import { router } from '@components/router';
import { BlockViewer, ErrHandler } from '@components/page-builder';
import { CourseBlockContext, load } from '../admin-storefront/types';
import { blockDefinitions } from '../admin-storefront/block-definitions';
import { useCurrentUser } from '@components/router/session-context';

export function Page({ state, setState }: LoadedProps<typeof load>) {
  const { content } = state.builderState;
  const currentUser = useCurrentUser();
  const canEdit = currentUser?.level === 'admin' || currentUser?.level === 'superadmin';

  if (!content?.blockIds.length) {
    return <ErrorPage title="404 | Not found" />;
  }

  return (
    <FixedPage title="Store">
      <CourseBlockContext.Provider value={{ state, setState }}>
        <div class="flex grow flex-col bg-white sales-page text-base text-gray-700">
          {content.blockIds.map((id, i) => {
            const blockState = content.blocks[id];
            const definition = blockDefinitions[blockState.type];
            return (
              <ErrHandler key={id}>
                <BlockViewer
                  isFirst={i === 0}
                  definition={definition}
                  state={blockState}
                  nextSkewed={content.blocks[content.blockIds[i + 1] || '']?.skewed}
                />
              </ErrHandler>
            );
          })}
        </div>
        {canEdit && (
          <a class="btn fixed top-8 right-8 bg-white shadow-lg" href="/admin/store">
            Edit Storefront
          </a>
        )}
      </CourseBlockContext.Provider>
    </FixedPage>
  );
}

router.add({
  isPublic: true,
  url: 'store',
  render: Page,
  load,
});
