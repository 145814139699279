import { UserProfileIcon } from '@components/avatars';
import { IcoChat, IcoPinned } from '@components/icons';
import { useRouteParams } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { timeago } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';
import { emptyLessonTitle } from 'shared/terminology';
import { URLS } from 'shared/urls';
import { State } from './index';

interface Props {
  course: State['course'];
  discussion: State['feed']['discussions'][0];
}

function StartText({ discussion, course }: Pick<Props, 'discussion' | 'course'>) {
  const tenant = useCurrentTenant();
  const intl = useIntl();
  const { lesson } = discussion;
  const { categoryId } = useRouteParams();

  const [prefix, startLesson, suffix] =
    categoryId === genericDiscussionCategoryIds.lessons
      ? intl.split('Started in <>{lesson:string}</> lesson', {
          lesson: lesson?.title || emptyLessonTitle(tenant),
        })
      : intl.split('Started by <>{author:string}</>', {
          author: discussion.author.name,
        });

  return (
    <span class="line-clamp-1 leading-tight md:max-w-60">
      {prefix}
      <a
        class="capitalize"
        href={
          lesson
            ? URLS.student.lesson({ course, lesson })
            : URLS.student.memberProfile({
                course,
                user: discussion.author,
              })
        }
      >
        {startLesson}
      </a>
      {suffix} {timeago(discussion.createdAt, ' ago')}
    </span>
  );
}

export function ListItem({ course, discussion }: Props) {
  const { categoryId } = useRouteParams();
  const intl = useIntl();

  return (
    <a
      href={URLS.student.coursePage({
        course,
        page: `discussions/${categoryId}/${discussion.id}`,
      })}
    >
      <article class="flex items-center justify-between p-4 text-gray-800 dark:text-gray-200 bg-gray-50 dark:bg-gray-800 rounded-lg mb-4 border dark:border-none relative">
        {discussion.isPinned && <IcoPinned class="absolute left-1 top-1 w-4 h-4 text-indigo-500" />}
        <div class="flex items-center justify-center mr-2 md:mr-4">
          <UserProfileIcon user={discussion.author} size="w-10 h-10" />
        </div>
        <div class="w-full mr-4">
          <span class="text-base font-semibold leading-6">{discussion.prompt}</span>
          <span class="flex items-center gap-2 text-xs dark:text-gray-400 leading-3">
            <span class="hidden md:inline-flex">
              <StartText discussion={discussion} course={course} />
            </span>
            <Dot />
            {discussion.lastComment && (
              <>
                <span>
                  {intl('Last reply {timeago:string} by', {
                    timeago: timeago(discussion.lastComment.createdAt, ' ago')!,
                  })}{' '}
                  {discussion.lastComment.userName}
                </span>
                <Dot />
              </>
            )}
            <span class="inline-flex items-center">
              <IcoChat />
              <span class="ml-1">{discussion.numComments || 0}</span>
            </span>
          </span>
        </div>
      </article>
    </a>
  );
}

/**
 * Small '.' divider for spacing out text chunks in a single block
 */
export function Dot() {
  return <span class="leading-none hidden md:inline text-lg opacity-75">·</span>;
}
