import { Button } from '@components/buttons';
import { IcoPinned } from '@components/icons';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useEsc } from 'client/utils/use-esc';
import { on } from 'minidoc-editor';
import { ComponentChildren, createContext } from 'preact';
import {
  Dispatch,
  StateUpdater,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'preact/hooks';
import { MembershipLevel } from 'server/types';
import { CourseNavLinks } from './course-nav-links';
import { Props, StudentTabs } from './props';

export interface SideNavContext {
  isPinned: boolean;
  isMenuVisible: boolean;
  isPinnable: boolean;
  setIsPinned(f: (x: boolean) => boolean): void;
  setIsMenuVisible: Dispatch<StateUpdater<boolean>>;
}

export const SideNavContext = createContext<SideNavContext>({
  isPinned: false,
  isMenuVisible: false,
  isPinnable: false,
  setIsPinned: () => {},
  setIsMenuVisible: () => {},
});

export function SideNavProvider({
  isPinnable,
  storageKey,
  onPinChange,
  children,
}: {
  isPinnable: boolean;
  storageKey: string;
  onPinChange?: (isPinned: boolean) => void;
  children: ComponentChildren;
}) {
  /**
   * This gets complicated. The rules are as follows:
   * - The first time the user ever sees the site, we should animate the menu out
   * - The second+ time the user ever sees the site, the menu should start out hidden (no animation)
   * - If the menu is hidden, and the user performs a "show menu", we animate in
   * - If the menu is visible, and the user performs a "hide menu", we animate out
   * - If the user has pinned the menu, it should not animate at all, and should display flex
   */
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isPinned, setIsPinned] = useState(
    () => !isPinnable || localStorage.getItem(storageKey) !== 'false',
  );
  const ctx = useMemo(
    (): SideNavContext => ({
      isMenuVisible,
      setIsMenuVisible,
      isPinned,
      isPinnable,
      setIsPinned(f) {
        setIsPinned((x) => {
          const result = f(x);
          localStorage.setItem(storageKey, `${result}`);
          onPinChange?.(result);
          return result;
        });
      },
    }),
    [isMenuVisible, setIsMenuVisible, isPinned, isPinnable, setIsPinned],
  );

  return <SideNavContext.Provider value={ctx}>{children}</SideNavContext.Provider>;
}

export function StudentSideNav({
  course,
  accessLevel,
  currentLink,
  hiddenSideNavContent,
  children,
}: {
  course: Props['course'];
  accessLevel: MembershipLevel;
  currentLink: StudentTabs;
  hiddenSideNavContent?: ComponentChildren;
  children?: ComponentChildren;
}) {
  const { isMenuVisible, isPinnable, isPinned, setIsMenuVisible, setIsPinned } =
    useContext(SideNavContext);
  const [animateOut, setAnimateOut] = useState(
    isPinned ? 'hidden animate-slide-out-sidenav lg:block' : '-translate-x-full',
  );
  const root = useRef<HTMLElement>(null);

  useDidUpdateEffect(() => {
    setAnimateOut('animate-slide-out-sidenav');
  }, [isMenuVisible, isPinned]);

  useEsc(() => setIsMenuVisible(false), { escapeOnly: true });

  useEffect(() => {
    if (!root.current) {
      return;
    }
    // This is a hacky way to autoscroll to a specified element.
    const scrollTo = root.current.querySelector('.js-scroll-to');
    if (scrollTo) {
      const top = scrollTo.getBoundingClientRect().top;
      const bounds = root.current.getBoundingClientRect();
      // visualViewport is not supported on older browsers
      const windowHeight = window.visualViewport?.height || window.innerHeight;
      const height = windowHeight / 2;
      root.current.scrollTop = Math.max(0, top - bounds.top - height);
    }
  }, []);

  useEffect(() => {
    if (isMenuVisible) {
      return on(document, 'click', () => setIsMenuVisible(false));
    }
  }, [isMenuVisible]);

  const animation = isMenuVisible ? 'animate-slide-in-sidenav' : animateOut;

  return (
    <>
      {isPinnable && !isPinned && (
        <Button
          class="flex items-start h-screen max-h-full pt-20 sticky top-0"
          onMouseEnter={() => setIsMenuVisible(true)}
          onClick={() => setIsMenuVisible(true)}
        >
          {hiddenSideNavContent}
        </Button>
      )}
      <nav
        ref={root}
        class={`fixed h-screen lg:sticky inset-y-0 left-0 z-40 lg:z-20 text-gray-700 dark:text-gray-400 ${
          !isPinnable || isPinned ? 'lg:animate-none xl:w-[33vw]' : ''
        } ${animation}`}
      >
        <div
          class={`relative h-screen transition-all duration-150 ${
            isMenuVisible || isPinned ? 'w-80 xl:w-96' : 'w-0'
          }`}
        >
          {isPinnable && (
            <header
              class={`items-center absolute z-10 top-0 right-0 p-3 rounded-r-lg ${
                !isMenuVisible ? '' : 'flex'
              } ${isPinned ? 'lg:flex' : ''}`}
            >
              {isPinned && (
                <Button
                  class="hidden lg:inline-flex p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
                  onClick={() => (isPinned ? setIsPinned((x) => !x) : setIsMenuVisible((x) => !x))}
                >
                  <IcoPinned class="w-4 h-4 opacity-50 dark:text-gray-200 dark:opacity-100 rotate-45" />
                </Button>
              )}
              {!isPinned && isMenuVisible && (
                <Button
                  class={`hidden lg:inline-block p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 ${
                    isPinned || !isMenuVisible ? ' hidden' : ''
                  }`}
                  onClick={() => setIsPinned((x) => !x)}
                >
                  <IcoPinned class="w-4 h-4 opacity-50 dark:text-gray-200 dark:opacity-100" />
                </Button>
              )}
            </header>
          )}
          <div
            class={`border-r dark:border-r dark:border-gray-700 overflow-y-auto mini-scroll bg-white dark:bg-gray-900 lg:bg-gray-50 h-full lg:p-6 
            ${!isPinned && isPinnable ? 'lg:bg-opacity-100' : 'lg:shadow-none lg:drop-shadow-none'}
            ${isMenuVisible || !isPinned ? 'shadow-2xl drop-shadow-sm' : ''}`}
          >
            <div class="p-4">
              <div class={`${children ? 'border-b dark:border-gray-700' : ''} mb-6 lg:hidden`}>
                <CourseNavLinks
                  course={course}
                  currentLink={currentLink}
                  accessLevel={accessLevel}
                />
              </div>
              <div class="px-4 lg:px-0">{children}</div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
