import { ComponentChildren } from 'preact';
import { IcoChevronRight, IcoLogo } from '@components/icons';
import { useCurrentTenant } from '@components/router/session-context';
import { Tenant } from 'server/types';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { accentGradient } from 'shared/tenant-style-utils';
import { useImageUrl } from 'client/utils/cdn';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

type Brand = RpxResponse<typeof rpx.branding.getBrand>;

export function LoginWrapper({ children, brand }: { brand?: Brand; children?: ComponentChildren }) {
  const tenant = useCurrentTenant();

  if (!tenant.isCore) {
    return (
      <TenantLoginWrapper brand={brand} tenant={tenant}>
        {children}
      </TenantLoginWrapper>
    );
  }

  return (
    <div class="min-h-screen bg-white flex flex-col lg:flex-row">
      <div class="flex flex-col justify-center relative px-4 py-10 lg:px-24 bg-white">
        <div class="max-w-lg mx-auto">
          <div class="theme-logo-color text-indigo-700 flex flex-col justify-center items-center mb-8">
            <IcoLogo class="h-16" url={tenant.iconUrl} />
            {tenant.showName && <h1 class="mt-4 text-3xl">{tenant.name}</h1>}
          </div>
          {children}
        </div>
      </div>
      <div class="flex-grow flex items-center justify-center p-10 bg-gradient-to-br from-violet-600 to-indigo-600 text-white relative overflow-hidden">
        <div class="bg-gradient-to-br from-transparent to-violet-400 opacity-20 absolute top-0 left-0 -skew-y-12 w-3/4 h-96"></div>
        <div class="bg-gradient-to-br from-transparent to-pink-300 opacity-50 absolute bottom-0 right-0 -skew-y-12 w-3/4 h-96"></div>
        <div class="bg-gradient-to-br from-cyan-300 to-transparent opacity-20 absolute bottom-0 left-0 -skew-y-12 w-1/3 h-96"></div>
        <div class="relative max-w-lg">
          <h2 class="text-white mb-8">
            <span class="font-medium text-4xl lg:text-5xl block mb-4">
              Free training for course creators
            </span>
            <span class="text-2xl lg:text-4xl">5 Steps to Your Online Course</span>
          </h2>
          <div class="flex mb-6">
            <img
              class="block h-20 w-20 min-w-20 rounded-full border-4 border-white shadow-xl"
              src={`${RUZUKU_ASSETS_BASE_URL}/ruzuku-promo-user.jpg`}
            />
            <span class="ml-6 flex flex-col space-y-2">
              <span class="text-base">
                “I enjoyed the simplicity of the course, how clear and concise it was… it was really
                useful, enjoyable and actionable!”
              </span>
              <span class="opacity-75">@veggiecookingclasses</span>
            </span>
          </div>
          <a
            class="bg-orange-500 hover:bg-orange-400 text-white text-xl p-2 px-6 rounded-full shadow-2xl inline-flex items-center justify-center"
            href="https://courses.ruzuku.com/courses/5-steps-to-your-online-course--88ae8e35-6b17-465d-98eb-f65fb56c3252/salespage"
          >
            <span class="mr-2">Get our free training for course creators</span>
            <IcoChevronRight />
          </a>
          <footer class="mt-4">
            <a class="text-inherit opacity-75" href="https://www.ruzuku.com/">
              Learn more about Ruzuku ⤑
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
}

function TenantLoginWrapper(props: {
  tenant: Tenant;
  brand?: Brand;
  children?: ComponentChildren;
}) {
  const brand = props.brand || props.tenant;
  const { name, showName } = brand;
  const loginBgUrl = useImageUrl(brand.loginBgUrl);

  return (
    <div
      class="w-full min-h-screen p-10 py-40 rounded-sm flex items-center justify-center relative bg-gray-50 bg-cover"
      style={{
        backgroundImage: loginBgUrl ? `url(${loginBgUrl})` : undefined,
      }}
    >
      <div class="relative py-3 my-4 w-lg max-w-full">
        <div
          class="absolute inset-0 bg-gradient-to-r from-indigo-400 to-blue-400 shadow-lg transform -skew-y-6 rounded mx-2.5"
          style={{
            backgroundImage: accentGradient(brand),
          }}
        ></div>
        <div class="relative p-8 bg-white shadow-lg rounded-sm flex flex-col space-y-6">
          <div
            class="theme-logo-color text-indigo-700 flex flex-col justify-center items-center mb-8"
            style={{ color: brand.logoColor }}
          >
            <IcoLogo class="h-16 sm:h-24 max-w-full" url={brand.iconUrl} />
            {showName && (
              <h1 class={`mt-4 text-center ${name?.length < 20 ? 'text-3xl' : 'text-xl'}`}>
                {name}
              </h1>
            )}
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}
