import { showModalForm } from '@components/modal-form';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { SlideOver } from '@components/slide-over';
import { parseResponse } from 'media-captions';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { durationToClockString } from 'shared/dateutil';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { IcoChevronDown, IcoChevronUp, IcoSearch, IcoX } from '@components/icons';
import { showError } from '@components/app-error';
import { showToast } from '@components/toaster';
import { rpx } from 'client/lib/rpx-client';

interface Props {
  fileId: UUID;
}

type Cue = {
  id: string;
  startClock: string;
  endClock: string;
  text: string;
  lowerText: string;
};

function LineEditor({
  cue,
  searchTerm,
  isFound,
  isFocused,
  onChange,
}: {
  cue: Cue;
  searchTerm: string;
  isFound: boolean;
  isFocused: boolean;
  onChange: (opts: { text: string; shouldLoseFocus: boolean }) => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const text = useMemo(() => {
    if (!isFound || !searchTerm) {
      return cue.text;
    }
    const tokens = searchTerm
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ')
      .sort((a, b) => b.length - a.length);
    return cue.text.replace(
      new RegExp(`(${tokens.join('|')})`, 'gi'),
      (match) => '<mark>' + match + '</mark>',
    );
  }, [cue, isFound, searchTerm]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    if (isFocused) {
      const parent = containerRef.current.parentNode as HTMLElement;
      parent.scrollTop = containerRef.current.offsetTop - parent.offsetTop;
    }
  }, [isFocused]);

  return (
    <div ref={containerRef} class="flex flex-col mb-2">
      <span class="text-gray-500">
        {cue.startClock} - {cue.endClock}
      </span>
      <div
        class={`focus:ring-indigo-400 focus:border-indigo-400 rounded sm:text-sm px-3 py-2 border ${
          isFocused ? 'border-orange-600' : 'border-gray-300'
        }`}
        contenteditable="plaintext-only"
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            e.preventDefault();
          }
        }}
        onBlur={(e) => {
          const textContent = e.currentTarget.textContent || '';
          // Strip out <mark> tags
          const newText = textContent.replace(/<mark>/g, '').replace(/<\/mark>/g, '');
          onChange({
            text: newText,
            shouldLoseFocus: !textContent.includes(searchTerm),
          });
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}

export function showTranscriptEditor({ fileId }: Props) {
  return showModalForm<{
    newFileId?: UUID;
  }>(({ resolve }) => {
    const input = useRef<HTMLInputElement>(null);

    const [isSaving, setIsSaving] = useState(false);
    const [cues, setCues] = useState<Cue[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [foundCues, setFoundCues] = useState<string[]>([]);
    const [focusedCue, setFocusedCue] = useState<string | undefined>(undefined);
    const focusedCueIndex = foundCues.indexOf(focusedCue || '');

    useAsyncEffect(async () => {
      try {
        // Invalidate the cache by appending a timestamp
        const captionUrl = `/captions/${fileId}?${Date.now()}`;
        const parsed = await parseResponse(fetch(captionUrl));
        setCues(
          parsed.cues.map((cue) => ({
            id: `${cue.startTime}-${cue.endTime}`,
            startClock: durationToClockString({
              duration: cue.startTime,
            }),
            endClock: durationToClockString({
              duration: cue.endTime,
            }),
            text: cue.text,
            lowerText: cue.text.toLowerCase(),
          })),
        );
      } catch (e) {
        showError(e);
      }
    }, [fileId]);

    function goToNextFoundCue() {
      const nextIndex = focusedCueIndex === foundCues.length - 1 ? 0 : focusedCueIndex + 1;
      setFocusedCue(foundCues[nextIndex]);
    }

    function goToPrevFoundCue() {
      const prevIndex = focusedCueIndex === 0 ? foundCues.length - 1 : focusedCueIndex - 1;
      setFocusedCue(foundCues[prevIndex]);
    }

    async function save() {
      setIsSaving(true);
      try {
        const lines = ['WEBVTT', ''];
        cues.forEach((cue) => {
          lines.push(`${cue.startClock} --> ${cue.endClock}`);
          lines.push(cue.text);
          lines.push('');
        });
        const result = await rpx.files.saveCaptions({
          id: fileId,
          content: lines.join('\n'),
        });
        resolve(result);
        showToast({
          type: 'ok',
          title: 'Transcript saved',
          message: 'The transcript has been saved successfully.',
        });
      } catch (e) {
        showError(e);
      } finally {
        setIsSaving(false);
      }
    }

    return (
      <SlideOver hideCloseButton close={() => {}}>
        <header class="flex flex-col lg:flex-row justify-between gap-2 text-gray-900 sticky top-0 z-10 bg-white pb-4">
          <h2 class="text-base lg:text-xl font-bold flex-1">Modify Transcript</h2>
          <div class={`flex flex-1 relative`}>
            <Button
              tabIndex={-1}
              type="button"
              class="btn absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer shadow-none text-gray-400 dark:text-white"
              onClick={() => {
                setSearchTerm?.('');
                setFoundCues([]);
                setFocusedCue(undefined);
                input.current?.focus();
              }}
            >
              {searchTerm ? <IcoX /> : <IcoSearch />}
            </Button>
            <input
              ref={input}
              type="text"
              class={`ruz-input block w-full py-1 text-sm pl-9`}
              placeholder="Search transcript"
              value={searchTerm}
              onInput={(e: any) => {
                const term = e.target.value.toLowerCase();
                if (term.length > 0) {
                  const foundCues = cues.filter((cue) => cue.lowerText.includes(term));
                  setFoundCues(foundCues.map((cue) => cue.id));
                  setFocusedCue(foundCues[0]?.id);
                } else {
                  setFoundCues([]);
                }
                setSearchTerm?.(term);
              }}
              onKeyDown={(e) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                  if (e.shiftKey) {
                    goToPrevFoundCue();
                  } else {
                    goToNextFoundCue();
                  }
                }
              }}
            />
            {foundCues.length > 0 && (
              <div class="flex items-center absolute right-2 top-2 gap-2">
                <span class="text-xs">
                  {focusedCueIndex + 1}/{foundCues.length}
                </span>
                <Button onClick={goToPrevFoundCue}>
                  <IcoChevronUp />
                </Button>
                <Button onClick={goToNextFoundCue}>
                  <IcoChevronDown />
                </Button>
              </div>
            )}
            {searchTerm.length > 0 && foundCues.length === 0 && (
              <span class="absolute right-2 top-2 text-xs">No results</span>
            )}
          </div>
        </header>
        <section class="rounded-lg relative px-1 pb-16 overflow-y-auto h-(screen-48) lg:h-(screen-40)">
          {cues.map((cue) => (
            <LineEditor
              key={cue.id}
              cue={cue}
              searchTerm={searchTerm}
              isFocused={focusedCue === cue.id}
              isFound={foundCues.includes(cue.id)}
              onChange={({ text, shouldLoseFocus }) => {
                const newCues = cues.map((c) =>
                  c.id === cue.id ? { ...c, text, lowerText: text.toLowerCase() } : c,
                );
                if (searchTerm) {
                  const foundCues = newCues.filter((cue) => cue.lowerText.includes(searchTerm));
                  setFoundCues(foundCues.map((cue) => cue.id));
                }
                if (shouldLoseFocus && focusedCue === cue.id) {
                  setFocusedCue(undefined);
                }
                setCues(newCues);
              }}
            />
          ))}
        </section>
        <footer class="flex gap-4 pt-4">
          <BtnPrimary class="px-12" isLoading={isSaving} onClick={save}>
            Save
          </BtnPrimary>
          <BtnSecondary class="px-12" onClick={() => resolve()}>
            Cancel
          </BtnSecondary>
        </footer>
      </SlideOver>
    );
  });
}
