import { useRef, useEffect } from 'preact/hooks';
import { on } from 'minidoc-editor';

/**
 * useAnyKeyDown calls the specified callback any time any keydown event fires on the window.
 */
export function useAnyKeyDown(callback: (e: KeyboardEvent) => void) {
  const ref = useRef(callback);
  ref.current = callback;
  useEffect(() => on(window, 'keydown', (e) => ref.current(e)), []);
}

/**
 * useCtrlKey allows applications to hook into ctrl / cmd + key shortcuts.
 */
export function useCtrlKey(key: string, callback: (e: KeyboardEvent) => void) {
  useAnyKeyDown((e) => (e.ctrlKey || e.metaKey) && e.code === key && callback(e));
}

/**
 * useCtrlSaveKey registers Ctrl / Cmd + S and calls the specified callback.
 * This does no error handling, so that is left up to the caller.
 */
export function useCtrlSaveKey(callback: (e: KeyboardEvent) => any, key = 'KeyS') {
  useCtrlKey(key, (e) => {
    e.preventDefault();
    callback(e);
  });
}
