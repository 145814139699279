import { FormSection, FormSubSection } from '@components/async-form';
import { BtnCopy } from '@components/buttons';
import { IcoExternalLink } from '@components/icons';
import { ComponentChildren } from 'preact';
import { ExpandedCoupon, ExpandedPrice } from 'server/payments/service';
import { useIntl } from 'shared/intl/use-intl';
import * as fmt from 'shared/payments/fmt';
import { PriceSummary } from './price-summary';
import { fullyQualify } from './urls';

export function LinksTab({ children }: { children: ComponentChildren }) {
  return (
    <FormSection>
      <FormSubSection>{children}</FormSubSection>
    </FormSection>
  );
}

export function CouponLink({
  title,
  subtitle,
  price,
  coupon,
  checkoutUrl,
  showDisabled,
}: {
  title: string;
  subtitle: string;
  price: ExpandedPrice;
  coupon: ExpandedCoupon;
  showDisabled: boolean;
  checkoutUrl(opts: { priceId: string; couponId?: string }): string;
}) {
  const intl = useIntl();
  const isAvailable = price.isEnabled && coupon.isEnabled;

  if (!showDisabled && !isAvailable) {
    return null;
  }

  return (
    <div class={`flex flex-col gap-2 rounded-md border p-4 ${isAvailable ? '' : 'bg-gray-50'}`}>
      {!isAvailable && (
        <header class="bg-gray-200 p-2 rounded-md border text-center">
          This price point / coupon combo is disabled.
        </header>
      )}
      <div class="grid grid-cols-2 gap-4 p-2">
        <strong>{title}</strong>
        <strong>{subtitle}</strong>

        {!!coupon.freeTrialPeriod &&
          (price.paymentType === 'paymentplan' || price.paymentType === 'subscription') && (
            <>
              <span>Free trial</span>
              <span class="opacity-75">
                {Math.max(coupon.freeTrialPeriod, price.freeTrialPeriod || 0)}
                {' days'}
              </span>
            </>
          )}

        <span>Subtotal</span>
        <span class="opacity-75">
          {fmt.price(price)}{' '}
          {fmt.priceSuffix({
            item: price,
            intl,
          })}
        </span>
        <span>Discount</span>
        <span class="opacity-75">
          {fmt.discountPrice({ price, coupon })} {fmt.discountSuffix({ price, coupon, intl })}
        </span>
        <span>Total</span>
        <span class="opacity-75">
          <PriceSummary price={price} coupon={coupon} />
        </span>

        {isAvailable && (
          <>
            <a
              class="flex items-center mb-4"
              href={checkoutUrl({
                priceId: price.id,
                couponId: coupon.id,
              })}
              rel="noreferrer"
              target="_blank"
            >
              Checkout page
              <IcoExternalLink class="w-4 h-4 opacity-75 ml-2" />
            </a>
            <BtnCopy
              class="text-center justify-center border border-gray-300 hover:bg-gray-100 active:bg-gray-200 hover:border-transparent rounded-md"
              value={fullyQualify(
                checkoutUrl({
                  priceId: price.id,
                  couponId: coupon.id,
                }),
              )}
              margin="m-0"
            >
              Copy checkout link
            </BtnCopy>
          </>
        )}
      </div>
    </div>
  );
}
