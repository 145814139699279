import { showError } from '@components/app-error';
import { Case } from '@components/conditional';
import { IcoStripe } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { rpx } from 'client/lib/rpx-client';
import { BtnStripeConnect } from 'client/pages/pmts/components/stripe-connect';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { StepItem } from './step-item';

interface Props {
  courseId: UUID;
  onComplete: () => void;
}

export function StripeIntegrationStep({ courseId, onComplete }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [integrated, setIntegrated] = useState(false);

  useAsyncEffect(async () => {
    try {
      const { stripeAcct } = await rpx.accounts.getIntegrations();

      setIntegrated(!!stripeAcct);
      // Mark this step as complete
      // if Stripe is already integrated
      if (stripeAcct) {
        onComplete();
      }
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <StepItem
      title="Set up your account to process student payments (optional)"
      stepKey="paymentIntegration"
      onSkip={onComplete}
    >
      <Case
        when={!integrated}
        fallback={
          <div>
            Stripe is properly configured. If you would like to change Stripe accounts, please{' '}
            <a href="mailto:support@ruzuku.com">contact support</a>, and they will walk you through
            the process.
          </div>
        }
      >
        <p>
          You can connect with external payment processors (such as{' '}
          <a href="http://stripe.com" rel="noreferrer" target="_blank">
            Stripe
          </a>
          ) to accept payments for your course.
        </p>
        <BtnStripeConnect
          class="inline-flex items-center justify-center w-full bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-1"
          courseId={courseId}
        >
          <span class="flex items-center gap-2">
            Connect with
            <IcoStripe class="h-10 w-14" fill="white" />
          </span>
        </BtnStripeConnect>
      </Case>
    </StepItem>
  );
}
