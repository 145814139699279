import { router } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { IcoLogo } from '@components/icons';
import { useCurrentTenant } from '@components/router/session-context';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { NotificationPreferences } from '../student-course-preferences/notification-preferences';
import { showToast } from '@components/toaster';
import { DigestEmailSubscription, StudentCoursePreferences } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';

/*
 * This page modifies student notification preferences.
 * The backend needs the code query param to be able to understand
 * the user id and course id.
 */
function Page(props: LoadedProps<typeof load>) {
  const intl = useIntl();
  const { code } = props.route.params;
  const { course } = props.data;
  const [preferences, setPreferences] = useState(props.data.preferences);
  const [isSaving, setIsSaving] = useState(false);
  const tenant = useCurrentTenant();

  useDocumentTitle(['Unsubscribe', course.title]);

  async function save(newPreferences: StudentCoursePreferences) {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    try {
      await rpx.courses.saveStudentCoursePreferencesWithToken({
        token: code,
        preferences: newPreferences,
      });
      showToast({
        type: 'ok',
        title: intl('Saved'),
        message: intl('Your preferences have been saved.'),
      });
    } catch (err) {
      showError(err);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <div class="py-10">
      <header>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <IcoLogo class="w-24 h-24" url={tenant.iconUrl} />
        </div>
      </header>
      <main class="max-w-7xl mx-auto mt-16 p-8">
        <NotificationPreferences
          courseTitle={course.title}
          guideName={course.guideName}
          preferences={preferences}
          save={(newPreferences) => setPreferences(newPreferences)}
        />
        <footer class="flex flex-col lg:flex-row justify-end lg:space-x-8 space-y-4 lg:space-y-0">
          <BtnSecondary
            class="text-lg px-8 py-4"
            isLoading={isSaving}
            onClick={() => {
              const resettedPreferences = {
                ...preferences,
                subscribedToAnnouncements: false,
                subscribedToMeetingReminders: false,
                subscribedToCommentReplies: false,
                digestEmailSubscription: 'off' as DigestEmailSubscription,
              };

              setPreferences(resettedPreferences);
              save(resettedPreferences);
            }}
          >
            {intl('Unsubscribe from all')}
          </BtnSecondary>
          <BtnPrimary
            class="text-lg px-8 py-4"
            isLoading={isSaving}
            onClick={() => save(preferences)}
          >
            {intl('Save Changes')}
          </BtnPrimary>
        </footer>
      </main>
    </div>
  );
}

function load(route: AppRoute) {
  return rpx.courses.getStudentCoursePreferencesWithToken({
    token: encodeURIComponent(route.params.code),
  });
}

router.add({
  url: 'course-unsubscribe',
  isPublic: true,
  render: Page,
  load,
});
