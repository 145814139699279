import { router } from '@components/router';
import { URLS } from 'shared/urls';

export function finalCheckoutPageURL(course: { id: string; title: string }) {
  return URLS.student.coursePage({ course, page: 'profile-fields' });
}

export async function redirectToCourse(course: { id: string; title: string }) {
  return router.redirectTo(finalCheckoutPageURL(course));
}
