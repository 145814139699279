import { time12Formatter, time24Formatter } from './dateutil';
import { DateRange } from './types';

export function TimeSlots({
  hour12,
  availability,
  selected,
  onSelect,
}: {
  hour12: boolean;
  selected?: DateRange;
  availability: DateRange[];
  onSelect(range: DateRange): void;
}) {
  const fmt = hour12 ? time12Formatter : time24Formatter;
  return (
    <section class="flex flex-col gap-2">
      {availability.map((x) => {
        return (
          <button
            key={x.start}
            class={`text-center p-2 rounded hover:bg-gray-700 hover:text-white border border-gray-200 transition-all ${
              x.start.getTime() === selected?.start.getTime() ? 'bg-gray-700 text-white' : ''
            }`}
            onClick={() => onSelect(x)}
          >
            {fmt.format(x.start)}
          </button>
        );
      })}
    </section>
  );
}
