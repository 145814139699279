import { AsyncForm } from '@components/async-form';
import { BtnPrimary } from '@components/buttons';
import { HeadingSecondary } from '@components/headings';
import { showToast } from '@components/toaster';
import { Toggle } from '@components/toggle';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { FullCourse } from 'server/types';

const store = rpx.courseSettings;

export function TranscriptSettings({
  course,
}: {
  course: Pick<FullCourse, 'id' | 'title' | 'hideCaptions'>;
}) {
  const [hideCaptions, setHideCaptions] = useState(course.hideCaptions);

  async function submit() {
    await store.setCourseSettings({
      courseId: course.id,
      hideCaptions,
    });
    showToast({
      title: 'Success',
      message: `Captions & Transcripts settings for ${course.title} are saved.`,
      type: 'ok',
    });
  }

  return (
    <AsyncForm class="w-full flex flex-col gap-6" onSubmit={submit}>
      <HeadingSecondary>Captions & Transcripts Settings</HeadingSecondary>
      <div class="flex pb-6">
        <Toggle
          class="mt-1"
          name="displayPeople"
          checked={hideCaptions}
          onClick={() => setHideCaptions(!hideCaptions)}
        />
        <span class="flex flex-col ml-4">
          <span class="text-gray-900 dark:text-gray-200">
            Hide closed captions and transcripts from students
          </span>
          <span class="text-gray-500 dark:text-gray-400 mt-1">
            Students won't be able to access closed captions or transcripts if this is enabled.
          </span>
        </span>
      </div>
      <BtnPrimary>Save</BtnPrimary>
    </AsyncForm>
  );
}
