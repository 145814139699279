import { BtnCTA } from '@components/buttons';
import { PageNavBar, PageNavBody } from '@components/page-nav-bar';
import { ComponentChildren } from 'preact';

interface Props {
  itemName: string;
  newItemHref: string;
  children: ComponentChildren;
}

export function NavBar({ itemName, newItemHref, children }: Props) {
  return (
    <PageNavBar title={itemName + 's'}>
      <BtnCTA class="w-full capitalize" href={newItemHref}>
        Create {itemName}
      </BtnCTA>
      <PageNavBody>{children}</PageNavBody>
    </PageNavBar>
  );
}
