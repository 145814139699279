import { BtnPrimary } from '@components/buttons';
import { useState } from 'preact/hooks';
import { Menu } from '@components/dropdown';
import { BtnCancel } from '@components/dialog';

interface Props {
  days: number;
  hours: number;
  minutes: number;
  onHide: () => void;
  onApply: (minutes: number) => void;
}

export function TimeEditModal(props: Props) {
  const [hours, setHours] = useState(() => props.days * 24 + props.hours);
  const [minutes, setMinutes] = useState(props.minutes);
  const offsetMinutes = hours * 60 + minutes;

  return (
    <Menu position="top-4 left-2" zIndex="z-50" onHide={props.onHide}>
      <div class="min-w-96 p-4" onClick={(e) => e.stopPropagation()}>
        <section>
          Scheduled to send
          <input
            class="mx-1 inline-ruz-input w-14 appearance-none text-center"
            type="number"
            placeholder="hours"
            value={hours}
            min={0}
            onInput={(e: any) => setHours(parseInt(e.target.value, 10))}
          />
          hours and
          <input
            class="inline-ruz-input w-14 appearance-none text-center mx-1"
            type="number"
            min={0}
            max={59}
            placeholder="minutes"
            value={minutes}
            onInput={(e: any) => setMinutes(parseInt(e.target.value, 10))}
          />
          minutes before the start date.
        </section>
        <footer class="flex items-center gap-6">
          <BtnPrimary
            disabled={offsetMinutes <= 0}
            onClick={() => props.onApply(offsetMinutes)}
            class="rounded-full px-6"
          >
            Apply
          </BtnPrimary>
          <BtnCancel onClick={props.onHide} />
        </footer>
      </div>
    </Menu>
  );
}
