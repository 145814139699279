import { BtnPrimary, Button } from '@components/buttons';
import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';
import { IcoGripper, IcoPlus } from '@components/icons';
import { CourseImage } from '@components/course-image';
import { UpsellPriceSummary } from './product-price-preview';
import { Offer, Upsell } from './types';
import { useState } from 'preact/hooks';
import { pluralize } from 'shared/formatting';

type Props = {
  upsell: Upsell;
  offers: Offer[];
  onReorder(offers: Offer[]): void;
};

export function OfferSummary({ offer }: { offer: Offer }) {
  return (
    <span class="flex-grow flex items-center gap-4 p-4 pl-0 rounded-md text-inherit">
      <CourseImage image={offer.product.imagePath} size="size-14" />
      <span class="flex flex-col">
        <span class="font-semibold">{offer.product.title}</span>
        <span>
          <UpsellPriceSummary price={offer.price} listPrice={offer.listPrice} />
        </span>
      </span>
    </span>
  );
}

export function OffersTab(props: Props) {
  const newItemURL = `/upsells/${props.upsell.id}/new?redirect=${encodeURIComponent(
    location.pathname + location.search,
  )}`;
  const [offers, setOffers] = useState<Array<Offer & { id: string }>>(() =>
    props.offers.map((o) => ({ ...o, id: o.productId })),
  );

  return (
    <section class="flex flex-col gap-6">
      {!!offers.length && (
        <header class="flex items-center justify-between">
          <h2 class="font-semibold">
            {offers.length} {pluralize('Offer', offers.length)}
          </h2>
        </header>
      )}
      {!offers.length && (
        <div class="flex flex-col">
          <h2 class="text-xl">Add an offer</h2>
          <p class="text-gray-600">
            You haven't added any offers yet. Click{' '}
            <Button class="text-indigo-600 font-medium underline" href={newItemURL}>
              add offer
            </Button>{' '}
            to offer a course or product as part of this upsell.
          </p>
        </div>
      )}
      {offers.length > 0 && (
        <DraggableProvider
          onDragComplete={() => {
            props.onReorder(offers);
          }}
          onTargetChange={(opts) => setOffers(reorderItems(offers, opts))}
          canHandleDrop={(dragState, table) => dragState.dragging.table === table}
        >
          <div class="flex flex-col gap-2">
            {offers.map((offer) => (
              <Draggable
                table="upsellitem"
                key={offer.id}
                id={offer.id}
                class="flex border rounded-2xl hover:bg-gray-50 overflow-hidden"
              >
                <span
                  class="hover:bg-gray-200 flex items-center justify-center p-3 text-gray-400 transition-all rounded-l-2xl cursor-move"
                  draggable
                >
                  <IcoGripper />
                </span>
                <Button
                  key={offer.id}
                  href={`/upsells/${props.upsell.id}/offers/${offer.id}`}
                  class="flex-grow flex text-inherit"
                >
                  <OfferSummary offer={offer} />
                </Button>
              </Draggable>
            ))}
          </div>
        </DraggableProvider>
      )}
      <footer>
        <BtnPrimary class="rounded-full gap-2 pr-4" href={newItemURL}>
          <IcoPlus class="size-4 stroke-2" />
          <span>Add offer</span>
        </BtnPrimary>
      </footer>
    </section>
  );
}
