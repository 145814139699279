import { ComponentChildren } from 'preact';
import { CourseCheckoutPage } from './course-checkout-page';
import { useIntl } from 'shared/intl/use-intl';
import { CheckoutCourse } from './types';

function PleaseContactGuide({ course }: { course: CheckoutCourse }) {
  const intl = useIntl();
  if (!course) {
    return null;
  }

  const [prefix, email, suffix] = intl.split(
    'Please contact <>{email:string}</> with any questions.',
    {
      email: course.guide.email,
    },
  );

  return (
    <span>
      {' '}
      {prefix}
      <a href={`mailto:${course.guide.email}`}>{email}</a>
      {suffix}
    </span>
  );
}

export function CourseCheckoutErrorPage(props: {
  course: CheckoutCourse;
  title: string;
  children: ComponentChildren;
}) {
  return (
    <CourseCheckoutPage course={props.course} title={props.title}>
      <p class="text-gray-500">
        {props.children}
        <PleaseContactGuide course={props.course} />
      </p>
    </CourseCheckoutPage>
  );
}
