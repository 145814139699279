import { BtnPrimary, Button } from '@components/buttons';
import { IcoPlus } from '@components/icons';
import type { FullUpsell } from './types';
import { CourseImage } from '@components/course-image';
import { pluralize } from 'shared/formatting';
import { useCurrentTenant } from 'client/lib/auth';

type Props = {
  upsell: FullUpsell;
};

export function ApplyToTab({ upsell }: Props) {
  const { terminology } = useCurrentTenant();
  const newItemURL = `/upsells/${upsell.id}/applied-to/new`;

  return (
    <section class="flex flex-col gap-6">
      {!!upsell.appliedProducts.length && (
        <header>
          <h2 class="font-semibold">
            {pluralize(
              `Applied to ${upsell.numAppliedPrices} price point`,
              upsell.numAppliedPrices,
            )}
            {pluralize(
              ` across ${upsell.appliedProducts.length} product`,
              upsell.appliedProducts.length,
            )}
          </h2>
        </header>
      )}

      {!upsell.appliedProducts.length && (
        <div class="flex flex-col gap-2">
          <h2 class="text-xl">Apply upsell to a {terminology.course} or product</h2>
          <p class="text-gray-600">
            You haven't applied this upsell to any {terminology.courses} or products. The upsell
            will not be shown to anyone until you apply it to a {terminology.course} or product.
          </p>
        </div>
      )}

      {!!upsell.appliedProducts.length && (
        <div class="flex flex-col gap-2">
          {upsell.appliedProducts.map((product) => (
            <Button
              key={product.id}
              href={`/upsells/${upsell.id}/applied-to/${product.id}`}
              class="flex-grow flex items-center gap-4 p-4 rounded-2xl text-inherit border hover:bg-gray-50"
            >
              <CourseImage image={product.imagePath} size="size-14" />
              <span class="flex flex-col">
                <span class="font-semibold">{product.title}</span>
                <span>
                  {product.numPrices} {pluralize('price point', product.numPrices)}
                </span>
              </span>
            </Button>
          ))}
        </div>
      )}
      <footer class="flex">
        <BtnPrimary class="rounded-full gap-2 pr-4" href={newItemURL}>
          <IcoPlus class="size-4 stroke-2" />
          <span>Apply upsell to a {terminology.course} or product</span>
        </BtnPrimary>
      </footer>
    </section>
  );
}
