import { UserProfileIcon } from '@components/avatars';
import { Dropdown, MenuItem } from '@components/dropdown';
import {
  IcoBook,
  IcoChat,
  IcoFile,
  IcoFilter,
  IcoRectangleGroup,
  IcoUser,
} from '@components/icons';
import { ComponentChildren } from 'preact';

const activity = [
  {
    id: 1,
    type: 'comment',
    course: 'Production course for Ruzuku devs',
    user: {
      name: 'Eduardo Benz',
      profilePhotoUrl:
        'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
    discussion: 'Step up',
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. ',
    date: '6d ago',
  },
  {
    id: 2,
    type: 'signup',
    course: 'Production course for Ruzuku devs',
    user: {
      name: 'Hilary Mahy',
    },
    pricePoint: '15$',
    date: '2d ago',
  },
  {
    id: 3,
    type: 'assignment',
    course: 'Get Your Next Course Going!',
    user: {
      name: 'Test User',
    },
    lesson: 'New Lesson',
    score: '50%',
    date: '6h ago',
  },
  {
    id: 4,
    type: 'comment',
    discussion: 'New ideas',
    course: 'Production course for Ruzuku devs',
    user: {
      name: 'Jason Meyers',
      profilePhotoUrl:
        'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.',
    date: '2h ago',
  },
];

function Item({
  user,
  action,
  date,
  course,
  subtext,
  icon,
}: {
  user: {
    name: string;
    profilePhotoUrl?: string;
  };
  action: string;
  date: string;
  course: string;
  subtext?: string;
  icon: ComponentChildren;
}) {
  return (
    <div class="relative flex items-start space-x-3">
      <div class="relative">
        {user.profilePhotoUrl && <UserProfileIcon user={user} size="size-10" />}
        <span
          class={`${
            user.profilePhotoUrl
              ? 'absolute top-1/2 left-1/2 p-1 bg-indigo-500 text-white'
              : 'block ml-1 p-2 bg-indigo-100 text-gray-800'
          } rounded-full border border-white`}
        >
          {icon}
        </span>
      </div>
      <div class="min-w-0 flex-1">
        <div>
          <div class="text-sm">
            <a class="font-medium text-gray-900 mr-1">{user.name}</a>
            <span>{action}</span>
          </div>
          <p class="mt-0.5 text-sm text-gray-500">
            {date} on <a href="#">{course}</a> course
          </p>
        </div>
        {!!subtext && (
          <div class="mt-2 text-sm text-gray-700">
            <p>{subtext}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function Feed() {
  return (
    <div class="flow-root">
      <ul role="list" class="-mb-8">
        {activity.map((item, activityItemIdx) => (
          <li key={item.id}>
            <div class="relative pb-8">
              {activityItemIdx !== activity.length - 1 ? (
                <span
                  aria-hidden="true"
                  class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                />
              ) : null}
              {item.type === 'comment' && (
                <Item
                  user={item.user}
                  action={`commented on "${item.discussion}" discussion`}
                  date={item.date}
                  course={item.course}
                  subtext={item.comment}
                  icon={<IcoChat class="w-4 h-4" />}
                />
              )}
              {item.type === 'signup' && (
                <Item
                  user={item.user}
                  action={`signed up using "${item.pricePoint}" price point`}
                  date={item.date}
                  course={item.course}
                  icon={<IcoUser class="w-4 h-4" />}
                />
              )}
              {item.type === 'assignment' && (
                <Item
                  user={item.user}
                  action={`submitted an assigment for "${item.lesson}" lesson`}
                  date={item.date}
                  course={item.course}
                  icon={<IcoFile class="w-4 h-4" />}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function RecentActivities() {
  return (
    <div class="mt-8 lg:w-readable-container">
      <h2 class="text-lg font-semibold text-gray-900 mb-4">Recent activities</h2>
      <div class="flex items-center gap-6 mb-8">
        <strong class="inline-flex gap-1 items-center">
          <IcoFilter /> Filters
        </strong>
        <div class="flex gap-2">
          <Dropdown
            triggerClass="bg-indigo-100 px-2 py-1 rounded"
            renderMenu={() => (
              <div class="p-2 pb-0 flex flex-col">
                <MenuItem class="flex p-2 hover:bg-gray-50 items-center rounded-md">
                  All types
                </MenuItem>
                <MenuItem class="flex p-2 hover:bg-gray-50 items-center rounded-md">
                  New comments
                </MenuItem>
              </div>
            )}
          >
            <span class="inline-flex items-center gap-2">
              <IcoRectangleGroup />
              All types
            </span>
          </Dropdown>
          <Dropdown
            triggerClass="bg-indigo-100 px-2 py-1 rounded"
            renderMenu={() => (
              <div class="p-2 pb-0 flex flex-col">
                <MenuItem class="flex p-2 hover:bg-gray-50 items-center rounded-md">
                  All courses
                </MenuItem>
                <MenuItem class="flex p-2 hover:bg-gray-50 items-center rounded-md">
                  First course
                </MenuItem>
              </div>
            )}
          >
            <span class="inline-flex items-center gap-2">
              <IcoBook />
              All courses
            </span>
          </Dropdown>
        </div>
      </div>
      <Feed />
    </div>
  );
}
