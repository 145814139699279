/**
 * User settings and profile screens
 */

import { router, useRouteParams } from '@components/router';

// Tabs nav
import { Tab, Tabs } from '@components/tabs';

// Settings screens
import { ProfileTab } from './profile-tab';
import { PasswordTab } from './password-tab';
import { StripeOauthRedirect } from './stripe-integration';
import { useAuth } from '@components/router/session-context';

// Layout and forms
import { HeadingPrimary } from '@components/headings';
import { FixedPage, FixedContent } from '@components/fixed-page';
import { TopBar } from '@components/top-bar';
import { StudentNavBar } from '@components/nav/student-nav-bar';
import { hasLevel } from 'shared/auth';
import { LoadingIndicator } from '@components/loading-indicator';
import { ZoomOauthRedirect } from './zoom-integration';
import { BillingTab } from './billing-tab';
import { SiteTab } from './site-tab';
import { useIntl } from 'shared/intl/use-intl';
import { IntegrationsTab } from './integrations-tab';
import { useEffect } from 'preact/hooks';
import { GiftsTab } from './gifts-tab';
import { DeleteAccountTab } from './delete-account-tab';

function Page() {
  const intl = useIntl();
  const { tab = 'profile' } = useRouteParams();
  const { user, setUser } = useAuth();
  const isGuide = hasLevel(user, 'guide');
  const isAdmin = hasLevel(user, 'admin');
  const title = intl('Account');

  if (!user) {
    return (
      <FixedPage title={title}>
        <LoadingIndicator />
      </FixedPage>
    );
  }

  return (
    <FixedPage title={title}>
      <FixedContent class="bg-white">
        {!isGuide && <StudentNavBar />}
        {isGuide && <TopBar />}
        <div class="w-full max-w-4xl mx-auto">
          <div class="px-8 py-6">
            <HeadingPrimary title={intl('Account')} />
            <div class="minidoc-scroll-container">
              <Tabs>
                <Tab isSelected={tab === 'profile'} href="/account/profile">
                  {intl('Profile')}
                </Tab>
                <Tab isSelected={tab === 'password'} href="/account/password">
                  {intl('Password')}
                </Tab>
                {hasLevel(user, 'guide') && (
                  <Tab isSelected={tab === 'integrations'} href="/account/integrations">
                    Integrations
                  </Tab>
                )}
                <Tab isSelected={tab === 'billing'} href="/account/billing">
                  Billing
                </Tab>
                {user.tier === 'pro' && (
                  <Tab isSelected={tab === 'site'} href="/account/site">
                    Site
                  </Tab>
                )}
                <Tab isSelected={tab === 'gifts'} href="/account/gifts">
                  {intl('Gifts')}
                </Tab>
                {!isAdmin && (
                  <Tab isSelected={tab === 'delete'} href="/account/delete">
                    {intl('Delete Account')}
                  </Tab>
                )}
              </Tabs>
              <div class="flex flex-col pb-20">
                {(tab === 'init' || tab === 'profile') && (
                  <ProfileTab user={user} setUser={setUser} />
                )}
                {tab === 'integrations' && <IntegrationsTab />}
                {tab === 'password' && <PasswordTab />}
                {tab === 'billing' && <BillingTab />}
                {tab === 'site' && <SiteTab />}
                {tab === 'gifts' && <GiftsTab />}
                {tab === 'delete' && !isAdmin && <DeleteAccountTab />}
              </div>
            </div>
          </div>
        </div>
      </FixedContent>
    </FixedPage>
  );
}

/**
 * Redirect users from the specified v1 route to the specified v2 URL.
 */
function addV1Redirect(from: string, to: string) {
  router.add({
    url: from,
    render() {
      useEffect(() => {
        router.goto(to);
      }, []);
      return null;
    },
  });
}

addV1Redirect('settings', '/account');
addV1Redirect('settings/picture', '/account');
addV1Redirect('settings/password', '/account/password');

router.add({
  url: 'account',
  render: Page,
  authLevel: 'student',
});

router.add({
  url: 'account/:tab',
  render: Page,
  authLevel: 'student',
});

/**
 * This is the URL that Stripe returns to when performing a connect operation.
 * We'll render the integrations tab in this scenario, and it will take care of
 * handling any remaining connect logic.
 */
router.add({ url: 'account/:tab/:integrationType', render: Page, authLevel: 'guide' });

/**
 * This is the URL that Stripe returns to. It is always core Ruzuku, so we'll
 * handle this without any authentication and redirect to the appropriate
 * private tenant URL.
 */
router.add({ url: 'account/integrations/stripe', isPublic: true, render: StripeOauthRedirect });

/**
 * This is the URL that Zoom returns to. It is always core Ruzuku, so we'll
 * handle this without any authentication and redirect to the appropriate
 * private tenant URL.
 */
router.add({ url: 'account/integrations/zoom', isPublic: true, render: ZoomOauthRedirect });
