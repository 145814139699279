import { IcoDotsHorizontal, IcoPencil, IcoTrash, IcoUser } from '@components/icons';
import { Comment } from 'server/types';
import { showToast } from '@components/toaster';
import { useContext, useState } from 'preact/hooks';
import { DiscussionContext } from './reducer';
import { Case } from '@components/conditional';
import { UserProfileIcon } from '@components/avatars';
import { useRouteParams } from '@components/router';
import { Dropdown, MenuItem } from '@components/dropdown';
import { CommentEditor } from './comment-editor';
import { showError } from '@components/app-error';
import { LikeButton, LikeCount } from './like-button';
import { Timeago } from '@components/timeago';
import { useCurrentUser } from '@components/router/session-context';
import { Attachments } from '@components/attachments';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { URLS } from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';
import { showDialog } from '@components/dialog';

const store = rpx.comments;

interface CommentProps {
  courseId: UUID;
  comment: Comment;
  parent?: Comment;
  highlightedComment?: UUID;
}

function scrollToRef(el?: null | HTMLElement) {
  if (el && !el.dataset.scrolled) {
    el.dataset.scrolled = 'true';
    // `smooth` is somehow not working on MacOS Chrome
    // possibly because the element is just rendered
    el.scrollIntoView({ behavior: 'auto', block: 'center' });
  }
}

/**
 * Render a comment / reply.
 */
export function CommentItem(props: CommentProps) {
  const intl = useIntl();
  const { comment, parent } = props;
  const { user, isLiked, likeCount } = comment;
  const [{ guideId, hidePeople }, dispatch] = useContext(DiscussionContext);
  const { courseId, courseSlug } = useRouteParams();
  const currentUser = useCurrentUser();
  const [isEditing, setIsEditing] = useState(false);
  const isMyComment = currentUser?.id === user.id;

  async function onDelete() {
    const confirmed = await showDialog({
      mode: 'warn',
      title: comment.parentId
        ? intl('Are you sure you want to delete this comment?')
        : intl('Are you sure you want to delete this post?'),
      children: `${
        comment.replyIds ? intl('All replies in this post will be deleted as well.') : ''
      } ${intl('This cannot be undone.')}`,
      confirmButtonText: comment.parentId ? intl('Delete comment') : intl('Delete post'),
      cancelButtonText: intl('Cancel'),
    });

    if (!confirmed) {
      return;
    }

    try {
      await store.deleteComment({
        id: comment.id,
      });
      showToast({
        type: 'ok',
        title: intl('Successfully deleted'),
        message: comment.parentId
          ? intl('The comment has been deleted.')
          : intl('The post has been deleted.'),
      });
      dispatch({ type: 'deleted', payload: comment.id });
    } catch (err) {
      showError(err);
    }
  }

  async function toggleLike() {
    const liked = !comment.isLiked;
    // Update the like status optimistically.
    dispatch({
      type: 'toggleLike',
      payload: {
        commentId: comment.id,
        isLiked: liked,
      },
    });

    try {
      await store.toggleCommentLike({
        id: comment.id,
        isLiked: liked,
      });
    } catch {
      // Revert the action if the endpoint fails.
      dispatch({
        type: 'toggleLike',
        payload: {
          commentId: comment.id,
          isLiked: !liked,
        },
      });
    }
  }

  if (isEditing) {
    return (
      <CommentEditor
        courseId={props.courseId}
        comment={comment}
        parent={parent}
        class="border p-4 rounded-xl bg-gray-50 dark:bg-gray-800 dark:border-none mb-2"
        buttonTitle={intl('Save')}
        onHide={() => setIsEditing(false)}
      />
    );
  }

  return (
    <div
      class={`flex rounded ${comment.id === props.highlightedComment ? 'an-highlight' : ''}`}
      id={comment.id}
      ref={comment.id === props.highlightedComment ? scrollToRef : undefined}
    >
      <span class="mr-3 flex flex-col items-center">
        {user.isRemoved && (
          <span class="w-8 h-8 rounded-full bg-gray-500 opacity-50 inline-flex items-center justify-center text-white">
            <IcoUser class="fill-current w-5 h-5" />
          </span>
        )}
        {!user.isRemoved && <UserProfileIcon user={user} size="w-8 h-8" />}
      </span>
      <div class="flex-grow pt-1">
        <header class="flex items-start justify-between">
          <span class="flex items-center leading-5">
            {user.isRemoved && <span class="opacity-75 mr-2">{intl('[former participant]')}</span>}
            {!user.isRemoved && (
              <span class="block text-gray-900 font-medium dark:text-gray-200 mr-2 whitespace-nowrap overflow-ellipsis max-w-28 md:max-w-60 overflow-hidden">
                <Case when={!hidePeople} fallback={user.name}>
                  <a
                    class="text-inherit"
                    href={URLS.student.memberProfile({
                      course: {
                        id: courseId,
                        title: courseSlug,
                      },
                      user,
                    })}
                  >
                    {user.name}
                  </a>
                </Case>
              </span>
            )}
            <Timeago class="text-gray-500 dark:text-gray-400 mr-2" date={comment.timestamp} />
            {user.id === guideId && (
              <span class="inline-flex items-center ml-1 mr-2 bg-theme-primary bg-gray-500 text-white p-0.5 px-1 text-xs rounded-full">
                {intl('Guide')}
              </span>
            )}

            <Case when={isMyComment || currentUser?.id === guideId}>
              <Dropdown
                hideDownIcon
                class="text-gray-500 dark:text-gray-200"
                renderMenu={() => (
                  <div class="flex flex-col p-2 pb-0">
                    <MenuItem onClick={onDelete}>
                      <IcoTrash />
                      <span class="ml-2">{intl('Delete')}</span>
                    </MenuItem>
                    {isMyComment && (
                      <MenuItem onClick={() => setIsEditing(true)}>
                        <IcoPencil />
                        <span class="ml-2">{intl('Edit this comment')}</span>
                      </MenuItem>
                    )}
                  </div>
                )}
              >
                <span class="inline-flex rounded-full p-1 hover:bg-gray-100 dark:hover:bg-gray-700">
                  <IcoDotsHorizontal />
                </span>
              </Dropdown>
            </Case>
          </span>
          <span class="flex items-center text-gray-400 space-x-2 whitespace-nowrap">
            <LikeCount commentId={comment.id} count={likeCount} />
            <LikeButton isLiked={isLiked} onClick={isMyComment ? () => {} : toggleLike} />
          </span>
        </header>
        <div class={`${comment.attachments.length ? 'pt-3' : 'pt-1'}`}>
          <Attachments
            attachments={comment.attachments}
            user={user}
            timestamp={comment.timestamp}
            allowFullScreen
          />
          <ReadonlyMinidoc class="font-studentcontent" id={comment.id} content={comment.content} />
        </div>
      </div>
    </div>
  );
}
