import { ManualDom } from '@components/manual-dom';
import { useState } from 'preact/hooks';
import { StickyToolbar } from '@components/sticky-toolbar';
import { mediaCard, mediaMiddleware, mediaToolbarAction } from '@components/media-card';
import {
  cardMiddleware,
  defaultToolbarActions,
  minidocToolbar,
  placeholder,
  scrubbable,
} from 'minidoc-editor';
import { keyboardNav } from 'client/lib/keyboard-nav';
import { MessageTemplateField } from 'server/types';
import { autoFocusSelf } from 'client/utils/autofocus';
import { useConfiguration, useCurrentUser } from '@components/router/session-context';
import { useMinidoc } from '@components/minidoc';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { dataTemplateToolbarAction } from '@components/messages/data-template-toolbar-action';
import { ModalForm, showModalForm } from '@components/modal-form';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { StandardDialog } from '@components/dialog';
import { DefaultSpinner } from '@components/spinner';
import { esc } from 'shared/htm';

interface SaveState {
  title: string;
  content: string;
}

export function showEmailAllModal(opts: { tenantId: UUID; domain: string }) {
  return showModalForm(({ resolve }) => {
    const configuration = useConfiguration();
    const user = useCurrentUser();
    const { data } = useTryAsyncData(
      () => rpx.tenants.getMyActiveStudentCount({ tenantId: opts.tenantId }),
      [],
    );
    const [state, setState] = useState<SaveState>(() => ({
      title: 'Your courses have moved to a new home!',
      content: [
        `<p>Hi, <template-field contenteditable="false">{{{recipient-name}}}</template-field></p>`,
        `<p>Your courses have moved to <a href="https://${esc(opts.domain)}">${esc(
          opts.domain,
        )}</a>.</p>`,
        `<p>Your same email and password can be used there, so head over and take a look. I look forward to seeing you there.</p>`,
        `<p>Thanks, ${esc(user?.displayName || user?.name || '')}</p>`,
      ].join('<p><br></p>'),
    }));
    const templateFields: MessageTemplateField[] = [
      {
        key: 'recipient-name',
        title: 'Recipient Name',
      },
    ];

    const editor = useMinidoc({
      doc: state.content,
      middleware: () => [
        /**
         * Adds placeholder text support to minidoc.
         */
        placeholder('Edit this email.'),
        /**
         * This adds toolbar support to minidoc. We don't want h1 support, so
         * we remove that from the defuault toolbar actions. And we *do* want
         * media support (the ability to place files within the document), so
         * we'll add our own media toolbar action.
         */
        minidocToolbar([
          ...defaultToolbarActions.filter((x) => {
            return x.id !== 'h1';
          }),
          // Modify quikpik to ignore non-image files
          mediaToolbarAction({
            label: 'Add image',
            accept: 'image/*',
            sources: ['filepicker', 'takephoto'],
          }),
          dataTemplateToolbarAction(templateFields),
        ]),
        /**
         * Here is where we register the cards we want to use. Media is for files.
         * In the future, we'll register surveys, quizes, etc here.
         */
        cardMiddleware([mediaCard]),
        /**
         * Prevent template fields from being scrubbed / erased by the
         * minidoc scrubber logic.
         */
        scrubbable.middleware(
          scrubbable.createScrubber({
            ...scrubbable.rules,
            leaf: {
              ...scrubbable.rules.leaf,
              FIGURE: {
                'data-state': true,
              },
            },
            child: {
              ...scrubbable.rules.child,
              'TEMPLATE-FIELD': {
                contenteditable: true,
              },
            },
          }),
        ),

        /**
         * This extends the editor to have an `insertMedia` method which is used
         * by the media toolbar action.
         */
        mediaMiddleware({
          isPublic: true,
          domain: configuration.tenant.domain,
          accept: 'image',
          hideContextMenu: true,
        }),
      ],
      onChange(doc) {
        setState((s) => ({ ...s, content: doc }));
      },
    });

    if (!data) {
      return (
        <StandardDialog onClose={resolve}>
          <DefaultSpinner />
        </StandardDialog>
      );
    }

    return (
      <ModalForm
        contentWidth
        title={
          <>
            Send an email to all {data.count} of your students at <em>{opts.domain}</em>
          </>
        }
        confirmButtonText={`Email ${data.count} students`}
        onSubmit={async () =>
          rpx.tenants.emailMyActiveStudents({
            tenantId: opts.tenantId,
            subject: state.title,
            content: state.content,
          })
        }
      >
        <section class="w-2xl max-w-full">
          <div class="pt-4 flex flex-col gap-6">
            <span class="flex gap-2 font-semibold">
              <span class="flex items-center w-16">To:</span>
              <span class="grow bg-gray-100 rounded-md p-2 px-4">
                {data.count} students at <em>{opts.domain}</em>
              </span>
            </span>

            <label class="flex gap-2 font-semibold">
              <span class="flex items-center w-16">Subject:</span>
              <input
                class="grow ruz-input"
                placeholder="Subject"
                type="text"
                ref={autoFocusSelf}
                value={state.title}
                onInput={(e: any) => setState((s) => ({ ...s, title: e.target.value }))}
                onKeyDown={(e: any) =>
                  keyboardNav(e, {
                    down() {
                      (editor.root as any).focus();
                    },
                  })
                }
              />
            </label>

            <StickyToolbar>
              <div class="grow md:flex md:items-center">
                <ManualDom el={editor.toolbar.root} />
              </div>
            </StickyToolbar>
            <EditorWrapper
              editor={editor}
              class="messages-content border focus-within:border-indigo-500 focus-within:ring-1 ring-indigo-500 rounded-md p-4"
            />
          </div>
        </section>
      </ModalForm>
    );
  });
}
