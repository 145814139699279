import { BtnPrimary } from '@components/buttons';

export interface Time {
  day: string;
  hh: string;
  mm: string;
  ampm: 'AM' | 'PM';
}

interface Props {
  time: Time;
  name?: string;
  setTime?(s: Time): void;
}

const noop = () => {};

export function TimeInput({ time, setTime = noop, name = 'time' }: Props) {
  return (
    <>
      <span class="mr-2">Day</span>
      <input
        type="number"
        name={`${name}.day`}
        placeholder="day"
        value={time.day}
        min={1}
        class="inline-ruz-input w-14 appearance-none"
        onInput={(e: any) => setTime({ ...time, day: e.target.value })}
      />
      <span class="mx-2">at</span>
      <input
        class="inline-ruz-input w-14 appearance-none text-center mr-2"
        name={`${name}.hh`}
        type="number"
        min="1"
        max="12"
        placeholder="hh"
        value={time.hh}
        onInput={(e: any) => setTime({ ...time, hh: e.target.value })}
      />
      :
      <input
        class="inline-ruz-input w-14 appearance-none text-center mx-2"
        type="number"
        min="0"
        max="59"
        name={`${name}.mm`}
        placeholder="mm"
        value={time.mm}
        onInput={(e: any) => setTime({ ...time, mm: e.target.value })}
      />
      <BtnPrimary
        type="button"
        name={`${name}.ampm`}
        value={time.ampm}
        onClick={(e: any) => {
          let ampm = e.target.value;
          ampm = ampm === 'AM' ? 'PM' : 'AM';
          e.target.value = ampm;
          e.target.textContent = ampm;
          setTime({ ...time, ampm });
        }}
      >
        {time.ampm}
      </BtnPrimary>
    </>
  );
}
