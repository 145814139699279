/**
 * This file contains the course outline block for the sales page.
 */

import { BtnSecondary } from '@components/buttons';
import { IcoChevronDown } from '@components/icons';
import { useRouteParams } from '@components/router';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { groupBy } from 'shared/utils';
import {
  RichProp,
  EditorProps,
  SalesBlockInitialState,
  ViewerProps,
} from '@components/page-builder';
import { Case } from '@components/conditional';
import { Dropdown, MenuItem } from '@components/dropdown';
import { intl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';

interface State {
  richText: string;
}

interface OutlineItem {
  id: UUID;
  title: string;
}

interface OutlineModule extends OutlineItem {
  lessons: OutlineItem[];
}

interface CourseOutline {
  courseId: UUID;
  courseTitle: string;
  modules: OutlineModule[];
  initialPage: OutlineModule[];
  hasMore: boolean;
}

const PAGE_SIZE = 10;

export const name = 'Course outline';

export const type = 'outline';

export const initialBlockState: SalesBlockInitialState = {
  type,
  paddingt: 'md',
};

export const initialState: State = {
  richText: `<h2>Outline</h2><p>You'll go from beginner to intermediate in a fast, fun, engaging way.</p>`,
};

export function MiniView() {
  return (
    <span class="block py-4">
      <span class="w-4/6 mx-auto block">
        <span class="block bg-gray-600 opacity-75 rounded h-1 mb-2 w-1/2"></span>
        <span class="ml-2 block bg-gray-500 opacity-75 rounded h-1 mb-2 w-5/6"></span>
        <span class="block bg-gray-600 opacity-75 rounded h-1 mb-2 w-1/2"></span>
        <span class="ml-2 block bg-gray-500 opacity-75 rounded h-1 mb-2 w-3/4"></span>
        <span class="ml-2 block bg-gray-500 opacity-75 rounded h-1 mb-2 w-5/6"></span>
      </span>
    </span>
  );
}

function LoadingPlaceholder(props: { class?: string }) {
  return <div class={`bg-gray-300 animate-pulse h-8 mb-2 rounded ${props.class || ''}`}></div>;
}

function LoadingView() {
  return (
    <div>
      <LoadingPlaceholder />
      <LoadingPlaceholder />
      <LoadingPlaceholder />
      <LoadingPlaceholder />
      <LoadingPlaceholder />
    </div>
  );
}

function ModuleBlock({
  title,
  lessons,
  isBgLight,
}: {
  title: string;
  isBgLight: boolean;
  lessons: OutlineItem[];
}) {
  return (
    <div>
      <h3 class="font-medium text-2xl mb-8">{title}</h3>
      <div class={`${isBgLight ? 'bg-gray-500/5' : 'bg-white/10'} p-10 py-6 rounded-2xl leading-7`}>
        {lessons.map((l, i) => (
          <div
            key={l.id}
            class={`py-4 font-medium ${i ? 'border-t' : ''} ${isBgLight ? '' : 'border-white/20'}`}
          >
            {l.title}
          </div>
        ))}
      </div>
    </div>
  );
}

function CourseOutline(
  props: Pick<EditorProps<unknown>, 'isBgLight'> & {
    outline: CourseOutline;
    isActive: boolean;
  },
) {
  const { modules, initialPage, hasMore } = props.outline;
  const [showAll, setShowAll] = useState(false);
  const visibleItems = showAll ? modules : initialPage;

  return (
    <div class={`text-left min-w-3/4 space-y-12 ${props.isActive ? '' : 'hidden'}`}>
      {visibleItems.map((x) => (
        <ModuleBlock key={x.id} title={x.title} lessons={x.lessons} isBgLight={props.isBgLight} />
      ))}
      {hasMore && !showAll && (
        <footer class="text-center mt-8">
          <BtnSecondary class="text-inherit rounded-full p-4 px-6" onClick={() => setShowAll(true)}>
            <span class="mr-2">
              {intl('Show All {count:number} Modules', {
                count: modules.length,
              })}
            </span>{' '}
            <IcoChevronDown />
          </BtnSecondary>
        </footer>
      )}
    </div>
  );
}

export function Editor(props: EditorProps<State> | ViewerProps<State>) {
  const { courseId } = useRouteParams();
  const [isLoading, setIsLoading] = useState(false);
  const [outlines, setOutlines] = useState<CourseOutline[]>([]);
  const [activeOutline, setActiveOutline] = useState<CourseOutline | undefined>();

  useAsyncEffect(async () => {
    try {
      const result = await rpx.courses.getCourseOutline({ courseId });
      const outlines = result.map((outline) => {
        const lessons = groupBy((x) => x.moduleId, outline.lessons);
        const modules: OutlineModule[] = [];

        outline.modules.forEach((l) => {
          modules.push({ ...l, lessons: lessons[l.id] || [] });
        });

        const hasMore = outline.lessons.length > PAGE_SIZE;
        let initialPage: OutlineModule[] = [];
        if (!hasMore) {
          initialPage = modules;
        } else {
          let count = 0;
          for (const module of modules) {
            if (count >= PAGE_SIZE) {
              break;
            }
            initialPage.push(module);
            count += module.lessons.length;
          }
        }

        return {
          courseId: outline.course.id,
          courseTitle: outline.course.title,
          modules,
          initialPage,
          hasMore,
        };
      });
      setOutlines(outlines);
      setActiveOutline(outlines[0]);
    } finally {
      setIsLoading(false);
    }
  }, [courseId]);

  return (
    <div class="md:px-20 max-w-4xl w-full mx-auto">
      <RichProp {...props} path="richText" class="mb-10 text-lg" />
      {isLoading && <LoadingView />}
      {outlines && (
        <div>
          <Case when={outlines.length > 1}>
            <div class="flex items-center justify-center">
              <Dropdown
                renderMenu={() => (
                  <div class="p-2 space-y-2 flex flex-col">
                    {outlines.map((outline) => (
                      <MenuItem key={outline.courseId} onClick={() => setActiveOutline(outline)}>
                        <span class="ml-3">{outline.courseTitle}</span>
                      </MenuItem>
                    ))}
                  </div>
                )}
                class="items-center mb-10 text-center"
                triggerClass="text-indigo-600 text-xl text-center bg-gray-800 hover:opacity-90 text-white px-6 py-2 text-lg items-center font-medium rounded-full"
              >
                <span class="mr-4">{activeOutline?.courseTitle || 'Select Course'}</span>
              </Dropdown>
            </div>
          </Case>
          {outlines.map((outline) => (
            <CourseOutline
              key={outline.courseId}
              outline={outline}
              isActive={outline.courseId === activeOutline?.courseId}
              isBgLight={props.isBgLight}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export const Viewer = Editor;
