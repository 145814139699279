import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

function Item({ title, desc, imagePath }: { title: string; desc: string; imagePath: string }) {
  return (
    <button class="flex items-center rounded-2xl overflow-hidden hover:border-indigo-400 hover:ring-2 hover:ring-indigo-400 px-4 py-3 gap-6 bg-white shadow">
      <img src={`${RUZUKU_ASSETS_BASE_URL}/${imagePath}`} class="rounded-xl h-24 w-24" />
      <span class="flex flex-col text-left">
        <span class="text-base font-semibold">{title}</span>
        <span class="text-gray-500 text-sm">{desc}</span>
      </span>
    </button>
  );
}

export function CreateWidget() {
  return (
    <div class="hidden lg:flex bg-gradient-to-b from-gray-100 to-indigo-50 p-4 rounded-2xl gap-4">
      <Item
        title={`Create a course`}
        desc="Engage and support students with discussions, chats, meetings, and assessments."
        imagePath="new-course.svg"
      />
      <Item
        title="Create a product"
        desc="Sell structured content (such as eBooks and videos) or take payment for a service package."
        imagePath="new-product.svg"
      />
      <Item
        title="Browse Instant Courses"
        desc="Explore 30+ professionally-designed courses that you can customize and make your own."
        imagePath="instant-courses.svg"
      />
    </div>
  );
}
