import { UserLevel } from 'server/types';

const userAuthLevels: Record<UserLevel, number> = {
  student: 0,
  guide: 1,
  admin: 2,
  superadmin: 3,
};

export function hasLevel(user: { level: UserLevel } | undefined, wanted: UserLevel) {
  if (!user) {
    return false;
  }

  const currentLevel = userAuthLevels[user.level];
  const wantedLevel = userAuthLevels[wanted];

  return currentLevel >= wantedLevel;
}
