import { Button } from '@components/buttons';
import { Draggable } from '@components/draggable';
import { IcoGripper, IcoTrash } from '@components/icons';
import { ComponentChildren } from 'preact';
import { useRef, useState } from 'preact/hooks';

interface Props {
  onDelete?(): void;
}

export function SubsectionToolbar({ onDelete }: Props) {
  return (
    <nav class="absolute right-full -top-4 mr-2 inset-y-0">
      <div class="flex flex-col items-center text-gray-600 bg-gray-100 rounded-full shadow an-slide-up">
        <label class="p-2 cursor-move" aria-label="Move" draggable>
          <IcoGripper class="w-4 h-4" />
        </label>
        {onDelete && (
          <Button class="p-2" aria-label="Delete" onClick={onDelete}>
            <IcoTrash class="w-4 h-4" />
          </Button>
        )}
      </div>
    </nav>
  );
}

/**
 * Wraps the subsection of a sales page editor block.
 */
export function SubsectionWrapper({ children }: { children: ComponentChildren }) {
  return <div class="content-block-body content-block-width mx-auto">{children}</div>;
}

export function SubsectionEditor(
  props: Props & {
    table: string;
    id: any;
    disabled?: boolean;
    children: ComponentChildren;
    class?: string;
  },
) {
  const [selectionState, setSelectionState] = useState(0);
  const selectionRef = useRef(selectionState);
  selectionRef.current = selectionState;
  const isSelected = !props.disabled && !!selectionState;

  if (props.disabled) {
    return <div class="flex flex-col">{props.children}</div>;
  }

  return (
    <Draggable
      id={props.id}
      table={props.table}
      tabIndex={-1}
      class={`flex flex-col rounded-3xl ${props.class || ''}`}
      onMouseDownCapture={() => setSelectionState(Date.now())}
      onFocusCapture={() => setSelectionState(Date.now())}
      onBlurCapture={() => {
        // This is a hacky way for us to ignore the blur if it was caused by
        // mousing into / focusing into another element within this component.
        if (Date.now() - selectionState > 15) {
          setSelectionState(0);
        }
      }}
    >
      {isSelected && <SubsectionToolbar onDelete={props.onDelete} />}
      {props.children}
    </Draggable>
  );
}
