import { UserProfileIcon } from '@components/avatars';

export function UserProfile({ user }: { user: { name: string } }) {
  return (
    <span class="flex items-center gap-3">
      <span>
        <UserProfileIcon user={user} size="w-9 h-9" />
      </span>
      <span class="flex flex-col leading-5">
        <strong>{user.name}</strong>
      </span>
    </span>
  );
}
