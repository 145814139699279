import { showError } from '@components/app-error';
import { BtnCTA } from '@components/buttons';
import { DEFAULT_CONTENT, Message } from '@components/messages';
import { PageNavBar, PageNavBody, PageNavLink, PageNavSegment } from '@components/page-nav-bar';
import { router } from '@components/router';
import dayjs from 'dayjs';
import { useState } from 'preact/hooks';
import { Course } from 'server/types';
import { toLocalDate } from 'shared/dateutil';
import { groupBy } from 'shared/utils';
import { messagesUrl } from './urls';
import { rpx } from 'client/lib/rpx-client';

const store = rpx.messages;

export type MessageType = 'system' | 'scheduled' | 'sending' | 'draft' | 'sent';

interface Props {
  course: Pick<Course, 'id' | 'accessFormat' | 'isProduct' | 'isAbsoluteSchedule'>;
  selectedId?: UUID | string;
  messages: Message[];
  onCreate(msg: Message): void;
}

function BtnCompose({
  course,
  onCreate,
}: {
  course: Props['course'];
  onCreate(msg: Message): void;
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const createNewDraft = async () => {
    try {
      setIsProcessing(true);
      const result = await store.saveMessage({
        title: '',
        content: DEFAULT_CONTENT,
        courseId: course.id,
      });
      const msg: Message = {
        id: result.id,
        editUrl: messagesUrl(course, result.id),
        subject: result.title,
        updatedAt: result.updatedAt,
      };
      onCreate(msg);
      router.goto(msg.editUrl);
    } catch (err) {
      showError(err);
    } finally {
      setIsProcessing(false);
    }
  };
  return (
    <BtnCTA class="w-full" onClick={createNewDraft} disabled={isProcessing}>
      Create new message
    </BtnCTA>
  );
}

const messageTypeNames: Record<MessageType, string> = {
  system: 'System emails',
  scheduled: 'Scheduled',
  sending: 'Sending',
  draft: 'Drafts',
  sent: 'Sent',
};

function getMessageType(m: Message, isAbsoluteSchedule: boolean): MessageType {
  if (m.isSystem) {
    return 'system';
  }
  if (m.sentAt) {
    return 'sent';
  }
  if (m.startDate) {
    if (!isAbsoluteSchedule) {
      const diff = dayjs().diff(m.startDate, 'hours');
      // If the message is scheduled to be sent within the near 24 hours, consider it as sending.
      // This is to prevent confusion for relative schedules.
      if (diff > -24 && diff < 24) {
        return 'sending';
      }
    }
    if (dayjs().isAfter(toLocalDate(m.startDate))) {
      return 'sent';
    }
  }
  return m.startDate || m.startOffset ? 'scheduled' : 'draft';
}

export function MessagesNavBar(props: Props) {
  const { course } = props;
  const groups = groupBy((x) => getMessageType(x, course.isAbsoluteSchedule), props.messages);
  let sections: MessageType[] = ['system', 'scheduled', 'sending', 'draft', 'sent'];
  if (groups.scheduled) {
    groups.scheduled.sort((a, b) => {
      const [val1, val2] =
        props.course.accessFormat === 'ondemand'
          ? [a.startOffset!, b.startOffset!]
          : [a.startDate!, b.startDate!];
      return val1 === val2 ? 0 : val1 > val2 ? 1 : -1;
    });
  }

  if (course.isProduct) {
    // Products don't support scheduled messages
    sections = sections.filter((x) => x !== 'scheduled' && x !== 'sending');
  }
  // We don't need the sending section for on-demand courses and absolute schedules
  if (course.accessFormat === 'ondemand' || course.isAbsoluteSchedule) {
    sections = sections.filter((x) => x !== 'sending');
  }

  return (
    <PageNavBar title="Messages">
      <BtnCompose course={props.course} onCreate={props.onCreate} />
      <PageNavBody>
        {sections.map((messageType) => (
          <PageNavSegment
            key={messageType}
            title={messageTypeNames[messageType]}
            numItems={groups[messageType]?.length || 0}
          >
            {groups[messageType]?.map((m) => (
              <PageNavLink key={m.id} href={m.editUrl} isSelected={m.id === props.selectedId}>
                {m.subject || 'Subject'}
                {(messageType === 'scheduled' || messageType === 'sent') && (
                  <span class="ml-auto opacity-60 text-xs whitespace-nowrap text-right">
                    {summarizeSchedule(props.course, m)}
                  </span>
                )}
              </PageNavLink>
            ))}
          </PageNavSegment>
        ))}
      </PageNavBody>
    </PageNavBar>
  );
}

/**
 * Summarize the schedule in a nav-bar-friendlyl format.
 */
function summarizeSchedule(course: Pick<Course, 'accessFormat'>, m: Message) {
  if (course.accessFormat === 'ondemand' && m.startOffset !== undefined) {
    return `Day ${Math.floor(m.startOffset / (60 * 24)) + 1}`;
  }
  if (course.accessFormat === 'scheduled' && m.startDate) {
    return dayjs(toLocalDate(m.startDate)).format('MMM D');
  }
  return '';
}
