/**
 * Module fmt contains formatting functions for pricing data.
 */

import { WithAvailability } from 'server/payments/availability';
import {
  CouponRow,
  PriceRow,
  CouponDuration,
  Currency,
  PaymentInterval,
  PaymentType,
} from 'server/types';
import { toHumanTime } from 'shared/dateutil';
import * as pmtmath from './math';
import { Internationalize } from 'shared/intl';

interface CurrencyInfo {
  symbol: string;
  isZeroDecimal: boolean;
}

const currencyInfo: Record<string, CurrencyInfo> = {};

function getFormatter(currency: string) {
  try {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
    });
  } catch (e) {
    // 'narrowSymbol' is not supported in all browsers, so fall back to 'symbol'.
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    });
  }
}

function getCurrencyInfo(currency: string) {
  let result = currencyInfo[currency];
  if (!result) {
    const fmt = getFormatter(currency);
    const parts = fmt.formatToParts(0.1);
    result = {
      symbol: parts.find((x) => x.type === 'currency')?.value || '$',
      isZeroDecimal: !parts.find((x) => x.type === 'decimal'),
    };
    currencyInfo[currency] = result;
  }
  return result;
}

/**
 * Determines if the currency is denominated in whole numbers rather than
 * fractions.
 */
export function isZeroDecimal(currency: Currency) {
  return getCurrencyInfo(currency).isZeroDecimal;
}

export const currencies: readonly Currency[] = [
  'USD',
  'EUR',
  'GBP',
  'CAD',
  'JPY', // Zero-decimal
  'AUD',
  'NZD',
  'SGD',
  'INR',
  'KRW', // Zero-decimal
  'HKD',
  'CHF',
  'ZAR',
  'PHP',
  'MXN',
];

function intervals({
  intl,
  interval,
  compact,
}: {
  intl: Internationalize;
  interval: PaymentInterval;
  compact?: boolean;
}) {
  if (compact) {
    return {
      day: 'dy',
      week: 'wk',
      month: 'mo',
      year: 'yr',
    }[interval];
  }

  return {
    day: intl('day'),
    week: intl('week'),
    month: intl('month'),
    year: intl('year'),
  }[interval];
}

function intervalCount({
  intl,
  interval,
  count,
}: {
  intl: Internationalize;
  interval: PaymentInterval;
  count: number;
}) {
  return {
    day: intl('{count:number} {count | pluralize day days}', { count }),
    week: intl('{count:number} {count | pluralize week weeks}', { count }),
    month: intl('{count:number} {count | pluralize month months}', { count }),
    year: intl('{count:number} {count | pluralize year years}', { count }),
  }[interval];
}

/**
 * interval converts a payment interval into an English abbreviation, e.g.:
 *
 * per mo
 * per yr
 */
function intervalAbbreviation({
  item,
  compact,
  intl,
}: {
  item: Pick<PriceRow, 'interval'>;
  compact?: boolean;
  intl: Internationalize;
}) {
  if (!item.interval) {
    return '';
  }

  const interval = intervals({
    interval: item.interval,
    intl,
    compact,
  });

  if (compact) {
    return `/ ${interval}`;
  }

  return intl('per {interval:string}', { interval });
}

/**
 * Convert a decimal to int (e.g. `20.31` becomes `2031`)
 */
export function decimalToCents(price: string | number, currency: Currency) {
  if (!price) {
    return 0;
  }
  if (isZeroDecimal(currency)) {
    return parseInt(`${price}`);
  }
  return parseInt(parseFloat(`${price}`).toFixed(2).replace('.', ''), 10);
}

/**
 * Convert the currency to its symbol.
 */
export function currencySymbol(currency: Currency) {
  return getCurrencyInfo(currency).symbol;
}

/**
 * price converts a price to a human-friendly string, e.g.:
 *
 * $88 if there are no cents
 * $88.50 if there are cents
 */
export function price(
  price: Pick<PriceRow, 'priceInCents' | 'currency'> & {
    omitCurrency?: boolean;
  },
) {
  const amount = price.priceInCents / (isZeroDecimal(price.currency) ? 1 : 100);
  // If we're in the brorwser, we'll use the user's locale (undefined) otherwise
  // we'll fallback to en-UK. The `en-UK` fallback means that our server-
  // generated content will always have an unambiguous prefix: e.g. US$30
  const locale = typeof window === 'undefined' ? 'en-UK' : undefined;
  const result = new Intl.NumberFormat(locale, {
    style: price.omitCurrency ? 'decimal' : 'currency',
    currency: price.currency,
  }).format(amount);
  return result.endsWith('.00') ? result.slice(0, -3) : result;
}

/**
 * discountPrice converts a coupon + price into an "amount off". If it is a
 * full discount, this returns "100%" otherwise, this is equivalent to calling
 * price, e.g.:
 *
 * $88
 * 100%
 */
export function discountPrice(opts: {
  price: Pick<PriceRow, 'priceInCents' | 'currency'>;
  coupon?: Pick<CouponRow, 'amountOff' | 'percentOff'>;
}) {
  const priceInCents = pmtmath.discountPrice(opts);
  if (priceInCents === 0) {
    return '';
  }
  if (priceInCents >= opts.price.priceInCents) {
    return '100%';
  }
  return price({ priceInCents, currency: opts.price.currency });
}

/**
 * priceSuffix returns a human-friendly description of the duration and
 * interval associated with a price. Can be used naturally with subtotal and
 * total, e.g.:
 *
 * "/ mo for 8 months"
 * "/ yr for 2 years"
 * "/ mo"
 * "/ yr"
 */
export function priceSuffix({
  item,
  compact,
  intl,
}: {
  item: Pick<PriceRow, 'numPayments' | 'interval'>;
  compact?: boolean;
  intl: Internationalize;
}) {
  if (!item.interval || item.numPayments === 1) {
    return '';
  }

  if (!item.numPayments) {
    return intervalAbbreviation({
      item,
      intl,
      compact,
    });
  }

  return intl('{abbreviation:string} for {intervalCount:string}', {
    abbreviation: intervalAbbreviation({
      item,
      intl,
    }),
    intervalCount: intervalCount({
      interval: item.interval,
      count: item.numPayments,
      intl,
    }),
  });
}

/**
 * discountSuffix returns a human-friendly description of the discount
 * duration, e.g.:
 *
 *   "off the first payment"
 *   "off all payments"
 *   "off the first N payments"
 */
export function discountSuffix({
  price: p,
  coupon: c,
  intl,
}: {
  price: Pick<PriceRow, 'numPayments' | 'paymentType'>;
  coupon?: Pick<CouponRow, 'duration' | 'numPayments'>;
  intl: Internationalize;
}) {
  if (!c || p.paymentType === 'free') {
    return '';
  }
  const numPayments = Math.min(p.numPayments || Number.MAX_SAFE_INTEGER, c.numPayments || 0);
  const appliesToAll =
    c.duration === 'forever' ||
    !c.numPayments ||
    (c.numPayments && p.numPayments && c.numPayments >= p.numPayments);

  // If the price is a single payment, then we'll say something like "$10 off"
  if (p.paymentType === 'paid') {
    return intl('off');
  }
  if (c.duration === 'once' || c.numPayments === 1) {
    return intl('off the first payment');
  }
  if (appliesToAll) {
    return intl('off all payments');
  }
  return intl('off the first {numPayments:number} payments', {
    numPayments,
  });
}

/**
 * firstBatchSuffix describes the duration of the first batch of payments for
 * a multiple-payment price that has a partial coupon, e.g.:
 *
 *    "for the first month"
 *    "/ yr for the first 2 years"
 *    "/ mo for the first 3 months"
 */
export function firstBatchSuffix({
  item,
  intl,
}: {
  item: Pick<PriceRow, 'numPayments' | 'interval'>;
  intl: Internationalize;
}) {
  if (!item.numPayments || !item.interval) {
    return '';
  }
  if (item.numPayments === 1) {
    return intl('for the first {interval}', {
      interval: intervals({
        interval: item.interval,
        intl,
      }),
    });
  }

  return intl('{abbreviation:string} for the first {intervalCount:string}', {
    abbreviation: intervalAbbreviation({
      item,
      intl,
    }),
    intervalCount: intervalCount({
      interval: item.interval,
      count: item.numPayments,
      intl,
    }),
  });
}

/**
 * availability summarizes the availability of the specified item. If the item is available,
 * this returns ''. Otherwise, it returns a human-friendly explanation.
 */
export function availability({
  item,
  intl,
}: {
  item: WithAvailability<Pick<PriceRow, 'availableOn' | 'expiresOn' | 'id'>>;
  intl: Internationalize;
}) {
  const type = item.id.startsWith('price-') ? 'price' : 'coupon';
  switch (item.availability) {
    case 'available':
      return '';
    case 'disabled':
      return intl('This {type:string} is no longer available.', { type });
    case 'expired':
      return intl('This {type:string} expired {time:string}.', {
        type,
        time: toHumanTime(item.expiresOn),
      });
    case 'notstarted':
      return intl('This {type:string} will be available {time:string}.', {
        type,
        time: toHumanTime(item.availableOn),
      });
    case 'full':
      return intl('This {type} is sold out.', { type });
    case 'productdisabled':
      return intl('Purchases are currently disabled.');
  }
}

export function describeCoupon({
  coupon: c,
  intl,
}: {
  coupon: Pick<CouponRow, 'numPayments' | 'duration' | 'amountOff' | 'percentOff' | 'currency'>;
  intl: Internationalize;
}) {
  const discount = c.amountOff
    ? `${price({ priceInCents: c.amountOff, currency: c.currency })}`
    : `${c.percentOff}%`;
  const describeDuration: Record<CouponDuration, string> = {
    once: intl('the first payment'),
    forever: intl('all payments'),
    repeating: intl('the first {numPayments:number} payments', {
      numPayments: c.numPayments || 0,
    }),
  };
  const duration = c.duration === 'repeating' && c.numPayments === 1 ? 'once' : c.duration;
  return `${discount} off ${describeDuration[duration || 'once']}`;
}

export function isSubscriptionLike(price: { paymentType: PaymentType }) {
  return price.paymentType === 'subscription' || price.paymentType === 'paymentplan';
}
