import { router } from '@components/router';
import { AdminNav, AdminTabWrapper } from '@components/admin-nav';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { EmptyScreen } from './empty-screen';
import {
  AboutMeBlock,
  defaultBgImg,
  FocusedTestimonialBlock,
  SalesBlockDefinition,
  SplitBlock,
  TestimonialBlock,
} from '@components/page-builder';
import * as ContentBlock from '@components/page-builder/content-block';
import * as CourseGridBlock from './course-grid-block';
import * as CoursePromoBlock from './course-promo-block';
import { SalesBlockState, SalespageContent } from 'server/types';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { LoadedProps } from 'client/lib/loaders';
import { PageEditor, usePageAutosaver } from '@components/page-builder/page-editor';
import { fullyQualify } from '../pmts/components/urls';
import { h as htm } from 'minidoc-editor';
import { CourseBlockContext, load } from './types';
import { useSubstate } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { blockDefinitions } from './block-definitions';
import { Color } from 'shared/colors';

function generateInitialContent(opts: {
  tenant: ReturnType<typeof useCurrentTenant>;
  currentUser: ReturnType<typeof useCurrentUser>;
}): SalespageContent {
  const { tenant } = opts;
  let id = 0;
  const blockState = (
    d: SalesBlockDefinition,
    initialState: any = d.initialState,
    initialBlockState?: Partial<SalesBlockState>,
  ) => {
    const state: SalesBlockState = {
      id: `${d.type}-${++id}`,
      type: d.type,
      state: initialState,
    };
    if (d.initialBlockState || initialBlockState) {
      Object.assign(state, d.initialBlockState, initialBlockState);
    }
    return state;
  };

  const blocks: SalesBlockState<unknown>[] = [
    blockState(
      ContentBlock,
      ContentBlock.generateState({
        richText: htm(
          'div',
          htm('h1', { 'data-align': 'center' }, tenant.name),
          htm('p', { 'data-align': 'center' }, 'Something exciting here.'),
        ).innerHTML,
      }),
      {
        bgimg: defaultBgImg,
        bgcolor: Color.white,
      },
    ),
    blockState(CoursePromoBlock, undefined, {
      bgcolor: Color.indigo700,
      fgcolor: Color.white,
    }),
    blockState(
      ContentBlock,
      ContentBlock.generateState({
        richText: htm(
          'div',
          htm('h2', { 'data-align': 'center' }, 'Welcome to my courses!'),
          htm('p', { 'data-align': 'center' }, 'All have been crafted just for people like you.'),
        ).innerHTML,
      }),
      {
        paddingb: 'sm',
      },
    ),
    blockState(CourseGridBlock, undefined, {
      paddingt: 'sm',
    }),
    blockState(FocusedTestimonialBlock),
    blockState(SplitBlock),
    blockState(TestimonialBlock, undefined, { bgcolor: Color.gray50 }),
    blockState(
      AboutMeBlock,
      AboutMeBlock.generateState({
        currentUser: opts.currentUser!,
        label: 'About Us',
        name: tenant.name,
      }),
    ),
  ];

  return {
    blockIds: blocks.map((x) => x.id),
    blocks: blocks.reduce((acc, x) => {
      acc[x.id] = x;
      return acc;
    }, {} as Record<string, SalesBlockState>),
  };
}

async function onSave({ content }: { content: SalespageContent }) {
  const courseIds = new Set<string>();
  const priceIds = new Set<string>();
  const couponIds = new Set<string>();

  const addReference = (x: CourseGridBlock.CoursePriceReference) => {
    courseIds.add(x.courseId);
    x.priceId && priceIds.add(x.priceId);
    x.couponId && couponIds.add(x.couponId);
  };

  content.blockIds.forEach((id) => {
    const block = content.blocks[id];
    if (block.type === CourseGridBlock.type) {
      const state = block.state as CourseGridBlock.State;
      state.courses.forEach(addReference);
    } else if (block.type === CoursePromoBlock.type) {
      const state = block.state as CoursePromoBlock.State;
      state.course && addReference(state.course);
    }
  });
  return rpx.storefront.save({
    content: {
      ...content,
      courseIds: Array.from(courseIds),
      priceIds: Array.from(priceIds),
      couponIds: Array.from(couponIds),
    },
  });
}

function Page({ state, setState }: LoadedProps<typeof load>) {
  const currentTenant = useCurrentTenant();
  const currentUser = useCurrentUser();
  const { content } = state.builderState;
  const resetStorefront = () => {
    setState((s) => ({
      ...s,
      builderState: {
        content: { blockIds: [], blocks: {} },
        selectedId: '',
      },
    }));
  };
  const generateStorefront = () => {
    const content = generateInitialContent({ tenant: currentTenant, currentUser });
    setState((s) => ({
      ...s,
      builderState: {
        content,
        selectedId: content.blockIds[0],
      },
    }));
  };
  const updater = useSubstate(
    setState,
    (s) => s.builderState,
    (s, builderState) => ({ ...s, builderState }),
  );

  const autosaver = usePageAutosaver({ content, onSave });

  return (
    <FixedPage bg="bg-white">
      <FixedContent class="bg-white">
        <AdminNav currentPage="storefront" pageTitle="Storefront" />
        {!content.blockIds.length && (
          <AdminTabWrapper>
            <EmptyScreen onCreate={generateStorefront} />
          </AdminTabWrapper>
        )}
        {!!content.blockIds.length && (
          <div class="p-8">
            <CourseBlockContext.Provider value={{ state, setState }}>
              <PageEditor
                title="Storefront"
                state={state.builderState}
                setState={updater}
                publicURL={fullyQualify('/store')}
                isConnected={autosaver.isConnected}
                isDirty={autosaver.isDirty}
                blockDefinitions={blockDefinitions}
                onReset={resetStorefront}
              />
            </CourseBlockContext.Provider>
          </div>
        )}
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  authLevel: 'admin',
  url: '/admin/store',
  load,
  render: Page,
});
