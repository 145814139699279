import { showDialog } from '@components/dialog';
import { rpx } from 'client/lib/rpx-client';
import { ERROR_CODES } from 'shared/consts';

export async function handleZoomIntegrationError({
  meetingId,
  courseId,
  err,
}: {
  meetingId?: UUID;
  courseId: UUID;
  err: any;
}) {
  if (meetingId && err.data.code === ERROR_CODES.zoomMeetingNotFound) {
    const isConfirmed = await showDialog({
      mode: 'warn',
      title: 'Zoom meeting not found',
      children:
        'This meeting is no longer available in your Zoom account. Do you want to delete it from Ruzuku?',
      confirmButtonText: 'Delete meeting',
    });
    if (isConfirmed) {
      await rpx.meetings.deleteMeeting({
        id: meetingId,
        courseId,
      });
      location.reload();
    }
    return true;
  }

  if (err.data.code === ERROR_CODES.zoomNeedsRefresh) {
    const result = await rpx.zoom.getZoomAccount();
    const isConfirmed = await showDialog({
      title: 'Zoom integration expired',
      children:
        'Our connection to your Zoom account has expired. Please re-integrate Zoom into your Ruzuku account by clicking the button.',
      mode: 'info',
      confirmButtonText: 'Re-integrate',
    });
    if (isConfirmed) {
      location.assign(result.oauth);
    }
    return true;
  }
  return false;
}
