import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';

export async function load(route: AppRoute) {
  const searchTerm = route.params.q || '';
  const [{ courses, cursor }, brands] = await Promise.all([
    rpx.tenants.getTenantCourses({
      searchTerm: route.params.q || '',
      secondSearchTerm: route.params.q2 || '',
      orderBy: 'createdAt',
    }),
    rpx.branding.getBrands(),
  ]);

  return {
    searchTerm,
    courses,
    cursor,
    brands: brands.map((x) => ({ id: x.id, name: x.name })),
  };
}

export type Data = Awaited<ReturnType<typeof load>>;
