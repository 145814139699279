/**
 * A slide-over component. See tailwind coponent slide-overs.
 * https://tailwindui.com/components/application-ui/overlays/slide-overs
 */

import { Button } from '@components/buttons';
import { IcoX } from '@components/icons';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';
import { useEsc } from 'client/utils/use-esc';
import { ComponentChildren } from 'preact';

export function SlideOver({
  children,
  p = 'px-4 sm:px-6 lg:px-10 py-6',
  w = 'max-w-4xl w-screen',
  contentContainerClass = '',
  close,
  position = 'right',
  hideCloseButton,
}: {
  children?: ComponentChildren;
  p?: string;
  w?: string;
  contentContainerClass?: string;
  close: () => void;
  position?: 'left' | 'right';
  hideCloseButton?: boolean;
}) {
  useEsc(close, { respectDefault: true, escapeOnly: true });
  useBodyScrollLock(true);

  return (
    <div
      class="fixed inset-0 overflow-hidden z-50"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="absolute inset-0 overflow-hidden">
        <div
          class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={close}
        />
        <div
          class={`pointer-events-none fixed inset-y-0 ${
            position === 'right' ? 'right-0' : 'left-0'
          } flex max-w-full`}
        >
          <div
            class={`${
              position === 'right' ? 'an-fade-in-left' : 'an-fade-in-right'
            } pointer-events-auto ${w} ${contentContainerClass}`}
          >
            <div class={`flex h-full flex-col overflow-auto bg-white shadow-xl`}>
              <div class={`relative flex flex-col flex-1 ${p}`}>{children}</div>
            </div>

            {!hideCloseButton && (
              <Button class="lg:hidden absolute top-6 right-6 z-50" onClick={close}>
                <IcoX class="w-5 h-5" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
