/**
 * This module contains all of the state management logic for the lesson editor.
 *
 * Loading here has similar requirements to the lesson view, and we probably should
 * consider whether the commonalities should be shared or not.
 */

import { insertUnique } from 'shared/utils';
import { Action, getCurrentCourse, normalizeOutline } from './actions';
import { Reducer } from 'preact/hooks';
import { produce } from 'immer';
import { ManageState } from './types';

export const reducer: Reducer<ManageState, Action> = produce(
  (state: ManageState, action: Action) => {
    switch (action.type) {
      case 'deselectLesson': {
        state.lessonId = undefined;
        return;
      }
      case 'reset': {
        return action.payload;
      }
      case 'loadingLesson': {
        state.loadingLesson = action.payload.lessonId;
        return;
      }
      case 'loadedLesson': {
        // If the user navigated to a different lesson, then we won't mess with loading indicator.
        const lesson = action.payload;
        const isStale = state.loadingLesson !== lesson.id;
        if (!isStale) {
          state.loadingLesson = undefined;
          state.lessonId = lesson.id;
          state.lessons[lesson.id] = lesson;
        }
        return;
      }
      case 'lessonSaved': {
        const lesson = action.payload;
        if (lesson.id) {
          Object.assign(state.lessons[lesson.id], lesson);
        }
        return;
      }
      case 'insertingModule': {
        state.adding = { type: 'module' };
        return;
      }
      case 'insertedModule': {
        const { module, moduleIds } = action.payload;
        state.adding = undefined;
        state.moduleId = module.id;
        state.modules[module.id] = module;
        state.courses[module.courseId].modules = moduleIds;
        return;
      }
      case 'rescheduleModule': {
        const updates = action.payload;
        const module = state.modules[updates.id];
        module.startDate = updates.startDate;
        module.startOffset = updates.startOffset;
        return;
      }
      case 'deletedModule': {
        const module = state.modules[action.payload.id];
        const course = state.courses[module.courseId];
        state.moduleId = undefined;
        course.modules = course.modules.filter((x) => x !== module.id);
        return;
      }
      case 'deletedLesson': {
        const lesson = state.lessons[action.payload.id];
        const module = state.modules[lesson.moduleId];
        module.lessons = module.lessons.filter((x) => x !== lesson.id);
        return;
      }
      case 'editingModule': {
        state.moduleId = action.payload.moduleId;
        return;
      }
      case 'savedModule': {
        state.moduleId = undefined;
        const { moduleIds, ...updates } = action.payload;
        Object.assign(state.modules[updates.id], updates);
        state.courses[updates.courseId].modules = moduleIds;
        moduleIds.forEach((id, seq) => {
          state.modules[id].seq = seq;
        });
        return;
      }
      case 'insertingLesson': {
        state.adding = { type: 'lesson', moduleId: action.payload.moduleId };
        return;
      }
      case 'insertedLesson': {
        const { lesson, lessonIds } = action.payload;
        state.adding = undefined;
        state.modules[lesson.moduleId].lessons = lessonIds;
        state.lessons[lesson.id] = lesson;
        return;
      }
      case 'dragReorder': {
        // Reorder modules
        const dragState = action.payload;
        if (dragState.dragging.table === 'modules') {
          const course = getCurrentCourse(state);
          course.modules = insertUnique(
            dragState.dragging.id,
            dragState.direction === 'after' ? 'after' : 'before',
            dragState.target.id,
            course.modules,
          );
          return;
        }

        // Reorder lessons
        const dragging = state.lessons[dragState.dragging.id];
        const module = state.modules[dragging.moduleId];

        // We're dragging our lesson over another lesson
        if (dragState.target.table === 'lessons') {
          module.lessons = insertUnique(
            dragState.dragging.id,
            dragState.direction === 'after' ? 'after' : 'before',
            dragState.target.id,
            module.lessons,
          );
          return;
        }

        // We're dragging our lesson over its own module, so this is a noop.
        if (dragState.target.table === 'modules' && dragState.target.id === module.id) {
          return;
        }

        // We're dragging our lesson over a new module, so we need to move it.
        const targetModule = state.modules[dragState.target.id];

        state.lessons[dragging.id].moduleId = targetModule.id;
        // Remove the lesson from its old module
        module.lessons = module.lessons.filter((l) => l !== dragging.id);
        // Add the lesson to its new module
        targetModule.lessons =
          dragState.direction === 'before'
            ? targetModule.lessons.concat(dragging.id)
            : [dragging.id, ...targetModule.lessons];
        return;
      }
      case 'loadedOutline': {
        const course = state.courses[action.payload.courseId];
        const outline = normalizeOutline({ ...action.payload, course });
        const lesson = state.lessonId ? state.lessons[state.lessonId] : undefined;
        if (!course) {
          return;
        }
        if (lesson) {
          // Preserve the full lesson, if we have it...
          outline.lessons[lesson.id] = lesson;
        }
        state.modules = outline.modules;
        state.lessons = outline.lessons;
        course.modules = outline.moduleIds;
        state.orderedLessonIds = outline.orderedLessonIds;
        return;
      }
      default:
        return state;
    }
  },
);
