import { addSubroute } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { StudentProductPage } from './student-product-page';
import { FullLesson } from '@components/module-helpers';
import { groupBy } from 'shared/utils';
import { useState } from 'preact/hooks';
import { DownloadsViewer } from '../student-course-modules/downloads';
import { useImageUrl } from 'client/utils/cdn';
import { UserProfileIcon } from '@components/avatars';
import { Dropdown } from '@components/dropdown';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { IcoArrowLeft, IcoArrowRight } from '@components/icons';
import * as URLS from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';

async function load(route: AppRoute) {
  const { courseId, lessonId } = route.params;
  const [course, lessonState] = await Promise.all([
    rpx.courses.getStudentCourse({
      id: courseId,
      redirectIncompleteProfiles: true,
    }),
    rpx.lessons.getFullLessonState({ courseId, lessonId }),
  ]);
  const lessonMap = groupBy((l) => l.moduleId, lessonState.lessons);
  const lessons = lessonState.modules.flatMap((m) => lessonMap[m.id]);
  const lesson =
    lessonState.lesson || (await rpx.lessons.getLesson({ lessonId: lessons[0].id, courseId }));

  return {
    course,
    lessonId: lessonState.lesson?.id,
    lessons: lessons.map<FullLesson>((l: any) =>
      l.id === lesson?.id ? { ...lesson, type: 'full' } : { ...l, type: 'partial' },
    ),
  };
}

function FooterLink(props: { href: string; direction: 'next' | 'prev'; title: string }) {
  const intl = useIntl();
  const label = props.direction === 'next' ? intl('Next') : intl('Previous');
  const Icon = props.direction === 'next' ? IcoArrowRight : IcoArrowLeft;
  return (
    <a
      href={props.href}
      class={`flex flex-col gap-1 justify-center p-4 rounded-md border hover:bg-gray-50 dark:hover:bg-gray-700/50 text-inherit dark:border-gray-700 text-base ${
        props.direction === 'next' ? 'items-end' : ''
      }`}
    >
      <span class="text-sm opacity-75">{label}</span>
      <span
        class={`flex items-center gap-2 overflow-hidden ${
          props.direction === 'next' ? 'flex-row-reverse' : ''
        }`}
      >
        <Icon class="shrink-0 size-4 opacity-75" />
        <span class="grow line-clamp-1">{props.title}</span>
      </span>
    </a>
  );
}

function Page({ state }: LoadedProps<typeof load>) {
  const { course, lessonId, lessons } = state;
  const { guide } = course;
  const lesson = lessons.find((l) => l.id === lessonId) || lessons[0];
  const lessonIndex = lessons.indexOf(lesson);
  const [showMenu, setShowMenu] = useState(false);
  const courseImage = useImageUrl(course.imagePath);
  const nextLesson = lessons[lessonIndex + 1];
  const prevLesson = lessons[lessonIndex - 1];

  return (
    <StudentProductPage
      course={course}
      membershipLevel={course.accessLevel}
      editLink={lesson && `/manage/products/${course.id}/content/${lesson.id}`}
      documentTitle={lesson?.title}
      isMenuShowing={showMenu}
      onMenuToggle={lessons.length > 1 ? () => setShowMenu((x) => !x) : undefined}
    >
      <div class="flex flex-grow">
        {lessons.length > 1 && (
          <>
            <div class="w-80 hidden lg:block 2xl:hidden"></div>
            {showMenu && (
              <div
                class="fixed inset-0 z-10 top-12 bg-gray-400/20 backdrop-blur-sm dark:bg-black/40 opacity-100"
                onMouseDown={() => setShowMenu(false)}
              ></div>
            )}
            <div
              class={`${
                showMenu ? 'bg-white dark:bg-gray-900 flex z-10 animate-slide-in-sidenav' : 'hidden'
              } flex-col lg:flex w-80 max-w-full overflow-auto border-r border-gray-900/10 xl:w-80 lg:dark:border-white/10 fixed h-full inset-y-0 left-0 pt-10 self-start min-h-screen text-sm text-gray-600 dark:text-gray-400`}
            >
              <nav class="flex flex-col flex-grow gap-1 py-10 lg:py-16 px-4 max-w-full">
                <h2
                  class="font-display font-semibold px-3 flex items-center gap-4 border-b dark:border-gray-700 mb-2 pb-6"
                  title={course.title}
                >
                  {courseImage && <img src={courseImage} class="rounded-md max-w-12" />}
                  <span class="line-clamp-2">{course.title} </span>
                </h2>
                {lessons.map((l) => (
                  <a
                    href={`/products/${course.id}/content/${l.id}`}
                    onClick={() => setShowMenu(false)}
                    key={l.id}
                    class={`${
                      l.id === lesson.id
                        ? 'text-indigo-500 text-theme-link font-semibold dark:text-gray-100 dark:bg-gray-500/25'
                        : 'text-gray-500 hover:theme-link font-medium hover:text-gray-800 dark:text-gray-400'
                    } hover:theme-link rounded-md p-2 px-3 dark:hover:bg-gray-500/25 dark:hover:text-white overflow-hidden whitespace-nowrap overflow-ellipsis`}
                  >
                    {l.title}
                  </a>
                ))}
              </nav>
              <footer class="mt-auto border-t dark:border-gray-700 bg-white dark:bg-gray-900 sticky bottom-0 p-4 px-6 flex items-center gap-4 whitespace-nowrap dark:text-white">
                <UserProfileIcon user={guide} size="size-10" />
                <span class="flex flex-col leading-5 overflow-hidden">
                  <strong class="text-ellipsis overflow-hidden">{guide.name}</strong>
                  <a href={`mailto:${guide.email}`} class="text-ellipsis overflow-hidden">
                    {guide.email}
                  </a>
                </span>
              </footer>
            </div>
          </>
        )}

        <section class="flex flex-col flex-grow items-center " key={lesson.id}>
          {lessons.length === 1 && (
            <h2 class="font-display font-semibold flex items-center gap-4 dark:border-gray-700 p-4 md:p-6 w-full border-b md:border-none">
              {courseImage && <img src={courseImage} class="rounded-md max-w-12" />}
              <span class="flex flex-col leading-5">
                <span class="line-clamp-2">{course.title}</span>
                <span class="flex gap-2">
                  <Dropdown
                    hideDownIcon
                    renderMenu={() => (
                      <div class="p-4 pb-2 flex items-center gap-4 whitespace-nowrap dark:text-white font-medium">
                        <UserProfileIcon user={guide} size="size-10" />
                        <span class="flex flex-col leading-5 overflow-hidden">
                          <span class="text-ellipsis overflow-hidden">{guide.name}</span>
                          <a href={`mailto:${guide.email}`} class="text-ellipsis overflow-hidden">
                            {guide.email}
                          </a>
                        </span>
                      </div>
                    )}
                  >
                    <span class="text-indigo-600 font-medium">{guide.name}</span>
                  </Dropdown>
                </span>
              </span>
            </h2>
          )}
          <div class="w-full px-4 py-8 sm:py-16 lg:px-8">
            <div class="max-w-2xl w-full mx-auto font-studentcontent text-lg flex flex-col gap-6">
              <header>
                {lessons.length > 1 && (
                  <div class="lg:hidden text-gray-400 text-sm font-semibold -mb-2">
                    {course.title}
                  </div>
                )}
                <h1 class="font-display text-2rem leading-snug tracking-tight text-gray-900 dark:text-white break-word">
                  {lesson.title}
                </h1>
              </header>
              {lesson.type === 'full' && (
                <>
                  <ReadonlyMinidoc
                    content={lesson.content}
                    id={lesson.id}
                    shouldRenderPdfViewer
                    hideCaptions={course.hideCaptions}
                  />
                  <footer class="mt-4">
                    <DownloadsViewer downloads={lesson.downloads} />
                  </footer>
                  <nav class="grid sm:grid-cols-2 gap-2 dark:text-white">
                    <span>
                      {prevLesson && (
                        <FooterLink
                          direction="prev"
                          href={URLS.products.customerView({
                            courseId: course.id,
                            lessonId: prevLesson.id,
                          })}
                          title={prevLesson.title}
                        />
                      )}
                    </span>
                    <span>
                      {nextLesson && (
                        <FooterLink
                          direction="next"
                          title={nextLesson.title}
                          href={URLS.products.customerView({
                            courseId: course.id,
                            lessonId: nextLesson.id,
                          })}
                        />
                      )}
                    </span>
                  </nav>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
    </StudentProductPage>
  );
}

const routeDef = addSubroute({
  url: 'products/:$courseId/content/:lessonId',
  load,
  async loadSubroute({ courseId, lessonId }, setState) {
    if (!lessonId) {
      return;
    }
    const lesson = await rpx.lessons.getLesson({ courseId, lessonId });
    setState((s) => ({
      ...s,
      lessonId,
      lessons: s.lessons.map((l) => (l.id === lesson.id ? { ...l, ...lesson, type: 'full' } : l)),
    }));
  },
  key: (p) => p.courseId,
  render: Page,
  authLevel: 'student',
});

addSubroute({
  ...routeDef,
  url: 'products/:courseId/content',
});

addSubroute({
  ...routeDef,
  url: 'products/:courseId',
});
