/**
 * The split-pane block.
 */
import { EditorProps, SalesBlockInitialState, ViewerProps } from './types';
import { RichProp } from './editable';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

interface State {
  left: string;
  right: string;
}

export const name = 'Split Pane';

export const type = 'split';

export const initialBlockState: SalesBlockInitialState = {
  type,
  paddingb: 'md',
};

export const initialState: State = {
  left: `<h2>Videos add nice visual interest</h2><p>For potential customers, a good intro video can make the difference between a sale and a bounce. Show; don't tell.</p>`,
  right: `<video src="/files/7e40a7fa-11d8-4a08-8b9d-7db8abdde8b6.mov" preload="none" controls="true" data-state="{&quot;url&quot;:&quot;/files/7e40a7fa-11d8-4a08-8b9d-7db8abdde8b6.mov&quot;,&quot;name&quot;:&quot;sample_1280x720_surfing_with_audio.mov&quot;,&quot;type&quot;:&quot;video/quicktime&quot;,&quot;ratio&quot;:56.189151599443676,&quot;width&quot;:1280,&quot;poster&quot;:&quot;${RUZUKU_ASSETS_BASE_URL}/Poster-87476264d38285105766809720378f6d84322aea8bb6a3ddfe.jpeg&quot;}"></video>`,
};

export function MiniView() {
  return (
    <span class="block py-4">
      <span class="w-4/6 mx-auto grid grid-cols-2 gap-4">
        <span class="flex flex-col flex-1">
          <span class="block bg-gray-500 opacity-75 rounded h-1 mb-2 w-1/2"></span>
          <span class="block bg-gray-400 opacity-75 rounded h-1 mb-2 w-full"></span>
          <span class="block bg-gray-400 opacity-75 rounded h-1 mb-2 w-3/4"></span>
        </span>
        <span class="bg-gray-600 opacity-50 rounded flex-1 flex items-center justify-center">
          <span class="bg-white rounded-full h-2 w-2 absolute"></span>
        </span>
      </span>
    </span>
  );
}

function View(props: ViewerProps<State> | EditorProps<State>) {
  return (
    <div class="grid md:grid-cols-2 gap-10 lg:gap-32 text-lg">
      <RichProp {...props} path="left" />
      <RichProp {...props} path="right" />
    </div>
  );
}

export const Viewer = View;

export const Editor = View;
