import { Button } from '@components/buttons';
import { useConfiguration, useCurrentUser } from '@components/router/session-context';
import dayjs from 'dayjs';
import { useState } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';

const localStorageKey = 'dismissedMaintenanceBanner';

function InnerBanner() {
  const configuration = useConfiguration();
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(() => {
    if (!configuration.maintenanceDate) {
      return false;
    }
    if (dayjs().isAfter(configuration.maintenanceDate)) {
      return false;
    }

    return localStorage.getItem(localStorageKey) !== configuration.maintenanceDate;
  });

  // The message has already been dismissed.
  if (!isVisible || !configuration.maintenanceDate) {
    return null;
  }

  function dismissBanner() {
    setIsVisible(false);
    if (configuration.maintenanceDate) {
      localStorage.setItem(localStorageKey, configuration.maintenanceDate);
    }
  }

  return (
    <header class="p-2 bg-red-600/80 text-white flex flex-col lg:flex-row items-center justify-center lg:gap-16">
      <h2 class="max-w-4xl">
        {intl(
          'SCHEDULED MAINTENANCE: Ruzuku will be unavailable for up to 15 minutes on {date:string} as we complete upgrades to the platform. We apologize for any inconvenience caused and thank you for your patience.',
          {
            date: dayjs(configuration.maintenanceDate).format('MMMM D, YYYY [at] h:mm A (z)'),
          },
        )}
      </h2>
      <Button
        class="border border-red-300 p-1 px-2 text-inherit rounded-md"
        onClick={dismissBanner}
      >
        {intl('Dismiss')}
      </Button>
    </header>
  );
}

export function MaintenanceBanner() {
  const user = useCurrentUser();
  if (!user) {
    return null;
  }
  return <InnerBanner />;
}
