import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { IcoCheckCircle, IcoPaypal } from '@components/icons';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { rpx } from 'client/lib/rpx-client';
import { AsyncForm, FormGroup } from '@components/async-form';
import { IntegrationRow, IntegrationValueItem } from './integration-value-item';

const store = rpx.paypalIntegration;

type Props = { clientId?: string };

function BtnPaypalIntegration({
  class: className = '',
  children,
}: {
  class?: string;
  children: ComponentChildren;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  if (!isEditing) {
    return (
      <Button
        type="button"
        class={`text-indigo-600 ${className}`}
        data-test="btnpaypalconnect"
        onClick={() => setIsEditing(true)}
      >
        {children}
      </Button>
    );
  }

  return (
    <AsyncForm
      onSubmit={async ({ clientId, secret }) => {
        setIsSaving(true);
        try {
          await store.saveIntegration({ clientId, secret });
          location.reload();
          // Infinite wait so that the form remains in "processing" state
          // while the page is reloading.
          await new Promise(() => {});
        } finally {
          setIsSaving(false);
        }
      }}
      class="space-y-6 py-4"
    >
      <p>Enter your PayPal API client id and secret in the fields below. To find these values:</p>
      <ol class="list-decimal list-inside space-y-2">
        <li>
          <Button target="_blank" href="https://www.paypal.com/signin">
            Log into your PayPal account
          </Button>
        </li>
        <li>
          <Button
            target="_blank"
            href="https://developer.paypal.com/developer/applications/create?env=live"
          >
            Create a PayPal App
          </Button>
          <ol class="ml-8 list-disc space-y-2 mt-2">
            <li>Enter "Ruzuku" for the App Name.</li>
            <li>Select "Merchant" for the App Type.</li>
            <li>Click "Create App."</li>
          </ol>
        </li>
        <li>
          <span>Copy your ids</span>
          <ol class="ml-8 list-disc space-y-2 mt-2">
            <li>Make sure you are in "Live" mode, not "Sandbox".</li>
            <li>Copy your Client ID and paste it in the Client ID field below.</li>
            <li>Copy your Secret and paste it in the Secret field below.</li>
            <li>Click the Save button below.</li>
          </ol>
        </li>
      </ol>
      <FormGroup prop="clientId" class="w-full mb-2">
        <input
          type="text"
          placeholder="Client ID"
          name="clientId"
          class="ruz-input block w-full sm:text-sm sm:leading-5 bg-transparent"
          autoComplete="off"
          data-private
        />
      </FormGroup>
      <FormGroup prop="secret" class="w-full mb-2">
        <input
          type="text"
          placeholder="Secret"
          name="secret"
          class="ruz-input block w-full sm:text-sm sm:leading-5 bg-transparent"
          autoComplete="off"
          data-private
        />
      </FormGroup>
      <footer class="grid grid-cols-2 gap-4">
        <BtnPrimary isLoading={isSaving} class="py-3">
          Save
        </BtnPrimary>
        <BtnSecondary class="py-3" type="button" onClick={() => setIsEditing(false)}>
          Cancel
        </BtnSecondary>
      </footer>
    </AsyncForm>
  );
}

function ConnectPage(props: Props) {
  if (props.clientId) {
    return (
      <div class="flex">
        <span>
          <IcoCheckCircle class="w-6 h-6 text-green-600 inline-block mr-2 -mt-0.5" />
        </span>
        <div>
          <p>
            PayPal is properly configured.
            <BtnPaypalIntegration>
              <span class="text-indigo-600 inline-flex items-center ml-2">
                Reconfigure
                <strong class="ml-1 italic">PayPal</strong>
              </span>
              .
            </BtnPaypalIntegration>
          </p>
          <IntegrationValueItem title="Client ID" value={props.clientId} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <p class="mb-2">Configure PayPal to start taking payments.</p>
      <BtnPaypalIntegration>
        <span class="bg-indigo-600 hover:bg-indigo-500 text-white px-4 py-2 inline-flex items-center justify-center rounded">
          <span class="flex items-center">
            Configure
            <strong class="ml-2 text-xl italic">PayPal</strong>
          </span>
        </span>
      </BtnPaypalIntegration>
    </div>
  );
}

export function PaypalIntegration(props: Props) {
  return (
    <IntegrationRow icon={<IcoPaypal />}>
      <ConnectPage {...props} />
    </IntegrationRow>
  );
}
