import { RouteProps, router } from '@components/router';
import { MessagesNavBar } from './messages-nav-bar';
import { messagesUrl } from './urls';
import { AppRoute } from 'client/lib/app-route/types';
import { useState } from 'preact/hooks';
import { EditorPane } from './editor-pane';
import { NotFound } from './not-found';
import { Message, Updater } from '@components/messages';
import { GuidePage, GuideProductPage } from '@components/guide-page';
import { rpx } from 'client/lib/rpx-client';

const sysStore = rpx.systemMessages;
const store = rpx.messages;

const defaultMessageId = 'welcome';

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const { messageId } = route.params;
  const [course, systemMessages, customMessages] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    sysStore.getSystemMessages({ courseId }),
    store.getMessages({ courseId }),
  ]);

  if (!messageId) {
    router.rewrite(messagesUrl(course, defaultMessageId));
  }

  const messages: Message[] = [];

  systemMessages.forEach((m) => {
    let subject = m.title;

    // We don't have anything other than 'welcome' system messages
    // for products.
    if (course.isProduct && m.type !== 'welcome') {
      return;
    }

    // We want the nav-bar subject for system messages to be a clear
    // indication of the message type, rather than the actual subject.
    if (m.type === 'welcome') {
      subject = 'Welcome';
    } else if (m.type === 'moduleStart') {
      subject = 'Start of Module';
    } else if (m.type === 'invitation') {
      subject = 'Invitation';
    }
    messages.push({
      // On the front end, we use the system message type as the message
      // id (e.g. 'welcome') so that we can have a uniform
      // URL scheme.
      id: m.type,
      subject,
      isSystem: true,
      updatedAt: m.isCustomized ? m.updatedAt : undefined,
      editUrl: messagesUrl(course, m.type),
    });
  });

  customMessages.forEach((m) => {
    messages.push({
      id: m.id,
      subject: m.title,
      isSystem: false,
      updatedAt: m.updatedAt,
      sentAt: m.sentAt,
      editUrl: messagesUrl(course, m.id),
      startDate: m.startDate,
      startOffset: course.accessFormat === 'ondemand' ? m.startOffset : undefined,
    });
  });

  return {
    course,
    messages,
  };
}

type InitialData = Awaited<ReturnType<typeof load>>;

function PageContent({ route, data }: RouteProps<InitialData>) {
  const { course } = data;
  const [messages, setMessages] = useState(data.messages);
  const messageId = route.params.messageId || route.params.messageId || defaultMessageId;
  const message = messages.find((m) => m.id === messageId);
  const onUpdate: Updater = (msg) =>
    setMessages((msgs) => {
      return msgs.map((m) => (m.id === msg.id ? { ...m, ...msg } : m));
    });
  const onCreate = (msg: Message) => setMessages((msgs) => [msg, ...msgs]);
  const onDelete = (id: UUID) => {
    const index = messages.findIndex((m) => m.id === id);
    const nextMsg = messages[index + 1] || messages[0];
    setMessages((msgs) => msgs.filter((m) => m.id !== id));
    router.goto(nextMsg.editUrl);
  };

  const onDuplicate = (msg: Omit<Message, 'editUrl'>) => {
    const newMsg = {
      ...msg,
      editUrl: messagesUrl(course, msg.id),
    };

    onCreate(newMsg);
    router.goto(newMsg.editUrl);
  };

  return (
    <section class="flex flex-grow flex-col md:flex-row">
      <MessagesNavBar
        course={course}
        selectedId={messageId}
        messages={messages}
        onCreate={onDuplicate}
      />
      <div class="p-4 md:p-8 md:pb-16 grow">
        {message && (
          <EditorPane
            course={course}
            messageId={messageId}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
          />
        )}
        {!message && <NotFound />}
      </div>
    </section>
  );
}

function Page(props: RouteProps<InitialData>) {
  const {
    data: { course },
    route,
  } = props;
  const messageId = route.params.messageId || route.params.messageId || defaultMessageId;
  const message = props.data.messages.find((m) => m.id === messageId);

  if (course.isProduct) {
    return (
      <GuideProductPage page="messages" product={course}>
        <PageContent {...props} />
      </GuideProductPage>
    );
  }

  return (
    <GuidePage course={course} documentTitle={message?.subject} type="messages">
      <PageContent {...props} />
    </GuidePage>
  );
}

[
  `manage/courses/:courseId/messages`,
  `manage/courses/:courseId/messages/:messageId`,
  `manage/products/:courseId/messages`,
  `manage/products/:courseId/messages/:messageId`,
].forEach((url) =>
  router.add({
    url,
    load,
    render: Page,
    authLevel: 'guide',
  }),
);
