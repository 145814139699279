import { ComponentChildren } from 'preact';

interface Item {
  key: string;
  title: ComponentChildren;
}

interface Props {
  items: Item[];
  name?: string;
  value: string;
  onChange: (newValue: string) => void;
}

/**
 * A fancy radio group:
 * https://tailwindui.com/components/application-ui/forms/radio-groups#component-45612766894822db447a2837d79355f5
 */
export function RadioGroup({ items, name, value, onChange }: Props) {
  return (
    <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-2">
      {items.map((item) => {
        const isChecked = item.key === value;

        return (
          <label
            key={item.key}
            class={`border rounded-md py-3 px-3 dark:border-transparent flex items-center justify-center text-sm font-medium sm:flex-1 cursor-pointer focus:outline-none dark:hover:bg-green-500 dark:hover:text-white ${
              isChecked
                ? 'border-transparent bg-theme-primary bg-indigo-600 dark:bg-green-500 text-white'
                : 'dark:text-gray-200 dark:bg-gray-700'
            }`}
          >
            <input
              type="radio"
              name={name}
              value={item.key}
              checked={isChecked}
              class="sr-only"
              onChange={() => onChange(item.key)}
            />
            {item.title}
          </label>
        );
      })}
    </div>
  );
}
