import { Dispatch } from 'client/lib/hooks';
import { produce } from 'immer';
import { Reducer } from 'preact/hooks';
import { Comment } from 'server/types';
import { createContext } from 'preact';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { unique } from 'shared/utils';

const store = rpx.comments;

export type Dispatcher = Dispatch<Action>;
type DiscussionContextT = [State, Dispatcher];

export interface State {
  /**
   * The id of the discussion whose comments are being displayed.
   */
  discussionId: UUID;

  /**
   * The id of the course guide.
   */
  guideId: UUID;

  /**
   * The ordered list of root comment ids.
   */
  rootCommentIds: UUID[];

  /*
   * All the parent and sub comments are stored in this <id, Comment> dictionary.
   */
  comments: Record<UUID, Comment>;

  /**
   * The cursor returned from the previous fetch.
   */
  cursor?: string;

  /*
   * This indicates whether the feed is fully loaded or not.
   */
  hasMore: boolean;

  /*
   * This is true when we're loading the initial or next page of the feed.
   */
  loading: boolean;

  /*
   * This removes any links to the people page when true.
   */
  hidePeople: boolean;
}

const PAGE_SIZE = 50;

export const initialState: State = {
  discussionId: '',
  guideId: '',
  comments: {},
  rootCommentIds: [],
  hasMore: false,
  loading: false,
  hidePeople: false,
};

export const DiscussionContext = createContext<DiscussionContextT>([initialState, () => {}]);

export type Action =
  | {
      type: 'loaded';
      payload: {
        comments: Comment[];
        hasMore?: boolean;
        cursor: State['cursor'];
      };
    }
  | { type: 'saved'; payload: Comment }
  | { type: 'deleted'; payload: UUID }
  | {
      type: 'toggleLike';
      payload: {
        commentId: UUID;
        isLiked: boolean;
      };
    }
  | { type: 'toggleLoading'; payload: boolean };

export const reducer: Reducer<State, Action> = produce((state: State, action: Action) => {
  switch (action.type) {
    case 'deleted': {
      const id = action.payload;
      const comment = state.comments[id];
      const parent = comment.parentId && state.comments[comment.parentId];
      delete state.comments[id];

      // Remove the id from parent comment if this is a reply.
      // Or remove the id from the root comment ids.
      const refs = parent ? parent.replyIds : state.rootCommentIds;
      if (refs) {
        refs.splice(refs.indexOf(id), 1);
      }

      break;
    }
    case 'saved': {
      const comment = action.payload;
      const isNew = !state.comments[comment.id];
      const parent = comment.parentId && state.comments[comment.parentId];
      state.comments[comment.id] = comment;

      // Insert the comment id if this is a new comment.
      if (isNew) {
        if (!parent) {
          state.rootCommentIds.unshift(comment.id);
          return;
        }

        if (parent.replyIds) {
          parent.replyIds.push(comment.id);
        } else {
          parent.replyIds = [comment.id];
        }
      }

      break;
    }
    case 'loaded': {
      const { comments, hasMore, cursor } = action.payload;

      state.rootCommentIds = unique([
        ...state.rootCommentIds,
        // Extracts reply ids
        ...comments.filter((r) => !r.parentId).map((r) => r.id),
      ]);

      // Convert our array of comments into a dictionary of comments.
      state.comments = comments.reduce((acc, r) => {
        acc[r.id] = r;
        return acc;
      }, state.comments);

      if (hasMore !== undefined) {
        state.hasMore = hasMore;
      }

      state.cursor = cursor;

      break;
    }
    case 'toggleLike': {
      const { commentId, isLiked } = action.payload;
      const comment = state.comments[commentId];

      comment.isLiked = isLiked;
      comment.likeCount += isLiked ? 1 : -1;

      break;
    }
    case 'toggleLoading': {
      state.loading = action.payload;
      break;
    }
  }
});

/**
 * Loads next page of the comments.
 */
export async function loadNextPage(state: State, dispatch: Dispatcher) {
  const { discussionId } = state;
  dispatch({
    type: 'toggleLoading',
    payload: true,
  });

  try {
    const result = await store.getComments({
      discussionId,
      limit: PAGE_SIZE,
      cursor: state.cursor,
    });
    dispatch({
      type: 'loaded',
      payload: {
        comments: result.comments,
        hasMore: result.hasMore,
        cursor: result.cursor,
      },
    });
  } catch (err) {
    if (err.data?.type !== 'noPreview') {
      showError(err);
    }
  } finally {
    dispatch({
      type: 'toggleLoading',
      payload: false,
    });
  }
}
