import { showError } from '@components/app-error';
import { useBasicAutosaver } from '@components/autosaver';
import { BtnCopy, Button } from '@components/buttons';
import { showDialog } from '@components/dialog';
import { IcoRefresh, IcoShow } from '@components/icons';
import {
  BlockEditor,
  BlockSettings,
  BlockToolbar,
  ErrHandler,
  NewSectionModal,
  RichTextWrapper,
  SalesBlockDefinition,
  scrollToSelectedSection,
} from '@components/page-builder';
import { PageNavBar, PageNavBody } from '@components/page-nav-bar';
import { SaveStatus } from '@components/save-status';
import { showToast } from '@components/toaster';
import { UndoNotice } from '@components/undo-notice';
import { isNetworkError } from 'client/lib/ajax';
import { substateUpdater, useDisposableMemo, useUndoRedo } from 'client/lib/hooks';
import { on } from 'minidoc-editor';
import { ComponentChildren } from 'preact';
import { Dispatch, StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import { SalesBlockState, SalespageContent } from 'server/types';
import { Color } from 'shared/colors';
import { dissoc } from 'shared/utils';

export interface SalesPageState<T = SalespageContent> {
  content: T;
  selectedId: string;
}

interface Props {
  isProduct?: boolean;
  title: string;
  publicURL: string;
  state: SalesPageState;
  setState: Dispatch<StateUpdater<SalesPageState>>;
  onReset(): void;
  blockDefinitions: Record<string, SalesBlockDefinition>;
  isDirty: boolean;
  isConnected: boolean;
  header?: ComponentChildren;
}

export function usePageAutosaver({
  content,
  onSave,
}: {
  content: SalesPageState['content'];
  onSave(opts: Pick<SalesPageState, 'content'>): Promise<unknown>;
}) {
  return useBasicAutosaver(content, (val) => {
    const promise = onSave({ content: val });

    promise.catch((err) => {
      if (!isNetworkError(err)) {
        showError(err);
      }
    });

    return promise;
  });
}

export function PageEditor({
  title,
  state,
  isProduct,
  setState,
  onReset,
  isDirty,
  isConnected,
  blockDefinitions,
  publicURL,
  header,
}: Props) {
  const { content, selectedId } = state;
  const [showUndoNotice, setShowUndoNotice] = useState(false);
  const undoRedo = useUndoRedo(state, setState);
  const [showNewSectionModal, setShowNewSectionModal] = useState<undefined | { blockId?: any }>();

  /**
   * Resets the course sales page to the original placeholder default state.
   */
  async function onResetSalesPage() {
    const lowerTitle = title.toLocaleLowerCase();
    const confirmed = await showDialog({
      mode: 'warn',
      title: `Reset ${lowerTitle} content?`,
      children: `This will delete all of your ${lowerTitle} content.`,
      confirmButtonText: `Yes, reset my ${lowerTitle}`,
      cancelButtonText: 'Cancel',
    });

    if (!confirmed) {
      return;
    }

    try {
      onReset();
      showToast({
        type: 'ok',
        title: `${title} reset`,
        message: `The ${lowerTitle} has been reset successfully.`,
      });
    } catch (err) {
      showError(err);
    }
  }

  useEffect(() => {
    if (!showUndoNotice) {
      return;
    }
    const timeout = setTimeout(() => setShowUndoNotice(false), 2000);
    return () => clearTimeout(timeout);
  }, [showUndoNotice]);

  const setSelectedId = useMemo(
    () => (id: string) => setState((s) => ({ ...s, selectedId: id })),
    [setState],
  );

  useEffect(() => {
    setTimeout(() => scrollToSelectedSection({ force: false }));
  }, [selectedId]);

  const setContent = useMemo(
    () =>
      substateUpdater(
        setState,
        (s) => s.content,
        (s, sub) => ({ ...s, content: sub }),
      ),
    [setState],
  );

  useEffect(() => {
    if (selectedId && !content.blocks[selectedId]) {
      setSelectedId('');
    }
  }, [content, selectedId]);

  const deleteBlock = async (id: string) => {
    const block = content.blocks[id];
    const def = blockDefinitions[block?.type];
    const allowDelete = def?.onDelete && (await def.onDelete({ state: block }));

    if (allowDelete === false) {
      return;
    }

    setSelectedId('');
    setContent((s) => ({
      ...s,
      blockIds: s.blockIds.filter((x) => id !== x),
      blocks: dissoc(id, s.blocks),
    }));
    setShowUndoNotice(true);
  };

  const newId = () => `${Date.now().toString(16)}-${content.blockIds.length.toString(16)}`;

  const insertBlock = (aboveId: string | undefined, state: SalesBlockState<any>) => {
    setContent((s) => {
      const blockIds = [...s.blockIds];
      const insertAtIndex = aboveId ? Math.max(0, blockIds.indexOf(aboveId)) : blockIds.length;
      blockIds.splice(insertAtIndex, 0, state.id);
      return {
        ...s,
        blockIds,
        blocks: {
          ...s.blocks,
          [state.id]: state,
        },
      };
    });

    setSelectedId(state.id);
    setTimeout(() => scrollToSelectedSection({ force: true }));
  };

  const cloneBlock = async (cloneId: string) => {
    const block = content.blocks[cloneId];
    const afterId = content.blockIds[content.blockIds.indexOf(cloneId) + 1];
    insertBlock(afterId, { ...block, id: newId() });
  };

  const onKey = useDisposableMemo(() => {
    const result = {
      Delete: () => {},
      Escape: () => {},
      dispose: on(document, 'keydown', (e) => {
        const me = result as any;
        if (me[e.code] && e.target === document.body) {
          me[e.code]();
        }
      }),
    };

    return result;
  }, []);

  onKey.Escape = () => selectedId && setSelectedId('');
  onKey.Delete = () => selectedId && deleteBlock(selectedId);

  const addBlock = (aboveId: string | undefined, type: string) => {
    const def = blockDefinitions[type];
    const id = newId();
    const val: SalesBlockState<any> = {
      id,
      type: def.type,
      state: def.initialState,
    };

    if (!def.initialBlockState && content.blockIds.length) {
      const prevBg = content.blocks[content.blockIds[content.blockIds.length - 1]]?.bgcolor;
      if (!prevBg) {
        val.bgcolor = Color.gray50;
      }
    }

    insertBlock(aboveId, val);
  };

  const moveBlock = (id: string, direction: number) => {
    function swap(blockIds: string[]): string[] {
      const moveFrom = blockIds.indexOf(id);
      const moveTo = Math.min(Math.max(0, moveFrom + direction), blockIds.length - 1);
      const result = [...blockIds];
      const tmp = result[moveFrom];
      result[moveFrom] = result[moveTo];
      result[moveTo] = tmp;
      return result;
    }

    const el = document.querySelector<HTMLDivElement>('.sales-page-active-block');
    const wrapper = el?.closest<HTMLDivElement>('.salespage-wrapper, .overflow-auto');
    if (!el || !wrapper) {
      return;
    }
    const scrollTop = el.offsetTop;
    setContent((s) => ({ ...s, blockIds: swap(s.blockIds) }));

    // Smooth-scroll the element into view.
    setTimeout(() => {
      if (!el || scrollTop == el.offsetTop) {
        return;
      }

      // Restore the scroll position, so we can smooth-scroll to the new one.
      if (scrollTop < el.offsetTop) {
        wrapper.scrollTo({ top: scrollTop });
      } else {
        wrapper.scrollTo({ top: scrollTop + el.offsetHeight });
      }

      setTimeout(scrollToSelectedSection);
    });
  };

  const selectedPos = content.blockIds.indexOf(selectedId!);
  const selectedState = content.blocks[content.blockIds[selectedPos]];
  const selectedDefinition = selectedState && blockDefinitions[selectedState.type];

  return (
    <section class="flex flex-col md:flex-row flex-grow max-h-screen">
      <PageNavBar title={title}>
        <PageNavBody>
          {selectedPos >= 0 && (
            <BlockSettings
              key={selectedId}
              definition={selectedDefinition}
              blockState={selectedState}
              isFirst={selectedPos === 0}
              onDelete={() => deleteBlock(selectedId)}
              onBlockStateChange={(v) => {
                setContent((s) => {
                  const newValue = typeof v === 'function' ? v(s.blocks[selectedState.id]) : v;
                  return {
                    ...s,
                    blocks: {
                      ...s.blocks,
                      [newValue.id]: newValue,
                    },
                  };
                });
              }}
            />
          )}
        </PageNavBody>
      </PageNavBar>
      <div class="bg-white shadow-md rounded-lg flex flex-col grow sales-page text-base max-w-full text-gray-700 relative border m-8">
        {showNewSectionModal && (
          <NewSectionModal
            onHide={() => setShowNewSectionModal(undefined)}
            definitions={blockDefinitions}
            onInsert={(type) => {
              addBlock(showNewSectionModal.blockId, type);
              setShowNewSectionModal(undefined);
            }}
          />
        )}

        <div class="bg-gray-100 flex justify-between px-4 rounded-t-lg border-b">
          <div class="pt-4 pb-3 mr-4 text-sm flex-0 whitespace-no-wrap">
            <i class="mx-1 rounded-full w-3 h-3 bg-red-400 inline-block"></i>
            <i class="mx-1 rounded-full w-3 h-3 bg-yellow-400 inline-block"></i>
            <i class="mx-1 rounded-full w-3 h-3 bg-green-400 inline-block"></i>
            <span class="ml-4 inline-flex items-center">
              <span class="font-semibold">{title}</span>
              <SaveStatus isDirty={isDirty} isConnected={isConnected} />
            </span>
            {header}
          </div>
          <nav class="flex items-center gap-4 text-sm">
            <Button
              class="inline-flex items-center gap-2 text-inherit hover:bg-gray-200 p-2 px-3 rounded capitalize"
              onClick={onResetSalesPage}
            >
              <IcoRefresh />
              Reset {title}
            </Button>

            <BtnCopy
              class="border-none hover:bg-gray-200 p-2 px-3 rounded"
              margin="margin-none"
              copiedText="Link copied"
              value={publicURL}
            >
              Share Link
            </BtnCopy>
            <Button
              class="inline-flex items-center gap-2 text-inherit hover:bg-gray-200 p-2 px-3 rounded"
              href={publicURL}
            >
              <IcoShow />
              <span>{isProduct ? `Customer` : `Student`} View</span>
            </Button>
          </nav>
        </div>
        {selectedPos >= 0 && (
          <BlockToolbar
            isFirst={selectedPos === 0}
            definition={selectedDefinition}
            definitions={blockDefinitions}
            onMove={moveBlock}
            onClone={cloneBlock}
            position={selectedPos}
            onAddBlock={() => setShowNewSectionModal({ blockId: selectedId })}
            setState={setContent}
            state={selectedState}
          />
        )}
        <RichTextWrapper onRedo={undoRedo.redo} onUndo={undoRedo.undo}>
          {content.blockIds.map((id, i) => {
            const blockState = content.blocks[id];
            const definition = blockDefinitions[blockState.type];
            return (
              <ErrHandler key={id} onRemove={() => deleteBlock(id)}>
                <BlockEditor
                  isFirst={i === 0}
                  definition={definition}
                  definitions={blockDefinitions}
                  onMove={moveBlock}
                  position={i}
                  nextSkewed={content.blocks[content.blockIds[i + 1] || '']?.skewed}
                  isSelected={id === selectedId}
                  onAddBlock={() => setShowNewSectionModal({ blockId: id })}
                  onSelect={() => setSelectedId(id)}
                  setState={setContent}
                  state={blockState}
                />
              </ErrHandler>
            );
          })}
        </RichTextWrapper>
        <Button
          class="w-full bg-gray-100 p-4 hover:bg-gray-200 rounded-b-lg"
          onClick={() => setShowNewSectionModal({})}
        >
          + Add Section
        </Button>
        <UndoNotice
          displayed={showUndoNotice}
          text="Section deleted."
          hide={() => setShowUndoNotice(false)}
          onClick={undoRedo.undo}
        />
      </div>
    </section>
  );
}
