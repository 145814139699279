import { highlightTerms } from './utils';
import { IcoPencil, IcoSettings, IcoUsers } from '@components/icons';
import { useImageUrl } from 'client/utils/cdn';
import dayjs from 'dayjs';
import { Course } from './types';
import { UserProfileIcon } from '@components/avatars';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { hasLevel } from 'shared/auth';
import { ProgressBar } from '@components/progress-bar';
import { URLS } from 'shared/urls';
import { useCourseGuideMenu } from '@components/guide-course-menu/use-course-guide-menu';
import { Button } from '@components/buttons';
import { courseLabel } from 'shared/terminology';
import { useIntl } from 'shared/intl/use-intl';
import { ListType } from './course-list';
import { truncateId } from 'shared/utils';

interface Props {
  course: Course;
  displayDate: 'createdAt' | 'lastOpenedAt';
  highlightTerm: string;
  readonly?: boolean;
  onClick?: () => void;
  isArchived?: boolean;
  listType: ListType;
}

function formatDate(date: Date) {
  const now = dayjs();
  let format = 'h:mm A';
  if (!now.isSame(date, 'year')) {
    format = 'MMM, YYYY';
  } else if (!now.isSame(date, 'day')) {
    format = 'MMM D';
  }
  return dayjs(date).format(format);
}

function CourseItem({
  course,
  highlightTerm,
  displayDate,
  listType,
  readonly,
  onClick,
  isArchived,
}: Props) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const isGuideAccount = hasLevel(useCurrentUser(), 'guide');
  const { prefix, highlight, suffix } = highlightTerms(course, highlightTerm);
  const src = useImageUrl(course.imagePath);
  const isDraftLike = course.status === 'draft' && !course.numStudents;
  const isEditable = course.level === 'guide' || course.level === 'facilitator';

  const showMenu = useCourseGuideMenu({
    course,
    listenCtrlKey: false,
  });

  let href: string;
  if (isEditable) {
    href =
      !course.isBundle && (isDraftLike || !course.numStudents)
        ? URLS.guide.lessons({
            courseId: course.id,
            isProduct: course.isProduct,
          })
        : URLS.guide.course({
            courseId: course.id,
            isProduct: course.isProduct,
          });
  } else {
    href = URLS.student.course({
      course,
    });
  }

  if (listType === 'list') {
    return (
      <span class="flex hover:bg-gray-50 hover:shadow rounded-md overflow-hidden">
        <Button
          class="flex gap-4 text-inherit flex-grow relative group"
          href={onClick ? undefined : href}
          onClick={onClick}
        >
          <span class="flex relative items-center justify-center text-center w-20 min-w-20 bg-gray-100 border-b overflow-hidden">
            <span class={isArchived ? 'grayscale opacity-50' : ''}>
              {src && <img src={`${src}?width=600`} class="object-cover" />}
              {!src && <span class="opacity-50">{intl('No image')}</span>}
            </span>
            {isArchived && (
              <span class="absolute inset-x w-full bottom-0 bg-gray-800/75 text-white">
                {intl('Archived')}
              </span>
            )}
          </span>

          <span class="flex flex-col gap-2 p-2">
            <strong class="text-sm line-clamp-1">
              <span>{prefix}</span>
              {highlight && <span class="font-bold bg-yellow-200">{highlight}</span>}
              {suffix && <span>{suffix}</span>}
            </strong>
            <span class="flex items-center text-sm divide-x">
              <span class="px-3 flex items-center gap-3">
                <UserProfileIcon user={course.guide} size="w-8 h-8" />
                <span class="inline-flex flex-col">
                  <span>{course.guide.name}</span>
                </span>
              </span>

              {isEditable && (
                <>
                  <span class="px-3">
                    {course.isProduct ? '' : tenant.terminology.Course} {truncateId(course.id)}{' '}
                  </span>
                  <span class="px-3">
                    {course.status === 'published' ? intl('Signups open') : intl('Signups closed')}
                  </span>
                  {(!isDraftLike || !!course.numStudents) && (
                    <span class="px-3 inline-flex items-center">
                      <IcoUsers class="w-4 h-4 opacity-75 mr-0.5" />
                      {(course.numStudents || 0).toLocaleString()}
                    </span>
                  )}
                </>
              )}
            </span>
          </span>
          <span class="hidden items-center text-gray-500 text-sm ml-auto pr-3 gap-4 sm:flex">
            <CourseDate displayDate={displayDate} course={course} intl={intl} />
          </span>
        </Button>

        {!readonly && isEditable && (
          <Button
            class="text-gray-500 rounded-r-md p-2 hover:bg-gray-200"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              showMenu();
            }}
          >
            <IcoSettings class="w-5 h-5" />
          </Button>
        )}
      </span>
    );
  }

  return (
    <span
      class={`relative inline-block border ${
        isEditable ? 'border-green-300' : ''
      } hover:ring-1 ring-indigo-600 hover:border-indigo-600 rounded-lg`}
    >
      <Button
        class="flex flex-col rounded-md text-inherit w-full group text-left"
        href={onClick ? undefined : href}
        onClick={onClick}
      >
        {isEditable && !readonly && (
          <span class="absolute -top-3 left-0 right-0 text-center z-10">
            <span
              class={`px-2 text-sm text-gray-700 text-opacity-75 ${
                isDraftLike ? 'bg-gray-50' : 'bg-green-50 border-green-300 text-green-700'
              }  border rounded-xl inline-flex items-center shadow-lg`}
            >
              <IcoPencil class="w-4 h-4 mr-1 opacity-75" />
              Edit {isDraftLike ? ' draft ' : ''}{' '}
              {courseLabel({
                course,
                tenant,
              })}
            </span>
          </span>
        )}
        <span class="relative w-full overflow-hidden aspect-square bg-gray-100 flex items-center justify-center text-center rounded-t-md">
          <span class={isArchived ? 'grayscale opacity-50' : ''}>
            {src && <img src={`${src}?width=600`} class="object-cover" />}
            {!src && <span class="opacity-50">{intl('No image')}</span>}
          </span>
          {isArchived && (
            <span class="absolute inset-x w-full bottom-0 bg-gray-800/75 text-white p-2">
              {intl('Archived')}
            </span>
          )}
          {isEditable && (
            <span class="absolute bottom-0 left-4 right-4 bg-white rounded-t-md px-2 text-xs opacity-0 group-hover:opacity-100 transition-opacity border border-b-0 shadow">
              {course.isProduct ? 'id:' : tenant.terminology.Course} {truncateId(course.id)}{' '}
            </span>
          )}
        </span>
        <span class="flex flex-col">
          <span class="flex items-center px-4 my-4 text-sm border-b border-dashed pb-4 relative">
            <UserProfileIcon user={course.guide} size="w-8 h-8" />
            <span class="ml-3 inline-flex flex-col">
              <span class="opacity-50 text-sm leading-4">{intl('Guide')}</span>
              <span>{course.guide.name}</span>
            </span>
          </span>
          <span class="flex items-center h-12 px-4 mb-2">
            <strong class="text-sm line-clamp-2 max-h-full">
              <span>{prefix}</span>
              {highlight && <span class="font-bold bg-yellow-200">{highlight}</span>}
              {suffix && <span>{suffix}</span>}
            </strong>
          </span>
          <span class="flex items-center text-gray-500 px-4 mb-4 text-sm">
            <CourseDate displayDate={displayDate} course={course} intl={intl} />
          </span>
          {!isEditable && isGuideAccount && (
            <span
              class="flex flex-grow items-center flex-col border-t border-dashed py-6 px-4 relative"
              data-tooltip={`${course.progress || 0}% complete`}
            >
              <ProgressBar
                progress={course.progress || 0}
                showPercent={false}
                barClass="bg-indigo-400"
                bgClass="bg-gray-50"
              />
            </span>
          )}
          {isEditable && (
            <span class="inline-flex justify-between border-t border-dashed py-4 px-4 opacity-75">
              <span class="inline-flex rounded text-sm overflow-hidden items-center">
                <span class="pr-3">
                  {course.status === 'published' ? intl('Signups open') : intl('Signups closed')}
                </span>
                {(!isDraftLike || !!course.numStudents) && (
                  <span class="px-3 inline-flex items-center border-l">
                    <IcoUsers class="w-4 h-4 opacity-75 mr-0.5" />
                    {(course.numStudents || 0).toLocaleString()}
                  </span>
                )}
              </span>
              {!readonly && isEditable && (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    showMenu();
                  }}
                >
                  <IcoSettings class="w-7 h-7 hover:bg-gray-200 text-gray-500 rounded-full p-1" />
                </Button>
              )}
            </span>
          )}
        </span>
      </Button>
    </span>
  );
}

function CourseDate({
  displayDate,
  course,
  intl,
}: {
  displayDate: 'lastOpenedAt' | 'createdAt';
  course: Course;
  intl: ReturnType<typeof useIntl>;
}) {
  return (
    <span class="text-gray-500">
      {displayDate === 'lastOpenedAt'
        ? intl('Last opened {date:string}', {
            date: formatDate(course.lastOpenedAt),
          })
        : intl('Created {date:string}', {
            date: formatDate(course.createdAt),
          })}
    </span>
  );
}

export default CourseItem;
