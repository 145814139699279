import { GuidePage } from '@components/guide-page';
import { CourseStats } from './course-stats';
import { URLS } from 'shared/urls';
import { Subcourses } from './subcourses';
import { Button } from '@components/buttons';
import { UserProfileIcon } from '@components/avatars';
import { timeago } from 'shared/dateutil';
import { OverviewPageData } from './index';
import { FullCourse } from 'server/types';
import { IcoClock } from '@components/icons';
import { Case } from '@components/conditional';

interface Props {
  course: FullCourse;
  signupsData: OverviewPageData['recentSignups'];
}

export function BundleOverview(props: Props) {
  const { course, signupsData } = props;

  return (
    <GuidePage course={course} type="dashboard">
      <div class="flex flex-col w-full max-w-7xl mx-auto p-8 gap-6">
        <div class="flex flex-col grow space-y-8">
          <Case when={signupsData.signups.length > 0}>
            <div class="grid grid-cols-1 lg:grid-cols-2 pb-4">
              <CourseStats class="pr-8" course={course} hideLessons />
              <div class="w-1/2 md:pl-8 md:border-l">
                <div class="flex items-center space-x-2">
                  <h2 class="text-xl font-medium text-zinc-900 capitalize pl-4">Recent Signups</h2>
                  {signupsData.hasMore && (
                    <a
                      href={URLS.guide.coursePage({
                        courseId: course.id,
                        page: 'students',
                      })}
                    >
                      View all
                    </a>
                  )}
                </div>
                {signupsData.signups.map((signup) => (
                  <Button
                    key={signup.user.id}
                    class="flex items-center p-6 py-4 space-x-4 hover:bg-zinc-50"
                    href={URLS.guide.student({
                      courseId: course.id,
                      userId: signup.user.id,
                    })}
                  >
                    <div class="flex-shrink-0">
                      <UserProfileIcon user={signup.user} size="h-10 w-10" />
                    </div>
                    <div class="flex-1 min-w-0">
                      <p class="text-sm font-medium text-gray-900 truncate">{signup.user.name}</p>
                      <dl class="flex space-x-2 text-xs text-zinc-500 dark:text-zinc-200">
                        <dd class="hidden md:flex items-center space-x-1">
                          <IcoClock class="h-4 w-4" />
                          <span>{timeago(signup.date)}</span>
                        </dd>
                      </dl>
                    </div>
                  </Button>
                ))}
              </div>
            </div>
          </Case>
          <Subcourses
            bundle={course}
            hasStudents={!!course.numStudents && course.numStudents > 0}
          />
        </div>
      </div>
    </GuidePage>
  );
}
