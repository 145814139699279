import { FixedContent, FixedPage } from '@components/fixed-page';
import { TopBar } from '@components/top-bar';
import { MyCoursesPageWrapper } from '../user-my-courses/my-courses-page-wrapper';
import { ComponentChildren } from 'preact';

export function UpsellsPage({
  title = 'Upsells',
  children,
  buttons,
}: {
  title?: string;
  children: ComponentChildren;
  buttons?: ComponentChildren;
}) {
  return (
    <FixedPage title={title}>
      <FixedContent class="bg-white">
        <TopBar />
        <MyCoursesPageWrapper tab="upsells" buttons={buttons}>
          {children}
        </MyCoursesPageWrapper>
      </FixedContent>
    </FixedPage>
  );
}
