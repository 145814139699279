import { AsyncForm, FormGroup, InputLabel } from '@components/async-form';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { DateInputWithTimezone, TimezoneDiff } from '@components/date-picker';
import { MeetingDuration } from '@components/duration-select';
import { HeadingPrimary } from '@components/headings';
import { useRouteParams } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { Meeting } from 'server/types';
import { handleZoomIntegrationError } from './helpers';
import { MeetingTypeData, MeetingTypes } from './type-tab';
import { meetingUrl } from './urls';

interface Props {
  onCreate: (meeting: Meeting) => void;
}

interface FormData {
  title: Meeting['title'];
  durationMinutes: number;
  scheduledAt?: Date;
}

export function NewMeetingForm({ onCreate }: Props) {
  const { courseId } = useRouteParams();
  const { terminology } = useCurrentTenant();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    durationMinutes: 30,
    scheduledAt: undefined,
  });
  const [typeData, setTypeData] = useState<MeetingTypeData>({
    type: 'videoconference',
    slidesFile: undefined,
    recordMeeting: true,
    allowRecordingDownloads: true,
    zoomHasWaitingRoom: false,
  });

  async function createMeeting() {
    setIsSubmitting(true);
    try {
      const result = await rpx.meetings.createMeeting({
        courseId,
        title: formData.title,
        scheduledAt: formData.scheduledAt?.toISOString(),
        durationMinutes: formData.durationMinutes,
        type: typeData.type,
        slidesFile: typeData.slidesFile,
        recordMeeting: typeData.recordMeeting,
        allowRecordingDownloads: typeData.allowRecordingDownloads,
        zoomHasWaitingRoom: typeData.zoomHasWaitingRoom,
        // This field is an optional url.
        // So I'm sending `undefined` if it's empty
        // to skip the url validation.
        externalJoinUrl: typeData.externalJoinUrl || undefined,
      });
      onCreate(result);
    } catch (err) {
      const isHandled = await handleZoomIntegrationError({
        err,
        courseId,
      });
      if (!isHandled) {
        throw err;
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <AsyncForm class="flex flex-col py-8 w-full max-w-5xl mx-auto" onSubmit={createMeeting}>
      <HeadingPrimary title={`New ${terminology.meeting}`} />
      <FormGroup prop="title" class="mb-6">
        <InputLabel>Title</InputLabel>
        <input
          type="text"
          placeholder={`${terminology.Meeting} title`}
          name="title"
          class="inline-ruz-input w-1/2"
          onChange={(e: any) => setFormData((s) => ({ ...s, title: e.target.value }))}
        />
      </FormGroup>
      <FormGroup class="mb-6" prop="scheduledAt">
        <InputLabel>Scheduled Start</InputLabel>
        <div>
          <DateInputWithTimezone
            name="scheduledAt"
            includeTime
            placeholder="Scheduled start date"
            value={formData.scheduledAt}
            onChange={(newDate) => {
              // Reset seconds and ms value as we don't
              // display anything after the minute value
              newDate?.setSeconds(0, 0);
              setFormData((s) => ({ ...s, scheduledAt: newDate }));
            }}
          />
        </div>
        <TimezoneDiff date={formData.scheduledAt} class="mt-2" />
      </FormGroup>
      <FormGroup class="mb-6" prop="durationMinutes">
        <InputLabel>Duration</InputLabel>
        <span class="text-sm">
          This is the expected duration of the {terminology.meeting}. You can always go over the
          expected time up to 4 hours per {terminology.meeting}.
        </span>
        <div class="mt-2">
          <MeetingDuration
            name="durationMinutes"
            value={formData.durationMinutes}
            onNewDuration={(newDuration) =>
              setFormData((s) => ({ ...s, durationMinutes: newDuration }))
            }
          />
        </div>
      </FormGroup>
      <FormGroup class="mb-6" prop="type">
        <InputLabel>Choose the {terminology.meeting} type</InputLabel>
        <span class="text-sm">
          You may update the {terminology.meeting} details and upload presentations later.
        </span>
        <MeetingTypes
          meeting={typeData}
          canEditDetails
          onChange={(updates) => setTypeData({ ...typeData, ...updates })}
          onSlideDelete={() => setTypeData({ ...typeData, slidesFile: undefined })}
        />
      </FormGroup>
      <footer class="flex mt-2 justify-end">
        <BtnPrimary class="px-8 py-3" isLoading={isSubmitting}>
          Create {terminology.Meeting}
        </BtnPrimary>
        <BtnSecondary type="button" class="ml-4 px-8 py-3" href={meetingUrl({ courseId })}>
          Cancel
        </BtnSecondary>
      </footer>
    </AsyncForm>
  );
}
