import { rpx } from 'client/lib/rpx-client';
import { RouteProps, router } from '@components/router';
import { LoginWrapper } from '@components/login-wrapper';
import { AutoForm, FormGroup, useAutoFormState } from '@components/async-form';
import { Password } from '@components/password';
import { useAuth, useCurrentTenant } from '@components/router/session-context';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useEffect } from 'preact/hooks';
import { showToast } from '@components/toaster';
import { BtnPrimary } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { AppRoute } from 'client/lib/app-route/types';
import { V1_USER } from 'shared/consts';
import { getRedirectUrl } from 'client/utils/url';

router.add({
  url: 'login',
  load,
  isPublic: true,
  render: Page,
});

async function load(route: AppRoute) {
  const { courseId, brandId } = route.params;
  if (!courseId && !brandId) {
    return { brand: undefined };
  }
  const brand = await rpx.branding.getBrand({ courseId, brandId });
  return { brand };
}

type Data = Awaited<ReturnType<typeof load>>;
type Props = RouteProps<Data>;

function submitV1Login(data: any) {
  const doc = document;
  // Don't send the referrer info to v1, so it assumes we're app.ruzuku.com.
  doc.head.insertAdjacentHTML('beforeend', '<meta name="referrer" content="never" />');
  // Inject the v1 login form.
  doc.body.insertAdjacentHTML(
    'beforeend',
    `
    <form style="display:none" id="v1loginform" method="POST" action="https://app.ruzuku.com/session">
      <input name="login" />
      <input name="password" />
      <button>Submit</button>
    </form>
  `,
  );

  // Fill out and submit the v1 login form.
  doc.querySelector<HTMLInputElement>('#v1loginform input[name=login]')!.value = data.email;
  doc.querySelector<HTMLInputElement>('#v1loginform input[name=password]')!.value = data.password;
  doc.querySelector<HTMLFormElement>('#v1loginform')!.submit();
}

function Page(props: Props) {
  const intl = useIntl();
  const auth = useAuth();
  const tenant = useCurrentTenant();
  const emailParam = props.route.params.email;
  const [state, ctx] = useAutoFormState({
    initialState: {
      email: emailParam || '',
      password: '',
    },
  });

  useDocumentTitle([
    intl('Sign in to {tenant:string}', {
      tenant: tenant.name,
    }),
  ]);

  async function login(data: any) {
    try {
      await auth.login(rpx.auth.login(data));
      router.goto(getRedirectUrl());
      // Keep the form spinning while we redirect the SPA.
      await new Promise((r) => setTimeout(r, 1000));
    } catch (err) {
      if (err?.data === V1_USER) {
        submitV1Login(data);
        // Keep the form spinning while we redirect to v1.
        return new Promise(() => {});
      }
      throw err;
    }
  }

  useEffect(() => {
    if (emailParam) {
      showToast({
        type: 'warn',
        title: intl('Email address already exists'),
        message: intl(
          `You will get access to the course creation tools as soon as you log in. You may use "Forgot your password?" button if you don't remember your password.`,
        ),
        timeout: 60000,
      });
    }
  }, [emailParam]);

  /*
   * Users may sometimes be redirected to the login page even if
   * they have an active session. So we're checking that here
   * and redirecting them to `redirect` URL or home page if the session exists.
   */
  useAsyncEffect(async () => {
    if (auth.user) {
      router.goto(getRedirectUrl());
    }
  }, []);

  return (
    <LoginWrapper brand={props.data.brand}>
      <AutoForm onSubmit={login} ctx={ctx}>
        <div class="space-y-6 py-4">
          <FormGroup prop="email" class="w-full mb-2">
            <input
              type="text"
              placeholder={intl('email')}
              name="email"
              defaultValue={emailParam}
              value={state.email}
              class="ruz-input block w-full sm:text-sm sm:leading-5 bg-transparent"
              data-private
            />
          </FormGroup>
          <FormGroup prop="password" class="w-full mb-3">
            <Password name="password" placeholder={intl('password')} class="bg-transparent" />
          </FormGroup>
          <BtnPrimary class="w-full py-3" isLoading={ctx.isProcessing}>
            {intl('Sign in')}
          </BtnPrimary>
        </div>
      </AutoForm>

      <footer class="mt-8 pt-4 text-center block border-t border-gray-200">
        <a href={`/forgot-password?email=${encodeURIComponent(state.email)}`}>
          {intl('Forgot your password?')}
        </a>
      </footer>
    </LoginWrapper>
  );
}
