/**
 * An internal Ruzuku payment management screen.
 */

import { router, useRouteParams } from '@components/router';
import {
  Price,
  Coupon,
  Item,
  defaultFolderId,
  SetState,
  State,
  load,
  useFolderStats,
  Folder,
} from './state';
import { LoadedProps } from 'client/lib/loaders';
import { FixedContent, FixedPage } from '@components/fixed-page';
import { AdminNav, AdminTabWrapper } from '@components/admin-nav';
import { IcoFolder, IcoRefresh, IcoTrash } from '@components/icons';
import { useMemo, useState } from 'preact/hooks';
import { pluralize } from 'shared/formatting';
import { Checkbox } from '@components/checkbox';
import { produce } from 'immer';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { corePrices, reverseRoute } from 'shared/urls';
import { SlideOver } from '@components/slide-over';
import { PriceDetail } from '../components/prices-page';
import { CouponDetail } from '../components/coupons-page';
import { BackButton, CompactMenuItem, CreateMenu, NavBox } from './header-bar';
import * as urls from './urls';
import { Row } from './rows';
import { useRecentlyViewedTracker } from './recently-viewed';
import { showNewCouponModal } from './new-coupon-modal';
import { showNewPriceModal } from './new-price-modal';
import { showArchiveItemsModal } from './archive-items-modal';
import { showRenameFolderModal } from './rename-folder-modal';
import { showNewFolderModal } from './new-folder-modal';
import { showMoveItemsModal } from './move-items-modal';
import { EmptyRowset } from './empty-rowset';
import { PriceCouponRow } from 'server/types';
import { CORE_INSTANT_COURSES_PRODUCT_ID } from 'shared/ids';

function ArchiveToggleMenuItem({
  visibleItems,
  state,
  setState,
}: {
  visibleItems: Item[];
  state: State;
  setState: SetState;
}) {
  const { action, items } = useMemo(() => {
    const items = (
      state.selected.length
        ? state.selected.map((id) => state.items[id]).filter((x) => !!x)
        : visibleItems
    ) as Array<Coupon | Price>;
    const action: 'archive' | 'activate' = items.every((x) => x.isEnabled) ? 'archive' : 'activate';
    return { items, action };
  }, [state.selected, state.items]);

  return (
    <CompactMenuItem
      Ico={action === 'archive' ? IcoTrash : IcoRefresh}
      text={`${action === 'archive' ? 'Archive' : 'Activate'} ${
        state.selected.length ? '' : 'all'
      } ${items.length} ${pluralize('item', items.length)}`}
      onClick={async () => {
        await showArchiveItemsModal({
          action,
          items,
        });
        setState((s) => {
          items.forEach((x) => {
            const item = s.items[x.id];
            if (item.type === 'price' || item.type === 'coupon') {
              item.isEnabled = action === 'activate';
            }
          });
        });
      }}
    />
  );
}

function MoveMenuItem({ state, setState }: { state: State; setState: SetState }) {
  const { productId } = useRouteParams();

  return (
    <CompactMenuItem
      Ico={IcoFolder}
      text={`Move ${state.selected.length} items`}
      onClick={() =>
        showMoveItemsModal({
          folders: Object.values(state.items).filter((x) => x.type === 'folder') as Folder[],
          productId,
          items: state.selected,
          onMove(toFolder) {
            setState((s) => {
              if (!s.items[toFolder.id]) {
                s.items[toFolder.id] = toFolder;
              }
              state.selected.forEach((itemId) => {
                s.itemToFolder[itemId] = toFolder.id;
              });
            });
            router.goto(location.pathname + `?folderId=${toFolder.id}`);
          },
        })
      }
    />
  );
}

function Page({
  state,
  setState: baseSetState,
  route: { params: routeParams },
}: LoadedProps<typeof load>) {
  const { folderId, couponId, priceId, productId } = routeParams;
  const [searchTerm, setSearchTerm] = useState('');

  const folder = folderId ? (state.items[folderId] as Folder) : undefined;

  useRecentlyViewedTracker({
    folderId,
    productId,
  });

  const folderStats = useFolderStats(state);

  const setState = useMemo(() => {
    const setter = (fn: (state: State) => void) => {
      if (typeof fn === 'function') {
        baseSetState(produce(fn));
      } else {
        baseSetState(fn);
      }
    };

    return setter;
  }, []);

  const visibleItems = useMemo(() => {
    let result: Item[];
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = Object.values(state.items).filter((x) => {
        const name = x.type === 'coupon' ? x.code : x.name;
        return name.toLowerCase().includes(term);
      });
    } else if (folderId) {
      result = Object.entries(state.itemToFolder)
        .filter(([, id]) => id === folderId)
        .map(([id]) => state.items[id]);
    } else {
      result = Object.values(state.items).filter((x) => x.type === 'folder');
    }

    return result.sort((a, b) => {
      const aname = a.type === 'coupon' ? a.code : a.name;
      const bname = b.type === 'coupon' ? b.code : b.name;
      return aname > bname ? 1 : -1;
    });
  }, [folderId, searchTerm, state]);

  useDidUpdateEffect(
    function onNavigation() {
      setSearchTerm('');
      setState((s) => {
        s.selected = [];
      });
    },
    [routeParams],
  );

  const detailItem = useMemo(() => {
    const id = priceId || couponId;
    const item = id ? state.items[id] : undefined;
    if (!item || item.type === 'folder') {
      return;
    }
    if (item.type === 'price') {
      const applyTo = state.priceCoupons
        .filter((pc) => pc.isEnabled && pc.priceId === id)
        .map((pc) => state.items[pc.couponId] as Coupon);
      return { ...item, applyTo };
    } else if (item.type === 'coupon') {
      const applyTo = state.priceCoupons
        .filter((pc) => pc.isEnabled && pc.couponId === id)
        .map((pc) => state.items[pc.priceId] as Price);
      return { ...item, applyTo };
    }
  }, [priceId, couponId, state.items, state.priceCoupons]);

  return (
    <FixedPage>
      {detailItem && detailItem.type === 'price' && (
        <SlideOver
          close={() => {
            router.goto(location.pathname);
          }}
        >
          <PriceDetail
            key={detailItem.id}
            couponUrl={({ couponId }) => `?couponId=${couponId}`}
            checkoutUrl={corePrices.checkoutUrl}
            priceUrl={({ priceId }) => `?priceId=${priceId}`}
            signupUrl={({ userId }) => `/admin/people/${userId}`}
            price={detailItem}
            supportsStripe
            supportsPaypal={false}
            showDisabled
            updatePrice={(id, f) => {
              setState((s) => {
                const price = s.items[id];
                if (price && price.type === 'price') {
                  Object.assign(price, f(price));
                }
              });
            }}
            onCouponsChanged={(result) => {
              setState((s) => {
                const priceCoupons = s.priceCoupons.filter((pc) => pc.priceId != result.priceId);
                s.priceCoupons = priceCoupons.concat(
                  result.applyTo.map((couponId) => ({
                    couponId,
                    priceId: result.priceId,
                    isEnabled: true,
                  })),
                );
              });
            }}
          />
        </SlideOver>
      )}

      {detailItem && detailItem.type === 'coupon' && (
        <SlideOver
          close={() => {
            router.goto(location.pathname);
          }}
        >
          <CouponDetail
            key={detailItem.id}
            couponUrl={({ couponId }) => `?couponId=${couponId}`}
            checkoutUrl={corePrices.checkoutUrl}
            priceUrl={({ priceId }) => `?priceId=${priceId}`}
            signupUrl={({ userId }) => `/admin/people/${userId}`}
            coupon={detailItem}
            showDisabled
            updateCoupon={(id, f) => {
              setState((s) => {
                const coupon = s.items[id];
                if (coupon && coupon.type === 'coupon') {
                  Object.assign(coupon, f(coupon));
                }
              });
            }}
            onPricesChanged={(result) => {
              setState((s) => {
                const priceCoupons = s.priceCoupons.filter((pc) => pc.couponId != result.couponId);
                s.priceCoupons = priceCoupons.concat(
                  result.applyTo.map((priceId) => ({
                    priceId,
                    couponId: result.couponId,
                    isEnabled: true,
                  })),
                );
              });
            }}
          />
        </SlideOver>
      )}
      <FixedContent class="bg-white">
        <AdminNav currentPage="prices" />

        <AdminTabWrapper>
          <h1 class="text-2xl mb-6">
            {productId === CORE_INSTANT_COURSES_PRODUCT_ID
              ? 'Instant Courses Pricing'
              : 'Ruzuku Pricing'}
          </h1>

          <div class="border rounded-2xl">
            <header class="sticky bg-white top-0 rounded-t-2xl mt-px z-10">
              <div class="flex items-center p-2 border-b">
                <BackButton href={reverseRoute(urls.baseUrl, { productId })} disabled={!folder} />
                <NavBox
                  key={folder?.id}
                  folder={folder}
                  baseUrl={reverseRoute(urls.baseUrl, { productId })}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  onSubmit={() => {
                    const item = visibleItems[0];
                    if (!item) {
                      return;
                    }
                    const url = urls.itemUrl({
                      item,
                      folderId: state.itemToFolder[item.id],
                      productId,
                    });
                    if (url) {
                      router.goto(url);
                    }
                  }}
                  onRenameFolder={() =>
                    folder &&
                    showRenameFolderModal({
                      folder,
                      onRename(name) {
                        setState((s) => {
                          const item = s.items[folder.id];
                          if (item?.type === 'folder') {
                            item.name = name;
                          }
                        });
                      },
                    })
                  }
                />

                <div class="ml-auto">
                  <CreateMenu
                    onNewPrice={() =>
                      showNewPriceModal({
                        folderId: folder?.id,
                        productId,
                        setPrice(price) {
                          setState((s) => {
                            const existing = s.items[price.id];
                            if (existing) {
                              Object.assign(existing, price);
                            } else {
                              s.items[price.id] = price;
                            }
                            s.itemToFolder[price.id] = folder?.id || defaultFolderId;
                          });
                        },
                      })
                    }
                    onNewCoupon={() => {
                      const applicablePrices = Object.values(state.items).filter((x) => {
                        return x.type === 'price';
                      }) as Price[];
                      showNewCouponModal({
                        folderId: folder?.id,
                        productId,
                        applicablePrices,
                        onCreate(coupon) {
                          setState((s) => {
                            s.items[coupon.id] = coupon;
                            if (coupon.applyTo) {
                              s.priceCoupons = [
                                ...s.priceCoupons,
                                ...coupon.applyTo.map<PriceCouponRow>((price) => ({
                                  priceId: price.id,
                                  couponId: coupon.id,
                                  isEnabled: true,
                                })),
                              ];
                            }
                            s.itemToFolder[coupon.id] = folder?.id || defaultFolderId;
                          });
                        },
                      });
                    }}
                    onNewFolder={() =>
                      showNewFolderModal({
                        productId,
                        onCreate(result) {
                          setState((s) => {
                            s.items[result.id] = { ...result, type: 'folder' };
                          });
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div class="m-2">
                {folder && visibleItems.length > 0 && (
                  <div
                    class={`inline-flex items-center space-x-2 border rounded-lg ${
                      state.selected.length ? 'border-gray-200 shadow' : 'border-transparent'
                    }`}
                  >
                    <Checkbox
                      wrapperClass="p-2 cursor-pointer"
                      checked={state.selected.length === visibleItems.length}
                      onClick={() => {
                        setState((s) => {
                          if (s.selected.length === visibleItems.length) {
                            s.selected = [];
                          } else {
                            s.selected = visibleItems.map((x) => x.id);
                          }
                        });
                      }}
                    >
                      <span class="text-gray-600 ml-1">
                        {state.selected.length === visibleItems.length
                          ? 'Deselect all'
                          : 'Select all'}
                      </span>
                    </Checkbox>
                    {state.selected.length > 0 && (
                      <>
                        <span class="border-l h-4"></span>
                        <MoveMenuItem state={state} setState={setState} />
                        <ArchiveToggleMenuItem
                          state={state}
                          setState={setState}
                          visibleItems={visibleItems}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </header>
            {!visibleItems.length && (
              <EmptyRowset
                isSearch={!!searchTerm}
                folder={folder}
                onDeleteFolder={() =>
                  folder &&
                  setState((s) => {
                    delete s.items[folder.id];
                  })
                }
              />
            )}
            {!!visibleItems.length && (
              <div class="text-gray-600 pb-4 px-2 w-full">
                {visibleItems.map((x) => {
                  const isSelected = state.selected.indexOf(x.id) >= 0;
                  const toggleSelection = () =>
                    setState((s) => {
                      if (isSelected) {
                        s.selected = s.selected.filter((id) => id !== x.id);
                      } else {
                        s.selected.push(x.id);
                      }
                    });
                  return (
                    <Row
                      key={x.id}
                      item={x}
                      isSelected={isSelected}
                      folderStats={folderStats}
                      toggleSelection={toggleSelection}
                      showFolder={searchTerm && state.itemToFolder[x.id]}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}

router.add({
  url: urls.baseUrl,
  load,
  render: Page,
  authLevel: 'superadmin',
});

router.add({
  url: urls.folderUrl,
  load,
  render: Page,
  authLevel: 'superadmin',
});
