import { BtnWarning } from '@components/buttons';
import { AsyncForm, FormGroup } from '@components/async-form';
import { Password } from '@components/password';
import { showToast } from '@components/toaster';
import { useIntl } from 'shared/intl/use-intl';
import { AccountTabContent } from './account-tab-content';
import { useState } from 'preact/hooks';
import { IcoTrash } from '@components/icons';
import { rpx } from 'client/lib/rpx-client';
import { showDialog } from '@components/dialog';

const DELETE_CONFIRMATION = 'delete all of my data';

export function DeleteAccountTab() {
  const intl = useIntl();
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  async function deleteAccount() {
    // One last confirmation
    const isConfirmed = await showDialog({
      mode: 'warn',
      title: intl('Are you sure you want to delete your account?'),
      children: intl(
        'All of your data will be permanently removed from our servers. This action cannot be undone.',
      ),
      confirmButtonText: intl('Delete Account'),
    });

    if (!isConfirmed) {
      return;
    }

    setIsDeleting(true);
    try {
      await rpx.auth.deleteCurrentUserAccount({ password });
      showToast({
        type: 'ok',
        title: intl('Updated'),
        message: intl('Settings saved'),
      });
      // Redirect to the login page
      location.assign('/');
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <AsyncForm onSubmit={deleteAccount}>
      <AccountTabContent title={intl('Are you sure you want to delete your account?')}>
        <div class="mb-8">
          {intl(
            'All of your data will be permanently removed from our servers. This action cannot be undone.',
          )}
        </div>
        <FormGroup prop="currentPassword">
          <label class="text-zinc-900">{intl('Enter your current password')}</label>
          <Password
            name="currentPassword"
            placeholder={intl('Password')}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </FormGroup>
        <div class="mt-4">
          <label class="block mb-2">
            Type <strong class="uppercase">{DELETE_CONFIRMATION}</strong> to confirm
          </label>
          <input
            type="text"
            class="ruz-input"
            value={confirmation}
            onChange={(e: any) => setConfirmation(e.target.value)}
          />
        </div>
        <footer class="mt-8">
          <BtnWarning
            class="w-full mt-3"
            disabled={password.length === 0 || confirmation.toLowerCase() !== DELETE_CONFIRMATION}
            isLoading={isDeleting}
          >
            <IcoTrash class="mr-2" />
            {intl('Delete Account')}
          </BtnWarning>
        </footer>
      </AccountTabContent>
    </AsyncForm>
  );
}
