/**
 * Display a full-size image. This is a dumb hack to work around the fact that
 * some of our v1 images force-download when viewed in a new tab.
 */

import { RouteProps, router } from '@components/router';
import { useMemo } from 'preact/hooks';

/**
 * Strip the URL search params so that images like "?width=800" become full size
 */
function stripURLSearchParams(url: string) {
  try {
    const parsed = new URL(url, location.href);
    if (url.startsWith('/files/')) {
      // If it's one of our files, we'll request the original so it displays
      // full size. For some reason, the CDN resizes even if we don't ask it
      // to do so.
      parsed.search = '?raw=true';
    } else {
      // If it's an external URL, we'll still strip off the search params,
      // as we have some direct CDN URLs that got ported from v2 with a
      // hard-coded ?width=1024 or whatever, and we want to deliver as
      // close to the original size as we can here.
      parsed.search = '';
    }
    return parsed.toString();
  } catch (err) {
    console.error(err);
  }
  return url;
}

export function Page(props: RouteProps) {
  const url = useMemo(() => stripURLSearchParams(props.route.params.url), []);
  return (
    <div class="w-screen h-screen overflow-auto bg-stone-800 flex items-center justify-center">
      <img src={url} alt="Full-size image" />
    </div>
  );
}

router.add({
  isPublic: true,
  url: 'view-image',
  render: Page,
});
