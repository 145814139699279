import { RouteProps, router } from '@components/router';
import { NavBar } from './nav-bar';
import { meetingRoute, meetingUrl } from './urls';
import { AppRoute } from 'client/lib/app-route/types';
import { FullCourse, Meeting } from 'server/types';
import { useEffect, useReducer } from 'preact/hooks';
import { MeetingDetail } from './meeting-detail';
import { NewMeetingForm } from './new-meeting-form';
import { Case } from '@components/conditional';
import { reducer } from './reducer';
import { groupMeetingsByStatus } from 'shared/meeting-utils';
import { EmptyPage } from '@components/empty-page';
import { IcoPresentation } from '@components/icons';
import { GuidePage } from '@components/guide-page';
import { URLS } from 'shared/urls';
import { completeCourseStep } from '@components/course-checklist';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';

interface RouteData {
  course: FullCourse;
  meetings: Meeting[];
}

function Page(props: RouteProps<RouteData>) {
  const { course, meetings: loadedMeetings } = props.data;
  const { meetingId } = props.route.params;
  const { terminology } = useCurrentTenant();

  const [state, dispatch] = useReducer(reducer, {
    meetings: loadedMeetings,
  });
  const { meetings } = state;
  const isCreatePage = meetingId === 'new';
  const selectedMeeting = meetings.find((w) => w.id === meetingId);
  const { draft, past, upcoming } = groupMeetingsByStatus(meetings);

  // Go to the first available meeting, if none was requested.
  useEffect(() => {
    if (!isCreatePage && !selectedMeeting && meetings.length > 0) {
      const firstId = upcoming[0]?.id ?? past[0]?.id;
      router.rewrite(meetingUrl({ courseId: course.id, meetingId: firstId }));
    }
  }, [isCreatePage, selectedMeeting, course]);

  return (
    <GuidePage
      course={course}
      isLoading={!course}
      viewLink={
        selectedMeeting &&
        URLS.student.meeting({
          course,
          meeting: selectedMeeting,
        })
      }
      documentTitle={selectedMeeting?.title}
      type="meetings"
    >
      <Case when={meetings.length > 0 && !isCreatePage}>
        <NavBar draft={draft} upcoming={upcoming} past={past} />
      </Case>
      <div class="flex flex-col grow p-4 md:p-8 max-w-5xl mx-auto">
        <Case
          when={!isCreatePage}
          fallback={
            <NewMeetingForm
              onCreate={(meeting) => {
                dispatch({
                  type: 'add',
                  payload: {
                    meeting,
                  },
                });
                router.goto(meetingUrl({ courseId: course.id, meetingId: meeting.id }));
                completeCourseStep('createMeeting');
              }}
            />
          }
        >
          <Case when={meetings.length === 0}>
            <EmptyPage
              Ico={IcoPresentation}
              actionText={`Create your first ${terminology.meeting}`}
              description={`You haven't created any ${terminology.meetings}.`}
              title={`Create a ${terminology.meeting}`}
              centeredDescription
              href={meetingUrl({ courseId: course.id, page: 'new' })}
            />
          </Case>
          {selectedMeeting && (
            <MeetingDetail
              course={course}
              key={selectedMeeting.id}
              meeting={selectedMeeting}
              hasEnrolledStudents={course.numStudents ? course.numStudents > 0 : false}
              dispatch={dispatch}
            />
          )}
        </Case>
      </div>
    </GuidePage>
  );
}

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, meetings] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.meetings.getMeetings({ courseId }),
  ]);

  return {
    course,
    meetings,
  };
}

router.add({
  url: meetingRoute(''),
  render: Page,
  load,
  authLevel: 'guide',
});

router.add({
  url: meetingRoute(':meetingId'),
  load,
  render: Page,
  authLevel: 'guide',
});

router.add({
  url: meetingRoute(':meetingId/:tab'),
  load,
  render: Page,
  authLevel: 'guide',
});
