/**
 * This is a generic video player based on Vidstack.
 */

import { h } from 'minidoc-editor';
import { ajax } from 'client/lib/ajax';
import { type Props as VidstackProps, tagName as vidstackTagName } from './vidstack-web-component';

interface MediaRec {
  url: string;
  poster?: string;
  type: string;
  ratio?: number;
  width?: number;
  captionsURL?: string;
  // Indicates whether or not this is a URL that needs
  // to get resolved via our files endpoint.
  isResolved?: boolean;
  class?: string;
}

export interface Props extends MediaRec {
  fileId?: string;
  hasCaptions?: boolean;
  load?: VidstackProps['load'];
  setProps?(fn: (r: MediaRec) => MediaRec): void;
  tmpUrl?: string;
  captionsURL?: string;
  hideCaptions?: boolean;
  onVideoElement?(el: HTMLVideoElement): void;
  overlay?: string;
}

/**
 * Compute the aspect ratio.
 */
export function getAspectRatio(el: HTMLElement) {
  return (el.offsetHeight / el.offsetWidth) * 100;
}

export function getAspectRatioStyle(state: { ratio?: number; width?: number }) {
  if (!state.ratio) {
    return '';
  }
  const ratio = `aspect-ratio: ${state.ratio ? `100 / ${state.ratio};` : '16/9'}`;
  // This complexity is required so that tall images don't get scrunched
  // by our max-height.
  const width = state.width
    ? `width: min(${state.width}px, 100%, calc(var(--max-height, 100vh - 10rem) / ${
        state.ratio / 100
      }));`
    : '';

  return ratio + width;
}

function showMediaError(err: any, placeholder: HTMLElement) {
  console.error(err);
  placeholder.append(
    h(
      '.media-err.flex-grow.h-full.flex.items-center.justify-center.bg-gray-100.text-gray-800.dark:bg-gray-800.rounded-md',
      'Failed to load media.',
    ),
  );
}

const isAbsoluteUrl = (s: string) => /^https?:\/\//.test(s);

/**
 * If we're serializing, we'll output a standard audio / video
 * element and call it a day. If we're serializing as email,
 * we ought to output a poster image and a link that takes the
 * user to a special media page where the audio / video can
 * be consumed (that's still a TODO). If we're rendering for
 * edit / live view, we'll load the correct URL from the server
 * so that if it's been transcoded, we use that. While we're
 * loading, we render a placeholder w/ the correct aspect ratio.
 */

export function renderMediaPlayer(props: Props) {
  const wrapper = h('.flex-grow');

  async function init() {
    const isFileReady = props.isResolved || props.tmpUrl || isAbsoluteUrl(props.url);
    const result = isFileReady ? props : await ajax(props.url);
    const attrs: VidstackProps = {
      id: result.id,
      url: props.tmpUrl || result.url,
      type: result.type,
      poster: props.poster || result.poster,
      load: props.load,
      overlay: props.overlay,
      ratio: props.ratio?.toString(),
      hideCaptions: props.hideCaptions,
      hasCaptions: !!(props.captionsURL || result.captionsURL),
      captionsURL: props.captionsURL || result.captionsURL,
    };

    const player = h(vidstackTagName, attrs);
    wrapper.append(player);

    // If we are in edit mode, and haven't yet computed an aspect ratio, we'll do so now.
    if (props.setProps) {
      player.addEventListener(
        'can-play',
        () => {
          const ratio = getAspectRatio(wrapper);
          if (ratio !== props.ratio) {
            props.setProps?.((x) => ({ ...x, ratio }));
          }
        },
        true,
      );

      const isModified = props.fileId !== attrs.id || props.hasCaptions !== attrs.hasCaptions;
      // If we're in edit mode, we'll update the captions URL when it becomes available.
      if (isModified) {
        props.setProps?.((x) => ({ ...x, hasCaptions: attrs.hasCaptions, fileId: attrs.id }));
      }
    }

    // If the caller wants the video element, we'll attempt to provide it.
    if (props.onVideoElement) {
      setTimeout(() => {
        const video = wrapper.querySelector('video');
        if (video) {
          props.onVideoElement?.(video);
        }
      }, 10);
    }
  }

  // Display a little error UI if we fail to initialize
  init().catch((err) => showMediaError(err, wrapper));

  return h(`.relative.rounded-lg`, wrapper);
}

export function setPoster({ poster, playerEl }: { poster: string; playerEl: HTMLElement }) {
  const component = playerEl.querySelector(vidstackTagName);
  const video = playerEl.querySelector('video');
  if (video) {
    video.poster = poster;
  }
  if (component) {
    (component as any).poster = poster;
  }
}
