/**
 * This page renders core pricing plans.
 * It is publicly available because it's rendered in the marketing site
 * through an iframe.
 */

import { router } from '@components/router';
import { CorePricingTable } from './components/core-pricing-table';

function Page() {
  return (
    <div class="flex text-gray-700 bg-white">
      <div class="flex flex-col grow max-w-4xl mx-auto">
        <CorePricingTable
          // Open links in the parent window
          hrefTarget="_top"
          onLoad={() => {
            // Send the content height to the container frame on the marketing site
            const height = document.documentElement.getBoundingClientRect().height;
            parent.postMessage(['iframeHeight', height], '*');
          }}
        />
      </div>
    </div>
  );
}

router.add({
  url: 'pricing-plans',
  render: Page,
  isPublic: true,
});
