import { ComponentChildren } from 'preact';

export function SectionModal(props: {
  onHide(): void;
  children: ComponentChildren;
  position?: 'left' | 'middle' | 'right';
}) {
  const pos =
    props.position === 'right'
      ? 'justify-end'
      : props.position === 'middle'
      ? 'justify-center'
      : 'justify-start';
  return (
    <div
      class={`fixed inset-0 z-50 flex items-center an-slide-up ${pos} p-8`}
      onClick={props.onHide}
    >
      <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-50 "></div>
      <div
        class="relative bg-white text-gray-700 rounded-xl shadow-2xl mini-scroll overflow-auto max-h-(screen-80)"
        onClick={(e) => e.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  );
}
