import { CheckoutSection } from '../checkout';
import { useImageUrl } from 'client/utils/cdn';
import { useIntl } from 'shared/intl/use-intl';
import { SubCourse } from './types';

function SubCourse({ course }: { course: SubCourse }) {
  const src = useImageUrl(course.imagePath);

  return (
    <section class="flex items-center gap-6">
      {src && (
        <span class="block w-20 min-w-20">
          <img class="rounded w-full" src={`${src}?width=200`} />
        </span>
      )}
      <span class="font-medium flex-grow">{course.title}</span>
    </section>
  );
}

export function SubCourses({ subcourses }: { subcourses?: SubCourse[] }) {
  const intl = useIntl();

  if (!subcourses?.length) {
    return null;
  }

  return (
    <CheckoutSection title={intl('Courses included in this bundle')}>
      {subcourses.map((subcourse) => (
        <SubCourse key={subcourse.id} course={subcourse} />
      ))}
    </CheckoutSection>
  );
}
