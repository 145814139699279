/**
 * The FAQ section for the sales page builder.
 */
import { SubsectionDragProvider } from './subsection-drag-provider';
import { SubsectionEditor, SubsectionWrapper } from './subsection-toolbar';
import { EditorProps, ViewerProps } from './types';
import { useIdGen } from './id-gen';
import { MultilineProp, RichProp } from './editable';
import { BtnAdd } from './btn-add';

interface FAQ {
  id: number;
  question: string;
  answer: string;
}

interface State {
  title: string;
  faqs: FAQ[];
}

function MiniFAQ() {
  return (
    <span class="flex items-center my-1">
      <span class="flex h-fullt opacity-50 font-bold leading-none pr-2">?</span>
      <span class="grow">
        <span class="block w-3/4 h-1 mb-1 rounded bg-gray-400"></span>
        <span class="block w-4/5 h-1 mb-1 rounded bg-gray-300"></span>
        <span class="block w-1/2 h-1 mb-1 rounded bg-gray-300"></span>
      </span>
    </span>
  );
}

export const initialState: State = {
  title: `<h2>Frequently Asked Questions</h2>`,
  faqs: [
    {
      id: 1,
      question: 'Who is this for?',
      answer: 'Everyone, from beginners to experts.',
    },
    {
      id: 2,
      question: 'Will I get personal feedback on my work?',
      answer: 'Yes. I will personally review and comment on all posted work.',
    },
  ],
};

export const type = 'faq';

export const name = 'FAQ';

export function MiniView() {
  return (
    <span class="grid grid-cols-2">
      <MiniFAQ />
      <MiniFAQ />
      <MiniFAQ />
      <MiniFAQ />
    </span>
  );
}

export function Editor(props: EditorProps<State> | ViewerProps<State>) {
  const nextId = useIdGen(props.state.faqs);
  const editorProps = props as EditorProps<State>;
  const readonly = !editorProps.setState;

  return (
    <SubsectionDragProvider
      table={type}
      onReorder={(reorder) => editorProps.setState((s) => ({ ...s, faqs: reorder(s.faqs) }))}
    >
      <SubsectionWrapper>
        <RichProp {...props} path="title" />
        <div class="grid md:grid-cols-2 gap-10 mt-10">
          {props.state.faqs.map((q, i) => (
            <SubsectionEditor
              key={q.id}
              id={q.id}
              table={type}
              disabled={readonly}
              onDelete={() =>
                editorProps.setState((s) => ({ ...s, faqs: s.faqs.filter((x) => x.id !== q.id) }))
              }
            >
              <div class="flex flex-col">
                <h3 class="font-medium text-lg leading-7">
                  <MultilineProp {...props} path={['faqs', i, 'question']} />
                </h3>
                <div class="text-sm mt-2 text-opacity-75">
                  <MultilineProp {...props} path={['faqs', i, 'answer']} />
                </div>
              </div>
            </SubsectionEditor>
          ))}
          {editorProps.isSelected && (
            <BtnAdd
              isBgLight={props.isBgLight}
              onClick={() =>
                editorProps.setState((s) => ({
                  ...s,
                  faqs: [...s.faqs, { id: nextId(), question: '', answer: '' }],
                }))
              }
            >
              + Add Question &amp; Answer
            </BtnAdd>
          )}
        </div>
      </SubsectionWrapper>
    </SubsectionDragProvider>
  );
}

export const Viewer = Editor;
