/**
 * CommandPalette is a general purpose command palette / menu system.
 */
import { Button } from '@components/buttons';
import { showModalForm } from '@components/modal-form';
import { router } from '@components/router';
import { SearchBox } from '@components/search-box';
import { Spinner } from '@components/spinner';
import { useAnyKeyDown, useAsyncData, useCtrlKey } from 'client/lib/hooks';
import { ComponentChildren } from 'preact';
import { Dispatch, Inputs, StateUpdater, useEffect, useMemo, useState } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';
import { capitalizeFirst, groupBy, uniqueBy } from 'shared/utils';
import {
  IcoBook,
  IcoChat,
  IcoFolder,
  IcoList,
  IcoUser,
  IcoVideoCamera,
  IcoX,
} from '@components/icons';
import { Case } from '@components/conditional';
import { FullCourse } from 'server/types';
import { IcoTranscript } from '@components/icons/video-player';
import { TooltippedItem } from '@components/tooltip';
import { BaseDialog, hideModals } from '@components/dialog';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';

export type CommandType =
  | 'menu'
  | 'recent'
  | 'student'
  | 'module'
  | 'lesson'
  | 'caption'
  | 'meeting'
  | 'discussion';

type SearchResultTypes = Exclude<CommandType, 'menu' | 'recent'>;

type SearchSectionConfig = {
  key: CommandType;
  title: string;
  asGrid?: boolean;
  icon?: () => JSX.Element;
};

export interface Command {
  id: string;
  type: CommandType;
  /**
   * If specified, this is displayed right-aligned in the item.
   */
  subtype?: string;
  href?: string;
  onClick?(): void;
  title: string;
  subtitle?: string;
  keywords: string;
  icon?(): JSX.Element | null;
  render?(): JSX.Element | null;
}

export type PaletteState = {
  term: string;
  type?: CommandType;
};

interface CommandProvider {
  course: Pick<FullCourse, 'isProduct' | 'supportsCaptions'> & {
    guide: {
      email?: string;
    };
  };
  isGuideSearch?: boolean;
  types: Array<CommandType | undefined>;

  // Persisting the state out of the palette
  // so it can be restored when the palette is opened again on the same page.
  state: PaletteState;
  setState: Dispatch<StateUpdater<PaletteState>>;

  // staticGuideMenu retrieves a list of course menu and commands.
  staticGuideMenu?: Array<Command | false | undefined>;

  // This is a list of recent searches that will be shown
  // if no search term is entered.
  cachedSearches?: Command[];

  // search provides an a set of commands from the server that match the
  // search term. The palette will combine these with the staticGuideMenu.
  search(
    term: string,
    type?: SearchResultTypes,
  ): Promise<{
    totalHits: Record<SearchResultTypes, number>;
    items: Command[];
  }>;

  footerActions?: ComponentChildren;
}

function useKeyNav(commands: Command[], inputs: Inputs, hide: () => void) {
  const [index, setIndex] = useState(0);

  useAnyKeyDown((e) => {
    if (index >= 0 && e.code === 'Enter') {
      e.preventDefault();
      const match = commands[index];
      const el = match && document.getElementById(`cmd-${match.id}`);
      const href = (el as HTMLAnchorElement)?.href;
      if (href) {
        // Save search term in history.
        match.onClick?.();
        hide();
        router.goto(href);
      } else if (el) {
        el.click();
      }
    } else if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      e.preventDefault();
      const direction = e.code === 'ArrowUp' ? -1 : 1;
      const newIndex = Math.max(0, Math.min(commands.length - 1, index + direction));
      setIndex(newIndex);
    }
  });

  useEffect(() => setIndex(0), inputs);

  return commands[index]?.id;
}

export function useCommandPalette(fn: () => CommandProvider, listenCtrlKey = true) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const showMenu = () => {
    if (!isMenuVisible) {
      setIsMenuVisible(true);
      hideModals();
      showModalForm(({ resolve }) => {
        const provider = fn();
        return (
          <CommandPalette
            provider={provider}
            hide={() => resolve(undefined)}
            listenCtrlKey={listenCtrlKey}
          />
        );
      }).finally(() => setIsMenuVisible(false));
    }
  };

  useCtrlKey('KeyK', (e) => {
    if (listenCtrlKey && !isMenuVisible) {
      e.preventDefault();
      showMenu();
    }
  });

  return showMenu;
}

/**
 * Show a command palette modal.
 */
function CommandPalette({
  provider,
  hide,
  listenCtrlKey,
}: {
  provider: CommandProvider;
  hide(): void;
  listenCtrlKey: boolean;
}) {
  const intl = useIntl();
  const isProduct = provider.course.isProduct;
  const sectionConfig: Record<CommandType, SearchSectionConfig> = {
    menu: { key: 'menu', title: intl('Manage'), icon: () => <IcoList /> },
    recent: {
      key: 'recent',
      title: intl('Recent Searches'),
    },
    student: { key: 'student', title: intl('Students'), asGrid: true, icon: () => <IcoUser /> },
    meeting: { key: 'meeting', title: intl('Meetings'), icon: () => <IcoVideoCamera /> },
    module: { key: 'module', title: intl('Modules'), icon: () => <IcoFolder /> },
    lesson: {
      key: 'lesson',
      title: isProduct ? intl('Pages') : intl('Lessons'),
      icon: () => <IcoBook />,
    },
    caption: {
      key: 'caption',
      title: intl('Transcripts'),
      icon: () => <IcoTranscript class="w-4 h-4" />,
    },
    discussion: { key: 'discussion', title: intl('Discussions'), icon: () => <IcoChat /> },
  };
  const [searchState, setSearchState] = useState(provider.state);
  const staticGuideMenu = useMemo(
    () => provider.staticGuideMenu?.filter((x) => !!x) as Command[],
    [provider.staticGuideMenu],
  );
  const [ctrlKPrefix, ctrlK, ctrlKSuffix] = intl.split(
    `Press <>ctrl k</> to open this menu at any time.`,
  );
  const showCategories = !provider.isGuideSearch || !!searchState.term;
  const searchResultsAvailable = !!searchState.term || (!!searchState.type && showCategories);

  const asyncMenu = useAsyncData(
    async () => {
      if (!searchResultsAvailable) {
        return;
      }
      if (searchState.type === 'recent' || searchState.type === 'menu') {
        return;
      }
      return provider.search(searchState.term, searchState.type);
    },
    [searchState.term, searchState.type, searchResultsAvailable],
    500,
  );

  useBodyScrollLock(true);
  useEffect(() => provider.setState(searchState), [searchState]);

  return (
    <BaseDialog contentWidth onClose={hide}>
      <div class="flex flex-col min-h-full sm:w-4xl w-full h-(screen-16) overflow-auto">
        <header class="sticky top-0 bg-white z-10 pb-4">
          <SearchBox
            placeholder={intl('Type something to search...')}
            focusOnce
            class="ruz-input font-semibold block w-full pl-10 p-4 text-sm rounded-none border-none border-gray-200"
            containerClass="w-full border-b"
            onTermChange={(term) => setSearchState((s) => ({ ...s, term }))}
            value={searchState.term}
          />
          {showCategories && provider.types.length > 0 && (
            <div class="p-4 pb-0 text-gray-500">
              <p class="pl-2">{intl('What are you looking for?')}</p>
              <div class="grid grid-cols-2 md:flex md:flex-wrap gap-2 md:gap-1 mt-2">
                {provider.types.map((type) => {
                  if (!type) {
                    return null;
                  }
                  const config = sectionConfig[type];
                  const isSelected = searchState.type === config.key;
                  const totalHits = asyncMenu.data?.totalHits[config.key as SearchResultTypes];
                  const showTotalHits = searchState.type === undefined || isSelected;

                  if (type === 'caption' && !provider.course.supportsCaptions) {
                    return (
                      <TooltippedItem
                        key={config.key}
                        tooltip={
                          <span>
                            Want searchable transcripts and closed captions for all your course
                            videos?
                            <Case
                              when={provider.isGuideSearch}
                              fallback={
                                <span>
                                  <a
                                    class="text-indigo-400 ml-1"
                                    href={`mailto:${provider.course.guide.email}`}
                                  >
                                    Ask your Guide
                                  </a>{' '}
                                  to upgrade to Ruzuku Pro.
                                </span>
                              }
                            >
                              Upgrade to{' '}
                              <a class="text-indigo-400" href="/account/billing">
                                Ruzuku Pro
                              </a>
                              . Or contact{' '}
                              <a class="text-indigo-400" href="mailto:sales@ruzuku.com">
                                sales@ruzuku.com
                              </a>{' '}
                              for a walkthrough.
                            </Case>
                          </span>
                        }
                        tooltipPosition="left-full -translate-x-1/2 top-6"
                      >
                        <span class="inline-flex items-center px-3 md:px-2 py-1 capitalize rounded-lg text-xs font-semibold gap-1 outline-none relative bg-gray-100 text-gray-600 opacity-60 cursor-not-allowed">
                          {config.icon?.()}
                          {config.title}
                        </span>
                      </TooltippedItem>
                    );
                  }

                  return (
                    <Button
                      key={config.key}
                      class={`inline-flex items-center px-3 md:px-2 py-1 capitalize rounded-lg text-xs font-semibold gap-1 outline-none hover:bg-indigo-100 ${
                        isSelected ? 'bg-indigo-200 text-gray-900' : 'bg-gray-100 text-gray-600'
                      }`}
                      onClick={() =>
                        setSearchState((s) => ({ ...s, type: isSelected ? undefined : config.key }))
                      }
                    >
                      {config.icon?.()}
                      <span class="grow text-left">
                        {config.title}
                        {showTotalHits && totalHits !== undefined && (
                          <span class="ml-1 text-gray-600">({totalHits})</span>
                        )}
                      </span>
                      {isSelected && <IcoX />}
                    </Button>
                  );
                })}
              </div>
            </div>
          )}
        </header>
        <div class="flex-grow">
          {!searchState.term && (
            <Case
              when={provider.isGuideSearch}
              fallback={
                !searchState.type && (
                  <RecentSearchesList items={provider.cachedSearches || []} hide={hide} />
                )
              }
            >
              <CourseGuideMenu items={staticGuideMenu} hide={hide} />
            </Case>
          )}
          {searchResultsAvailable && (
            <SearchResults
              data={asyncMenu.data}
              isLoading={asyncMenu.isLoading}
              searchTerm={searchState.term}
              searchType={searchState.type}
              hide={hide}
              staticGuideMenu={staticGuideMenu}
              sectionConfig={sectionConfig}
              setSearchType={(type) => setSearchState((s) => ({ ...s, type }))}
            />
          )}
        </div>
        {listenCtrlKey && (
          <footer class="flex justify-between items-center px-6 py-2 2xl:py-4 border-t">
            <span class="text-gray-400">
              {ctrlKPrefix}
              <span class="bg-gray-50 border rounded px-1">{ctrlK}</span>
              {ctrlKSuffix}
            </span>
            {provider.footerActions}
          </footer>
        )}
      </div>
    </BaseDialog>
  );
}

function CourseGuideMenu({ items, hide }: { items: Command[]; hide(): void }) {
  const staticGroups = useMemo(() => {
    return groupBy((x) => x.subtype!, items);
  }, [items]);
  const selectedId = useKeyNav(items, [], hide);

  return (
    <div class="grid sm:grid-cols-4 px-2">
      {Object.entries(staticGroups).map(([k, v]) => {
        return (
          <div key={k} class={`p-1 2xl:p-1 space-y-1`}>
            <h3 class={`font-semibold text-gray-900 capitalize p-2 text-xs`}>{k}</h3>
            {v.map((cmd) => (
              <BtnMenuItem key={cmd.id} cmd={cmd} isSelected={selectedId === cmd.id} hide={hide} />
            ))}
          </div>
        );
      })}
    </div>
  );
}

function RecentSearchesList({ items, hide }: { items: Command[]; hide(): void }) {
  const selectedId = useKeyNav(items, [], hide);
  return (
    <div class={`flex flex-col px-4`}>
      <SearchSection
        config={{ key: 'recent', title: 'Recent Searches' }}
        items={items}
        selectedId={selectedId}
        hide={hide}
      />
    </div>
  );
}

function SearchResults({
  data,
  searchTerm,
  searchType,
  staticGuideMenu,
  sectionConfig,
  isLoading,
  setSearchType,
  hide,
}: {
  data?: Awaited<ReturnType<CommandProvider['search']>>;
  searchTerm: string;
  searchType?: CommandType;
  staticGuideMenu?: Command[];
  cachedSearches?: Command[];
  sectionConfig: Record<CommandType, SearchSectionConfig>;
  isLoading: boolean;
  setSearchType(type: CommandType): void;
  hide(): void;
}) {
  const intl = useIntl();

  const searchableCommands = useMemo(() => {
    // Convert the menu items from a developer-friendly shape
    // to a search-friendly shape.
    return (
      staticGuideMenu?.map((item) => ({
        ...item,
        exact: item.title.toLowerCase(),
        terms: item.keywords.split(' '),
      })) || []
    );
  }, []);

  const matchingMenuItems = useMemo(() => {
    if (searchType !== undefined && searchType !== 'menu') {
      return [];
    }
    if (!searchTerm) {
      return searchableCommands;
    }
    const result: Array<Command & { score: number }> = [];
    const searchTerms = searchTerm.toLowerCase().trim().split(' ');
    searchableCommands.forEach((item, i) => {
      if (isWordMatch(searchTerm, item.exact)) {
        result.push({ ...item, score: 100 + i });
        return;
      }
      const score = item.terms.reduce((acc, t) => {
        if (searchTerms.some((s) => isWordMatch(t, s))) {
          return acc + 1;
        }
        return acc;
      }, 0);
      if (score > 0) {
        result.push({ ...item, score });
      }
    });
    return result.sort((a, b) => b.score - a.score);
  }, [searchTerm, searchType]);

  // Note: We may want to also do a client-side score for the async menu items,
  // as they will not be sorted properly otherwise.
  const allMatches = uniqueBy(
    (x) => x.id,
    [...matchingMenuItems, ...(isLoading ? [] : data?.items || [])],
  );
  const selectedId = useKeyNav(allMatches, [searchTerm, searchType], hide);
  const sections = groupBy((x) => x.type, allMatches);

  return (
    <div class="flex flex-col p-4 pt-0">
      {!isLoading && !allMatches.length && (
        <p class="pt-4 pl-2">
          {searchTerm
            ? intl('There are no {type:string} matches for "{searchTerm:string}".', {
                searchTerm,
                type: searchType || '',
              })
            : intl('There are no {type:string} found in this course.', {
                type: searchType || '',
              })}
        </p>
      )}
      <div class="divide-y">
        {Object.entries(sections).map(([k, v]) => {
          const config = sectionConfig[k as CommandType];
          return (
            <SearchSection
              key={k}
              config={config}
              items={v}
              totalHits={data?.totalHits[k as SearchResultTypes]}
              selectedId={selectedId}
              isFocused={searchType === config.key}
              onFocus={() => setSearchType(config.key)}
              hide={hide}
            />
          );
        })}
      </div>
      {!!searchTerm && searchType !== 'menu' && isLoading && (
        <div class="flex items-center justify-center p-4">
          <Spinner class="border-indigo-400 w-8 h-8" />
        </div>
      )}
    </div>
  );
}

function SearchSection({
  config,
  items,
  selectedId,
  totalHits,
  isFocused,
  onFocus,
  hide,
}: {
  config: SearchSectionConfig;
  items?: Command[];
  selectedId: string;
  totalHits?: number;
  isFocused?: boolean;
  hide(): void;
  onFocus?(): void;
}) {
  const intl = useIntl();

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div class={`py-2`}>
      <div class="p-2">
        <h3 class="text-base text-gray-700 font-semibold capitalize">{config.title}</h3>
        <h4 class="-mt-1 text-sm text-gray-500">
          <Case
            when={totalHits !== undefined && totalHits > items.length}
            fallback={intl('{count:number} {count:number | pluralize result results}', {
              count: totalHits || items.length,
            })}
          >
            {intl('{count:number} {count:number | pluralize result results} shown', {
              count: items.length,
            })}
            {!!totalHits && !isFocused && (
              <Button class="ml-1 text-indigo-600" onClick={onFocus}>
                {intl('({count:number} total)', {
                  count: totalHits,
                })}
              </Button>
            )}
            {!!totalHits && isFocused && (
              <span class="ml-1">
                {intl('from {count:number} total. Please write more to narrow down results.', {
                  count: totalHits,
                })}
              </span>
            )}
          </Case>
        </h4>
      </div>
      <div class={`${config.asGrid ? 'grid sm:grid-cols-3' : 'flex flex-col'}`}>
        {items.map((item) => (
          <BtnMenuItem
            key={item.id}
            cmd={item}
            isSelected={item.id === selectedId}
            showSubtype
            hide={hide}
          />
        ))}
      </div>
    </div>
  );
}

function BtnMenuItem({
  cmd,
  isSelected,
  showSubtype,
  hide,
}: {
  cmd: Command;
  isSelected: boolean;
  showSubtype?: boolean;
  hide(): void;
}) {
  const buttonId = `cmd-${cmd.id}`;
  useEffect(() => {
    if (isSelected) {
      const el = document.getElementById(buttonId);
      el?.scrollIntoView({ block: 'center' });
    }
  }, [buttonId, isSelected]);

  return (
    <Button
      id={buttonId}
      onClick={() => {
        cmd.onClick?.();
        hide();
      }}
      href={cmd.href}
      class={`block w-full font-semibold rounded-md text-left border-2 text-gray-600 hover:text-gray-700 hover:bg-gray-50 p-2 ${
        isSelected ? 'border-indigo-500' : 'border-transparent'
      }`}
    >
      <span class="flex items-center justify-between">
        {cmd.render ? (
          cmd.render()
        ) : (
          <span class={`flex space-x-3 ${cmd.subtitle ? '' : 'items-center'}`}>
            {cmd.icon?.()}
            <span class={`inline-flex flex-col ${cmd.subtitle ? '-mt-1' : ''}`}>
              <span
                class={cmd.type === 'recent' ? '' : '[&>em]:bg-yellow-200 [&>em]:not-italic'}
                dangerouslySetInnerHTML={{ __html: capitalizeFirst(cmd.title) }}
              />
              {cmd.subtitle && (
                <span
                  class={`text-gray-400 font-normal ${
                    cmd.type === 'recent' ? '' : '[&>em]:bg-yellow-200 [&>em]:not-italic'
                  }`}
                  dangerouslySetInnerHTML={{ __html: cmd.subtitle }}
                />
              )}
            </span>
          </span>
        )}
        {showSubtype && !!cmd.subtype && (
          <span class="hidden md:block opacity-60 ml-2 capitalize">{cmd.subtype}</span>
        )}
      </span>
    </Button>
  );
}

/**
 * Check that one of the strings is a prefix of the other.
 * true:  a = 'hi'   b = 'hit'
 * true:  a = 'hit'  b = 'hi'
 * false: a = 'foo'  b = 'tofoo'
 */
function isWordMatch(a: string, b: string) {
  if (a.length > b.length) {
    const tmp = b;
    b = a;
    a = tmp;
  }
  return b.startsWith(a);
}
