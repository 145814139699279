import { getDate, monthFormatter, weekdayColumnFormatter } from './dateutil';
import { IcoChevronLeft, IcoChevronRight } from '@components/icons';
import { datesEq, shiftMonth } from '@components/date-picker';

function getDates(month: Date): Date[] {
  // We'll always have 5 rows just to keep the calendar stable
  const result: Date[] = [];
  // Move to the start of the month
  const start = new Date(month.getFullYear(), month.getMonth(), 1);
  // Move to the start of the week
  start.setDate(start.getDate() - start.getDay());
  let count = 0;

  for (let y = 0; y < 5; ++y) {
    for (let x = 0; x < 7; ++x) {
      const dt = new Date(start);
      dt.setDate(dt.getDate() + count);
      result.push(dt);
      ++count;
    }
  }

  return result;
}

function dateClass(opts: { date: Date; today: Date; activeDate: Date }) {
  const isToday = datesEq(opts.date, opts.today);
  const isActive = datesEq(opts.activeDate, opts.date);
  const isAvailable =
    opts.date.toISOString() >= opts.today.toISOString() &&
    opts.date.getDay() > 0 &&
    opts.date.getDay() < 6;

  const today = isToday && isAvailable ? 'bg-gray-200 font-bold' : '';
  const active = isActive ? 'bg-gray-700 text-white' : '';
  const available = isAvailable
    ? 'bg-gray-100 text-black hover:bg-gray-700 hover:text-white cursor-pointer'
    : active;
  return `${today} ${active || available}`;
}

export function Calendar(props: {
  transition: string;
  focusedDate: Date;
  setFocusedDate(date: Date): void;
  activeDate: Date;
  setActiveDate(date: Date): void;
}) {
  const { activeDate, setActiveDate, focusedDate, setFocusedDate } = props;
  const today = getDate();
  const dates = getDates(focusedDate);

  return (
    <section class="flex flex-col gap-4">
      <header class="flex items-center gap-4 justify-between lg:px-4">
        <h2 key={focusedDate} class={`font-semibold ${props.transition}`}>
          {monthFormatter.format(focusedDate)}
        </h2>
        <nav class="flex items-center gap-2">
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 aspect-square rounded focus:ring-2 hover:bg-gray-100 transition-all"
            onClick={() => setFocusedDate(shiftMonth(focusedDate, -1))}
          >
            <IcoChevronLeft />
          </button>
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 aspect-square rounded focus:ring-2 hover:bg-gray-100 transition-all"
            onClick={() => setFocusedDate(shiftMonth(focusedDate, 1))}
          >
            <IcoChevronRight />
          </button>
        </nav>
      </header>
      <div class={`grid grid-cols-7 gap-2 ${props.transition}`} key={focusedDate}>
        {new Array(7).fill(0).map((_, d) => (
          <span key={d} class="text-gray-500 text-center p-2 text-xs">
            {weekdayColumnFormatter.format(dates[d])}
          </span>
        ))}
        {dates.map((dt) => {
          if (dt.getMonth() !== focusedDate.getMonth()) {
            return <span key={dt}></span>;
          }
          const isAvailable = dt >= today;

          return (
            <button
              type="button"
              key={dt}
              disabled={!isAvailable}
              onClick={() => setActiveDate(dt)}
              class={`rounded inline-flex items-center justify-center aspect-square transition-all ${dateClass(
                { activeDate, date: dt, today },
              )}`}
            >
              {dt.getDate()}
            </button>
          );
        })}
      </div>
    </section>
  );
}
