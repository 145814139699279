// Functions for loading, opening, and closing the Helpscout beacon, and a
// miniature "toggle beacon" icon for display on mobile
// See Beacon js API docs: https://developer.helpscout.com/beacon-2/web/javascript-api/

import { useState, useEffect } from 'preact/hooks';
import { User } from 'server/types';

import { IcoX } from '@components/icons';

declare global {
  interface Window {
    Beacon: any;
  }
}

declare const Beacon: any;

/**
 * Adds effect to initialize the helpscout beacon widget
 */
export function useBeacon(opts: { user?: User; enable: boolean; beaconId?: string }) {
  useEffect(() => {
    // Enable helpscout beacon with user info unless we are at login screen
    if (opts.enable && !beaconLoaded()) {
      if (!opts.beaconId) {
        return;
      }
      enableBeacon(opts.beaconId, opts.user);
    } else if (!opts.enable) {
      exitBeacon();
    }
  }, [opts.user?.id, opts.enable, (window as any).Beacon, (window as any).beaconID]);
}

/**
 * Is the beacon present and already initialized?
 * */
const beaconLoaded = () => window.Beacon && Boolean(Beacon('info'));

/**
 * Initialize the helpscout chat beacon and add user session context if it's not already loaded
 * */
export function enableBeacon(beaconID: string, user: User | undefined) {
  if (!window.Beacon) {
    return;
  }

  try {
    Beacon('init', beaconID);
    user && identifyUser(user);
  } catch (e) {
    console.error(`unable to initialize helpscout beacon: `, e);
  }
}

/**
 * Add user information context to the beacon
 * */
export function identifyUser(user: User) {
  const { id: userId, name, email, timezone, level } = user;
  Beacon('identify', {
    name,
    email,
    userId,
    timezone,
    level,
  });
}

/**
 * Open the helpscout beacon widget
 * */
function openBeacon() {
  Beacon && Beacon('open');
}

/**
 * Close the helpscout beacon widget
 * */
function closeBeacon() {
  Beacon && Beacon('close');
}

/**
 * Disable / destroy the helpscout chat beacon
 * */
export function exitBeacon() {
  beaconLoaded() && Beacon('destroy');
}

/**
 * A miniature helpscout toggle button for mobile, which takes up less space than the original
 * Helpscout beacon icon
 * This is invisible at medium-size screens and above.
 */
export function HelpscoutMiniButton() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <button
      id="helpscout-toggle-button"
      type="button"
      class="fixed bottom-0 left-0 inline-flex items-center justify-center bg-indigo-500 text-white rounded-full m-4 w-8 h-8"
      onClick={() => {
        setIsOpen(!isOpen);
        isOpen ? closeBeacon() : openBeacon();
      }}
    >
      <span class="inline-block middle">
        {isOpen ? <IcoX /> : <span class="leading-none">?</span>}
      </span>
    </button>
  );
}
