import {
  blockDefinitions as defaultDefinitions,
  SalesBlockDefinition,
} from '@components/page-builder';

import * as CourseGridBlock from './course-grid-block';
import * as CoursePromoBlock from './course-promo-block';

export const blockDefinitions: Record<string, SalesBlockDefinition> = {
  [CourseGridBlock.type]: CourseGridBlock,
  [CoursePromoBlock.type]: CoursePromoBlock,
  ...defaultDefinitions,
};
