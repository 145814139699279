/**
 * This is a parameterized manage prices page. It is used for managing both
 * core prices and course prices. The only difference is in the params.
 */

import { useEffect, useState } from 'preact/hooks';
import { ItemTitle } from './item-title';
import { ListItem } from './paginated-list';
import * as fmt from 'shared/payments/fmt';
import { NavBar } from './nav-bar';
import { ArchiveToggle } from './archive-toggle';
import { PmtTabs } from './price-tabs';
import {
  IcoChevronDown,
  IcoChevronUp,
  IcoCreditCard,
  IcoDotsHorizontal,
  IcoExternalLink,
  IcoPencil,
  IcoTrash,
} from '@components/icons';
import { BtnCopy, BtnPreWarning, BtnPrimary, BtnSecondary } from '@components/buttons';
import { CouponLink, LinksTab } from './links-tab';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { NewPriceForm } from './new-price-form';
import { showError } from '@components/app-error';
import { indexBy, partition, uniqueBy } from 'shared/utils';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ExpandedPrice } from 'server/payments/service';
import { AsyncForm, FormSection } from '@components/async-form';
import { showToast } from '@components/toaster';
import { Checkbox } from '@components/checkbox';
import { MetadataTab } from './metadata-tab';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { EmptyPriceScreen } from './empty-screen';
import { router, useRouteParams } from '@components/router';
import { fullyQualify } from './urls';
import { setUrlSearchParam } from '../../../utils/url';
import { CORE_INSTANT_COURSES_PRODUCT_ID, CORE_PRODUCT_ID } from 'shared/ids';
import { showUpdatePriceModal } from './update-price-modal';
import * as dtfmt from 'shared/dateutil';
import { pluralize } from 'shared/formatting';
import { useIntl } from 'shared/intl/use-intl';
import { Dropdown } from '@components/dropdown';
import { SignupsTab } from './signups-tab';
import { AvailableDates } from './signup-limits-tab';
import { showDialog } from '@components/dialog';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { UpsellsTab } from './upsells-tab';
import { Note } from '@components/note';

const store = rpx.prices;

type Props = RpxResponse<typeof store.init> & {
  supportsStripe: boolean;
  supportsPaypal: boolean;
  isCorePrice: boolean;
  courseId?: string; // The id of the course, if this is a course-based screen
  priceUrl(opts: { priceId: string }): string;
  couponUrl(opts: { couponId: string }): string;
  checkoutUrl(opts: { priceId: string; couponId?: string }): string;
  pricesUrl: string;
  newUrl: string;
  signupUrl(opts: { priceId: string; userId: string }): string;
  /**
   * If specified, this will be sent as additional metadata to be stored on
   * any Stripe objects we create.
   */
  stripeMetadata?: Record<string, string>;
};

type LoadedPrice = Props['prices'][0] & { isInSalesPage?: boolean };

type TabName = 'links' | 'limits' | 'signups' | 'coupons' | 'metadata' | 'accessLimits' | 'upsells';

/**
 * A helper to build the tab props.
 */
const tab = (name: TabName, text: string) => ({
  text,
  tab: name,
  href: setUrlSearchParam('tab', name),
});

type OnCouponsChanged = (result: Parameters<typeof store.allowCoupons>[0]) => void;

function CouponsTab({ price, onSave }: { price: ExpandedPrice; onSave: OnCouponsChanged }) {
  const intl = useIntl();
  const [key, setKey] = useState(0);
  const { data } = useTryAsyncData(
    () => rpx.coupons.loadCoupons({ productId: price.productId }),
    [price.productId],
  );
  const applyTo = price.applyTo?.map((x) => x.id);
  const coupons = data || [];

  return (
    <AsyncForm
      key={key}
      onSubmit={async (formData) => {
        const result = {
          productId: price.productId,
          priceId: price.id,
          applyTo: formData.applyTo || [],
        };
        await store.allowCoupons(result);
        showToast({
          type: 'ok',
          title: 'Saved',
          message: `Saved changes.`,
        });
        onSave(result);
      }}
    >
      <FormSection title="Allow coupons">
        {!data && <DefaultSpinner />}
        {data && (
          <div class="inline-flex flex-col space-y-6 py-4">
            {coupons.map((coupon) => {
              const discountPrice = fmt.discountPrice({ price, coupon });
              return (
                <Checkbox
                  wrapperClass="cursor-pointer w-full"
                  alignment="flex items-start"
                  checked={!applyTo || applyTo.includes(coupon.id)}
                  key={price.id}
                  name="[]applyTo"
                  value={coupon.id}
                >
                  <span class="flex flex-col -mt-1 ml-1">
                    <span>
                      <strong class="text-gray-600 mr-2">{coupon.code}</strong>
                      <span class="opacity-75">{fmt.describeCoupon({ coupon, intl })}</span>
                    </span>
                    {discountPrice && (
                      <span class="opacity-75">
                        Discount {discountPrice} {fmt.discountSuffix({ price, coupon, intl })}
                      </span>
                    )}
                  </span>
                </Checkbox>
              );
            })}
          </div>
        )}
        {data && data.length === 0 && <p class="opacity-75">There are no applicable coupons.</p>}
      </FormSection>
      {data && data.length > 0 && (
        <footer class="flex items-center">
          <BtnPrimary class="mr-2 capitalize">Save</BtnPrimary>
          <BtnSecondary type="button" onClick={() => setKey((k) => ++k)}>
            Cancel
          </BtnSecondary>
        </footer>
      )}
    </AsyncForm>
  );
}

export function PriceDetail({
  price,
  checkoutUrl,
  signupUrl,
  couponUrl,
  priceUrl,
  updatePrice,
  onCouponsChanged,
  supportsPaypal,
  supportsStripe,
  stripeMetadata,
  courseId,
  showDisabled,
}: Pick<
  Props,
  | 'signupUrl'
  | 'checkoutUrl'
  | 'priceUrl'
  | 'couponUrl'
  | 'supportsStripe'
  | 'supportsPaypal'
  | 'stripeMetadata'
  | 'courseId'
> & {
  price: LoadedPrice;
  showDisabled: boolean;
  onCouponsChanged: OnCouponsChanged;
  updatePrice(id: string, fn: (p: LoadedPrice) => LoadedPrice): void;
}) {
  const intl = useIntl();
  const { tab: routeTab } = useRouteParams();
  const user = useCurrentUser()!;
  const tenant = useCurrentTenant();
  const selectedTab = routeTab || ((price.isUpsellOffer ? 'signups' : 'links') as TabName);
  const showUpsells = user.tier === 'pro' || !tenant.isCore || user.level === 'superadmin';

  async function updateExpirationDates(updatedPrice: ExpandedPrice) {
    if (!courseId || !price.accessDuration || !updatedPrice.accessDuration) {
      return;
    }

    if (updatedPrice.accessDuration <= price.accessDuration) {
      return;
    }

    const increase = dtfmt.minutesToTimeString(
      updatedPrice.accessDuration - price.accessDuration,
      intl,
    );
    const isConfirmed = await showDialog({
      mode: 'info',
      title: 'Extend course access for current students?',
      children: `You just extended the access this price point provides to new students by ${increase}. Would you like to provide current students an additional ${increase} of access as well?`,
      confirmButtonText: 'Extend access for current students',
      cancelButtonText: 'Keep existing access limits',
    });

    if (!isConfirmed) {
      return;
    }

    try {
      await rpx.manageStudents.syncExpirationDates({
        courseId,
        priceId: updatedPrice.id,
      });
      showToast({
        type: 'ok',
        title: 'Courses access extended',
        message: 'The access for current students has been extended.',
      });
    } catch (err) {
      showError(err);
    }
  }

  const editButtons = price.isUpsellOffer ? (
    <BtnSecondary href={`/upsells/offer-by-price?price=${price.id}`} class="gap-2">
      <IcoPencil />
      <span>Manage upsell offer</span>
    </BtnSecondary>
  ) : (
    <div class="flex items-center gap-4">
      <BtnSecondary
        onClick={async () => {
          await showUpdatePriceModal({
            price,
            supportsStripe,
            supportsPaypal,
            stripeMetadata,
            async onSave(updatedPrice) {
              updatePrice(updatedPrice.id, () => updatedPrice);
              showToast({
                type: 'ok',
                title: 'Price point saved',
                message: 'Your changes have been saved.',
              });
              if (price.numSignups && price.numSignups > 0) {
                await updateExpirationDates(updatedPrice);
              }
            },
          });
        }}
      >
        <IcoPencil />
        <span class="ml-1.5">Edit Price Point</span>
      </BtnSecondary>
      {price.isEnabled && price.isInSalesPage && (
        <BtnPreWarning
          class="gap-1.5"
          type="button"
          onClick={() => {
            showDialog({
              mode: 'info',
              title: 'This price point is in use.',
              children: (
                <>
                  You cannot archive this price point until you remove it from your{' '}
                  <a class="text-indigo-600" href={`/manage/courses/${courseId}/salespage`}>
                    sales page
                  </a>
                  .
                </>
              ),
              hideCancel: true,
              confirmButtonText: 'OK',
            });
          }}
        >
          <IcoTrash />
          <span>Archive</span>
        </BtnPreWarning>
      )}
      {!price.isInSalesPage && (
        <ArchiveToggle
          isEnabled={price.isEnabled}
          itemName="price point"
          onToggle={async () => {
            try {
              const isEnabled = !price.isEnabled;
              await store.updatePrice({
                productId: price.productId,
                priceId: price.id,
                isEnabled,
              });
              updatePrice(price.id, (p) => ({ ...p, isEnabled }));
            } catch (err) {
              showError(err);
            }
          }}
        />
      )}
    </div>
  );

  return (
    <div class="flex-grow p-4 md:p-8">
      <header class="flex justify-between">
        <ItemTitle
          ico={<IcoCreditCard class="w-12 h-12 bg-green-400 rounded-full text-white p-2" />}
          title={price.name}
          subtitle={
            <div class="flex flex-col space-y-2">
              <AvailableDates item={price} />
              <span class="space-x-2">
                <span>
                  {fmt.price(price)}{' '}
                  {fmt.priceSuffix({
                    item: price,
                    intl,
                  })}
                </span>

                {!!price.freeTrialPeriod && (
                  <>
                    <span>•</span>
                    <span>{price.freeTrialPeriod} day free trial</span>
                  </>
                )}

                {price.allowStripe && (
                  <>
                    <span>•</span>
                    <span>Stripe</span>
                  </>
                )}

                {price.allowPaypal && (
                  <>
                    <span>•</span>
                    <span>PayPal</span>
                  </>
                )}

                {price.isGiftable && (
                  <>
                    <span>•</span>
                    <span>Giftable</span>
                  </>
                )}

                {!!price.maxPurchases && (
                  <>
                    <span>•</span>
                    <span>
                      {price.maxPurchases} {pluralize('seat', price.maxPurchases)}
                    </span>
                  </>
                )}

                {price.accessDuration && price.accessDuration > -1 && (
                  <>
                    <span>•</span>
                    <span>
                      Access lasts {dtfmt.minutesToTimeString(price.accessDuration, intl)}
                    </span>
                  </>
                )}
              </span>
            </div>
          }
        />
        <nav class="hidden md:block ml-4 space-x-2 whitespace-nowrap">{editButtons}</nav>
        <Dropdown
          hideDownIcon
          class="md:hidden text-gray-500 dark:text-gray-200"
          bg="bg-gray-100"
          renderMenu={() => <div class="flex flex-col p-2 space-y-2">{editButtons}</div>}
        >
          <span class="inline-flex md:hidden rounded-full p-1 hover:bg-gray-100 dark:hover:bg-gray-700">
            <IcoDotsHorizontal class="w-6 h-6" />
          </span>
        </Dropdown>
      </header>
      {!price.isUpsellOffer && (
        <PmtTabs
          selectedTab={selectedTab}
          tabs={[
            tab('links', 'Links'),
            tab('signups', `Signups (${price.numSignups || 0})`),
            tab('coupons', `Coupons (${price.applyTo?.length || 0})`),
            showUpsells ? tab('upsells', `Upsell`) : undefined,
            (price.productId === CORE_PRODUCT_ID ||
              price.productId === CORE_INSTANT_COURSES_PRODUCT_ID) &&
            user.level === 'superadmin'
              ? tab('metadata', 'Metadata')
              : undefined,
          ]}
        />
      )}
      {price.isUpsellOffer && (
        <Note>
          This is an upsell offer price, and can be managed on the{' '}
          <a href={`/upsells/offer-by-price?price=${price.id}`}>upsell offer</a> page.
        </Note>
      )}
      {selectedTab === 'upsells' && showUpsells && (
        <UpsellsTab price={price} updatePrice={(fn) => updatePrice(price.id, fn as any)} />
      )}
      {selectedTab === 'links' && (
        <LinksTab>
          <div class="inline-flex flex-col gap-8">
            <div class="p-6 rounded-md border">
              <div class="grid grid-cols-2 gap-4">
                <strong>Default checkout page</strong>
                <span class="ml-2">
                  {fmt.price(price)}{' '}
                  {fmt.priceSuffix({
                    item: price,
                    intl,
                  })}
                </span>
                <a
                  class="flex items-center my-1"
                  href={checkoutUrl({ priceId: price.id })}
                  rel="noreferrer"
                  target="_blank"
                >
                  Checkout page
                  <IcoExternalLink class="w-4 h-4 opacity-75 ml-2" />
                </a>
                <BtnCopy
                  value={fullyQualify(checkoutUrl({ priceId: price.id }))}
                  class="text-center justify-center border border-gray-300 hover:bg-gray-100 active:bg-gray-200 hover:border-transparent rounded-md"
                  margin="m-0"
                >
                  Copy checkout link
                </BtnCopy>
              </div>
            </div>

            {price.upsellId && (
              <div class="p-6 rounded-md border">
                There is an upsell applied to this price.
                <a
                  class="flex items-center my-1"
                  href={`/upsells/${price.upsellId}?tab=applyto`}
                  rel="noreferrer"
                  target="_blank"
                >
                  View upsell
                  <IcoExternalLink class="w-4 h-4 opacity-75 ml-2" />
                </a>
              </div>
            )}

            {price.applyTo?.map((coupon) => (
              <CouponLink
                key={coupon.id}
                title="With coupon"
                subtitle={coupon.code}
                price={price}
                coupon={coupon}
                checkoutUrl={checkoutUrl}
                showDisabled={showDisabled}
              />
            ))}
          </div>
        </LinksTab>
      )}
      {selectedTab === 'signups' && (
        <SignupsTab
          mode="coupon"
          productId={price.productId}
          priceId={price.id}
          userHref={signupUrl}
          priceHref={priceUrl}
          couponHref={couponUrl}
          numPendingGifts={price.numPendingGifts}
        />
      )}
      {selectedTab === 'coupons' && price.applyTo && (
        <CouponsTab price={price} onSave={onCouponsChanged} />
      )}
      {selectedTab === 'metadata' && (
        <MetadataTab
          metadata={price.metadata || {}}
          hideTier={price.productId !== CORE_PRODUCT_ID}
          onSave={async (metadata) => {
            await store.updatePrice({
              priceId: price.id,
              productId: price.productId,
              metadata,
            });
            showToast({
              type: 'ok',
              title: 'Saved',
              message: `Saved changes.`,
            });
            updatePrice(price.id, (p) => ({ ...p, metadata }));
          }}
        />
      )}
    </div>
  );
}

function PricesPage(props: Props) {
  const intl = useIntl();
  const { priceId } = useRouteParams();
  const productId = props.product.id;
  const [state, setState] = useState(() => {
    const priceIds = props.prices.map((x) => x.id);
    return {
      isLoading: false,
      prices: indexBy((x) => x.id, props.prices),
      showDisabled: false,
      priceIds,
    };
  });
  /*
   * Selectors / derived state
   */
  const price = state.prices[priceId];

  const priceList = state.priceIds
    .map((id) => state.prices[id])
    .filter((x) => (!!x && state.showDisabled ? x : x.isEnabled))
    .map((p) => ({
      id: p.id,
      title: p.name,
      isUpsellOffer: p.isUpsellOffer,
      upsellId: p.upsellId,
      subtitle: `${fmt.price(p)} ${fmt.priceSuffix({
        item: p,
        intl,
      })}`,
      suffix: p.upsellId ? 'Upsell applied' : '',
      numPurchases: p.numSignups || 0,
      isEnabled: p.isEnabled,
      isSelected: p.id === price?.id,
      href: props.priceUrl({ priceId: p.id }),
    }));

  const reloadPrice = async (priceId: string) => {
    try {
      const price = await store.loadPrice({
        productId,
        priceId,
        courseId: props.courseId,
      });
      if (price) {
        setState((s) => ({
          ...s,
          prices: { ...s.prices, [price.id]: price },
          priceIds: uniqueBy((x) => x, [...s.priceIds, price.id]),
        }));
      }
    } catch (err) {
      showError(err);
    }
  };

  /*
   * Effects
   */
  useAsyncEffect(async () => {
    // We have a price in our URL, but we haven't fetched it yet.
    // It belongs to a later page in our paginated list, so we'll
    // fetch it out of band.
    if (priceId && !price?.applyTo) {
      await reloadPrice(priceId);
    }
  }, [priceId, price?.applyTo]);

  useEffect(() => {
    if (!price && priceList.length) {
      router.rewrite(priceList[0].href);
    }
  }, [price]);

  const hasDisabled = props.prices.some((p) => !p.isEnabled);
  const [offerPrices, normalPrices] = partition((x) => !!x.isUpsellOffer, priceList);
  const [showOffers, setShowOffers] = useState(() => offerPrices.some((x) => x.id === priceId));

  return (
    <div class="flex flex-col md:flex-row flex-grow">
      <NavBar itemName="price point" newItemHref={props.newUrl}>
        <div class="flex flex-col gap-2 pt-4">
          {hasDisabled && (
            <Checkbox
              wrapperClass="p-2"
              onClick={() => setState((s) => ({ ...s, showDisabled: !s.showDisabled }))}
              checked={state.showDisabled}
            >
              Show archived price points
            </Checkbox>
          )}
          {normalPrices.map((x) => (
            <ListItem key={x.id} {...x} />
          ))}
          {offerPrices.length > 0 && (
            <section class="flex flex-col border rounded-2xl mt-4 overflow-hidden">
              <button
                class="px-3 p-2 flex items-center justify-between font-semibold hover:bg-gray-50"
                onClick={() => setShowOffers((x) => !x)}
              >
                <span>
                  {offerPrices.length} {pluralize('upsell offer price point', offerPrices.length)}
                </span>
                {showOffers ? <IcoChevronUp /> : <IcoChevronDown />}
              </button>
              {showOffers && offerPrices.map((x) => <ListItem key={x.id} {...x} noRounding />)}
            </section>
          )}
        </div>
      </NavBar>
      {price && (
        <PriceDetail
          key={price.id}
          priceUrl={props.priceUrl}
          courseId={props.courseId}
          couponUrl={props.couponUrl}
          checkoutUrl={props.checkoutUrl}
          signupUrl={props.signupUrl}
          stripeMetadata={props.stripeMetadata}
          supportsPaypal={props.supportsPaypal}
          supportsStripe={props.supportsStripe}
          price={price}
          showDisabled={state.showDisabled}
          updatePrice={(id, f) => {
            setState((s) => ({
              ...s,
              prices: { ...s.prices, [id]: f(s.prices[id]) },
            }));
          }}
          onCouponsChanged={(x) => {
            reloadPrice(x.priceId);
          }}
        />
      )}
    </div>
  );
}

function NewPricePage(props: Props) {
  return (
    <div class="flex-grow p-4 md:p-8 max-w-4xl mx-auto">
      <NewPriceForm
        courseId={props.courseId}
        cancelHref={props.pricesUrl}
        supportsStripe={props.supportsStripe}
        supportsPaypal={props.supportsPaypal}
        createPrice={async (opts) => {
          const price = await store.createPrice({
            ...(opts as any),
            isCorePrice: props.isCorePrice,
            productId: props.product.id,
            stripeMetadata: props.stripeMetadata,
          });
          location.assign(props.priceUrl({ priceId: price.id }));
          return price;
        }}
      />
    </div>
  );
}

export function ManagePricesPage(props: Props) {
  const { priceId } = useRouteParams();
  const hasPrices = !!props.prices.length;

  if (priceId === 'new') {
    return NewPricePage(props);
  }
  if (hasPrices) {
    return PricesPage(props);
  }
  return EmptyPriceScreen({
    newUrl: props.newUrl,
    supportsPaypal: props.supportsPaypal,
    supportsStripe: props.supportsStripe,
  });
}
