import { RouteProps, router } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { AppRoute } from 'client/lib/app-route/types';
import { StudentPage } from '@components/student-page';
import { StudentMeetingWithRecordings } from 'server/types';
import { MeetingDetail } from './meeting-detail';
import { useEffect, useState } from 'preact/hooks';
import { meetingUrl as manageMeetingUrl } from '../guide-course-meetings/urls';
import { showError } from '@components/app-error';
import { LoadingIndicator } from '@components/loading-indicator';
import { groupMeetingsByStatus } from 'shared/meeting-utils';
import { useCurrentUser } from '@components/router/session-context';
import { NavBar } from './nav-bar';
import { EmptyPage } from '@components/empty-page';
import { IcoPresentation } from '@components/icons';
import { URLS } from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';

type Data = Awaited<ReturnType<typeof load>>;

function Page(props: RouteProps<Data>) {
  const { course, meetings } = props.data;
  const { meetingId } = props.route.params;
  const user = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<StudentMeetingWithRecordings | undefined>(
    undefined,
  );
  const { past, upcoming } = groupMeetingsByStatus(meetings);
  const intl = useIntl();

  // Go to the first available meeting, if none was requested.
  useEffect(() => {
    if (!meetingId && meetings.length > 0) {
      const firstMeeting = upcoming[0] || past[0];
      router.rewrite(
        URLS.student.meeting({
          course,
          meeting: firstMeeting,
        }),
      );
    }
  }, [meetingId, course]);

  useEffect(() => {
    async function fetchSelectedMeeting() {
      setIsLoading(true);
      try {
        const result = await rpx.meetings.getStudentMeeting({
          courseId: course.id,
          meetingId,
        });
        setSelectedMeeting(result);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    }

    if (!meetingId) {
      setSelectedMeeting(undefined);
    } else if (meetingId !== selectedMeeting?.id) {
      fetchSelectedMeeting();
    }
  }, [meetingId]);

  return (
    <StudentPage
      course={course}
      accessLevel={course.accessLevel}
      currentLink="meetings"
      editLink={{
        url: manageMeetingUrl({
          courseId: course.id,
          meetingId,
        }),
      }}
      sideNavContent={!!meetings.length && <NavBar past={past} upcoming={upcoming} />}
      documentTitle={selectedMeeting?.title}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && !meetings.length && (
        <EmptyPage
          Ico={IcoPresentation}
          title={intl('No meetings yet')}
          description={intl('There are no meetings scheduled.')}
          centeredDescription
        />
      )}
      {selectedMeeting && (
        <MeetingDetail
          key={selectedMeeting.id}
          course={course}
          meeting={selectedMeeting}
          isGuide={course.guide.id === user?.id}
        />
      )}
    </StudentPage>
  );
}

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, meetings] = await Promise.all([
    rpx.courses.getStudentCourse({ id: courseId }),
    rpx.meetings.getStudentMeetings({ courseId }),
  ]);

  return {
    course,
    meetings,
  };
}

router.add({
  url: 'courses/:courseId/meetings',
  load,
  render: Page,
  authLevel: 'student',
});

router.add({
  url: 'courses/:courseId/meetings/:meetingId',
  load,
  render: Page,
  authLevel: 'student',
});
