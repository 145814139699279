/**
 * This module contains the logic for managing asset uploads within the editor.
 */
import quikpik from 'quikpik';
import { showError } from '@components/app-error';
import { makeUploader, UploadState } from 'client/lib/rpx-client';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { showModalForm } from '@components/modal-form';
import { BaseDialog } from '@components/dialog';
import { BtnPrimary } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { IcoChevronDown, IcoChevronUp } from '@components/icons';

type QuikpikOpts = Parameters<typeof quikpik>[0];

export interface FilePickerResult {
  fileId: UUID;
  filePath: string;
  publicUrl: string;
  type: string;
  name: string;
}

/**
 * Wraps the quikpik file picker with a more convenient, app-specific signature.
 * quikpik().then(({ fileId, url }) => doSomething(fileId, url))
 * @param opts
 */
export async function filepicker(
  opts: Omit<QuikpikOpts, 'upload'> & {
    isPublic?: boolean;
  } = {},
) {
  const picker = quikpik({
    sources: ['filepicker', 'takephoto', 'takeaudio', 'takevideo'],
    ...opts,
    customProgress: true,
    upload({ files, onProgress }) {
      // TODO: Add support for multiple-uploads, possibly as a separate function.
      const file = files[0];

      if (opts.customProgress) {
        const uploader = makeUploader({
          file,
          isPublic: opts.isPublic,
          onProgress({ status, progress }) {
            onProgress(progress, status);
          },
        });
        return { promise: uploader.upload(), cancel: uploader.abort };
      }

      return {
        promise: showModalForm<FilePickerResult | undefined>(({ resolve }) => (
          <BaseDialog onClose={resolve} contentWidth blurBg={false}>
            <section class="p-8 w-2xl max-w-full">
              <UploaderUI file={file} onClose={resolve} onComplete={resolve} />
            </section>
          </BaseDialog>
        )),
        cancel() {},
      };
    },
  });

  try {
    return await picker;
  } catch (err) {
    picker.cancel();
    showError({
      error: 'Failed to upload file. Please try again.',
    });
    throw err;
  }
}

export function UploaderUI({
  file,
  onClose,
  onComplete,
}: {
  file: File;
  onClose(): void;
  onComplete(result: FilePickerResult | undefined): void;
}) {
  const [state, setState] = useState<UploadState & { showError?: boolean; error?: Error }>({
    status: 'init',
    progress: 0,
  });
  const intl = useIntl();
  const uploader = useMemo(
    () =>
      makeUploader({
        file,
        onProgress({ status, progress }) {
          setState((s) => ({ ...s, status, progress }));
        },
      }),
    [file],
  );
  const upload = useCallback(
    async function upload() {
      try {
        setState((s) => ({ ...s, status: 'init', showError: false, error: undefined }));
        const result = await uploader.upload();
        onComplete(result);
      } catch (err) {
        setState((s) => ({ ...s, status: 'failed', error: err }));
      }
    },
    [uploader],
  );
  useEffect(() => {
    upload();
    // If the upload component is unloaded, we'll abort the upload. If the upload is alredy
    // completed, this is a noop.
    return () => uploader.abort();
  }, [uploader]);

  return (
    <div class="flex flex-col gap-2">
      <header class="font-semibold flex justify-between gap-4 flex-grow whitespace-nowrap">
        <span>
          {state.status === 'init' && intl('Initializing...')}
          {state.status === 'uploading' && intl('Uploading...')}
          {state.status === 'finishing' && intl('Finalizing...')}
          {state.status === 'failed' && intl('Upload failed!')}
          {state.status === 'done' && intl('Complete!')}
        </span>
        <span>
          {state.status !== 'failed' && <>{Math.round(state.progress)}%</>}
          {state.status === 'failed' && state.error && (
            <button
              type="button"
              class="text-indigo-600 ml-2 inline-flex gap-2 items-center"
              onClick={() => setState((s) => ({ ...s, showError: !s.showError }))}
            >
              {intl('Show error')} {state.showError ? <IcoChevronUp /> : <IcoChevronDown />}
            </button>
          )}
        </span>
      </header>
      <section class="w-full overflow-hidden text-ellipsis text-gray-500">{file.name}</section>
      {state.status !== 'failed' && (
        <div class="relative h-2 rounded-full overflow-hidden">
          <div
            class={`absolute inset-y-0 left-0 bg-green-400 rounded-full w-full transition-all ${
              state.status === 'done' ? '' : 'animate-pulse'
            }`}
            style={`width: ${state.progress}%`}
          ></div>
        </div>
      )}
      {state.status === 'failed' && (
        <>
          {state.error && state.showError && (
            <div class="text-gray-500 overflow-auto max-h-80">
              <div>{state.error.message}</div>
              <pre>{state.error.stack}</pre>
            </div>
          )}
          <footer class="flex gap-4 items-center mt-4">
            <BtnPrimary class="rounded-full p-2 px-4" onClick={upload}>
              {intl('Retry')}
            </BtnPrimary>
            <button
              type="button"
              class="rounded-full p-2 px-4 hover:bg-gray-100"
              onClick={() => onClose()}
            >
              {intl('Cancel')}
            </button>
          </footer>
        </>
      )}
    </div>
  );
}
