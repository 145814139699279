import { showError } from '@components/app-error';
import { router } from '@components/router';
import { useEffect } from 'preact/hooks';

type RedirectError = Parameters<typeof showError>[0];

// Flash an error message and redirect to passed URL
export function redirectWithFlashError(toUrl = '/', err: RedirectError) {
  localStorage.flashError = JSON.stringify(err);
  router.goto(toUrl);
}

// Add hook to check for flashed error messages
export function useFlashError() {
  useEffect(() => {
    const errStr = localStorage.flashError;
    if (!errStr) {
      return;
    }
    try {
      // Clear it so that a refresh doesn't display it again.
      delete localStorage.flashError;
      showError(JSON.parse(errStr) as RedirectError);
    } catch (err) {
      console.error(err);
    }
  }, []);
}
