import { FullCourse, StudentMeetingWithRecordings } from 'server/types';
import { fmtFullTime, fullTimeFormat, minutesToTimeString } from 'shared/dateutil';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { Case } from '@components/conditional';
import { Pill } from '@components/pill';
import { MeetingActions } from './meeting-actions';
import { IcoCalendar, IcoClock, IcoInfo, IcoPlay, IcoSlides } from '@components/icons';
import { AddToCalendarButtons } from './add-to-calendar-buttons';
import { RecordingPlayer } from '@components/recording-player';
import { MeetingTypeIcons } from './nav-bar';
import { useIntl } from 'shared/intl/use-intl';
import { useMeetingType } from '@components/course-overview/use-meeting-type';
import { useImageUrl } from 'client/utils/cdn';
import { isPDF } from 'shared/media';
import { useMeetingStatus } from 'client/lib/hooks/use-meeting-status';
import dayjs from 'dayjs';
import { useInterval } from 'client/utils/use-interval';
import { useState } from 'preact/hooks';
import { useCurrentUser } from 'client/lib/auth';

interface Props {
  course: Pick<FullCourse, 'level' | 'hideCaptions'>;
  meeting: StudentMeetingWithRecordings;
  isGuide: boolean;
}

function PDFViewer({ file }: { file: NonNullable<StudentMeetingWithRecordings['slidesFile']> }) {
  const url = useImageUrl(file.url);

  return (
    <iframe class="bg-gray-800 w-full rounded aspect-[8/12]" src={`/pdf.html?pdf=${url}`}></iframe>
  );
}

export function MeetingDetail({ course, meeting, isGuide }: Props) {
  const user = useCurrentUser();
  const status = useMeetingStatus(meeting);
  const intl = useIntl();
  const [timeLeft, setTimeLeft] = useState(() => dayjs(meeting.scheduledAt).toNow(true));

  const meetingTypes = useMeetingType();

  useInterval(
    () => {
      setTimeLeft(dayjs(meeting.scheduledAt).toNow(true));
    },
    // Run this block on each 30 seconds
    status === 'scheduled' || status === 'aboutToStart' ? 30000 : undefined,
  );

  return (
    <div class="px-10 mx-auto xl:mx-0 lg:min-w-readable max-w-readable dark:text-gray-200">
      <header class="pb-5 border-b border-gray-200 dark:border-gray-700">
        <div class="flex flex-col align-center mb-6 space-y-3">
          <div>
            {status === 'draft' && <Pill color="yellow">{intl('Draft')}</Pill>}
            {status === 'ended' && <Pill color="red">{intl('COMPLETED')}</Pill>}
            {status === 'inProgress' && (
              <span class="inline-block animate-live">
                <Pill color="green">{intl('ON AIR')}</Pill>
              </span>
            )}
            {status === 'scheduled' && <Pill color="yellow">{intl('NOT STARTED')}</Pill>}
            {status === 'aboutToStart' && <Pill color="yellow">{intl('ABOUT TO START')}</Pill>}
          </div>
          <div class="font-display text-3xl tracking-tight text-gray-900 dark:text-gray-200 mb-9">
            <h1 class="!mb-0">{meeting.title}</h1>
          </div>
        </div>
        <dl class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-6 my-6 text-gray-500 dark:text-gray-200">
          <dd
            class="flex items-center space-x-2 relative"
            data-tooltip={fmtFullTime(meeting.scheduledAt, {
              includeTimezone: true,
              timeZone: user?.timezone,
            })}
          >
            <IcoCalendar />
            <span>
              {meeting.scheduledAt
                ? fullTimeFormat.format(meeting.scheduledAt)
                : intl('Not scheduled yet')}
            </span>
          </dd>
          <dd class="flex items-center space-x-2">
            <IcoClock />
            <span>{minutesToTimeString(meeting.durationMinutes, intl)}</span>
          </dd>
          <dd class="flex items-center space-x-2">
            {MeetingTypeIcons[meeting.type]}
            <span>{meetingTypes[meeting.type]}</span>
          </dd>
        </dl>
        <Case when={status !== 'ended'}>
          <div class="w-full">
            <MeetingActions meeting={meeting} isGuide={isGuide} />
            <p class="inline-flex flex-col lg:flex-row items-center lg:items-start mt-6 text-sm text-zinc-500 dark:text-gray-400 space-y-2 lg:space-y-0">
              <div class="flex flex-col">
                <Case when={status === 'scheduled' || status === 'aboutToStart'}>
                  <div class="flex gap-2">
                    <span>
                      <IcoInfo class="size-5" />
                    </span>
                    <span>
                      {isGuide
                        ? intl(
                            'Guides can join the meeting anytime. Students will be able to join the meeting after the scheduled start time.',
                          )
                        : intl('You will be able to join the meeting in {timeLeft:string}.', {
                            timeLeft,
                          })}
                    </span>
                  </div>
                </Case>
                {(meeting.type === 'videoconference' || meeting.type === 'presentation') && (
                  <span class="block mt-2">
                    <a
                      class={`${status === 'scheduled'} text-zinc-500 underline`}
                      href="https://support.ruzuku.com/article/750-joining-a-meeting"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {intl('Need help joining the meeting?')}
                    </a>
                  </span>
                )}
              </div>
            </p>
          </div>
        </Case>
      </header>

      {status === 'ended' && meeting.slidesFile && isPDF(meeting.slidesFile.type) && (
        <div class="my-6">
          <header class="flex items-center font-medium text-gray-700 dark:text-gray-200 mb-6">
            <IcoSlides class="h-5 w-5 text-gray-500 dark:text-gray-200 mr-1" />
            <span>{intl('Slides')}</span>
          </header>

          <div class="border border-gray-400 rounded-md">
            <PDFViewer file={meeting.slidesFile} />
          </div>
        </div>
      )}

      <Case
        when={meeting.recordings.length > 0}
        fallback={
          <Case when={status === 'ended' && meeting.type !== 'external' && meeting.recordMeeting}>
            <div class="pb-5 border-b border-gray-200 mt-6">
              <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
                  {intl('Recordings')}
                </h3>
              </div>
              <p class="mt-2 text-sm text-gray-500 dark:text-gray-200">
                {intl(
                  "You'll soon be able to play the meeting recording files here if the event was recorded by the guide.",
                )}
              </p>
            </div>
          </Case>
        }
      >
        <div class="py-8 border-b">
          <header class="flex items-center font-medium text-gray-700 dark:text-gray-200 mb-6">
            <IcoPlay class="h-5 w-5 text-gray-500 dark:text-gray-200 mr-1" />
            <span>{intl('Session Recordings')}</span>
          </header>
          <div class="space-y-6">
            {meeting.recordings.map((recording) => (
              <RecordingPlayer
                key={recording.id}
                recording={recording}
                allowDownload={recording.allowDownload}
                hideCaptions={course.level === 'student' && course.hideCaptions}
              />
            ))}
          </div>
        </div>
      </Case>
      {meeting.description && (
        <ReadonlyMinidoc class="pt-8 prose-lg" id={meeting.id} content={meeting.description} />
      )}
      {status === 'scheduled' && (
        <footer class="mt-8">
          <AddToCalendarButtons meeting={meeting} />
        </footer>
      )}
    </div>
  );
}
