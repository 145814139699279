/**
 * Loads and displays a paginated table of the payment logs for a purchase.
 */
import { PriceRow, PurchaseRow, Currency, LogType } from 'server/types';
import { PaginatedPane, usePaginatedCursor } from './paginated-list';
import * as fmt from 'shared/payments/fmt';
import { rpx } from 'client/lib/rpx-client';
import { fmtFullTime } from 'shared/dateutil';
import { stripeHref } from './stripe-link';
import { IcoDotsHorizontal, IcoExternalLink } from '@components/icons';
import { showProcessingModal } from '@components/processing-modal';
import { showToast } from '@components/toaster';
import { showError } from '@components/app-error';
import * as stripeIds from 'shared/payments/stripe';
import { intl, useIntl } from 'shared/intl/use-intl';
import { Dropdown, MenuItem } from '@components/dropdown';
import { showDialog } from '@components/dialog';

const store = rpx.paymentHistory;

function describeLog(type: LogType) {
  switch (type) {
    case 'trial':
      return intl('trial');
    case 'payment_plan_complete':
      return intl('plan complete');
    case 'seller_canceled':
      return intl('canceled plan');
    case 'subscriber_canceled':
      return intl('plan canceled by subscriber');
    case 'subscription_canceled':
      return intl('plan canceled');
    case 'subscription_canceling':
      return intl('plan canceling');
    case 'subscription_activated':
      return intl('plan activated');
    case 'payment_failure':
      return intl('payment failed');
    default:
      return type;
  }
}

export function PaymentHistory(props: {
  purchaseId: string;
  priceId: string;
  paymentType: PriceRow['paymentType'];
  purchaseStatus: PurchaseRow['status'];
  asSeller: boolean;
  onReset(): void;
}) {
  const intl = useIntl();
  const [logs, loadNext] = usePaginatedCursor((cursor) =>
    store.loadLogs({
      purchaseId: props.purchaseId,
      cursor,
    }),
  );

  const refund = async (item: (typeof logs)['data'][0]) => {
    const confirmed = await showDialog({
      mode: 'warn',
      title: intl('Refund this payment?'),
      children: intl('This cannot be undone.'),
      confirmButtonText: intl('Issue refund'),
    });
    if (!confirmed) {
      return;
    }
    try {
      await showProcessingModal({
        title: intl('Issuing refund...'),
        promise: store.refund({ entryId: item.id }),
      });
      showToast({
        title: intl('Refund issued.'),
        type: 'ok',
        message: intl('The refund succeeded.'),
      });
      props.onReset();
    } catch (err) {
      showError(err);
    }
  };

  const toggleIgnored = async (item: (typeof logs)['data'][0]) => {
    const ignored = !item.ignorePayment;
    const numPaymentsRemaining = await showProcessingModal({
      title: 'Loading...',
      promise: store.getNumPaymentsRemaining({ purchaseId: props.purchaseId }),
    });
    const isCompleting = numPaymentsRemaining === 1 && !ignored;
    const confirm = () => {
      if (isCompleting) {
        return showDialog({
          mode: 'warn',
          title: 'Complete this payment plan?',
          children: 'Counting this payment will complete the payment plan.',
          confirmButtonText: 'Complete payment plan',
        });
      }
      if (ignored) {
        return showDialog({
          mode: 'ok',
          title: 'Ignore this payment?',
          children: `This payment won't count towards the completion of the payment plan.`,
          confirmButtonText: 'Ignore payment',
        });
      }
      return showDialog({
        mode: 'ok',
        title: 'Include this payment?',
        children: `This payment will count towards the completion of the payment plan.`,
        confirmButtonText: 'Include payment',
      });
    };
    if (await confirm()) {
      try {
        await showProcessingModal({
          title: isCompleting
            ? `Completing payment plan...`
            : ignored
            ? 'Ignoring payment...'
            : 'Including payment...',
          promise: store.setIgnored({ entryId: item.id, ignored, isCompleting }),
        });
        if (isCompleting) {
          // In this case, we really want to make sure the cancellation and all
          // related status changes are properly reflected.
          location.reload();
        } else {
          props.onReset();
        }
      } catch (err) {
        showError(err);
      }
    }
  };

  return (
    <PaginatedPane
      state={logs}
      loadNext={loadNext}
      render={() => (
        <div class="max-w-(screen-16)">
          <table class="mb-4 text-sm table-auto w-full dark:bg-transparent">
            <tr>
              <th class="dark:bg-transparent text-inherit">{intl('Amount')}</th>
              <th class="dark:bg-transparent text-inherit">{intl('Description')}</th>
              <th class="dark:bg-transparent text-inherit">{intl('Date')}</th>
            </tr>
            {logs.data.map((l) => (
              <tr key={l.id}>
                <td class="table-cell font-bold">
                  {!!l.currency &&
                    fmt.price({
                      priceInCents: (l.amount || 0) * (l.type === 'refund' ? -1 : 1),
                      currency: l.currency as Currency,
                    })}
                </td>
                <td class="table-cell text-wrap">
                  <div class="flex items-center justify-between">
                    {!l.giftRecipientEmail && (
                      <span>
                        <span class="capitalize">
                          {describeLog(l.type)} {l.message}
                        </span>
                        {l.refunded && (
                          <span class="bg-green-50 border border-green-200 text-green-600 rounded p-0.5 px-1 text-xs lowercase mx-1">
                            {intl('refunded')}
                          </span>
                        )}
                        {props.asSeller && l.ignorePayment && (
                          <span
                            class="relative bg-red-50 border border-red-200 text-red-600 rounded p-0.5 px-1 text-xs mx-1"
                            data-tooltip="This payment does not count towards payment plan completion"
                          >
                            ignored
                          </span>
                        )}
                      </span>
                    )}
                    {!!l.giftRecipientEmail && <span>Gift to {l.giftRecipientEmail}</span>}
                    {!props.asSeller &&
                      (stripeIds.isPaymentIntent(l.stripeId) || stripeIds.isCharge(l.stripeId)) && (
                        <a
                          class="inline-flex items-center space-x-1"
                          target="_blank"
                          rel="noreferrer"
                          href={`/api/stripe-receipt/${l.id}`}
                        >
                          <IcoExternalLink />
                          <span>{intl('View Receipt')}</span>
                        </a>
                      )}
                  </div>
                </td>
                <td class="table-cell">
                  <div class="flex justify-between">
                    <span>{fmtFullTime(l.logDate || l.createdAt)}</span>
                    {props.asSeller && l.stripeId && (
                      <Dropdown
                        hideDownIcon
                        triggerClass="inline-flex items-center gap-2"
                        renderMenu={() => (
                          <div class="flex flex-col px-2 pt-2">
                            <MenuItem href={stripeHref(l.stripeId)}>
                              <span class="mr-1">{intl('View in Stripe')}</span>
                              <IcoExternalLink />
                            </MenuItem>
                            {l.type === 'payment' && (
                              <MenuItem onClick={() => refund(l)}>{intl('Issue refund')}</MenuItem>
                            )}
                            {
                              // If we have an active payment plan, we'll allow ignoring / unignoring
                              // payments which were made for the current purchase's price. Note: it's
                              // possible that the student buys "PlanA", cancels, then re-purchases.
                              // In this case, we'll have two separate purchase records, but payments
                              // for each count against the active, since it was for the same plan.
                              l.type === 'payment' &&
                                props.paymentType === 'paymentplan' &&
                                props.purchaseStatus === 'active' &&
                                l.priceId === props.priceId && (
                                  <MenuItem onClick={() => toggleIgnored(l)}>
                                    {l.ignorePayment ? 'Undo ignore' : 'Ignore'} payment
                                  </MenuItem>
                                )
                            }
                          </div>
                        )}
                      >
                        <span>Manage</span>
                        <IcoDotsHorizontal class="rotate-90 w-4 h-4" />
                      </Dropdown>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}
      renderEmpty={() => <p>{intl('No payment history.')}</p>}
    />
  );
}
