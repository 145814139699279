import { router } from '@components/router';
import * as EditPage from './guide-course-salespage';

// This is a private page, but we're leaving
// the route definition public, because guides
// tend to share this URL when they're trying
// to share the salespage, so we leave the authorization
// logic up to the load function and it's RPCs.
['manage/courses/:courseId/salespage', 'manage/products/:courseId/salespage'].map((url) =>
  router.add({
    isPublic: true,
    url,
    render: EditPage.Page,
    load: EditPage.load,
  }),
);
