/**
 * This file contains helpers for dealing with paginated lists of payment
 * system records.
 */
import type { ComponentChildren, h } from 'preact';
import { Fragment } from 'preact';
import { PageNavLink } from '@components/page-nav-bar';
import { IcoUsers } from '@components/icons';
import { BtnSecondary } from '@components/buttons';
import { useState } from 'preact/hooks';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { showError } from '@components/app-error';
import { Spinner } from '@components/spinner';
import { Checkbox } from '@components/checkbox';

interface Props {
  isSelected: boolean;
  href: string;
  title: string;
  subtitle: string;
  suffix?: string;
  numPurchases: number;
  maxPurchases?: number;
  isEnabled: boolean;
  noRounding?: boolean;
}

export function ListItem({
  isSelected,
  title,
  subtitle,
  href,
  suffix,
  numPurchases,
  maxPurchases,
  isEnabled,
  noRounding,
}: Props) {
  return (
    <PageNavLink isSelected={isSelected} href={href} noRounding={noRounding}>
      <span class="grow flex flex-col py-2 gap-1">
        <span class="flex justify-between items-center">
          <strong>{title}</strong>
          <span class="opacity-75 inline-flex items-center ml-4">
            <IcoUsers class="w-4 h-4 mr-1" />
            {numPurchases || 0}
            {!!maxPurchases && ` of ${maxPurchases}`}
          </span>
        </span>
        <span class="flex flex-col gap-2">
          <span class="opacity-60">{subtitle}</span>
          {(suffix || !isEnabled) && (
            <span class="inline-flex gap-2">
              {suffix && (
                <span class="bg-indigo-100 p-1 px-2 rounded text-indigo-600 text-xs">{suffix}</span>
              )}
              {!isEnabled && (
                <span class="bg-gray-200 p-1 px-2 rounded text-gray-600 text-xs">archived</span>
              )}
            </span>
          )}
        </span>
      </span>
    </PageNavLink>
  );
}

export function PaginatedList<T extends { id: string; isEnabled?: boolean }>({
  data,
  hasMore,
  hasDisabled,
  RenderItem,
  loadMore,
  wrapper,
}: {
  data: T[];
  hasMore: boolean;
  hasDisabled: boolean;
  RenderItem(item: T): h.JSX.Element | null;
  loadMore(opts: { startingAfter?: string; includeDisabled: boolean }): Promise<unknown>;
  wrapper?(props: { children: ComponentChildren }): h.JSX.Element | null;
}) {
  const [isLoading, setIsLoading] = useState(false);
  // Indicates whether or not we should fetch disabled items.
  const [includeDisabled, setIncludeDisabled] = useState(!hasDisabled);
  // The cursor in the paginated dataset we're currently showing / fetching
  // data that comes after this item in the list.
  const [showAfter, setShowAfter] = useState<T | undefined>();
  // When we change "includeDisabled" or "showAfter", we'll load the relevant
  // page of data.
  useDidUpdateEffect(async () => {
    setIsLoading(true);
    try {
      await loadMore({ startingAfter: showAfter?.id, includeDisabled });
    } finally {
      setIsLoading(false);
    }
  }, [includeDisabled, showAfter]);
  const Wrapper = wrapper || Fragment;
  const visibleData = includeDisabled ? data : data.filter((x) => x.isEnabled);
  return (
    <div class="flex flex-col gap-4 pt-4">
      {hasDisabled && (
        <Checkbox
          wrapperClass="p-2"
          onClick={() => {
            // We've change whether or not we're showing diabled items, so we
            // also reset the "showAfter" value so that we end up loading the
            // first page of enabled / disabled items.
            setIncludeDisabled((x) => !x);
            setShowAfter(undefined);
          }}
        >
          Show archived items
        </Checkbox>
      )}
      <Wrapper>
        {visibleData.map((x) => (
          <RenderItem key={x.id} {...x} />
        ))}
      </Wrapper>
      {hasMore && (
        <BtnSecondary onClick={() => setShowAfter(data[data.length - 1])} isLoading={isLoading}>
          Load more
        </BtnSecondary>
      )}
    </div>
  );
}

interface PaginatedData<T> {
  hasMore: boolean;
  data: T[];
}

export interface PaginatedState<T> extends PaginatedData<T> {
  isLoading: boolean;
}

interface PaginatedProps<T extends { id: any }> {
  state: PaginatedState<T>;
  render(items: T[]): null | h.JSX.Element;
  renderEmpty(): null | h.JSX.Element;
  loadNext(): void;
}

type CursorData<T, C> = { data: T[]; cursor?: C };
type CursorLoader<T, C> = (cursor: C | undefined) => Promise<CursorData<T, C>>;

export function usePaginatedCursor<T extends CursorLoader<any, any>>(loader: T) {
  type TItem = Awaited<ReturnType<T>>['data'][0];
  type TCursor = Awaited<ReturnType<T>>['cursor'];
  type TState = {
    isLoading: boolean;
    cursor: TCursor;
    hasMore: boolean;
    data: TItem[];
  };

  const [cursor, setCursor] = useState<TCursor>(undefined);
  const [state, setState] = useState<TState>({
    isLoading: true,
    hasMore: false,
    cursor,
    data: [],
  });
  useAsyncEffect(async () => {
    try {
      setState((x) => ({ ...x, isLoading: true }));
      const res = await loader(cursor);
      setState((x) => ({
        isLoading: false,
        hasMore: !!res.cursor,
        cursor: res.cursor,
        data: cursor ? [...x.data, ...res.data] : res.data,
      }));
    } catch (err) {
      showError(err);
      setState((x) => ({ ...x, isLoading: false }));
    }
  }, [cursor]);

  // Returns the paginated state, and a "loadMore" function
  const result: [typeof state, () => void] = [state, () => setCursor(state.cursor)];
  return result;
}

/**
 * PaginatedPane is a generic pagination component that renders a list of
 * items, as well as a "Load more" button.
 */
export function PaginatedPane<T extends { id: any }>({
  state,
  render,
  renderEmpty,
  loadNext,
}: PaginatedProps<T>) {
  if (!state.data.length && state.isLoading) {
    return (
      <div class="flex items-center justify-center">
        <Spinner class="border-indigo-400" />
      </div>
    );
  }

  if (!state.data.length) {
    return renderEmpty();
  }

  return (
    <>
      {render(state.data)}
      <footer class="mt-4">
        {state.hasMore && (
          <BtnSecondary onClick={loadNext} isLoading={state.isLoading}>
            Load more
          </BtnSecondary>
        )}
      </footer>
    </>
  );
}
