import { Dispatch, StateUpdater, useMemo, useRef, useState } from 'preact/hooks';
import { Calendar } from './calendar';
import { MeetingSummary } from './meeting-summary';
import { DateRange, MeetingDetail } from './types';
import { getDate, weekdayFormatter } from './dateutil';
import { ComponentChildren } from 'preact';
import { TimeSlots } from './time-slots';
import { mkdate } from '@components/date-picker';
import { router } from '@components/router';
import { toQueryString } from 'shared/urls';

// TODO: this is demo only; create the real thing...
function genAvailability(date: Date) {
  const duration = 15; // minutes
  const setTime = (dt: Date, time: string) => {
    const result = new Date(dt);
    const [h, m] = time.split(':').map((s) => parseInt(s, 10));
    result.setHours(h, m, 0, 0);
    return result;
  };
  const addMins = (dt: Date, mins: number) =>
    mkdate((x) => x.setTime(x.getTime() + mins * 1000 * 60), dt);
  const startTime = setTime(date, '09:00');
  const endTime = setTime(date, '17:00');
  const availability: DateRange[] = [];
  let curr = startTime;
  while (curr < endTime) {
    const range: DateRange = {
      start: curr,
      end: addMins(curr, duration),
    };
    if (range.end > endTime) {
      break;
    }
    availability.push(range);
    curr = range.end;
  }
  return availability;
}

function useTransitionTracker({ date }: { date: Date }) {
  const { current } = useRef({
    date,
    animation: 'an-fade-in-left',
  });
  if (current.date > date) {
    current.animation = 'an-fade-in-right';
  } else if (current.date < date) {
    current.animation = 'an-fade-in-left';
  }
  current.date = date;
  return current.animation;
}

function BtnHour(props: { onClick(): void; isSelected: boolean; children: ComponentChildren }) {
  return (
    <button
      type="button"
      class={`${
        props.isSelected ? 'bg-gray-200' : ''
      } px-2 rounded hover:bg-gray-100 transition-all`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export function ScheduleBooking({
  meeting,
  setMeeting,
}: {
  meeting: MeetingDetail;
  setMeeting: Dispatch<StateUpdater<MeetingDetail>>;
}) {
  const [activeDate, setActiveDate] = useState(() => meeting.schedule?.start || getDate());
  const [focusedDate, setFocusedDate] = useState(activeDate);
  const availability = useMemo(() => genAvailability(activeDate), [activeDate]);
  const transition = useTransitionTracker({ date: focusedDate });
  const setHour12 = (hour12: boolean) => setMeeting((x) => ({ ...x, hour12 }));

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-5xl">
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-6 gap-10">
          <section>
            <MeetingSummary {...meeting} />
          </section>
          <section class="lg:col-span-2 border-y sm:border-t-0 py-10 sm:pt-0 lg:p-0 lg:border-0 lg:border-x lg:px-4">
            <Calendar
              activeDate={activeDate}
              transition={transition}
              setActiveDate={(dt) => {
                setActiveDate(dt);
                document.querySelector('.js-time-header')?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
              focusedDate={focusedDate}
              setFocusedDate={setFocusedDate}
            />
          </section>
          <section
            key={activeDate}
            class={`flex flex-col gap-2 an-fade-in-left sm:col-end-3 lg:col-end-auto`}
          >
            <header class="js-time-header flex items-center justify-between gap-4">
              <span class="font-semibold">{weekdayFormatter.format(activeDate)}</span>
              <span class="flex border gap-1 rounded-md p-1">
                <BtnHour isSelected={!!meeting.hour12} onClick={() => setHour12(true)}>
                  12h
                </BtnHour>
                <BtnHour isSelected={!meeting.hour12} onClick={() => setHour12(false)}>
                  24h
                </BtnHour>
              </span>
            </header>
            <div class="flex flex-col grow overflow-auto relative ">
              <section class="lg:absolute inset-x-0 top-0">
                <TimeSlots
                  hour12={!!meeting.hour12}
                  availability={availability}
                  selected={meeting.schedule}
                  onSelect={(range) => {
                    router.goto(
                      toQueryString(
                        {
                          start: range.start.toISOString(),
                          end: range.end.toISOString(),
                        },
                        '?',
                      ),
                    );
                  }}
                />
              </section>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
