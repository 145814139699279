import { mediaToolbarAction, QuikpikOpts } from '@components/media-card';
import { embedHtmlToolbarAction } from '@components/raw-html-card';
import { h, MinidocToolbarAction, on } from 'minidoc-editor';
import { Internationalize } from 'shared/intl';

interface Props {
  id?: string;
  mediaOnly?: boolean;
  compact?: boolean;
  onItemSelect?: () => void;
  triggerHTML?: string;
  intl: Internationalize;
}

interface MenuItemProps {
  text: string;
  icon: string;
  onclick(): void;
}

export const LiteralIconImg = `<svg fill="currentColor" viewBox="0 0 24 24"><path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"/></svg>`;

/**
 * A minidoc toolbar dropdown menu.
 */
export const toolbarDropdown = (props: Props): MinidocToolbarAction => {
  const { mediaOnly, intl, onItemSelect } = props;
  const mediaAction = mediaToolbarAction();
  const defaultTriggerHTML = `<svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      class="w-4 h-4"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
  </svg>
  ${props.compact ? '' : `<span class="hidden lg:inline-block ml-1.5">${intl('Add media')}</span>`}
  ${
    props.compact
      ? ''
      : `<svg class="ml-1 h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
  </svg>`
  }`;

  return {
    id: 'dd_insert',
    label: 'Insert',
    html: `<span class="js-dd-insert inline-flex items-center px-2">${
      props.triggerHTML || defaultTriggerHTML
    }</span>`,
    init(t) {
      embedHtmlToolbarAction.init?.(t);
      mediaAction.init?.(t);
    },
    run(t) {
      const span = t.toolbar.root.querySelector('.js-dd-insert')!;
      const menuItem = ({ icon, text, onclick }: MenuItemProps) => {
        return h('button', {
          class:
            'w-full px-4 py-2 text-left text-sm leading-5 text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900',
          type: 'button',
          role: 'menuitem',
          onclick,
          innerHTML: `<span class="flex items-center">${icon}<span class="ml-2">${text}</span></span>`,
        });
      };
      const btnInsertFile = (text: string, icon: string, opts: QuikpikOpts) =>
        menuItem({
          text,
          icon,
          onclick: () => {
            if (onItemSelect) {
              onItemSelect();
            } else {
              mediaAction.run(t, opts);
            }
          },
        });
      const menu = h(
        'div',
        {
          class:
            'an-fade-down origin-top-right absolute right-0 right-0 top-8 w-56 py-2 rounded-md shadow-2xl z-10 bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-900 flex flex-col',
          role: 'menu',
          'aria-orientation': 'vertical',
          'aria-labelledby': 'options-menu',
          onmousedown: (e: Event) => e.stopPropagation(),
          onclick: (e: Event) => {
            e.stopPropagation();
            menu.remove();
          },
        },
        btnInsertFile(intl('Image'), LiteralIconImg, {
          accept: 'image/*',
          sources: ['filepicker', 'takephoto'],
        }),
        btnInsertFile(
          intl('Video or audio file'),
          `<svg fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>`,
          {
            accept: 'video/*,audio/*',
            sources: ['filepicker', 'takeaudio', 'takevideo'],
          },
        ),
        btnInsertFile(
          intl('File or PDF'),
          `<svg fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" /></svg>`,
          {},
        ),
        !mediaOnly && h('hr'),
        !mediaOnly &&
          menuItem({
            text: intl('HTML or embed code'),
            icon: embedHtmlToolbarAction.html,
            onclick: () => embedHtmlToolbarAction.run(t),
          }),
      );
      span.appendChild(menu);
      const off = on(window, 'mousedown', () => {
        menu.remove();
        off();
      });
    },
  };
};
