import {
  blockDefinitions as baseDefinitions,
  SalesBlockDefinition,
} from '@components/page-builder';
import * as OutlineBlock from './outline-block';
import * as PricingBlock from './pricing-block';
import * as BannerBlock from './banner-block';
import * as CTABlock from './cta-block';

export const blockDefinitions: Record<string, SalesBlockDefinition> = {
  ...baseDefinitions,
  [OutlineBlock.type]: OutlineBlock,
  [PricingBlock.type]: PricingBlock,
  [BannerBlock.type]: BannerBlock,
  [CTABlock.type]: CTABlock,
};
